import React, { useEffect } from 'react'
import { Form as FinalForm } from 'react-final-form'

import { Form } from '../../../../components/Form'
import { getDiff, getFormValidate } from '../../../../utils/form'
import { userAccountSchema } from '../../utils/schemas'
import { sanitizePhone } from '../../../../utils/sanitize'

import { useUserAccount } from './hooks'
import { FormActions, FormFields } from './components'

const initialValues = {
  permissions: [],
  role: 'EMPLOYEE'
}

const validateForm = getFormValidate(userAccountSchema)

export const AccountForm: React.FC = () => {
  const { account, getUserAccount, updateUserAccount } = useUserAccount()

  useEffect(() => {
    getUserAccount()
  }, [])

  function handleSubmit(values: any) {
    if (!account) return

    const { nickname, email, oldPassword, newPassword } = values
    const phone = sanitizePhone(values.phoneFormatted)

    const diff = getDiff<any>(account, {
      nickname,
      email,
      phone,
      oldPassword,
      newPassword
    })

    if (!diff) return

    updateUserAccount(diff)
  }

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={{ ...initialValues, ...account }}
      validate={validateForm}
      validateOnBlur
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormActions />
          <FormFields />
        </Form>
      )}
    </FinalForm>
  )
}
