import { useContext } from 'react'
import { toast } from 'react-toastify'

import {
  createOrder as apiCreateOrder,
  updateOrder as apiUpdateOrder,
  closeOrder as apiCloseOrder,
  concludeOrder as apiConcludeOrder,
  reopenOrder as apiReopenOrder,
  sendDocuments as apiSendDocuments
} from '../../../../../services/order'

import { OrderFormatted } from '../../../utils/formatOrder'
import { OrderFormContext } from '../context'

export function useOrder() {
  const {
    order,
    loading,
    isCertificateOrder,
    setLoading,
    getOrderState,
    updateOrderState
  } = useContext(OrderFormContext)

  function createOrder(data: Partial<OrderFormatted>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiCreateOrder(data))
      .then(data => updateOrderState(data))
      .then(() => toast.info('Pedido cadastrado com sucesso'))
      .finally(() => setLoading(false))
  }

  function updateOrder(data: Partial<OrderFormatted>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiUpdateOrder(order.id, data))
      .then(data => updateOrderState(data))
      .then(() => toast.info('O pedido foi alterado'))
      .finally(() => setLoading(false))
  }

  function closeOrder(onFinally?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiCloseOrder(order.id))
      .then(data => updateOrderState(data))
      .then(() => toast.info('O pedido foi fechado'))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function concludeOrder(statusDate: Date, onFinally?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiConcludeOrder(order.id, statusDate))
      .then(data => updateOrderState(data))
      .then(() => toast.info('O pedido foi concluído'))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function reopenOrder(onFinally?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiReopenOrder(order.id))
      .then(data => updateOrderState(data))
      .then(() => toast.info('O pedido foi reaberto'))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function sendOrderDocuments(onFinally?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiSendDocuments(order.id))
      .then(() => toast.info('O e-mail foi enviado'))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  return {
    order,
    loading,
    isCertificateOrder,
    createOrder,
    updateOrder,
    closeOrder,
    concludeOrder,
    reopenOrder,
    sendOrderDocuments
  }
}
