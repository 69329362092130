import React from 'react'
import { Button } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import copy from 'copy-to-clipboard'

import { Show } from '../../../../../../../../components/Show'

import { useOrder } from '../../../../hooks'

export const OpenVideoConferenceAction: React.FC = () => {
  const { order, isCertificateOrder } = useOrder()

  function handleVideoConferenceButtonClick() {
    if (!order?.videoConferenceUrl) return

    copy(order.videoConferenceUrl)
    toast.info('Link da videoconferência copiado para área de transferência')
  }

  const orderExist = !!order
  const orderIsOpen = orderExist && order?.status === 'OPEN'
  const orderHasVideoConferenceUrl = !!order?.videoConferenceUrl

  return (
    <Show
      condition={
        isCertificateOrder && !orderIsOpen && orderHasVideoConferenceUrl
      }
      component={
        <Button
          type="button"
          content="Videoconferência"
          floated="right"
          onClick={handleVideoConferenceButtonClick}
        />
      }
    />
  )
}
