import React, { useState } from 'react'

import { invertOrder } from '../utils/list'

export type OrderDirection = 'ASC' | 'DESC'

interface PaginationProviderProps<Query = any> {
  totalItems?: number
  page?: number
  limit?: number
  orderBy?: string
  order?: OrderDirection
  query?: Query
  children?: React.ReactNode
}

export interface Pagination<Query = any> {
  page: number
  limit: number
  orderBy?: string
  order: OrderDirection
  query?: Query
}

interface PaginationContextContent<Query = any> {
  pagination: Pagination<Query>
  goToPreviousPage: () => void
  goToNextPage: () => void
  goToFirstPage: () => void
  goToLastPage: (totalItems?: number) => void
  changeOrderBy: (orderBy: string) => void
  changeOrder: (order: OrderDirection) => void
  changeLimit: (value: number) => void
  changeQuery: (query: Query) => void
}

export const PaginationContext = React.createContext<PaginationContextContent>(
  {} as PaginationContextContent
)

export const PaginationProvider: React.FC<PaginationProviderProps> = ({
  totalItems = 0,
  page = 1,
  limit = 10,
  orderBy,
  order = 'ASC',
  query,
  children
}) => {
  const [pagination, setPagination] = useState<Pagination>({
    page,
    limit,
    orderBy,
    order,
    query
  })

  function goToPreviousPage() {
    const { page } = pagination
    setPagination({ ...pagination, page: page - 1 })
  }

  function goToNextPage() {
    const { page } = pagination
    setPagination({ ...pagination, page: page + 1 })
  }

  function goToFirstPage() {
    setPagination({ ...pagination, page: 1 })
  }

  function goToLastPage(useTotalItems?: number) {
    const { limit } = pagination
    const totalOfItems = useTotalItems ?? totalItems
    const calcPages = (totalOfItems || 1) / limit
    const page = Math.ceil(calcPages)
    setPagination({ ...pagination, page })
  }

  function changeOrderBy(orderBy: string) {
    const sameName = pagination.orderBy === orderBy
    const order = sameName ? invertOrder(pagination.order) : pagination.order

    setPagination({ ...pagination, page: 1, orderBy, order })
  }

  function changeOrder(order: OrderDirection) {
    setPagination({ ...pagination, page: 1, order })
  }

  function changeLimit(limit: number) {
    setPagination({ ...pagination, page: 1, limit })
  }

  function changeQuery<Query = any>(query: Query) {
    setPagination({ ...pagination, page: 1, query })
  }

  return (
    <PaginationContext.Provider
      value={{
        pagination,
        goToPreviousPage,
        goToNextPage,
        goToFirstPage,
        goToLastPage,
        changeOrderBy,
        changeOrder,
        changeLimit,
        changeQuery
      }}
    >
      {children}
    </PaginationContext.Provider>
  )
}
