import React from 'react'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'

import { PermissionGroup } from './PermissionGroup'
import { OnCheck } from './AllPermissions'
import { BlankOption } from './styles'

interface PriceListPermissionsProps {
  onChange: (data: OnCheck) => void
  isChecked: (resource: string, actions: string[]) => boolean
}

export const PriceListPermissions: React.FC<PriceListPermissionsProps> = ({
  onChange,
  isChecked
}) => {
  function handleCheck(
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) {
    const value = data.value as string
    const checked = data.checked as boolean
    onChange({ value, checked })
  }

  return (
    <>
      <PermissionGroup label="Tabela de preços:">
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('priceList', ['Get', 'List'])}
          value="priceList:Get,List"
          label="Ler"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('priceList', ['Create'])}
          value="priceList:Create"
          label="Cadastrar"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('priceList', ['Update'])}
          value="priceList:Update"
          label="Atualizar"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('priceList', ['Delete'])}
          value="priceList:Delete"
          label="Excluir"
        />
      </PermissionGroup>
      <PermissionGroup>
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('priceListItem', ['List'])}
          value="priceListItem:List"
          label="Ler preços"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('priceListItem', ['Create'])}
          value="priceListItem:Create"
          label="Adicionar preço"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('priceListItem', ['Update'])}
          value="priceListItem:Update"
          label="Atualizar preço"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('priceListItem', ['Delete'])}
          value="priceListItem:Delete"
          label="Remover preço"
        />
      </PermissionGroup>
    </>
  )
}
