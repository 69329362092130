import React from 'react'
import { Divider } from 'semantic-ui-react'
import { useForm } from 'react-final-form'

import {
  Form,
  Input,
  PostalCodeInput
} from '../../../../../../../components/Form'
import { CepData } from '../../../../../../../services/address'

export const AddressFields: React.FC = () => {
  const form = useForm()

  function focusByName(name: string) {
    const elements = document.getElementsByName(name)
    const input = elements[0]

    input?.focus()
  }

  function handleCepSearch(data: CepData) {
    form.change('street', data.logradouro?.toUpperCase() || '')
    form.change('neighborhood', data.bairro?.toUpperCase() || '')
    form.change('city', data.cidade?.toUpperCase() || '')
    form.change('ibgeCity', data.ibgeCidade)
    form.change('state', data.siglaEstado?.toUpperCase() || '')
    const fieldName = data.logradouro ? 'number' : 'street'
    focusByName(fieldName)
  }

  return (
    <>
      <Divider horizontal content="Endereço" />
      <Form.Group>
        <PostalCodeInput
          name="postalCode"
          label="CEP"
          onSuccess={handleCepSearch}
        />
      </Form.Group>
      <Form.Group>
        <Input
          name="street"
          label="Logradouro"
          width={10}
          maxLength={100}
          uppercase
        />
        <Input name="number" label="Número" width={2} maxLength={10} numeric />
        <Input
          name="complement"
          label="Complemento"
          width={4}
          maxLength={100}
          uppercase
        />
      </Form.Group>
      <Form.Group>
        <Input
          name="neighborhood"
          label="Bairro"
          maxLength={100}
          width={7}
          uppercase
        />
        <Input
          name="city"
          label="Cidade"
          maxLength={100}
          width={7}
          uppercase
          readOnly
        />
        <Input name="state" label="Estado" width={2} uppercase readOnly />
      </Form.Group>
      <input name="ibgeCity" hidden maxLength={7} />
    </>
  )
}
