import styled from 'styled-components'
import PerfectScrollbar from 'react-perfect-scrollbar'

export const Wrapper = styled.div`
  height: 100%;
`

export const Scrollable = styled(PerfectScrollbar as any)`
  height: 100vh;
`

export const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
`
