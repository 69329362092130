import { stringToNumber } from '../utils/number'

import api from './api'
import { Product } from './product'
import { Service } from './service'

export interface PriceListItemExternal {
  id: string
  priceListId: string
  serviceId?: string
  productId?: string
  service?: Service
  product?: Product
  price: number
  cost: number
}

export interface PriceListItem {
  id: string
  priceListId: string
  serviceId?: string
  productId?: string
  service?: Service
  product?: Product
  price: number
  cost: number
}

export interface ListPriceListItemOptions {
  page?: number
  limit?: number
  search?: string
  type?: 'service' | 'product'
}

export interface PriceListItemListResult {
  priceListItems: PriceListItem[]
  total: number
}

function toExternalFormat(
  priceListItem: Partial<PriceListItem>
): Partial<PriceListItemExternal> {
  const { serviceId, productId, cost, price } = priceListItem

  return {
    serviceId,
    productId,
    cost: stringToNumber(cost),
    price: stringToNumber(price)
  }
}

export async function listPriceListItems(
  priceListId: string,
  paginate?: ListPriceListItemOptions
): Promise<PriceListItemListResult> {
  const { page, limit, search, type } = paginate || {}
  const response = await api.get(`/price-lists/${priceListId}/items`, {
    params: { page, limit, search, type }
  })
  const { items: priceListItems, total } = response.data

  return {
    priceListItems,
    total
  }
}

export async function createPriceListItem(
  priceListId: string,
  data: Partial<PriceListItem>
): Promise<PriceListItem> {
  const body = toExternalFormat(data)
  const response = await api.post(`/price-lists/${priceListId}/items`, body)

  return response.data
}

export async function updatePriceListItem(
  id: string,
  priceListId: string,
  data: Partial<PriceListItem>
): Promise<PriceListItem> {
  const body = toExternalFormat(data)
  const response = await api.put(
    `/price-lists/${priceListId}/items/${id}`,
    body
  )

  return response.data
}

export async function deletePriceListItem(
  id: string,
  priceListId: string
): Promise<boolean> {
  const response = await api.delete(`/price-lists/${priceListId}/items/${id}`)

  return response.data.status
}
