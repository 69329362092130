import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { AccountStatus, AccountType } from '../../services/account'
import { RegistrationContainer } from '../../components/RegistrationContainer'
import { PaginationProvider } from '../../contexts/PaginationContext'
import { OrderDirection } from '../../utils/list'

import { AccountsTable, AccountForm } from './components'
import { useBreadcrumb } from '../../hooks/useBreadcrumb'

export interface QueryParameters {
  type: AccountType
  status: AccountStatus
  search?: string
  orderBy?: string
  order?: OrderDirection
}

const initialQuery: QueryParameters = {
  type: 'RECEIVABLE',
  status: 'OPEN'
}

export const Accounts: React.FC = () => {
  const breadcrumb = useBreadcrumb({
    financeiro: 'Financeiro',
    inclusao: 'Inclusão',
    edicao: 'Edição'
  })

  return (
    <RegistrationContainer breadcrumbItems={breadcrumb}>
      <PaginationProvider query={initialQuery}>
        <Routes>
          <Route path="/" element={<AccountsTable />} />
          <Route path="/inclusao" element={<AccountForm />} />
          <Route path="/edicao" element={<AccountForm />} />
        </Routes>
      </PaginationProvider>
    </RegistrationContainer>
  )
}
