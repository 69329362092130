import styled from 'styled-components'

interface DropContainerProps {
  active?: boolean
}

export const DropContainer = styled.div<DropContainerProps>`
  width: 100%;
  padding: 30px;
  border: 3px dashed #2185d0;
  border-radius: 5px;
  text-align: center;
  background-color: ${({ active }) => (active ? '#ecf7ff' : 'white')};

  span {
    cursor: default;
  }
`
