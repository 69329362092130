import React from 'react'
import { Table } from 'semantic-ui-react'

import {
  FixedTableCell,
  ItemControllers
} from '../../../../../components/Table'
import { can } from '../../../../../config/permissions'

import { PersonFormatted } from '../../../utils/formatPerson'

interface PeopleTableRowProps {
  person: PersonFormatted
  onUpdateButtonClick: (person: PersonFormatted) => void
  onDeleteButtonClick: (person: PersonFormatted) => void
}

export const PeopleTableRow: React.FC<PeopleTableRowProps> = ({
  person,
  onUpdateButtonClick,
  onDeleteButtonClick
}) => {
  return (
    <Table.Row key={person.id}>
      <FixedTableCell content={person.name} maxWidth={300} />
      <Table.Cell content={person.documentFormatted} />
      <Table.Cell content={person.firstEmail} />
      <Table.Cell content={person.phoneFormatted} />
      <Table.Cell textAlign="right">
        <ItemControllers
          onUpdateClick={() => onUpdateButtonClick(person)}
          onDeleteClick={() => onDeleteButtonClick(person)}
          permissionsToUpdate={can.person.update}
          permissionsToDelete={can.person.delete}
        />
      </Table.Cell>
    </Table.Row>
  )
}
