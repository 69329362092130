import Joi from 'joi'

const messages = {
  name: {
    required: 'Nome do sistema é obrigatório',
    max: 'Permitido no máximo 20 caracteres'
  }
}

export const systemSchema = Joi.object({
  id: Joi.string().uuid({ version: ['uuidv4'] }),
  name: Joi.string().max(20).trim().required().messages({
    'any.required': messages.name.required,
    'string.empty': messages.name.required,
    'string.max': messages.name.max
  })
})
