import { useState } from 'react'
import { toast } from 'react-toastify'

import {
  deleteSignature as apiDeleteSignature,
  invoiceSignature as apiInvoiceSignature
} from '../../../../../services/signature'

export function useSignature() {
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [invoiceLoading, setInvoiceLoading] = useState(false)

  function deleteSignature(id: string, onSuccess?: () => void) {
    Promise.resolve()
      .then(() => setDeleteLoading(true))
      .then(() => apiDeleteSignature(id))
      .then(() => toast.info('A assinatura foi excluída'))
      .then(() => onSuccess?.())
      .finally(() => setDeleteLoading(false))
  }

  function invoiceSignature(id: string, onSuccess?: () => void) {
    Promise.resolve()
      .then(() => setInvoiceLoading(true))
      .then(() => apiInvoiceSignature(id))
      .then(() => toast.info('A assinatura foi faturada'))
      .then(() => onSuccess?.())
      .finally(() => setInvoiceLoading(false))
  }

  return {
    deleteLoading,
    invoiceLoading,
    deleteSignature,
    invoiceSignature
  }
}
