import React from 'react'

import { Image } from './styles'

interface LogoProps {
  type?: 'vertical' | 'icon'
}

export const Logo: React.FC<LogoProps> = ({ type = 'vertical' }) => {
  const logo = {
    vertical: '/images/logo-vertical.svg',
    icon: '/images/logo-icon.svg'
  }
  const src = logo[type]

  return <Image src={src} alt="Logo da SCONTTI" />
}
