import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

interface DefaultLoaderProps {
  active?: boolean
  inverted?: boolean
}

export const DefaultLoader: React.FC<DefaultLoaderProps> = ({
  active,
  inverted
}) => {
  return (
    <Dimmer active={active} inverted={inverted ?? true}>
      <Loader active={active} size="large" content="Carregando..." />
    </Dimmer>
  )
}
