import React, { useState } from 'react'
import { Button, Segment } from 'semantic-ui-react'
import { Form as FinalForm } from 'react-final-form'
import { toast } from 'react-toastify'

import { createDirectory, Directory } from '../../services/directory'
import { getFormValidate } from '../../utils/form'
import { Form, Input } from '../../components/Form'
import { FormActionsContainer } from '../../styles/FormActionsContainer'

import { directorySchema } from './schemas'

interface DirectoryFormProps {
  systemId?: string
  fatherId?: string
  onBack?: (directory: Directory) => void
}

const validateForm = getFormValidate(directorySchema)

export const DirectoryForm: React.FC<DirectoryFormProps> = ({
  systemId,
  fatherId,
  onBack
}) => {
  const [loading, setLoading] = useState(false)

  function handleSubmit(values: any) {
    if (!systemId) return

    const { name, fatherId } = values

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => createDirectory(systemId, { name, fatherId }))
      .then(() => toast.info('Dados salvos'))
      .finally(() => setLoading(false))
  }

  return (
    <FinalForm
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={{ fatherId }}
      validateOnBlur
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <FormActionsContainer>
            {onBack && (
              <Button
                type="button"
                content="Voltar"
                onClick={() => onBack(values)}
              />
            )}
            <Button type="submit" content="Salvar" floated="right" primary />
          </FormActionsContainer>
          <Segment>
            <Input
              name="name"
              label="Nome do diretório"
              maxLength={50}
              required
            />
          </Segment>
        </Form>
      )}
    </FinalForm>
  )
}
