import { SelectOption } from '../../components/Form/Select'
import { Category } from '../../services/category'
import { Group } from '../../services/group'
import {
  dateToLocaleDate,
  getMaxDateOfMonth,
  getMinDateOfMonth,
  getPreviousMonth,
  getToday
} from '../../utils/date'

export function categoryToOption(category: Category): SelectOption {
  return {
    key: category.id,
    value: category.id,
    text: category.name
  }
}

export function groupToOption(group: Group): SelectOption {
  return {
    key: group.id,
    value: group.id,
    text: group.name
  }
}

export const DEBOUNCE_TIME = 500

export const initialValues = {
  start: dateToLocaleDate(getMinDateOfMonth(getPreviousMonth(getToday()))),
  end: dateToLocaleDate(getMaxDateOfMonth(getPreviousMonth(getToday()))),
  status: ['CONCLUDED']
}

export const statusOptions = [
  { key: 'CONCLUDED', value: 'CONCLUDED', text: 'Concluído' },
  { key: 'APPROVED', value: 'APPROVED', text: 'Aprovado' },
  { key: 'ISSUED', value: 'ISSUED', text: 'Emitido' },
  { key: 'CLOSED', value: 'CLOSED', text: 'Fechado' },
  { key: 'OPEN', value: 'OPEN', text: 'Aberto' }
]
