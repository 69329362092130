import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  > div {
    width: 90%;
    max-width: 600px;
    min-height: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 5px;
  }

  .message {
    margin: auto !important;
  }
`

export const Info = styled.div`
  span {
    display: block;
    font-weight: 700;
    font-size: 1.1em;
    text-align: center;
  }
`

export const QrCode = styled.div``

export const CopyAndPaste = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  width: 100%;

  textarea {
    width: 75%;
    min-width: 220px;
    display: inline-block;
  }

  button {
    display: inline-block;
    height: max-content;
  }
`
