import React, { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Button, Segment } from 'semantic-ui-react'
import { Form as FinalForm } from 'react-final-form'
import { toast } from 'react-toastify'

import { Form, Input } from '../../components/Form'
import {
  getSystem,
  createSystem,
  updateSystem,
  System
} from '../../services/system'
import { getFormValidate } from '../../utils/form'
import { pages } from '../../config/pages'
import { FormActionsContainer } from '../../styles/FormActionsContainer'

import { systemSchema } from './schemas'

const validateForm = getFormValidate(systemSchema)

export const SystemForm: React.FC = () => {
  const [query, setQuery] = useSearchParams()
  const [system, setSystem] = useState<System>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const systemId = query.get('id')

    if (!systemId) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getSystem(systemId))
      .then(data => setSystem(data))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    const hasIdInQuery = query.has('id')

    if (!!system && !hasIdInQuery) {
      setQuery({ ...query, id: system.id })
    }
  }, [system])

  function handleSubmit(values: any) {
    const { name } = values

    if (!system) {
      Promise.resolve()
        .then(() => setLoading(true))
        .then(() => createSystem({ name }))
        .then(data => setSystem(data))
        .then(() => toast.info('Dados salvos'))
        .finally(() => setLoading(false))

      return
    }

    if (system.name === name) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => updateSystem(values.id, { name }))
      .then(data => setSystem(data))
      .then(() => toast.info('Dados salvos'))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <FormActionsContainer>
        <Button
          type="button"
          content="Voltar"
          as={Link}
          to={pages.systems.path}
        />
        <Button
          type="submit"
          content="Salvar"
          floated="right"
          form="system-form"
          primary
        />
      </FormActionsContainer>
      <Segment>
        <FinalForm
          onSubmit={handleSubmit}
          initialValues={system}
          validate={validateForm}
          validateOnBlur
        >
          {({ handleSubmit }) => (
            <Form id="system-form" onSubmit={handleSubmit} loading={loading}>
              <input name="id" hidden />
              <Input
                name="name"
                label="Nome do sistema"
                maxLength={20}
                required
                uppercase
              />
            </Form>
          )}
        </FinalForm>
      </Segment>
    </>
  )
}
