import api from './api'

export type UserRole = 'ADMIN' | 'EMPLOYEE' | 'PARTNER'

export type UserStatus = 'ACTIVE' | 'INACTIVE'

export type CommissionType = 'INDICATION' | 'RESALE'

export type UserPixType = 'EMAIL' | 'PHONE' | 'CPF' | 'CNPJ' | 'RANDOM'

export interface UserPix {
  type: UserPixType
  key: string
}

export interface User {
  id: string
  role: UserRole
  permissions: string[]
  nickname: string
  email: string
  phone: string
  status: UserStatus
  commission: number
  commissionType: CommissionType
  priceListId?: string
  groupId?: string
  partners: User[]
  pix?: UserPix | null
}

export interface UserPaginate {
  page?: number
  limit?: number
  orderBy?: string
  order?: 'ASC' | 'DESC'
  search?: string
  role?: UserRole
}

export interface UserListResult {
  users: User[]
  total: number
}

export async function getUser(id: string): Promise<User> {
  const response = await api.get(`/users/${id}`)
  const user = response.status === 200 ? response.data : undefined

  return user
}

export async function listUsers(
  paginate?: UserPaginate
): Promise<UserListResult> {
  const { page, limit, orderBy, order, search, role } = paginate || {}
  const response = await api.get(`/users`, {
    params: { page, limit, orderBy, order, search, role }
  })
  const { items: users, total } = response.data

  return {
    users,
    total
  }
}

export async function createUser(data: Partial<User>): Promise<User> {
  const response = await api.post('/users', data)
  const user = response.data

  return user
}

export async function updateUser(
  id: string,
  data: Partial<User>
): Promise<User> {
  const response = await api.put(`/users/${id}`, data)
  const user = response.data

  return user
}

export async function updateUserStatus(
  id: string,
  status: UserStatus
): Promise<User> {
  const response = await api.patch(`/users/${id}/status`, { status })
  const user = response.data

  return user
}

export async function resetUserPassword(id: string): Promise<void> {
  await api.patch(`/users/${id}/password`)
}

export async function deleteUser(id: string): Promise<boolean> {
  const response = await api.delete(`/users/${id}`)
  const result = response.data

  return result.status
}
