import { PriceList, getPriceList } from '../../../../../../services/priceList'

export function useFetchPriceList() {
  async function fetchPriceList(id: string): Promise<PriceList | undefined> {
    try {
      const priceList = await getPriceList(id)
      return priceList
    } catch (err: any) {
      const status = err.response?.status

      if (status === 404) return

      throw err
    }
  }

  return fetchPriceList
}
