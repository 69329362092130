import React from 'react'
import { Table } from 'semantic-ui-react'

import { ItemControllers } from '../../../../../components/Table'
import { can } from '../../../../../config/permissions'
import { Product } from '../../../../../services/product'

interface ProductsTableRowProps {
  product: Product
  onUpdateButtonClick: (product: Product) => void
  onDeleteButtonClick: (product: Product) => void
}

export const ProductsTableRow: React.FC<ProductsTableRowProps> = ({
  product,
  onUpdateButtonClick,
  onDeleteButtonClick
}) => {
  return (
    <Table.Row>
      <Table.Cell content={product.description} />
      <Table.Cell textAlign="right">
        <ItemControllers
          onUpdateClick={() => onUpdateButtonClick(product)}
          onDeleteClick={() => onDeleteButtonClick(product)}
          permissionsToUpdate={can.product.update}
          permissionsToDelete={can.product.delete}
        />
      </Table.Cell>
    </Table.Row>
  )
}
