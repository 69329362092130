import React, { useState } from 'react'
import { Input, Button, Modal } from 'semantic-ui-react'
import { PatternFormat } from 'react-number-format'
import { DateTimeInput as SemanticDateTimeInput } from 'semantic-ui-calendar-react'
import 'moment/locale/pt-br'

import { ModalContentWrapper } from './styles'

interface OnChangeData {
  value: string
}

interface DateTimeInputProps {
  id?: string
  name?: string
  value: string
  readOnly?: boolean
  disabled?: boolean
  fluid?: boolean
  minDate?: Date
  maxDate?: Date
  onChange?: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.SyntheticEvent<HTMLElement, Event>,
    data: OnChangeData
  ) => void
}

export const DateTimeInput: React.FC<DateTimeInputProps> = ({
  id,
  name,
  value,
  readOnly,
  disabled,
  fluid,
  minDate,
  maxDate,
  onChange
}) => {
  const [modal, setModal] = useState(false)

  function handleModalClose() {
    setModal(false)
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(event, { value: event.target.value })
  }

  function handlePickerChange(
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: any
  ) {
    setModal(false)
    onChange?.(event, { value: data.value })
  }

  return (
    <>
      <Input id={id} fluid={fluid}>
        <PatternFormat
          id={id}
          name={name}
          format="##/##/#### ##:##"
          value={value}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={disabled}
        />
        <Button
          icon="calendar"
          type="button"
          onClick={() => setModal(true)}
          disabled={readOnly || disabled}
          basic
        />
      </Input>
      <Modal open={modal} onClose={handleModalClose} size="tiny" basic>
        <Modal.Content>
          <ModalContentWrapper>
            <SemanticDateTimeInput
              value={value}
              onChange={handlePickerChange}
              localization="pt-br"
              dateFormat="DD/MM/YYYY"
              minDate={minDate}
              maxDate={maxDate}
              inline
            />
          </ModalContentWrapper>
        </Modal.Content>
      </Modal>
    </>
  )
}
