import React from 'react'

import { FormActionsContainer } from '../../../../../../../styles/FormActionsContainer'

import {
  GoToBackAction,
  SaveAction,
  CloseOrderAction,
  ConcludeOrderAction,
  ReopenOrderAction,
  SendOrderDocumentsAction,
  CheckVideoconferencingAvailability,
  RequestCertificateAction,
  RenewCertificateAction,
  DeleteRequestAction,
  OpenVideoConferenceAction
} from './actions'

export const FormActions: React.FC = () => {
  return (
    <FormActionsContainer>
      <GoToBackAction />
      <SaveAction />
      <CloseOrderAction />
      <ConcludeOrderAction />
      <ReopenOrderAction />
      <SendOrderDocumentsAction />
      <CheckVideoconferencingAvailability />
      <RenewCertificateAction />
      <RequestCertificateAction />
      <DeleteRequestAction />
      <OpenVideoConferenceAction />
    </FormActionsContainer>
  )
}
