import { useContext, useEffect, useState } from 'react'

import { getPerson, Person } from '../../../../../services/person'

import {} from '../hooks/useSignature'
import { SignatureFormContext } from '../context'

export function useSignatureCustomer() {
  const { signature } = useContext(SignatureFormContext)
  const [customer, setCustomer] = useState<Person>()
  const [loading, setLoading] = useState(false)

  function getCustomer(id: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getPerson(id))
      .then(data => setCustomer(data))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!signature) return
    getCustomer(signature.customerId)
  }, [signature])

  return {
    customer,
    loading
  }
}
