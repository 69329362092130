import React, { useEffect, useState } from 'react'
import { DropdownOnSearchChangeData } from 'semantic-ui-react'
import { useField } from 'react-final-form'
import { debounce } from 'debounce'

import {
  Form,
  Select,
  Currency
} from '../../../../../../../../../components/Form'
import { SelectOption } from '../../../../../../../../../components/Form/Select'
import { Show } from '../../../../../../../../../components/Show'

import { useUser, usePriceList, useGroup } from '../../../../../hooks'
import {
  priceListToSelectOption,
  groupToSelectOption
} from '../../../../../../../utils/selectOption'

const commissionTypeOptions = [
  { key: 'INDICATION', value: 'INDICATION', text: 'Indicação' },
  { key: 'RESALE', value: 'RESALE', text: 'Revenda' }
]

const DEBOUNCE_TIME = 500

export const CommissionGroup: React.FC = () => {
  const { user, group, priceList } = useUser()
  const {
    priceLists,
    loading: priceListsLoading,
    listPriceLists
  } = usePriceList()
  const [priceListOptions, setPriceListOptions] = useState<SelectOption[]>([])
  const { groups, loading: groupsLoading, listGroups } = useGroup()
  const [groupOptions, setGroupOptions] = useState<SelectOption[]>([])
  const roleField = useField('role')

  useEffect(() => {
    setPriceListOptions(priceLists.map(priceListToSelectOption))
  }, [priceLists])

  useEffect(() => {
    if (!user) return

    if (!priceList) return

    setPriceListOptions([priceListToSelectOption(priceList)])
  }, [priceList])

  useEffect(() => {
    setGroupOptions(groups.map(groupToSelectOption))
  }, [groups])

  useEffect(() => {
    if (!user) return

    if (!group) return

    setGroupOptions([groupToSelectOption(group)])
  }, [group])

  function searchGroup(data: DropdownOnSearchChangeData) {
    const { searchQuery } = data

    return debounce(() => listGroups(searchQuery), DEBOUNCE_TIME)()
  }

  function searchPriceList(data: DropdownOnSearchChangeData) {
    const { searchQuery } = data

    return debounce(() => listPriceLists(searchQuery), DEBOUNCE_TIME)()
  }

  const userIsPartner = roleField.input.value === 'PARTNER'

  return (
    <Form.Group widths={userIsPartner ? 4 : undefined}>
      <Currency
        name="commissionFormatted"
        label="Comissão"
        fluid={userIsPartner}
      />
      <Show condition={userIsPartner}>
        <Select
          name="commissionType"
          label="Tipo de comissão"
          options={commissionTypeOptions}
          uppercase
        />
        <Select
          name="groupId"
          label="Grupo"
          options={groupOptions}
          loading={groupsLoading}
          onSearchChange={(_, data) => searchGroup(data)}
          search
          uppercase
          clearable
        />
        <Select
          name="priceListId"
          label="Tabela do parceiro"
          options={priceListOptions}
          loading={priceListsLoading}
          onSearchChange={(_, data) => searchPriceList(data)}
          search
          uppercase
          clearable
        />
      </Show>
    </Form.Group>
  )
}
