import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { DefaultLoader } from '../../../../../components/DefaultLoader'
import { pages } from '../../../../../config/pages'
import {
  Signature,
  SignatureRecurrence
} from '../../../../../services/signature'

import {
  SignatureFormatted,
  formatSignature
} from '../../../utils/formatSignature'
import { recalculateSignatureByNewRecurrence } from '../../../utils/signatureCalculate'
import { useFetchSignature } from './hooks/useFetchSignature'

interface SignatureFormContextContent {
  loading: boolean
  signature?: SignatureFormatted
  setLoading: (value: boolean) => void
  getSignatureState: () => SignatureFormatted
  updateSignatureState: (signature: Signature) => void
  localChangeRecurrence: (newRecurrence: SignatureRecurrence) => void
}

export const SignatureFormContext =
  React.createContext<SignatureFormContextContent>(
    {} as SignatureFormContextContent
  )

interface SignatureFormProviderProps {
  children: React.ReactNode
}

export const SignatureFormProvider: React.FC<SignatureFormProviderProps> = ({
  children
}) => {
  const [signature, setSignature] = useState<SignatureFormatted>()
  const [loading, setLoading] = useState(false)
  const [query] = useSearchParams()
  const fetchSignature = useFetchSignature()
  const navigate = useNavigate()

  function getSignatureState(): SignatureFormatted {
    if (!signature) throw new Error('Nenhuma assinatura foi selecionada')

    return signature
  }

  function updateSignatureState(data: Signature) {
    setSignature(
      formatSignature({
        ...data,
        items: data.items ?? signature?.items ?? []
      })
    )
  }

  function _fetchSignature(signatureId: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => fetchSignature(signatureId))
      .then(data => {
        if (data) return updateSignatureState(data)

        navigate(`${pages.signatures.path}/inclusao`)
        toast.warn(
          'A assinatura não foi encontrada, mas você pode criar uma nova.'
        )
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const signatureId = query.get('id')
    if (!signatureId) return
    _fetchSignature(signatureId)
  }, [])

  function localChangeRecurrence(newRecurrence: SignatureRecurrence) {
    if (!signature) return
    const newSignature = recalculateSignatureByNewRecurrence(
      signature,
      newRecurrence
    )
    setSignature(newSignature)
  }

  return (
    <SignatureFormContext.Provider
      value={{
        loading,
        signature,
        setLoading,
        getSignatureState,
        updateSignatureState,
        localChangeRecurrence
      }}
    >
      <DefaultLoader active={loading} />
      {children}
    </SignatureFormContext.Provider>
  )
}
