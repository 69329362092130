import React from 'react'
import { Table, Button } from 'semantic-ui-react'

import { ItemControllers } from '../../../../../components/Table'
import { can } from '../../../../../config/permissions'

import { SignatureFormatted } from '../../../utils/formatSignature'

interface SignaturesTableRowProps {
  signature: SignatureFormatted
  onInvoiceButtonClick: (signature: SignatureFormatted) => void
  onUpdateButtonClick: (signature: SignatureFormatted) => void
  onDeleteButtonClick: (signature: SignatureFormatted) => void
}

export const SignaturesTableRow: React.FC<SignaturesTableRowProps> = ({
  signature,
  onInvoiceButtonClick,
  onUpdateButtonClick,
  onDeleteButtonClick
}) => {
  return (
    <Table.Row key={signature.id}>
      <Table.Cell content={signature.customer.name} />
      <Table.Cell content={signature.description} />
      <Table.Cell content={signature.period} />
      <Table.Cell content={signature.recurrenceFormatted} />
      <Table.Cell content={signature.dueDateFormatted} />
      <Table.Cell content={signature.valueFormatted} />
      <Table.Cell textAlign="center">
        <Button
          icon="check"
          onClick={() => onInvoiceButtonClick(signature)}
          disabled={!signature.openToInvoice}
          size="mini"
          circular
        />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <ItemControllers
          onUpdateClick={() => onUpdateButtonClick(signature)}
          onDeleteClick={() => onDeleteButtonClick(signature)}
          permissionsToUpdate={can.signature.update}
          permissionsToDelete={can.signature.delete}
        />
      </Table.Cell>
    </Table.Row>
  )
}
