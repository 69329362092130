import api from './api'
import { UserRole } from './users'

export interface UserAccount {
  id: string
  role: UserRole
  nickname: string
  email: string
  phone: string
  commission: number
  commissionType: string
}

export type GetUserAccountResult = UserAccount

export interface UpdateUserAccountData {
  nickname?: string
  email?: string
  phone?: string
  oldPassword?: string
  newPassword?: string
}

export async function getUserAccount(): Promise<GetUserAccountResult> {
  const response = await api.get('/user-account')

  const { id, role, nickname, email, phone, commission, commissionType } =
    response.data

  return {
    id,
    role,
    nickname,
    email,
    phone,
    commission,
    commissionType
  }
}

export async function updateUserAccount(
  data: UpdateUserAccountData
): Promise<UserAccount> {
  const response = await api.put('/user-account', data)

  const { id, role, nickname, email, phone, commission, commissionType } =
    response.data

  return {
    id,
    role,
    nickname,
    email,
    phone,
    commission,
    commissionType
  }
}
