import React, { useEffect, useState } from 'react'
import { useSearchParams, Link } from 'react-router-dom'
import { Form as FinalForm } from 'react-final-form'
import { Button, Segment } from 'semantic-ui-react'
import { toast } from 'react-toastify'

import {
  getCategory,
  createCategory,
  updateCategory,
  Category
} from '../../services/category'
import { getDiff, getFormValidate } from '../../utils/form'
import { pages } from '../../config/pages'
import { Form, Input } from '../../components/Form'
import { FormActionsContainer } from '../../styles/FormActionsContainer'

import { categorySchema } from './schemas'

const validateForm = getFormValidate(categorySchema)

export const CategoryForm: React.FC = () => {
  const [query, setQuery] = useSearchParams()
  const [category, setCategory] = useState<Category>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const categoryId = query.get('id')

    if (!categoryId) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getCategory(categoryId))
      .then(data => data && setCategory(data))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    const hasIdInQuery = query.has('id')

    if (!!category && !hasIdInQuery) {
      setQuery({ ...query, id: category.id })
    }
  }, [category])

  function handleSubmit(values: Category) {
    const { name, description } = values

    if (!category) {
      Promise.resolve()
        .then(() => setLoading(true))
        .then(() => createCategory({ name, description }))
        .then(data => setCategory(data))
        .then(() => toast.info('Dados salvos'))
        .finally(() => setLoading(false))

      return
    }

    const diff = getDiff(category, values)

    if (!diff) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => updateCategory(category.id, diff))
      .then(data => setCategory(data))
      .then(() => toast.info('Dados salvos'))
      .finally(() => setLoading(false))
  }

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={category}
      validate={validateForm}
      validateOnBlur
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <FormActionsContainer>
            <Button
              type="button"
              content="Voltar"
              as={Link}
              to={pages.categories.path}
            />
            <Button type="submit" content="Salvar" floated="right" primary />
          </FormActionsContainer>
          <Segment>
            <input name="id" hidden />
            <Input
              name="name"
              label="Nome da categoria"
              maxLength={40}
              required
              uppercase
            />
            <Input
              name="description"
              label="Descrição"
              maxLength={200}
              uppercase
            />
          </Segment>
        </Form>
      )}
    </FinalForm>
  )
}
