import api from './api'
import { PriceListItem } from './priceListItem'

export interface OrderItem {
  id: string
  orderId: string
  priceListItemId?: string
  description: string
  quantity: number
  price: number
  discount: number
  addition: number
  priceListItem?: PriceListItem
}

export interface OrderItemCreate {
  priceListId: string
  priceListItemId: string
  quantity: number
  discount: number
  addition: number
}

export async function createOrderItem(
  orderId: string,
  data: OrderItemCreate
): Promise<OrderItem> {
  const response = await api.post(`/orders/${orderId}/items`, data)
  const orderItem = response.data

  return orderItem
}

export async function updateOrderItem(
  id: string,
  orderId: string,
  data: Partial<OrderItem>
): Promise<OrderItem> {
  const response = await api.put(`/orders/${orderId}/items/${id}`, data)
  const orderItem = response.data

  return orderItem
}

export async function deleteOrderItem(
  id: string,
  orderId: string
): Promise<boolean> {
  const response = await api.delete(`/orders/${orderId}/items/${id}`)

  return response.data.status
}
