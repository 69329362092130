import React from 'react'

import { ConfirmationModal } from '../../../../../../../../../../components/ConfirmationModal'

import { ChargeFormatted } from '../../../../../../../../utils/formatCharge'

interface ChargeConfirmationModalProps {
  open: boolean
  charge: ChargeFormatted
  title: string
  question: string
  loading: boolean
  handleModal: (open: boolean) => void
  onConfirm: () => void
}

export const ChargeConfirmationModal: React.FC<
  ChargeConfirmationModalProps
> = ({ open, charge, title, question, loading, handleModal, onConfirm }) => {
  return (
    <ConfirmationModal
      open={open}
      title={title}
      loading={loading}
      handleModal={handleModal}
      onConfirm={onConfirm}
      onDecline={() => handleModal(false)}
    >
      <span>{question}</span>
      <br />
      <span>Valor de {charge.amountFormatted}</span>
      <br />
      <span>Vencimento em {charge.dueDateFormatted}</span>
    </ConfirmationModal>
  )
}
