import React from 'react'
import { Message } from 'semantic-ui-react'

import { DefaultLoader } from '../../components/DefaultLoader'

import { useBolepixPdf } from './useBolepixPdf'
import { Viewer, Container } from './styles'

export const BolepixPdf: React.FC = () => {
  const { bolepixPdf, error, loading } = useBolepixPdf()

  if (loading) {
    return (
      <Container>
        <DefaultLoader active />
      </Container>
    )
  }

  if (error) {
    return (
      <Container>
        <Message title="Houve um erro" content={error} negative />
      </Container>
    )
  }

  if (!bolepixPdf) {
    return (
      <Container>
        <Message
          title="Houve um problema"
          content="Bolepix não encontrado"
          warning
        />
      </Container>
    )
  }

  return (
    <Viewer
      title="Bolepix"
      src={`data:application/pdf;base64,${bolepixPdf?.toString('base64')}`}
    />
  )
}
