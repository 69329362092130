import { useContext } from 'react'
import { toast } from 'react-toastify'

import {
  WriteOffData,
  createAccount as apiCreateAccount,
  updateAccount as apiUpdateAccount,
  writeOffAccount as apiWriteOffAccount
} from '../../../../../services/account'

import { AccountFormatted } from '../../../utils/formatAccount'
import { AccountFormContext } from '../context/AccountFormContext'

export function useAccount() {
  const {
    account,
    person,
    group,
    loading,
    setLoading,
    getAccountState,
    updateAccountState
  } = useContext(AccountFormContext)

  function createAccount(
    data: Partial<AccountFormatted>,
    onSuccess?: (account: AccountFormatted) => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiCreateAccount(data))
      .then(data => updateAccountState(data))
      .then(account => onSuccess?.(account))
      .then(() => toast.info('Conta cadastrada com sucesso'))
      .finally(() => setLoading(false))
  }

  function updateAccount(data: Partial<AccountFormatted>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getAccountState())
      .then(account => apiUpdateAccount(account.id, data))
      .then(data => updateAccountState(data))
      .then(() => toast.info('A conta foi alterada'))
      .finally(() => setLoading(false))
  }

  function writeOffAccount(accountId: string, data: WriteOffData) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiWriteOffAccount(accountId, data))
      .then(data => updateAccountState(data))
      .then(() => toast.info('Conta baixada'))
      .finally(() => setLoading(false))
  }

  return {
    account,
    person,
    group,
    loading,
    createAccount,
    updateAccount,
    writeOffAccount
  }
}
