import React from 'react'
import { Table } from 'semantic-ui-react'

import { useOrder } from '../../../../hooks'

export const OrderChargesTableFooter: React.FC = () => {
  const { order } = useOrder()

  const { totalOfChargesFormatted } = order || {}

  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell content="Total" colSpan="2" />
        <Table.HeaderCell content={totalOfChargesFormatted} colSpan="3" />
      </Table.Row>
    </Table.Footer>
  )
}
