import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Segment } from 'semantic-ui-react'
import { Form as FinalForm } from 'react-final-form'

import { Service } from '../../../../../services/service'
import { getDiff, getFormValidate } from '../../../../../utils/form'
import { Form, Input } from '../../../../../components/Form'
import { FormActionsContainer } from '../../../../../styles/FormActionsContainer'
import { pages } from '../../../../../config/pages'

import { useService } from '../hooks/useService'
import { serviceSchema } from './schemas'

const validateForm = getFormValidate(serviceSchema)

export const ServiceMain: React.FC = () => {
  const { service, createService, updateService } = useService()

  function handleSubmit(values: Service) {
    const data = {
      ...values,
      serviceListCode: values.serviceListCode && +values.serviceListCode
    }

    if (!service) createService(data)

    if (service) {
      const diff = getDiff(service, data)

      if (!diff) return

      updateService(diff)
    }
  }

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={service}
      validate={validateForm}
      validateOnBlur
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormActionsContainer>
            <Button
              type="button"
              content="Voltar"
              as={Link}
              to={pages.services.path}
            />
            <Button type="submit" content="Salvar" floated="right" primary />
          </FormActionsContainer>
          <Segment>
            <input name="id" hidden />
            <Form.Group>
              <Input name="code" label="Código" width={2} numeric />
              <Input
                name="description"
                label="Descrição do serviço"
                width={7}
                maxLength={60}
                required
                uppercase
              />
              <Input
                name="serviceListCode"
                label="Código de lista de serviço"
                width={7}
                maxLength={5}
                numeric
              />
            </Form.Group>
          </Segment>
        </Form>
      )}
    </FinalForm>
  )
}
