import Joi from 'joi'

const messages = {
  name: {
    required: 'Nome é obrigatório',
    max: 'Permitido no máximo 50 caracteres'
  }
}

export const directorySchema = Joi.object({
  name: Joi.string().max(50).trim().required().messages({
    'any.required': messages.name.required,
    'string.empty': messages.name.required,
    'string.max': messages.name.max
  })
})
