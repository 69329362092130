import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  getCobPix,
  formatCobPix,
  CobPixInfoFormatted
} from '../services/cobPix'

export function useCobPix() {
  const [query] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [cobPix, setCobPix] = useState<CobPixInfoFormatted>()

  useEffect(() => {
    const orderId = query.get('orderId')
    const chargeId = query.get('chargeId') || query.get('paymentId')

    if (!orderId || !chargeId) {
      setError('Link inválido. Entre em contato para mais informações.')
      return
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getCobPix(orderId, chargeId))
      .then(data => formatCobPix(data))
      .then(data => setCobPix(data))
      .catch(err => setError(err.response?.data || err.message))
      .finally(() => setLoading(false))
  }, [])

  return {
    loading,
    error,
    cobPix
  }
}
