import React from 'react'
import { Segment, Table } from 'semantic-ui-react'

import { Show } from '../../../../../../components/Show'

import { useUser } from '../../hooks'
import {
  PartnershipHeader,
  PartnershipsTableHeader,
  PartnershipsTableRow
} from './components'

export const Partnership: React.FC = () => {
  const { user } = useUser()

  const userExist = !!user
  const userIsEmployee = userExist && user?.role === 'EMPLOYEE'

  return (
    <Show condition={userIsEmployee}>
      <Segment>
        <PartnershipHeader />

        <Table selectable striped compact>
          <PartnershipsTableHeader />
          <Table.Body>
            {user?.partners?.map(partner => (
              <PartnershipsTableRow key={partner.id} partner={partner} />
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </Show>
  )
}
