import { useState, useEffect } from 'react'

export const useLocalStorage = (
  key: string
): [string | null, (value: string) => void] => {
  const [value, setValue] = useState(() => {
    return localStorage.getItem(key)
  })

  useEffect(() => {
    localStorage.setItem(key, value || '')
  }, [key, value])

  return [value, setValue]
}
