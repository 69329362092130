import React from 'react'
import { toast } from 'react-toastify'

import { ConfirmationModal } from '../../../../../components/ConfirmationModal'
import { Service } from '../../../../../services/service'

interface ConfimDeleteModalProps {
  open: boolean
  service?: Service
  loading?: boolean
  handleModal: (open: boolean) => void
  onConfirm: (order: Service) => void
  onDecline: () => void
}

export const ConfirmDeleteModal: React.FC<ConfimDeleteModalProps> = ({
  open,
  service,
  loading,
  handleModal,
  onConfirm,
  onDecline
}) => {
  function handleConfirmDelete() {
    if (!service) {
      toast.error('Nenhum serviço selecionado')

      return
    }

    onConfirm(service)
  }

  return (
    <ConfirmationModal
      open={open}
      loading={loading}
      handleModal={handleModal}
      onConfirm={handleConfirmDelete}
      onDecline={onDecline}
    >
      <span>Deseja excluir o serviço?</span>
      <br />
      {service ? <strong>{service.description}</strong> : null}
    </ConfirmationModal>
  )
}
