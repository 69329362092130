import React, { useContext } from 'react'
import { Table } from 'semantic-ui-react'

import { ItemControllers } from '../../../../../components/Table'
import { can } from '../../../../../config/permissions'
import { Show } from '../../../../../components/Show'
import { AuthContext } from '../../../../../contexts/AuthContext'

import { UserFormatted } from '../../../utils/formatUser'

interface UsersTableRowProps {
  user: UserFormatted
  onUpdateButtonClick: (user: UserFormatted) => void
  onDeleteButtonClick: (user: UserFormatted) => void
}

export const UsersTableRow: React.FC<UsersTableRowProps> = ({
  user,
  onUpdateButtonClick,
  onDeleteButtonClick
}) => {
  const { user: account } = useContext(AuthContext)

  const accountIsAdmin = account?.role === 'ADMIN'

  return (
    <Table.Row>
      <Table.Cell content={user.nickname} />
      <Table.Cell content={user.roleFormatted} />
      <Table.Cell content={user.email} />
      <Table.Cell content={user.statusFormatted} />
      <Table.Cell content={user.phoneFormatted} />
      <Table.Cell textAlign="right">
        <Show condition={accountIsAdmin}>
          <ItemControllers
            onUpdateClick={() => onUpdateButtonClick(user)}
            onDeleteClick={() => onDeleteButtonClick(user)}
            permissionsToUpdate={can.user.update}
            permissionsToDelete={can.user.delete}
          />
        </Show>
      </Table.Cell>
    </Table.Row>
  )
}
