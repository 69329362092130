import React, { useContext, useState } from 'react'
import { Button } from 'semantic-ui-react'

import { useCan } from '../../../../../../../../hooks/useCan'
import { can } from '../../../../../../../../config/permissions'
import { Show } from '../../../../../../../../components/Show'
import { AuthContext } from '../../../../../../../../contexts/AuthContext'

import { useOrder } from '../../../../hooks'
import { ConcludeOrderModal } from './ConcludeOrderModal'

export const ConcludeOrderAction: React.FC = () => {
  const { order } = useOrder()
  const { user } = useContext(AuthContext)
  const [showConcludeModal, setShowConcludeModal] = useState(false)
  const canConcludeOrder = useCan(can.order.conclude)

  const userIsAdmin = user?.role === 'ADMIN'
  const orderExist = !!order
  const orderCanBeConcluded =
    orderExist && order?.status !== 'OPEN' && order?.status !== 'CONCLUDED'

  function handleConcludeButtonClick() {
    setShowConcludeModal(true)
  }

  return (
    <Show
      condition={orderCanBeConcluded && userIsAdmin && canConcludeOrder}
      component={
        <>
          <Button
            type="button"
            content="Concluir pedido"
            floated="right"
            onClick={handleConcludeButtonClick}
          />
          <ConcludeOrderModal
            open={showConcludeModal}
            handleModal={setShowConcludeModal}
          />
        </>
      }
    />
  )
}
