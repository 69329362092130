import React from 'react'

interface SecureLinkProps {
  href: string
  content: string
}

export const SecureLink: React.FC<SecureLinkProps> = ({ href, content }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {content}
    </a>
  )
}
