import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'

import { can } from '../../../../../../config/permissions'
import { Button } from '../../../../../../components/Button'
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal'

import { useOrder, useOrderNfse } from '../../hooks'
import { SecureLink } from './SecureLink'

export const OrderNfseTable: React.FC = () => {
  const { order, loading } = useOrder()
  const { cancelNfse } = useOrderNfse()
  const [showCancelNfseModal, setShowCancelNfseModal] = useState(false)

  const { nfse } = order || {}

  if (!nfse) return null

  function handleCancelButtonClick() {
    setShowCancelNfseModal(true)
  }

  function handleConfirmCancel() {
    const data = { observation: 'Para correção' }
    cancelNfse(data, () => setShowCancelNfseModal(false))
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="Número" />
          <Table.HeaderCell content="Link" />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell content={nfse.number} />
          <Table.Cell>
            <SecureLink href={nfse.link} content="Acessar" />
          </Table.Cell>
          <Table.Cell textAlign="center">
            <Button
              icon="ban"
              size="mini"
              onClick={handleCancelButtonClick}
              popup="Cancelar NFS-e"
              can={can.nfse.cancel}
              disabled={order?.status === 'CONCLUDED'}
              invertedPopup
              circular
            />
            <ConfirmationModal
              open={showCancelNfseModal}
              handleModal={setShowCancelNfseModal}
              onConfirm={handleConfirmCancel}
              onDecline={() => setShowCancelNfseModal(false)}
              loading={loading}
            >
              Deseja cancelar a Nota Fiscal de Serviço para este pedido? Esta
              ação não poderá ser desfeita.
            </ConfirmationModal>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}
