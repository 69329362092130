import React from 'react'
import { Table } from 'semantic-ui-react'

import { useOrder } from '../../../hooks'
import { OrderItemsTableHeader } from './OrderItemsTableHeader'
import { OrderItemsTableRow } from './OrderItemsTableRow'

export const OrderItemsTable: React.FC = () => {
  const { order } = useOrder()

  const orderIsOpen = order?.status === 'OPEN'

  return (
    <Table selectable striped compact>
      <OrderItemsTableHeader />
      <Table.Body>
        {order?.items?.map(item => (
          <OrderItemsTableRow
            key={item.id}
            item={item}
            readOnly={!orderIsOpen}
          />
        ))}
      </Table.Body>
    </Table>
  )
}
