import React, { useState, useCallback } from 'react'

import {
  License,
  LicenseListResult,
  listLicenses
} from '../../services/license'
import { RegistrationContainer } from '../../components/RegistrationContainer'
import {
  PaginationProvider,
  Pagination
} from '../../contexts/PaginationContext'

import { LicensesTable } from './LicensesTable'

export interface QueryParameters {
  search?: string
}

export const Licenses: React.FC = () => {
  const [licenses, setLicenses] = useState<License[]>([])
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(false)

  const fetchLicenses = useCallback(
    (pagination: Pagination<QueryParameters>) => {
      const { page, limit, query } = pagination
      const { search } = query || {}

      const onSuccess = (result: LicenseListResult) => {
        setLicenses(result.licenses)
        setTotalItems(result.total)
      }

      Promise.resolve()
        .then(() => setLoading(true))
        .then(() => listLicenses({ page, limit, search }))
        .then(result => onSuccess(result))
        .finally(() => setLoading(false))
    },
    []
  )

  function handleUpdateLicense(data: License) {
    const newList = licenses.map(license => {
      return license.id === data.id ? data : license
    })

    setLicenses(newList)
  }

  return (
    <RegistrationContainer breadcrumbItems={[{ name: 'Licenças' }]}>
      <PaginationProvider totalItems={totalItems}>
        <LicensesTable
          licenses={licenses}
          totalItems={totalItems}
          loading={loading}
          fetchLicenses={fetchLicenses}
          onUpdateLicense={handleUpdateLicense}
        />
      </PaginationProvider>
    </RegistrationContainer>
  )
}
