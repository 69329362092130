import React from 'react'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'

import { PermissionGroup } from './PermissionGroup'
import { OnCheck } from './AllPermissions'

interface AccountPermissionsProps {
  onChange: (data: OnCheck) => void
  isChecked: (resource: string, actions: string[]) => boolean
}

export const AccountPermissions: React.FC<AccountPermissionsProps> = ({
  onChange,
  isChecked
}) => {
  function handleCheck(
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) {
    const value = data.value as string
    const checked = data.checked as boolean
    onChange({ value, checked })
  }

  return (
    <PermissionGroup label="Financeiro:">
      <Checkbox
        onChange={handleCheck}
        checked={isChecked('account', ['Get', 'List'])}
        value="account:Get,List"
        label="Ler"
      />
      <Checkbox
        onChange={handleCheck}
        checked={isChecked('account', ['Create'])}
        value="account:Create"
        label="Cadastrar"
      />
      <Checkbox
        onChange={handleCheck}
        checked={isChecked('account', ['Update', 'WriteOff'])}
        value="account:Update,WriteOff"
        label="Atualizar"
      />
      <Checkbox
        onChange={handleCheck}
        checked={isChecked('account', ['Delete'])}
        value="account:Delete"
        label="Excluir"
      />
    </PermissionGroup>
  )
}
