import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Service } from '../../../../../services/service'
import { DefaultLoader } from '../../../../../components/DefaultLoader'
import { pages } from '../../../../../config/pages'

import { useFetchService } from './hooks'

interface ServiceFormContextContent {
  service?: Service
  loading: boolean
  setLoading: (value: boolean) => void
  getServiceState: () => Service
  updateServiceState: (service: Service) => Service
}

export const ServiceFormContext =
  React.createContext<ServiceFormContextContent>(
    {} as ServiceFormContextContent
  )

interface ServiceFormProviderProps {
  children: React.ReactNode
}

export const ServiceFormProvider: React.FC<ServiceFormProviderProps> = ({
  children
}) => {
  const [service, setService] = useState<Service>()
  const [loading, setLoading] = useState(false)
  const [query] = useSearchParams()
  const fetchService = useFetchService()
  const navigate = useNavigate()

  function getServiceState() {
    if (!service) throw new Error('Nenhum serviço foi selecionado')

    return service
  }

  function updateServiceState(service: Service) {
    setService(service)

    return service
  }

  useEffect(() => {
    const serviceId = query.get('id')

    if (!serviceId) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => fetchService(serviceId))
      .then(data => {
        if (data) return updateServiceState(data)

        navigate(`${pages.services.path}/inclusao`)
        toast.warn('O serviço não foi encontrado, mas você pode criar um novo.')
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <ServiceFormContext.Provider
      value={{
        service,
        loading,
        setLoading,
        getServiceState,
        updateServiceState
      }}
    >
      <DefaultLoader active={loading} />
      {children}
    </ServiceFormContext.Provider>
  )
}
