import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import {
  issueCobPix as apiIssueCobPix,
  refreshCobPix as apiRefreshCobPix,
  cancelCobPix as apiCancelCobPix,
  CobPix
} from '../../../../../services/cobPix'
import { Charge } from '../../../../../services/charge'

import { errorHandler } from '../../../utils/errorHandler'
import { OrderFormContext } from '../context'

export function useOrderChargeCobPix() {
  const { setLoading, getOrderState, updateOrderState } =
    useContext(OrderFormContext)
  const [errors, setErrors] = useState<string[]>([])

  function _replaceChargeInOrder(charge: Charge) {
    const order = getOrderState()

    updateOrderState({
      ...order,
      charges: order.charges.map(oldCharge =>
        oldCharge.id === charge.id ? charge : oldCharge
      )
    })
  }

  function _findCharge(chargeId: string) {
    const order = getOrderState()

    return order.charges.find(charge => charge.id === chargeId)
  }

  function _isCobPixPaid(cobPix: CobPix): boolean {
    const paidValue = cobPix.pix
      .map(pix => pix.paidValue)
      .reduce((previous, current) => previous + current, 0)

    return paidValue >= cobPix.value
  }

  function issueCobPix(
    chargeId: string,
    data: Pick<CobPix, 'type' | 'dueDate'>,
    onFinally?: () => void
  ) {
    const handleSuccess = (cobPix: CobPix) => {
      const charge = _findCharge(chargeId)

      if (!charge) return

      const newCharge = { ...charge, cobPix }
      _replaceChargeInOrder(newCharge)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiIssueCobPix(order.id, chargeId, data))
      .then(data => handleSuccess(data))
      .then(() => toast.info('A cobrança Pix foi emitida'))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function refreshCobPix(chargeId: string) {
    const handleSuccess = (cobPix: CobPix) => {
      const charge = _findCharge(chargeId)

      if (!charge) return

      const paid = _isCobPixPaid(cobPix)

      const newCharge = {
        ...charge,
        paymentDate: paid ? cobPix.pix[0].paymentDate : undefined,
        paid,
        cobPix
      }
      _replaceChargeInOrder(newCharge)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiRefreshCobPix(order.id, chargeId))
      .then(data => handleSuccess(data))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
  }

  function cancelCobPix(chargeId: string, onFinally?: () => void) {
    const handleSuccess = () => {
      const charge = _findCharge(chargeId)

      if (!charge) return

      const newCharge = { ...charge, cobPix: undefined }
      _replaceChargeInOrder(newCharge)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiCancelCobPix(order.id, chargeId))
      .then(() => handleSuccess())
      .then(() => toast.info('A cobrança Pix foi cancelada'))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  return {
    errors,
    issueCobPix,
    refreshCobPix,
    cancelCobPix
  }
}
