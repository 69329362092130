import { SelectOption } from '../../../../../components/Form/Select'
import { Category } from '../../../../../services/category'
import { Group } from '../../../../../services/group'
import { Person } from '../../../../../services/person'

export function personToSelectOption(person: Person): SelectOption {
  return {
    key: person.id,
    value: person.id,
    text: `${person.document} - ${person.name}`
  }
}

export function categoryToSelectOption(category: Category): SelectOption {
  return {
    key: category.id,
    value: category.id,
    text: category.name
  }
}

export function groupToSelectOptions(group: Group): SelectOption {
  return {
    key: group.id,
    value: group.id,
    text: group.name
  }
}
