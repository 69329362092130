import api from './api'
import { User } from './users'

export type PersonType = 'INDIVIDUAL' | 'COMPANY'

export interface Person {
  id: string
  type: PersonType
  document?: string
  emails?: string[]
  phone?: string
  birthDate?: string
  name: string
  tradeName?: string
  postalCode?: string
  street?: string
  number?: string
  complement?: string
  neighborhood?: string
  city?: string
  ibgeCity?: string
  state?: string
  active: boolean
  partnerId: string
  responsibleId?: string
  responsible?: Person
  partner?: User
}

export interface PersonPaginate {
  page?: number
  limit?: number
  orderBy?: string
  order?: 'ASC' | 'DESC'
  search?: string
  searchBy?: string[]
}

export interface PersonListResult {
  people: Person[]
  total: number
}

export async function getPerson(id: string): Promise<Person> {
  const response = await api.get(`/people/${id}`)
  const person = response.status === 200 ? response.data : undefined

  return person
}

export async function listPeople(
  paginate?: PersonPaginate
): Promise<PersonListResult> {
  const { page, limit, orderBy, order, search } = paginate || {}
  const searchBy = paginate?.searchBy ? paginate.searchBy.join(',') : undefined
  const response = await api.get(`/people`, {
    params: { page, limit, orderBy, order, search, searchBy }
  })
  const { items: people, total } = response.data

  return {
    people,
    total
  }
}

export async function createPerson(data: Partial<Person>): Promise<Person> {
  const response = await api.post('/people', data)
  const person = response.data

  return person
}

export async function updatePerson(
  id: string,
  data: Partial<Person>
): Promise<Person> {
  const response = await api.put(`/people/${id}`, data)
  const person = response.data

  return person
}

export async function deletePerson(id: string): Promise<boolean> {
  const response = await api.delete(`/people/${id}`)
  const result = response.data

  return result.status
}
