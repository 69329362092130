import { SignatureRecurrence } from '../../../services/signature'
import { SignatureFormatted, formatSignature } from './formatSignature'
import {
  SignatureItemFormatted,
  formatSignatureItem
} from './formatSignatureItem'

const MULTIPLIER_MONTHS = {
  MONTHLY: 1,
  QUARTERLY: 3,
  SEMI_ANNUAL: 6,
  ANNUAL: 12
}

function calculateItemTotalPrice(item: SignatureItemFormatted): number {
  const {
    quantity,
    priceListItem: { price }
  } = item
  const calculatedItemTotalPrice = quantity * price
  return calculatedItemTotalPrice
}

export function addItemInSignature(
  signature: SignatureFormatted,
  item: SignatureItemFormatted
): SignatureFormatted {
  const calculatedItemTotalPrice = calculateItemTotalPrice(item)
  const newSignatureValue = signature.value + calculatedItemTotalPrice
  return {
    ...signature,
    items: [...signature.items, item],
    value: newSignatureValue
  }
}

export function replaceItemInSignature(
  signature: SignatureFormatted,
  item: SignatureItemFormatted
): SignatureFormatted {
  const oldItem = signature.items.find(i => i.id === item.id)
  if (!oldItem) return signature
  const oldCalculatedItemTotalPrice = calculateItemTotalPrice(oldItem)
  const newCalculatedItemTotalPrice = calculateItemTotalPrice(item)
  const newSignatureValue =
    signature.value - oldCalculatedItemTotalPrice + newCalculatedItemTotalPrice
  return {
    ...signature,
    items: signature.items.map(i => (i.id === item.id ? item : i)),
    value: newSignatureValue
  }
}

export function removeItemFromSignature(
  signature: SignatureFormatted,
  itemId: string
): SignatureFormatted {
  const item = signature.items.find(item => item.id === itemId)
  if (!item) return signature
  const calculatedItemTotalPrice = calculateItemTotalPrice(item)
  const newSignatureValue = signature.value - calculatedItemTotalPrice
  return {
    ...signature,
    items: signature.items.filter(i => i.id !== item.id),
    value: newSignatureValue
  }
}

function recalculateItemByNewRecurrence(
  oldItem: SignatureItemFormatted,
  oldRecurrence: SignatureRecurrence,
  newRecurrence: SignatureRecurrence
): SignatureItemFormatted {
  const oldPrice = oldItem.price
  const oldMultiplier = MULTIPLIER_MONTHS[oldRecurrence]
  const newMultiplier = MULTIPLIER_MONTHS[newRecurrence]
  const newPrice = (newMultiplier / oldMultiplier) * oldPrice
  return formatSignatureItem({ ...oldItem, price: newPrice })
}

export function recalculateSignatureByNewRecurrence(
  signature: SignatureFormatted,
  newRecurrence: SignatureRecurrence
): SignatureFormatted {
  const oldRecurrence = signature.recurrence
  const newSignature = {
    ...signature,
    recurrence: newRecurrence,
    items: signature.items.map(item =>
      recalculateItemByNewRecurrence(item, oldRecurrence, newRecurrence)
    )
  }
  return formatSignature(newSignature)
}
