import { useContext } from 'react'

import { OrderFormContext } from '../context'

export function useOrderCustomer() {
  const { customer, responsible, setCustomer } = useContext(OrderFormContext)

  return {
    customer,
    responsible,
    setCustomer
  }
}
