import { useState } from 'react'

import {
  User,
  UserRole,
  listUsers as apiListUsers
} from '../../../services/users'

export function useUsers() {
  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState(false)

  function listUsers(search: string, role: UserRole) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiListUsers({ search, role }))
      .then(data => setUsers(data.users))
      .finally(() => setLoading(false))
  }

  return {
    users,
    loading,
    listUsers
  }
}
