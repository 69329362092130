interface FormatValueOptions {
  thousandSeparator?: string
  decimalSeparator?: string
  precision?: number
  prefix?: string
}

function onlyNumbers(value: string): string {
  return value.replace(/\D+/g, '') || '0'
}

function numberToCurrency(value: string, precision: number): string {
  const power = Math.pow(10, precision)
  const float = parseFloat(value) / power

  return float.toFixed(precision)
}

function formatInteger(value: string, separator: string): string {
  return value.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`)
}

function getNegativeSignal(value: string): string {
  return value.indexOf('-') >= 0 ? '-' : ''
}

function joinIntegerAndDecimal(
  integer: string,
  decimal: string,
  separator: string
): string {
  return decimal ? integer + separator + decimal : integer
}

export function formatValue(
  input: string | number,
  options?: FormatValueOptions
): string {
  const {
    thousandSeparator = '.',
    decimalSeparator = ',',
    precision = 2,
    prefix = ''
  } = options || {}

  const value = typeof input === 'number' ? input.toFixed(precision) : input
  const negative = getNegativeSignal(value)
  const numbers = onlyNumbers(value)
  const currency = numberToCurrency(numbers, precision)
  const [integer, decimal] = currency.split('.')
  const formattedInteger = formatInteger(integer, thousandSeparator)
  const formattedValue = joinIntegerAndDecimal(
    formattedInteger,
    decimal,
    decimalSeparator
  )

  return `${prefix}${negative}${formattedValue}`
}
