import api from './api'

interface LicensePerson {
  id: string
  name: string
  document: string
}

export interface License {
  id: string
  computerName: string
  username?: string
  active: boolean
  system: string
  personId?: string
  createdAt: string
  updatedAt: string
  person: LicensePerson
}

export interface LicensePaginate {
  page?: number
  limit?: number
  search?: string
}

export interface LicenseListResult {
  licenses: License[]
  total: number
}

export async function listLicenses(
  paginate?: LicensePaginate
): Promise<LicenseListResult> {
  const { page, limit, search } = paginate || {}
  const response = await api.get('/licenses', {
    params: { page, limit, search }
  })
  const { items: licenses, total } = response.data

  return {
    licenses,
    total
  }
}

export async function setLicenseStatus(
  id: string,
  active: boolean
): Promise<License> {
  const response = await api.patch(`/licenses/${id}/active`, { active })
  const license = response.data

  return license
}

export async function deleteLicense(id: string): Promise<boolean> {
  const response = await api.delete(`/licenses/${id}`)
  const result = response.data

  return result.status
}
