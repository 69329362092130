import { useContext, useState } from 'react'

import {
  APIKey,
  createApiKey as apiCreateApiKey,
  updateApiKeyIps as apiUpdateApiKeyIps,
  deleteApiKey as apiDeleteApiKey
} from '../../../../../services/apiKey'

import { PersonFormContext } from '../context'

export function useApiKeys() {
  const { person, apiKeys, getPersonState, setApiKeys } =
    useContext(PersonFormContext)
  const [loading, setLoading] = useState(false)

  function createApiKey(data: Partial<APIKey>, onSuccess?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getPersonState())
      .then(person => apiCreateApiKey(person.id, data))
      .then(data => setApiKeys([...apiKeys, data]))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  function updateApiKeyIps(id: string, ips: string, onSuccess?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getPersonState())
      .then(person => apiUpdateApiKeyIps(id, person.id, ips))
      .then(data =>
        setApiKeys(apiKeys.map(apiKey => (apiKey.id === id ? data : apiKey)))
      )
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  function deleteApiKey(id: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getPersonState())
      .then(person => apiDeleteApiKey(person.id, id))
      .then(() => setApiKeys(apiKeys.filter(apiKey => apiKey.id !== id)))
      .finally(() => setLoading(false))
  }

  return {
    person,
    apiKeys,
    loading,
    createApiKey,
    updateApiKeyIps,
    deleteApiKey
  }
}
