import React from 'react'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'

import { PermissionGroup } from './PermissionGroup'
import { OnCheck } from './AllPermissions'
import { BlankOption } from './styles'

interface PersonPermissionsProps {
  onChange: (data: OnCheck) => void
  isChecked: (resource: string, actions: string[]) => boolean
}

export const PersonPermissions: React.FC<PersonPermissionsProps> = ({
  onChange,
  isChecked
}) => {
  function handleCheck(
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) {
    const value = data.value as string
    const checked = data.checked as boolean
    onChange({ value, checked })
  }

  return (
    <>
      <PermissionGroup label="Pessoa:">
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('person', ['Get', 'List'])}
          value="person:Get,List"
          label="Ler"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('person', ['Create'])}
          value="person:Create"
          label="Cadastrar"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('person', ['Update'])}
          value="person:Update"
          label="Atualizar"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('person', ['Delete'])}
          value="person:Delete"
          label="Excluir"
        />
      </PermissionGroup>
      <PermissionGroup>
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('person', ['ListApiKeys'])}
          value="person:ListApiKeys"
          label="Ler chaves de API"
        />
      </PermissionGroup>
    </>
  )
}
