import React, { useState, useEffect } from 'react'
import { useForm } from 'react-final-form'
import { SemanticWIDTHS } from 'semantic-ui-react'
import { toast } from 'react-toastify'

import { CnpjData, getCnpjData } from '../../../../../../../services/cnpj'
import { Masked } from '../../../../../../../components/Form'
import { masks } from '../../../../../../../utils/mask'
import { isValidCnpj } from '../../../../../../../utils/document'

import { sanitizeExternalPhone } from '../../../../../utils/formatPerson'

interface CnpjInputProps {
  name: string
  mask: string
  label: string
  width: SemanticWIDTHS
  onLoading?: (loading: boolean) => void
}

export const CnpjInput: React.FC<CnpjInputProps> = ({
  name,
  mask,
  label,
  width,
  onLoading
}) => {
  const form = useForm()
  const [currentValue, setCurrentValue] = useState<string>()

  function removeMask(value: string): string {
    return value.replace(/[^0-9]/g, '').trim()
  }

  useEffect(() => {
    const field = form.getFieldState(name)
    const value = field?.value
    if (!value) return
    setCurrentValue(removeMask(value))
  }, [])

  function setFields(data: CnpjData) {
    const emailField = form.getFieldState('emailsFormatted')
    const phoneField = form.getFieldState('phoneFormatted')

    const sanitizedPhone = sanitizeExternalPhone(data.telefone)
    const email = emailField?.value || data.email
    const phone = phoneField?.value || masks.phone(`55${sanitizedPhone}`)

    form.change('name', data.nome)
    form.blur('name')
    form.change('birthDateFormatted', data.abertura)
    form.change('tradeName', data.fantasia)
    form.change('emailsFormatted', email)
    form.change('phoneFormatted', phone)
    form.change('postalCode', data.endereco.cep)
    form.change('street', data.endereco.logradouro)
    form.change('number', data.endereco.numero)
    form.change('complement', data.endereco.complemento)
    form.change('neighborhood', data.endereco.bairro)
    form.change('city', data.endereco.municipio)
    form.change('state', data.endereco.uf)
  }

  function handleApiError(err: any) {
    if (!err.response) return

    const message =
      err.response.data.message + '. ' + err.response.data.details?.join('. ')
    toast.error(message)
  }

  function handleCnpjBlur(event: any) {
    const { value } = event.target
    const cnpj = removeMask(value)

    if (cnpj === currentValue) return

    if (cnpj.length < 14) return

    const isValid = isValidCnpj(cnpj)

    if (!isValid) {
      form.focus(name)
      return toast.error('O valor digitado não é um CNPJ válido')
    }

    Promise.resolve()
      .then(() => onLoading?.(true))
      .then(() => setCurrentValue(cnpj))
      .then(() => getCnpjData(cnpj))
      .then(data => setFields(data))
      .catch(err => handleApiError(err))
      .finally(() => onLoading?.(false))
  }

  return (
    <Masked
      name={name}
      mask={mask}
      label={label}
      width={width}
      onBlur={handleCnpjBlur}
    />
  )
}
