import { useState } from 'react'

import {
  PermissionsScheme,
  getPermissionsScheme as apiGetPermissionsScheme
} from '../../../../../services/permission'

export function usePermissions() {
  const [scheme, setScheme] = useState<PermissionsScheme>()
  const [loading, setLoading] = useState(false)

  function getPermissionsScheme() {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiGetPermissionsScheme())
      .then(data => setScheme(data))
      .finally(() => setLoading(false))
  }

  return {
    scheme,
    loading,
    getPermissionsScheme
  }
}
