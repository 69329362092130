import { useContext } from 'react'

import { AuthContext } from '../contexts/AuthContext'

function compare(required: string, permissions: string[]): boolean {
  if (permissions.includes(required)) return true

  const [resource, action] = required.split(':')

  if (permissions.includes(`${resource}:*`)) return true

  if (permissions.includes(`*:${action}`)) return true

  return false
}

export function useCan(required: string[]): boolean {
  const { permissions } = useContext(AuthContext)

  if (permissions.includes('*:*')) return true

  return required.every(permission => compare(permission, permissions))
}
