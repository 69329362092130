import api from './api'

export async function linkAPartner(
  employeeId: string,
  partnerId: string
): Promise<void> {
  await api.post(`/users/${employeeId}/partners/${partnerId}`)
}

export async function unlinkAPartner(
  employeeId: string,
  partnerId: string
): Promise<void> {
  await api.delete(`/users/${employeeId}/partners/${partnerId}`)
}
