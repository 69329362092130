import axios from 'axios'

const baseURL = process.env.REACT_APP_CNPJ_URL
const token = process.env.REACT_APP_CNPJ_TOKEN

export const api = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
    'X-Strategy': 'token'
  }
})

export interface CnpjData {
  cnpj: string
  tipo: string
  porte: string
  nome: string
  fantasia: string
  abertura: string
  atividadePrincipal: Atividade[]
  atividadesSecundarias: Atividade[]
  naturezaJuridica: string
  endereco: Endereco
  email: string
  telefone: string
  efr: null
  situacao: string
  dataSituacao: string
  motivoSituacao: null
  situacaoEspecial: null
  dataSituacaoEspecial: null
  capitalSocial: number
  quadroSocietario: QuadroSocietario[]
  ultimaAtualizacao: Date
}

export interface Atividade {
  codigo: string
  descricao: string
}

export interface Endereco {
  cep: string
  logradouro: string
  numero: string
  complemento: null
  bairro: string
  municipio: string
  uf: string
}

export interface QuadroSocietario {
  nome: string
  qual: string
  paisOrigem: null
  nomeRepLegal: null
  qualRepLegal: null
}

export async function getCnpjData(cnpj: string): Promise<CnpjData> {
  const response = await api.get(`/consulta-federal/${cnpj}`)
  const data = response.status === 200 ? response.data : undefined

  return data
}
