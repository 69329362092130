import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'

import { FormActionsContainer } from '../../../../../../../styles/FormActionsContainer'

import { CreateApiKeyModal } from './CreateApiKeyModal'

export const ApiKeysTableActions: React.FC = () => {
  const [createModal, setCreateModal] = useState(false)

  function handleOpenModalClick() {
    setCreateModal(true)
  }

  return (
    <FormActionsContainer>
      <Button
        content="Criar chave"
        floated="right"
        onClick={handleOpenModalClick}
        primary
      />
      <CreateApiKeyModal open={createModal} handleModal={setCreateModal} />
    </FormActionsContainer>
  )
}
