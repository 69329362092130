import { useContext } from 'react'

import {
  createCharge,
  updateCharge,
  deleteCharge,
  Charge
} from '../../../../../services/charge'

import { OrderFormContext } from '../context'

export function useOrderCharge() {
  const { setLoading, getOrderState, updateOrderState } =
    useContext(OrderFormContext)

  function addCharge(data: Partial<Charge>, onSuccess?: () => void) {
    const order = getOrderState()

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => createCharge(order.id, data))
      .then(data =>
        updateOrderState({
          ...order,
          charges: [...order?.charges, data]
        })
      )
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  function modifyCharge(chargeId: string, data: Partial<Charge>) {
    const order = getOrderState()

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => updateCharge(order.id, chargeId, data))
      .then(data =>
        updateOrderState({
          ...order,
          charges: order.charges.map(charge =>
            charge.id === chargeId ? data : charge
          )
        })
      )
      .finally(() => setLoading(false))
  }

  function removeCharge(chargeId: string) {
    const order = getOrderState()

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => deleteCharge(order.id, chargeId))
      .then(() =>
        updateOrderState({
          ...order,
          charges: order?.charges.filter(charge => charge.id !== chargeId)
        })
      )
      .finally(() => setLoading(false))
  }

  return {
    addCharge,
    modifyCharge,
    removeCharge
  }
}
