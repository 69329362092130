import { useState, useEffect } from 'react'

import { getAppVersion } from '../utils/application'
import { getBackEndVersion } from '../services/version'

export function useAppVersion() {
  const frontendVersion = getAppVersion()
  const [backendVersion, setBackendVersion] = useState<string>()

  useEffect(() => {
    Promise.resolve()
      .then(() => getBackEndVersion())
      .then(setBackendVersion)
  }, [])

  return {
    frontendVersion,
    backendVersion
  }
}
