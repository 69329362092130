import React, { useEffect } from 'react'
import {
  Select as SemanticSelect,
  DropdownProps,
  DropdownOnSearchChangeData,
  SemanticWIDTHS
} from 'semantic-ui-react'
import { useForm, useField } from 'react-final-form'

import { useFieldError } from '../../../hooks/useFieldError'

import { FormField } from './styles'

export interface SelectOption {
  key: string | number
  value: string | number
  text: string
}

interface SelectProps {
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
  width?: SemanticWIDTHS
  fluid?: boolean
  readOnly?: boolean
  loading?: boolean
  search?: boolean
  multiple?: boolean
  clearable?: boolean
  options: SelectOption[]
  selectOnBlur?: boolean
  uppercase?: boolean
  lowercase?: boolean
  noResultsMessage?: string
  onSearchChange?: (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownOnSearchChangeData
  ) => void
  onChange?: (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => void
  onBlur?: (event: React.FocusEvent<HTMLElement>, data: DropdownProps) => void
}

export const Select: React.FC<SelectProps> = ({
  name,
  options,
  multiple,
  loading,
  uppercase,
  lowercase,
  search,
  onChange,
  noResultsMessage = 'Nenhum resultado encontrado',
  selectOnBlur = false,
  readOnly,
  ...rest
}) => {
  const form = useForm()
  const field = useField(name)
  const error = useFieldError(field)

  useEffect(() => {
    form.registerField(name, () => undefined, {})
  }, [])

  function handleChange(
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) {
    form.change(name, data.value)
    form.blur(name)
    onChange?.(event, data)
  }

  const preValue = field.input.value
  const value = multiple ? (!preValue ? [] : preValue) : preValue

  if (readOnly) {
    const text = options.find(option => option.value === value)?.text

    return (
      <FormField control="input" name={name} value={text} {...rest} readOnly />
    )
  }

  return (
    <FormField
      {...rest}
      control={SemanticSelect}
      name={name}
      options={options}
      multiple={multiple}
      loading={loading}
      search={search}
      value={value}
      uppercase={uppercase && +uppercase}
      lowercase={lowercase && +lowercase}
      onChange={handleChange}
      error={error}
      noResultsMessage={noResultsMessage}
      selectOnBlur={selectOnBlur}
    />
  )
}
