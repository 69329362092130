import React, { useState } from 'react'
import { Header, Button } from 'semantic-ui-react'

import { FormActionsContainer } from '../../../../../../../../styles/FormActionsContainer'
import { Show } from '../../../../../../../../components/Show'

import { useOrder } from '../../../../hooks'
import { AddChargeModal } from './AddChargeModal'

export const OrderChargesHeader: React.FC = () => {
  const { order } = useOrder()
  const [showAddChargeModal, setShowAddChargeModal] = useState(false)

  function handleOpenAddChargeModalClick() {
    setShowAddChargeModal(true)
  }

  const orderIsOpen = order?.status === 'OPEN'
  const orderTotalIsZero = order?.totalOrder === 0

  return (
    <FormActionsContainer>
      <Header as="h3" content="Cobranças" floated="left" />
      <Show condition={!orderTotalIsZero && orderIsOpen}>
        <Button
          content="Adicionar"
          floated="right"
          onClick={handleOpenAddChargeModalClick}
          primary
        />
      </Show>
      <AddChargeModal
        open={showAddChargeModal}
        handleModal={setShowAddChargeModal}
      />
    </FormActionsContainer>
  )
}
