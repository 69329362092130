import React from 'react'
import { toast } from 'react-toastify'

import { ConfirmationModal } from '../../../../../components/ConfirmationModal'

import { OrderFormatted } from '../../../utils/formatOrder'

import { ConfirmationContent } from './styles'

interface ConfimDeleteModalProps {
  open: boolean
  order?: OrderFormatted
  loading?: boolean
  handleModal: (open: boolean) => void
  onConfirm: (order: OrderFormatted) => void
  onDecline: () => void
}

export const ConfirmDeleteModal: React.FC<ConfimDeleteModalProps> = ({
  open,
  order,
  loading,
  handleModal,
  onConfirm,
  onDecline
}) => {
  function handleConfirmDelete() {
    if (!order) {
      toast.error('Nenhum pedido selecionado')

      return
    }

    onConfirm(order)
  }

  return (
    <ConfirmationModal
      open={open}
      loading={loading}
      handleModal={handleModal}
      onConfirm={handleConfirmDelete}
      onDecline={onDecline}
      confirmationCode
    >
      {order ? (
        <ConfirmationContent>
          <span>Deseja excluir o pedido?</span>
          <strong>Código do pedido: {order.code}</strong>
          <strong>{order.customer.name}</strong>
        </ConfirmationContent>
      ) : null}
    </ConfirmationModal>
  )
}
