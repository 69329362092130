import React from 'react'

import { ConfirmationModal } from '../../../../../../../../../components/ConfirmationModal'
import { AccountFormatted } from '../../../../../../../utils/formatAccount'

import { useAccount } from '../../../../../hooks'

interface ConfirmDeleteModalProps {
  open: boolean
  account: AccountFormatted
  handleModal: (open: boolean) => void
  onDelete: () => void
}

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  open,
  account,
  handleModal,
  onDelete
}) => {
  const { loading, deleteAccount } = useAccount()

  function handleDeleteAccount() {
    deleteAccount(account.id, () => {
      handleModal(false)
      onDelete()
    })
  }

  return (
    <ConfirmationModal
      open={open}
      loading={loading}
      handleModal={handleModal}
      onConfirm={handleDeleteAccount}
      onDecline={() => handleModal(false)}
    >
      <span>Deseja excluir a conta?</span>
      <br />
      <strong>{account.description}</strong>
    </ConfirmationModal>
  )
}
