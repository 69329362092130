import React, { useEffect, useState } from 'react'
import { Modal, Form, Button } from 'semantic-ui-react'
import { Form as FinalForm } from 'react-final-form'

import { Account } from '../../../../../../../../../services/account'
import { DateInput, Currency } from '../../../../../../../../../components/Form'
import { localeDateToDate } from '../../../../../../../../../utils/date'
import { stringToNumber } from '../../../../../../../../../utils/number'

import { formatAccount } from '../../../../../../../utils/formatAccount'
import { useAccount } from '../../../../../hooks'

interface WriteOffModalProps {
  open: boolean
  account: Account
  handleModal: (open: boolean) => void
  onSave?: (account: Account) => void
}

interface AccountFormatted extends Account {
  dueDateFormatted: string
  amountFormatted: string
  paymentDateFormatted?: string
  amountPaidFormatted: string
}

export const WriteOffModal: React.FC<WriteOffModalProps> = ({
  open,
  account: data,
  handleModal,
  onSave
}) => {
  const { loading, writeOffAccount } = useAccount()
  const [account, setAccount] = useState<AccountFormatted>()

  useEffect(() => {
    setAccount(formatAccount(data))
  }, [data])

  function handleSubmit(values: any) {
    const paymentDate = localeDateToDate(
      values.paymentDateFormatted
    ).toISOString()
    const amountPaid = stringToNumber(values.amountPaidFormatted)
    const data = { paymentDate, amountPaid }

    writeOffAccount(values.id, data, account => {
      onSave?.(account)
    })
  }

  return (
    <Modal
      open={open}
      onClose={() => handleModal(false)}
      size="mini"
      closeIcon
      closeOnDimmerClick
    >
      <Modal.Header content="Confirmação de pagamento" />
      <Modal.Content>
        <p>{account?.description}</p>
        <p>Data de vencimento: {account?.dueDateFormatted}</p>
        <p>Valor: {account?.amountFormatted}</p>
        <br />
        <FinalForm onSubmit={handleSubmit} initialValues={account}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} id="write-off-form">
              <input name="id" hidden />
              <DateInput
                name="paymentDateFormatted"
                label="Data do pagamento"
              />
              <Currency name="amountPaidFormatted" label="Valor pago" />
            </Form>
          )}
        </FinalForm>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="submit"
          content="Dar baixa"
          loading={loading}
          disabled={loading}
          form="write-off-form"
          primary
        />
      </Modal.Actions>
    </Modal>
  )
}
