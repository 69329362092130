import React, { useContext, useState } from 'react'

import { AccountFormatted } from '../../../../../../../utils/formatAccount'
import { Show } from '../../../../../../../../../components/Show'
import { Button } from '../../../../../../../../../components/Button'
import { PaginationContext } from '../../../../../../../../../contexts/PaginationContext'

import { useCobPix } from '../../../../../hooks'
import { CobPixQrCodeModal } from './CobPixQrCodeModal'

interface ViewCobPixQrCodeActionProps {
  account: AccountFormatted
}

export const ViewCobPixQrCodeAction: React.FC<ViewCobPixQrCodeActionProps> = ({
  account
}) => {
  const { pagination } = useContext(PaginationContext)
  const [showQrCodeModal, setShowQrCodeModal] = useState(false)
  const { cobPixInfo, loading, getCobPixInfo } = useCobPix()

  function handleButtonClick() {
    const { orderId } = account

    if (!orderId) return

    getCobPixInfo(orderId, account.id, () => {
      setShowQrCodeModal(true)
    })
  }

  const isCobPixReceivable =
    account.paymentType === 'COB_PIX' && pagination.query.type === 'RECEIVABLE'
  const accountIsPaid = account.isPaid

  return (
    <Show condition={isCobPixReceivable && !accountIsPaid}>
      <Button
        icon="qrcode"
        size="mini"
        popup="Ver QR Code"
        loading={loading}
        onClick={handleButtonClick}
        invertedPopup
        circular
      />
      <CobPixQrCodeModal
        open={showQrCodeModal}
        cobPix={cobPixInfo}
        handleModal={setShowQrCodeModal}
      />
    </Show>
  )
}
