import React, { useContext } from 'react'
import { Table } from 'semantic-ui-react'

import { PaginationContext } from '../../../../../../../../contexts/PaginationContext'
import { Show } from '../../../../../../../../components/Show'

import { AccountFormatted } from '../../../../../../utils/formatAccount'
import { AmountCell } from '../../../../styles'
import {
  BoletoRefreshAction,
  ViewBoletoPdfAction,
  AddPixProofAction,
  ViewPixProofAction,
  CobPixRefreshAction,
  ViewCobPixQrCodeAction,
  BolepixRefreshAction,
  ViewBolepixPdfAction
} from './actions'

interface ReceivablesTableRowProps {
  account: AccountFormatted
  onRefresh: (account: AccountFormatted) => void
}

export const ReceivablesTableRow: React.FC<ReceivablesTableRowProps> = ({
  account,
  onRefresh
}) => {
  const { pagination } = useContext(PaginationContext)

  return (
    <Table.Row negative={account.isOverdue} positive={account.isPaid}>
      <Table.Cell content={account.person.name} />
      <Table.Cell content={account.partner?.nickname} />
      <Table.Cell content={account.group?.name} />
      <Table.Cell content={account.description} />
      <Table.Cell content={account.paymentTypeFormatted} />
      <Table.Cell content={account.dueDateFormatted} />
      <AmountCell content={account.amountFormatted} />
      <Show condition={pagination.query.status === 'PAID'}>
        <Table.Cell content={account.amountPaidFormatted} />
        <Table.Cell content={account.paymentDateFormatted} />
      </Show>
      <Table.Cell textAlign="right">
        <ViewBoletoPdfAction account={account} />
        <BoletoRefreshAction account={account} onRefresh={onRefresh} />
        <AddPixProofAction account={account} onAddProof={onRefresh} />
        <ViewPixProofAction account={account} />
        <ViewCobPixQrCodeAction account={account} />
        <CobPixRefreshAction account={account} onRefresh={onRefresh} />
        <ViewBolepixPdfAction account={account} />
        <BolepixRefreshAction account={account} onRefresh={onRefresh} />
      </Table.Cell>
    </Table.Row>
  )
}
