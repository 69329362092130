import React, { useContext } from 'react'

import { Show } from '../../../../components/Show'
import { useCan } from '../../../../hooks/useCan'
import { can } from '../../../../config/permissions'
import { AuthContext } from '../../../../contexts/AuthContext'

import { PersonFormProvider } from './context'
import { PersonMain, ApiKeysTable } from './components'

export const PersonForm: React.FC = () => {
  const canReadApiKeys = useCan(can.person.readApiKeys)
  const { user } = useContext(AuthContext)

  return (
    <PersonFormProvider>
      <PersonMain />
      <Show
        condition={canReadApiKeys && user?.role === 'ADMIN'}
        component={<ApiKeysTable />}
      />
    </PersonFormProvider>
  )
}
