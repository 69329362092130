import React, { useState } from 'react'

import { Show } from '../../../../../../../../../../components/Show'
import { Button } from '../../../../../../../../../../components/Button'
import { can } from '../../../../../../../../../../config/permissions'
import { PDFViewerModal } from '../../../../../../../../../../components/PDFViewerModal'

import { ChargeFormatted } from '../../../../../../../../utils/formatCharge'
import { useOrder, useOrderChargeBoleto } from '../../../../../../hooks'
import { ChargeConfirmationModal } from '../components/ChargeConfirmationModal'

interface BoletoActionsProps {
  charge: ChargeFormatted
}

export const BoletoActions: React.FC<BoletoActionsProps> = ({ charge }) => {
  const { order, loading } = useOrder()
  const { issueBoleto, getBoletoPdf, refreshStatusBoleto, cancelBoleto } =
    useOrderChargeBoleto()
  const [showIssueModal, setShowIssueModal] = useState(false)
  const [showPdfModal, setShowPdfModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [boletoPdf, setBoletoPdf] = useState<Buffer>()

  function handleIssueButtonClick() {
    setShowIssueModal(true)
  }

  function handleRefreshButtonClick() {
    refreshStatusBoleto(charge.id)
  }

  function handlePdfButtonClick() {
    getBoletoPdf(charge.id, content => {
      setBoletoPdf(content)
      setShowPdfModal(true)
    })
  }

  function handleCancelButtonClick() {
    setShowCancelModal(true)
  }

  function handleConfirmIssue() {
    const { dueDate } = charge
    issueBoleto(charge.id, { type: 'INTER', dueDate }, () => {
      setShowIssueModal(false)
    })
  }

  function handleConfirmCancel() {
    cancelBoleto(charge.id, () => {
      setShowCancelModal(false)
    })
  }

  const { boleto } = charge
  const show = order?.status !== 'OPEN' && charge.paymentType === 'BOLETO'
  const boletoExist = !!boleto
  const boletoIsOpened = boleto?.status === 'OPENED'
  const boletoIsOverdue = boleto?.status === 'OVERDUE'
  const allowToViewPdf = boletoIsOpened || boletoIsOverdue
  const allowToRefresh = boletoIsOpened || boletoIsOverdue
  const allowToCancel = boletoIsOpened || boletoIsOverdue

  return (
    <Show condition={show}>
      <Button
        icon="arrow up"
        size="mini"
        disabled={boletoExist}
        onClick={handleIssueButtonClick}
        popup="Emitir boleto"
        invertedPopup
        circular
      />
      <ChargeConfirmationModal
        open={showIssueModal}
        charge={charge}
        title="Emitir boleto"
        question="Confirmar emissão de boleto"
        loading={loading}
        handleModal={setShowIssueModal}
        onConfirm={handleConfirmIssue}
      />
      <Button
        icon="refresh"
        size="mini"
        disabled={!allowToRefresh}
        onClick={handleRefreshButtonClick}
        popup="Atualizar status"
        invertedPopup
        circular
      />
      <Button
        icon="file alternate"
        size="mini"
        disabled={!allowToViewPdf}
        onClick={handlePdfButtonClick}
        popup="Ver PDF"
        invertedPopup
        circular
      />
      <PDFViewerModal
        open={showPdfModal}
        handleModal={setShowPdfModal}
        title="Boleto"
        pdf={boletoPdf}
      />
      <Button
        icon="ban"
        size="mini"
        disabled={!allowToCancel}
        onClick={handleCancelButtonClick}
        popup="Cancelar cobrança"
        can={can.boleto.cancel}
        invertedPopup
        circular
      />
      <ChargeConfirmationModal
        open={showCancelModal}
        charge={charge}
        title="Cancelar boleto"
        question="Confirmar cancelamento de boleto"
        loading={loading}
        handleModal={setShowCancelModal}
        onConfirm={handleConfirmCancel}
      />
    </Show>
  )
}
