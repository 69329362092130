import Joi from 'joi'

const messages = {
  description: {
    required: 'Descrição do produto é obrigatória',
    max: 'Permitido no máximo 60 caracteres'
  }
}

export const productSchema = Joi.object({
  id: Joi.string().uuid({ version: ['uuidv4'] }),
  description: Joi.string().max(60).trim().required().messages({
    'any.required': messages.description.required,
    'string.empty': messages.description.required,
    'string.max': messages.description.max
  })
})
