import { useState } from 'react'

import { listUsers as apiListUser, User } from '../../../../../services/users'

export function usePartner() {
  const [partners, setPartners] = useState<User[]>([])
  const [loading, setLoading] = useState(false)

  function listPartners(search?: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiListUser({ search, role: 'PARTNER' }))
      .then(data => setPartners(data.users))
      .finally(() => setLoading(false))
  }

  return {
    partners,
    loading,
    listPartners
  }
}
