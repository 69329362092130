import { Person } from '../../../services/person'
import { masks } from '../../../utils/mask'

export interface PersonFormatted extends Person {
  phoneFormatted?: string
}

export function formatPerson(person: Person): PersonFormatted {
  const { phone } = person

  return {
    ...person,
    phoneFormatted: phone ? masks.phone(phone) : undefined
  }
}
