import {
  Certificate,
  deleteCertificate,
  getCertificate
} from '../../../../../../services/certificate'
import { Person, getPerson } from '../../../../../../services/person'
import { Order, createOrder, getOrder } from '../../../../../../services/order'
import { User, getUser } from '../../../../../../services/users'
import { Service, getService } from '../../../../../../services/service'
import {
  PriceListItem,
  listPriceListItems
} from '../../../../../../services/priceListItem'
import { createOrderItem } from '../../../../../../services/orderItem'

export function useCreateCertificateOrder() {
  async function _getCertificate(certificateId: string): Promise<Certificate> {
    const certificate = await getCertificate(certificateId)

    if (!certificate) throw new Error('O certificado não foi encontrado')

    return certificate
  }

  async function _getCustomerFromCertificate(
    certificate: Certificate
  ): Promise<Person> {
    return getPerson(certificate.customerId)
  }

  async function _createOrderForCustomer(customer: Person): Promise<Order> {
    return createOrder({ customerId: customer.id })
  }

  async function _deleteCertificate(certificate: Certificate): Promise<void> {
    await deleteCertificate(certificate.id)
  }

  async function _getCustomerPartner(customer: Person): Promise<User> {
    return getUser(customer.partnerId)
  }

  async function _getServiceOfTheCertificate(
    certificate: Certificate
  ): Promise<Service> {
    return getService(certificate.serviceId)
  }

  async function _getPriceListItem(
    partner: User,
    service: Service
  ): Promise<PriceListItem | undefined> {
    if (!partner.priceListId) return

    const { priceListItems } = await listPriceListItems(partner.priceListId, {
      search: service.description
    })
    const [priceListItem] = priceListItems

    if (!priceListItem) {
      throw new Error('O serviço não foi encontrado na tabela de preços')
    }

    return priceListItem
  }

  async function _addItemInOrder(
    order: Order,
    priceListItem: PriceListItem
  ): Promise<void> {
    await createOrderItem(order.id, {
      priceListId: priceListItem.priceListId,
      priceListItemId: priceListItem.id,
      quantity: 1,
      addition: 0,
      discount: 0
    })
  }

  async function _loadOrder(order: Order): Promise<Order> {
    return getOrder(order.id) as Promise<Order>
  }

  async function createCertificateOrder(certificateId: string): Promise<Order> {
    const certificate = await _getCertificate(certificateId)
    const customer = await _getCustomerFromCertificate(certificate)
    const order = await _createOrderForCustomer(customer)
    await _deleteCertificate(certificate)
    const partner = await _getCustomerPartner(customer)
    const service = await _getServiceOfTheCertificate(certificate)
    const priceListItem = await _getPriceListItem(partner, service)

    if (priceListItem) {
      await _addItemInOrder(order, priceListItem)
    }

    return _loadOrder(order)
  }

  return createCertificateOrder
}
