import api from './api'
import { Account } from './account'
import { Boleto } from './boleto'
import { Pix } from './pix'
import { CobPix } from './cobPix'
import { Bolepix } from './bolepix'

export interface Charge extends Account {
  boleto?: Boleto
  pix?: Pix
  cobPix?: CobPix
  bolepix?: Bolepix
}

export async function createCharge(
  orderId: string,
  data: Partial<Charge>
): Promise<Charge> {
  const response = await api.post(`/orders/${orderId}/charges`, data)
  const charge = response.data

  return charge
}

export async function updateCharge(
  orderId: string,
  chargeId: string,
  data: Partial<Charge>
): Promise<Charge> {
  const response = await api.put(`/orders/${orderId}/charges/${chargeId}`, data)
  const charge = response.data

  return charge
}

export async function deleteCharge(
  orderId: string,
  chargeId: string
): Promise<boolean> {
  const response = await api.delete(`/orders/${orderId}/charges/${chargeId}`)
  const status = response.data.status

  return status
}
