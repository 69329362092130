import { useState } from 'react'
import { toast } from 'react-toastify'

import { deleteOrder as apiDeleteOrder } from '../../../../../services/order'

export function useOrder() {
  const [loading, setLoading] = useState(false)

  function deleteOrder(id: string, onSuccess?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiDeleteOrder(id))
      .then(() => toast.info('O pedido foi excluído'))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  return {
    loading,
    deleteOrder
  }
}
