import React from 'react'
import { toast } from 'react-toastify'

import { ConfirmationModal } from '../../../../../components/ConfirmationModal'
import { Product } from '../../../../../services/product'

interface ConfimDeleteModalProps {
  open: boolean
  product?: Product
  loading?: boolean
  handleModal: (open: boolean) => void
  onConfirm: (order: Product) => void
  onDecline: () => void
}

export const ConfirmDeleteModal: React.FC<ConfimDeleteModalProps> = ({
  open,
  product,
  loading,
  handleModal,
  onConfirm,
  onDecline
}) => {
  function handleConfirmDelete() {
    if (!product) {
      toast.error('Nenhum produto selecionado')

      return
    }

    onConfirm(product)
  }

  return (
    <ConfirmationModal
      open={open}
      loading={loading}
      handleModal={handleModal}
      onConfirm={handleConfirmDelete}
      onDecline={onDecline}
    >
      <span>Deseja excluir o produto?</span>
      <br />
      {product ? <strong>{product.description}</strong> : null}
    </ConfirmationModal>
  )
}
