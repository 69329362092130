import { Product, getProduct } from '../../../../../../services/product'

export function useFetchProduct() {
  async function fetchProduct(id: string): Promise<Product | undefined> {
    try {
      const product = await getProduct(id)
      return product
    } catch (err: any) {
      const status = err.response?.status

      if (status === 404) return

      throw err
    }
  }

  return fetchProduct
}
