import styled from 'styled-components'

export const Viewer = styled.iframe`
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  border: none;
`

export const Container = styled.div`
  height: 100vh;
  width: 70vw;
  padding: 20px 10px;
  margin: 0 auto;
`
