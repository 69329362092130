import React, { useState } from 'react'

import { Show } from '../../../../../../../../../../components/Show'
import { Button } from '../../../../../../../../../../components/Button'
import { can } from '../../../../../../../../../../config/permissions'
import { PDFViewerModal } from '../../../../../../../../../../components/PDFViewerModal'

import { ChargeFormatted } from '../../../../../../../../utils/formatCharge'
import { useOrder, useOrderChargeBolepix } from '../../../../../../hooks'
import { ChargeConfirmationModal } from '../components/ChargeConfirmationModal'

interface BolepixActionsProps {
  charge: ChargeFormatted
}

export const BolepixActions: React.FC<BolepixActionsProps> = ({ charge }) => {
  const { order, loading } = useOrder()
  const { getBolepixPdf, issueBolepix, refreshBolepix, cancelBolepix } =
    useOrderChargeBolepix()
  const [showPdfModal, setShowPdfModal] = useState(false)
  const [showIssueModal, setShowIssueModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [bolepixPdf, setBolepixPdf] = useState<Buffer>()

  function handlePdfButtonClick() {
    getBolepixPdf(charge.id, content => {
      setBolepixPdf(content)
      setShowPdfModal(true)
    })
  }

  function handleIssueButtonClick() {
    setShowIssueModal(true)
  }

  function handleRefreshButtonClick() {
    refreshBolepix(charge.id)
  }

  function handleCancelButtonClick() {
    setShowCancelModal(true)
  }

  function handleConfirmIssue() {
    const { dueDate } = charge
    issueBolepix(charge.id, { type: 'INTER', dueDate }, () => {
      setShowIssueModal(false)
    })
  }

  function handleConfirmCancel() {
    cancelBolepix(charge.id, () => {
      setShowCancelModal(false)
    })
  }

  const { bolepix } = charge
  const show = order?.status !== 'OPEN' && charge.paymentType === 'BOLEPIX'
  const bolepixExist = !!bolepix
  const bolepixIsOpened = bolepix?.status === 'OPENED'
  const bolepixIsOverdue = bolepix?.status === 'OVERDUE'
  const allowToViewPdf = bolepixIsOpened || bolepixIsOverdue
  const allowToRefresh = bolepixIsOpened || bolepixIsOverdue
  const allowToCancel = bolepixIsOpened || bolepixIsOverdue

  return (
    <Show condition={show}>
      <Button
        icon="arrow up"
        size="mini"
        disabled={bolepixExist}
        onClick={handleIssueButtonClick}
        can={can.bolepix.issue}
        popup="Emitir Bolepix"
        invertedPopup
        circular
      />
      <ChargeConfirmationModal
        open={showIssueModal}
        charge={charge}
        title="Emitir cobrança"
        question="Confirmar emissão de cobrança"
        loading={loading}
        handleModal={setShowIssueModal}
        onConfirm={handleConfirmIssue}
      />
      <Button
        icon="refresh"
        size="mini"
        disabled={!allowToRefresh}
        onClick={handleRefreshButtonClick}
        can={can.bolepix.refresh}
        popup="Atualizar status"
        invertedPopup
        circular
      />
      <Button
        icon="file alternate"
        size="mini"
        disabled={!allowToViewPdf}
        onClick={handlePdfButtonClick}
        popup="Ver PDF"
        invertedPopup
        circular
      />
      <PDFViewerModal
        open={showPdfModal}
        handleModal={setShowPdfModal}
        title="Bolepix"
        pdf={bolepixPdf}
      />
      <Button
        icon="ban"
        size="mini"
        disabled={!allowToCancel}
        onClick={handleCancelButtonClick}
        can={can.bolepix.cancel}
        popup="Cancelar cobrança"
        invertedPopup
        circular
      />
      <ChargeConfirmationModal
        open={showCancelModal}
        charge={charge}
        title="Cancelar cobrança"
        question="Confirmar cancelamento de cobrança"
        loading={loading}
        handleModal={setShowCancelModal}
        onConfirm={handleConfirmCancel}
      />
    </Show>
  )
}
