import React from 'react'
import { Table } from 'semantic-ui-react'

export const OrderItemsTableHeader: React.FC = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell content="Descrição" />
        <Table.HeaderCell content="Quantidade" />
        <Table.HeaderCell content="Preço" />
        <Table.HeaderCell content="Desconto" />
        <Table.HeaderCell content="Acréscimo" />
        <Table.HeaderCell content="Total" />
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
  )
}
