import React, { useContext, useState } from 'react'
import { Button } from 'semantic-ui-react'

import { useCan } from '../../../../../../../../hooks/useCan'
import { can } from '../../../../../../../../config/permissions'
import { Show } from '../../../../../../../../components/Show'
import { AuthContext } from '../../../../../../../../contexts/AuthContext'

import { useOrder } from '../../../../hooks'
import { HandleOrderConfirmationModal } from '../HandleOrderConfirmationModal'

export const ReopenOrderAction: React.FC = () => {
  const { order, loading, reopenOrder } = useOrder()
  const { user } = useContext(AuthContext)
  const [showReopenModal, setShowReopenModal] = useState(false)
  const canReopenOrder = useCan(can.order.reopen)

  function handleReopenButtonClick() {
    setShowReopenModal(true)
  }

  function handleConfirmToReopen() {
    reopenOrder(() => setShowReopenModal(false))
  }

  const userIsAdmin = user?.role === 'ADMIN'
  const orderExist = !!order
  const orderIsOpen = orderExist && order?.status === 'OPEN'

  return (
    <Show
      condition={orderExist && !orderIsOpen && userIsAdmin && canReopenOrder}
      component={
        <>
          <Button
            type="button"
            content="Reabrir pedido"
            floated="right"
            onClick={handleReopenButtonClick}
          />
          <HandleOrderConfirmationModal
            open={showReopenModal}
            order={order}
            loading={loading}
            title="Reabrir pedido"
            question="Você deseja reabrir este pedido?"
            handleModal={setShowReopenModal}
            onConfirm={handleConfirmToReopen}
            onDecline={() => setShowReopenModal(false)}
          />
        </>
      }
    />
  )
}
