import { useState } from 'react'

import {
  listPeople as apiListPeople,
  Person
} from '../../../../../services/person'

export function useResponsible() {
  const [people, setPeople] = useState<Person[]>([])
  const [loading, setLoading] = useState(false)

  function listPeople(search?: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiListPeople({ search }))
      .then(data => setPeople(data.people))
      .finally(() => setLoading(false))
  }

  return {
    people,
    loading,
    listPeople
  }
}
