import React, { useContext, useState } from 'react'

import { Show } from '../../../../../../../../../components/Show'
import { Button } from '../../../../../../../../../components/Button'
import { PDFViewerModal } from '../../../../../../../../../components/PDFViewerModal'
import { ImageViewerModal } from '../../../../../../../../../components/ImageViewerModal'
import { can } from '../../../../../../../../../config/permissions'
import { AccountFormatted } from '../../../../../../../utils/formatAccount'
import { PaginationContext } from '../../../../../../../../../contexts/PaginationContext'

import { usePix } from '../../../../../hooks'

interface ViewPixProofActionProps {
  account: AccountFormatted
}

export const ViewPixProofAction: React.FC<ViewPixProofActionProps> = ({
  account
}) => {
  const { pagination } = useContext(PaginationContext)
  const { proofFile, loading, getProof } = usePix()
  const [showPdfViewerModal, setShowPdfViewerModal] = useState(false)
  const [showImageViewerModal, setShowImageViewerModal] = useState(false)

  function handleButtonClick() {
    const { orderId } = account

    if (!orderId) return

    getProof(orderId, account.id, output => {
      const { mimetype } = output

      if (mimetype !== 'application/pdf') {
        setShowImageViewerModal(true)
      }

      if (mimetype === 'application/pdf') {
        setShowPdfViewerModal(true)
      }
    })
  }

  const isPixReceivable =
    account.paymentType === 'PIX' && pagination.query.type === 'RECEIVABLE'
  const accountIsPaid = account.isPaid

  return (
    <Show condition={isPixReceivable && accountIsPaid}>
      <Button
        icon="file alternate"
        size="mini"
        disabled={loading}
        onClick={handleButtonClick}
        loading={loading}
        can={can.pix.getProof}
        popup="Visualizar comprovante"
        invertedPopup
        circular
      />
      <PDFViewerModal
        open={showPdfViewerModal}
        handleModal={setShowPdfViewerModal}
        title="Comprovante de pagamento"
        pdf={proofFile?.content}
      />
      <ImageViewerModal
        open={showImageViewerModal}
        handleModal={setShowImageViewerModal}
        title="Comprovante de pagamento"
        image={proofFile?.content}
        mimetype={proofFile?.mimetype}
      />
    </Show>
  )
}
