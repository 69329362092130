import React from 'react'
import { Message, Segment } from 'semantic-ui-react'
import { Form as FinalForm } from 'react-final-form'

import { Form } from '../../../../../../components/Form'
import { Show } from '../../../../../../components/Show'
import { getDiff, getFormValidate } from '../../../../../../utils/form'

import { OrderFormatted } from '../../../../utils/formatOrder'
import { useOrder, useOrderAc } from '../../hooks'
import {
  StatusGroup,
  CustomerGroup,
  CommissionGroup,
  TotalsGroup
} from './InputGroups'
import { FormActions } from './FormActions'
import { orderSchema } from './schema'

const initialValues = {
  categoryId: 'fc86e2fd-34e2-4672-8fcc-3bd572b32e27',
  code: 0,
  totalItems: 0,
  totalOrder: 0,
  totalDiscount: 0,
  totalAddition: 0
}

const validateForm = getFormValidate(orderSchema)

export const OrderMain: React.FC = () => {
  const { order, createOrder, updateOrder } = useOrder()
  const { errors } = useOrderAc()

  function handleSubmit(values: OrderFormatted) {
    const { categoryId, customerId, employeeId } = values

    if (!order) createOrder({ categoryId, customerId, employeeId })

    if (order) {
      const diff = getDiff(order, { categoryId, customerId, employeeId })

      if (!diff) return

      updateOrder(diff)
    }
  }

  const hasErrors = errors.length > 0

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={{ ...initialValues, ...order }}
      validate={validateForm}
      validateOnBlur
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormActions />
          <Segment>
            <Show condition={hasErrors}>
              <Message
                header="Ocorreu algo inesperado"
                list={errors}
                negative
              />
            </Show>
            <input name="id" hidden />
            <StatusGroup />
            <CustomerGroup />
            <CommissionGroup />
            <TotalsGroup />
          </Segment>
        </Form>
      )}
    </FinalForm>
  )
}
