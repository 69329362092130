import React from 'react'
import { Table, Icon } from 'semantic-ui-react'

import {
  FixedTableCell,
  ItemControllers
} from '../../../../../components/Table'
import { can } from '../../../../../config/permissions'
import { Show } from '../../../../../components/Show'

import { OrderFormatted } from '../../../utils/formatOrder'

interface OrdersTableRowProps {
  order: OrderFormatted
  onUpdateButtonClick: (order: OrderFormatted) => void
  onDeleteButtonClick: (order: OrderFormatted) => void
}

export const OrdersTableRow: React.FC<OrdersTableRowProps> = ({
  order,
  onUpdateButtonClick,
  onDeleteButtonClick
}) => {
  return (
    <Table.Row>
      <Table.Cell content={order.code} />
      <FixedTableCell content={order.customer.name} maxWidth={300} />
      <Table.Cell content={order.issueDateFormatted} />
      <Table.Cell content={order.totalOrderFormatted} />
      <Table.Cell textAlign="center">
        <Show condition={order.hasNfse} component={<Icon name="check" />} />
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Show condition={order.paid} component={<Icon name="check" />} />
      </Table.Cell>
      <Table.Cell content={order.statusFormatted} />
      <Table.Cell textAlign="right">
        <ItemControllers
          onUpdateClick={() => onUpdateButtonClick(order)}
          onDeleteClick={() => onDeleteButtonClick(order)}
          permissionsToUpdate={can.order.update}
          permissionsToDelete={can.order.delete}
        />
      </Table.Cell>
    </Table.Row>
  )
}
