import React from 'react'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'

import { PermissionGroup } from './PermissionGroup'
import { OnCheck } from './AllPermissions'

interface PermissionPermissionsProps {
  onChange: (data: OnCheck) => void
  isChecked: (resource: string, actions: string[]) => boolean
}

export const PermissionPermissions: React.FC<PermissionPermissionsProps> = ({
  onChange,
  isChecked
}) => {
  function handleCheck(
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) {
    const value = data.value as string
    const checked = data.checked as boolean
    onChange({ value, checked })
  }

  return (
    <PermissionGroup label="Permissões">
      <Checkbox
        onChange={handleCheck}
        checked={isChecked('permission', ['Get'])}
        value="permission:Get"
        label="Ler"
      />
    </PermissionGroup>
  )
}
