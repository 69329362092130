import { useState } from 'react'

import {
  getCommissionReport as apiGetCommissionReport,
  CommissionReportOptions
} from '../../../services/report'

export function useCommissionReport() {
  const [report, setReport] = useState<Buffer>()
  const [loading, setLoading] = useState(false)

  function getCommissionReport(
    options: CommissionReportOptions,
    onSuccess: () => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiGetCommissionReport(options))
      .then(pdf => setReport(pdf))
      .then(() => onSuccess())
      .finally(() => setLoading(false))
  }

  return {
    report,
    loading,
    getCommissionReport
  }
}
