import React from 'react'
import { Header, Divider } from 'semantic-ui-react'

import { Wrapper } from './styles'
import { Breadcrumb, BreadcrumbItem } from './Breadcrumb'
import { Actions } from './Actions'

interface RegistrationContainerProps {
  title?: string
  breadcrumbItems?: BreadcrumbItem[]
  children?: React.ReactNode
  onSearchClick?: (text: string) => void
}

export const RegistrationContainer: React.FC<RegistrationContainerProps> = ({
  title,
  breadcrumbItems,
  onSearchClick,
  children
}) => {
  return (
    <Wrapper>
      <Header>
        {breadcrumbItems && <Breadcrumb breadcrumbItems={breadcrumbItems} />}
        <Divider />
        {title && <Header as="h1" content={title} />}
        <Actions onSearchClick={onSearchClick} />
      </Header>
      {children}
    </Wrapper>
  )
}
