import React from 'react'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'

import { PermissionGroup } from './PermissionGroup'
import { OnCheck } from './AllPermissions'
import { BlankOption } from './styles'

interface CategoryPermissionsProps {
  onChange: (data: OnCheck) => void
  isChecked: (resource: string, actions: string[]) => boolean
}

export const CategoryPermissions: React.FC<CategoryPermissionsProps> = ({
  onChange,
  isChecked
}) => {
  function handleCheck(
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) {
    const value = data.value as string
    const checked = data.checked as boolean
    onChange({ value, checked })
  }

  return (
    <PermissionGroup label="Categoria:">
      <BlankOption />
      <Checkbox
        onChange={handleCheck}
        checked={isChecked('category', ['Create'])}
        value="category:Create"
        label="Cadastrar"
      />
      <Checkbox
        onChange={handleCheck}
        checked={isChecked('category', ['Update'])}
        value="category:Update"
        label="Atualizar"
      />
      <Checkbox
        onChange={handleCheck}
        checked={isChecked('category', ['Delete'])}
        value="category:Delete"
        label="Excluir"
      />
    </PermissionGroup>
  )
}
