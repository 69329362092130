import React from 'react'
import { TableCellProps as SemanticTableCellProps } from 'semantic-ui-react'

import { TableCell } from './styles'

interface FixedTableCellProps extends SemanticTableCellProps {
  maxWidth?: number
}

export const FixedTableCell: React.FC<FixedTableCellProps> = ({
  maxWidth,
  ...rest
}) => {
  return <TableCell {...rest} style={{ maxWidth }} />
}
