import { useState } from 'react'

import {
  Category,
  listCategories as apiListCategories
} from '../../../services/category'

export function useCategories() {
  const [categories, setCategories] = useState<Category[]>([])
  const [loading, setLoading] = useState(false)

  function listCategories() {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiListCategories())
      .then(data => setCategories(data.categories))
      .finally(() => setLoading(false))
  }

  return {
    categories,
    loading,
    listCategories
  }
}
