import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

import { FormActionsContainer } from '../../../../../../styles/FormActionsContainer'
import { pages } from '../../../../../../config/pages'

export const FormActions: React.FC = () => {
  return (
    <FormActionsContainer>
      <Button
        type="button"
        content="Voltar"
        as={Link}
        to={pages.accounts.path}
      />
      <Button type="submit" content="Salvar" floated="right" primary />
    </FormActionsContainer>
  )
}
