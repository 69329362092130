import React from 'react'
import { toast } from 'react-toastify'

import { ConfirmationModal } from '../../../../../components/ConfirmationModal'
import { Group } from '../../../../../services/group'

interface ConfimDeleteModalProps {
  open: boolean
  group?: Group
  loading?: boolean
  handleModal: (open: boolean) => void
  onConfirm: (group: Group) => void
  onDecline: () => void
}

export const ConfirmDeleteModal: React.FC<ConfimDeleteModalProps> = ({
  open,
  group,
  loading,
  handleModal,
  onConfirm,
  onDecline
}) => {
  function handleConfirmDelete() {
    if (!group) {
      toast.error('Nenhum grupo selecionado')

      return
    }

    onConfirm(group)
  }

  return (
    <ConfirmationModal
      open={open}
      loading={loading}
      handleModal={handleModal}
      onConfirm={handleConfirmDelete}
      onDecline={onDecline}
    >
      <span>Deseja excluir o grupo?</span>
      <br />
      {group ? <strong>{group.name}</strong> : null}
    </ConfirmationModal>
  )
}
