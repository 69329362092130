import React, { useState } from 'react'
import { Button, Header } from 'semantic-ui-react'

import { FormActionsContainer } from '../../../../../../../styles/FormActionsContainer'
import { useCan } from '../../../../../../../hooks/useCan'
import { can } from '../../../../../../../config/permissions'
import { Show } from '../../../../../../../components/Show'

import { AddPartnerModal } from './AddPartnerModal'

export const PartnershipHeader: React.FC = () => {
  const [showAddPartnerModal, setShowPartnerModal] = useState(false)
  const canLinkAPartner = useCan(can.user.linkAPartner)

  return (
    <FormActionsContainer>
      <Header as="h3" content="Parceiros" floated="left" />
      <Show condition={canLinkAPartner}>
        <Button
          content="Adicionar"
          floated="right"
          onClick={() => setShowPartnerModal(true)}
          primary
        />
        <AddPartnerModal
          open={showAddPartnerModal}
          handleModal={setShowPartnerModal}
        />
      </Show>
    </FormActionsContainer>
  )
}
