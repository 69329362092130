import React, { useState } from 'react'
import { Icon, Table } from 'semantic-ui-react'

import { Show } from '../../../../../../../../../components/Show'
import { ItemControllers } from '../../../../../../../../../components/Table'
import { can } from '../../../../../../../../../config/permissions'

import { ChargeFormatted } from '../../../../../../../utils/formatCharge'
import { useOrder, useOrderCharge } from '../../../../../hooks'
import {
  EditableCells,
  NotEditableCells,
  ChargeConfirmationModal
} from './components'
import {
  BoletoActions,
  PixActions,
  CobPixActions,
  BolepixActions
} from './actions'

interface OrderChargesTableRowProps {
  charge: ChargeFormatted
}

export const OrderChargesTableRow: React.FC<OrderChargesTableRowProps> = ({
  charge
}) => {
  const { order, loading } = useOrder()
  const { removeCharge } = useOrderCharge()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  function handleDeleteButtonClick() {
    setShowDeleteModal(true)
  }

  function handleConfirmDelete() {
    removeCharge(charge.id)
  }

  const notHasAChargeMethod = !(
    !!charge.boleto ||
    !!charge.pix ||
    !!charge.cobPix ||
    !!charge.bolepix
  )
  const orderIsOpen = order?.status === 'OPEN'

  return (
    <Table.Row>
      <Show
        condition={notHasAChargeMethod}
        component={<EditableCells charge={charge} />}
      />
      <Show
        condition={!notHasAChargeMethod}
        component={<NotEditableCells charge={charge} />}
      />
      <Table.Cell content={charge.amountFormatted} />
      <Table.Cell content={charge.paid ? <Icon name="check" /> : null} />
      <Table.Cell textAlign="right">
        <BoletoActions charge={charge} />
        <PixActions charge={charge} />
        <CobPixActions charge={charge} />
        <BolepixActions charge={charge} />
        <Show condition={orderIsOpen}>
          <ItemControllers
            onDeleteClick={handleDeleteButtonClick}
            permissionsToDelete={can.charge.delete}
          />
          <ChargeConfirmationModal
            open={showDeleteModal}
            charge={charge}
            title="Excluir pagamento"
            question="Deseja excluir este pagamento?"
            loading={loading}
            handleModal={setShowDeleteModal}
            onConfirm={handleConfirmDelete}
          />
        </Show>
      </Table.Cell>
    </Table.Row>
  )
}
