import { useState } from 'react'

import { listUsers as apiListUser, User } from '../../../../../services/users'

export function useUser() {
  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState(false)

  function listUsers(search?: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiListUser({ search }))
      .then(data => setUsers(data.users))
      .finally(() => setLoading(false))
  }

  return {
    users,
    loading,
    listUsers
  }
}
