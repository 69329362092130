import React, { useState } from 'react'
import {
  Button,
  CheckboxProps,
  InputOnChangeData,
  Modal,
  Radio,
  Table
} from 'semantic-ui-react'

import { listProducts, Product } from '../../../services/product'
import { listServices, Service } from '../../../services/service'
import { Input } from '../../../components/Form/pure'

import { AddItemModalWrapper } from './styles'

interface OnSelectOptions {
  service?: Service
  product?: Product
}

interface AddItemPropsModal {
  open: boolean
  handleModal: (open: boolean) => void
  priceListId?: string
  onSelect: (options: OnSelectOptions) => void
}

type ItemType = 'SERVICE' | 'PRODUCT'

export const AddItemModal: React.FC<AddItemPropsModal> = ({
  open,
  handleModal,
  onSelect
}) => {
  const [type, setType] = useState<ItemType>('SERVICE')
  const [search, setSearch] = useState('')
  const [items, setItems] = useState<(Service | Product)[]>([])
  const [loading, setLoading] = useState(false)
  const [addedItems, setAddedItems] = useState<string[]>([])

  function toggleType(
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) {
    setType(data.value as ItemType)
    setItems([])
  }

  function handleSearchChange(
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    setSearch(data.value)
  }

  function searchItem() {
    const pagination = { search, limit: 10 }

    if (type === 'SERVICE') {
      Promise.resolve()
        .then(() => setLoading(true))
        .then(() => listServices(pagination))
        .then(result => setItems(result.services))
        .finally(() => setLoading(false))
    }

    if (type === 'PRODUCT') {
      Promise.resolve()
        .then(() => setLoading(true))
        .then(() => listProducts(pagination))
        .then(result => setItems(result.products))
        .finally(() => setLoading(false))
    }
  }

  function handleSearchKeyPress(evt: React.KeyboardEvent) {
    if (evt.key === 'Enter') {
      searchItem()
    }
  }

  function resetModal() {
    setSearch('')
    setType('SERVICE')
    setItems([])
    setAddedItems([])
  }

  function handleClose() {
    handleModal(false)
    resetModal()
  }

  async function selectItem(item: Service | Product) {
    const service = type === 'SERVICE' ? (item as Service) : undefined
    const product = type === 'PRODUCT' ? (item as Product) : undefined

    onSelect({ service, product })
    setAddedItems([...addedItems, item.id])
  }

  function handleSelectClick(item: Service | Product) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => selectItem(item))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      open={open}
      onOpen={() => handleModal(true)}
      onClose={handleClose}
      size="tiny"
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header content="Adicionar item" />
      <Modal.Content>
        <AddItemModalWrapper>
          <Radio
            name="type"
            value="SERVICE"
            checked={type === 'SERVICE'}
            label="Serviço"
            onChange={toggleType}
            disabled={loading}
          />
          <Radio
            name="type"
            value="PRODUCT"
            checked={type === 'PRODUCT'}
            label="Produto"
            onChange={toggleType}
            disabled={loading}
          />
          <Input
            name="description"
            placeholder="Descrição"
            onChange={handleSearchChange}
            onKeyPress={handleSearchKeyPress}
            disabled={loading}
            action={
              <Button icon="search" loading={loading} onClick={searchItem} />
            }
            uppercase
            fluid
          />
          <Table selectable striped compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content="Descrição" colSpan={2} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map(item => (
                <Table.Row key={item.id}>
                  <Table.Cell content={item.description} />
                  <Table.Cell textAlign="right">
                    <Button
                      basic
                      compact
                      circular
                      floated="right"
                      icon="add"
                      onClick={() => handleSelectClick(item)}
                      disabled={addedItems.includes(item.id)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </AddItemModalWrapper>
      </Modal.Content>
    </Modal>
  )
}
