import Joi from 'joi'

const messages = {
  serviceId: {
    required: 'O certificado é obrigatório'
  },
  customerId: {
    required: 'O cliente é obrigatório'
  },
  issueDate: {
    required: 'Data de emissão é obrigatória'
  },
  expirationDate: {
    required: 'Data de vencimento é obrigatória'
  }
}

export const certificateSchema = Joi.object({
  id: Joi.string().uuid({ version: ['uuidv4'] }),
  serviceId: Joi.string()
    .uuid({ version: ['uuidv4'] })
    .required()
    .messages({
      'any.required': messages.serviceId.required
    }),
  customerId: Joi.string()
    .uuid({ version: ['uuidv4'] })
    .required()
    .messages({
      'any.required': messages.customerId.required
    }),
  issueDate: Joi.string().required().messages({
    'any.required': messages.issueDate.required
  }),
  expirationDate: Joi.string().required().messages({
    'any.required': messages.expirationDate.required
  })
})
