import React, { useContext, useEffect, useState } from 'react'
import { DropdownProps } from 'semantic-ui-react'

import {
  Form,
  Input,
  Select,
  DateTimeInput
} from '../../../../../../../components/Form'
import { AuthContext } from '../../../../../../../contexts/AuthContext'
import { SelectOption } from '../../../../../../../components/Form/Select'
import {
  dateToLocaleDate,
  getToday,
  localeDateTimeToDate
} from '../../../../../../../utils/date'

import { categoryToSelectOption } from '../../../../../utils/selectOptions'
import { useOrder } from '../../../hooks'
import { useCategory } from '../../../hooks/useCategory'

export const StatusGroup: React.FC = () => {
  const { order, isCertificateOrder, updateOrder } = useOrder()
  const { user } = useContext(AuthContext)
  const { categories, loading, listCategories } = useCategory()
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([])

  useEffect(() => listCategories(), [])

  useEffect(() => {
    setCategoryOptions(categories.map(categoryToSelectOption))
  }, [categories])

  function handleCategoryBlur(data: DropdownProps) {
    const value = data.value as string

    updateOrder({ categoryId: value })
  }

  function changeStatusDate(value: string) {
    if (order?.status !== 'OPEN') return
    if (order?.statusDate === value) return

    updateOrder({ statusDate: value })
  }

  function handleStatusDateChange(event: { value: string }) {
    const value = localeDateTimeToDate(event.value).toISOString()
    changeStatusDate(value)
  }

  function handleStatusDateBlur(
    event: React.FocusEvent<HTMLInputElement, Element>
  ) {
    const value = localeDateTimeToDate(event.target.value).toISOString()
    changeStatusDate(value)
  }

  const userIsAdmin = user?.role === 'ADMIN'
  const orderIsOpen = order?.status === 'OPEN'
  const groupWidths = isCertificateOrder ? undefined : userIsAdmin ? 5 : 4
  const category = categories.find(
    category => category.id === order?.categoryId
  )

  return (
    <Form.Group widths={groupWidths}>
      <Input
        name="code"
        label="Código"
        width={isCertificateOrder ? 2 : undefined}
        readOnly
      />
      {userIsAdmin && orderIsOpen ? (
        <Select
          name="categoryId"
          label="Categoria"
          options={categoryOptions}
          loading={loading}
          width={isCertificateOrder ? 3 : undefined}
          onBlur={(_, data) => handleCategoryBlur(data)}
          search
          uppercase
        />
      ) : null}
      {userIsAdmin && !orderIsOpen ? (
        <Form.Input
          label="Categoria"
          value={category?.name}
          width={isCertificateOrder ? 3 : undefined}
          readOnly
        />
      ) : null}
      <Input
        name="issueDateFormatted"
        label="Data de emissão"
        width={isCertificateOrder ? 3 : undefined}
        readOnly
      />
      <DateTimeInput
        name="statusDateFormatted"
        label="Data de status"
        width={isCertificateOrder ? 3 : undefined}
        readOnly={!userIsAdmin || order?.status !== 'OPEN'}
        maxDate={dateToLocaleDate(getToday())}
        onBlur={handleStatusDateBlur}
        onChange={handleStatusDateChange}
      />
      {isCertificateOrder ? (
        <Input
          name="certificateIssueProtocolFormatted"
          label="Protocolo"
          width={isCertificateOrder ? 3 : undefined}
          readOnly
          uppercase
        />
      ) : null}
      <Input
        name="statusFormatted"
        label="Status"
        disabled={!order}
        width={isCertificateOrder ? 2 : undefined}
        readOnly
      />
    </Form.Group>
  )
}
