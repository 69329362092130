import React from 'react'
import { Link } from 'react-router-dom'

import { FormActionsContainer } from '../../../../../../../styles/FormActionsContainer'
import { Button } from '../../../../../../../components/Button'
import { pages } from '../../../../../../../config/pages'

export const FormActions: React.FC = () => {
  return (
    <FormActionsContainer>
      <Button type="button" content="Voltar" as={Link} to={pages.people.path} />
      <Button type="submit" content="Salvar" floated="right" primary />
    </FormActionsContainer>
  )
}
