import React, { useState } from 'react'

import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal'
import { DateTimeInput } from '../../../../../../../../components/Form/pure'
import {
  dateToLocaleDateTime,
  localeDateTimeToDate
} from '../../../../../../../../utils/date'

import { useOrder } from '../../../../hooks'

interface ConcludeOrderModalProps {
  open: boolean
  handleModal: (open: boolean) => void
}

const defaultValue = dateToLocaleDateTime(new Date())

export const ConcludeOrderModal: React.FC<ConcludeOrderModalProps> = ({
  open,
  handleModal
}) => {
  const { loading, concludeOrder } = useOrder()
  const [date, setDate] = useState(defaultValue)

  function handleConfirm() {
    const statusDate = localeDateTimeToDate(date.replace(',', ''))
    concludeOrder(statusDate, () => handleModal(false))
  }

  return (
    <ConfirmationModal
      open={open}
      title="Concluir pedido"
      loading={loading}
      handleModal={handleModal}
      onConfirm={handleConfirm}
      onDecline={() => handleModal(false)}
    >
      <label htmlFor="status-date">Data do status</label>
      <br />
      <DateTimeInput
        id="status-date"
        value={date}
        onChange={(_, { value }) => setDate(value)}
        maxDate={new Date()}
        fluid
      />
    </ConfirmationModal>
  )
}
