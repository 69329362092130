import { useState, useCallback } from 'react'

import {
  Service,
  ServiceListResult,
  listServices
} from '../../../../../services/service'
import { Pagination } from '../../../../../contexts/PaginationContext'

export interface QueryParameters {
  search?: string
}

export function useServicesList() {
  const [loading, setLoading] = useState(false)
  const [services, setServices] = useState<Service[]>([])
  const [totalItems, setTotalItems] = useState(0)

  const fetchServices = useCallback(
    (pagination: Pagination<QueryParameters>) => {
      const { page, limit, orderBy, order, query } = pagination
      const { search } = query || {}

      const onSuccess = (result: ServiceListResult) => {
        setServices(result.services)
        setTotalItems(result.total)
      }

      Promise.resolve()
        .then(() => setLoading(true))
        .then(() => listServices({ page, limit, orderBy, order, search }))
        .then(result => onSuccess(result))
        .finally(() => setLoading(false))
    },
    []
  )

  return {
    loading,
    services,
    totalItems,
    fetchServices
  }
}
