import React from 'react'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  html, body, #root {
    height: 100%;
  }

  html {
    font-family: 'Roboto', sans-serif;
  }
  
  /*
    Workaround for Semantic UI CSS fullscreen modal bug
  */

  .ui.fullscreen.modal, .ui.fullscreen.scrolling.modal {
    left: auto !important;
  }
` as React.ComponentClass
