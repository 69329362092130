import { SignatureItem } from '../../../services/signatureItem'
import { numberToCurrency } from '../../../utils/number'

export interface SignatureItemFormatted extends SignatureItem {
  discountFormatted: string
  priceFormatted: string
  totalPrice: number
  totalPriceFormatted: string
}

export function formatSignatureItem(
  signatureItem: SignatureItem
): SignatureItemFormatted {
  const totalPrice =
    signatureItem.quantity * signatureItem.price - signatureItem.discount
  return {
    ...signatureItem,
    discountFormatted: numberToCurrency(signatureItem.discount),
    priceFormatted: numberToCurrency(signatureItem.price),
    totalPrice,
    totalPriceFormatted: numberToCurrency(totalPrice)
  }
}
