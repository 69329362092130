import React from 'react'
import { Link } from 'react-router-dom'

import { pages } from '../../config/pages'

import { Wrapper } from './styles'

export const NotFound: React.FC = () => {
  return (
    <Wrapper>
      <div>
        <h1>Ooops...</h1>
        <span>A página que você tentou acessar não existe.</span>
        <br />
        <Link to={pages.home.path}>Voltar para a home</Link>
      </div>
    </Wrapper>
  )
}
