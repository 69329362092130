import { useContext } from 'react'
import { toast } from 'react-toastify'

import {
  Service,
  createService as apiCreateService,
  updateService as apiUpdateService
} from '../../../../../services/service'

import { ServiceFormContext } from '../context'

export function useService() {
  const { service, loading, setLoading, getServiceState, updateServiceState } =
    useContext(ServiceFormContext)

  function createService(data: Partial<Service>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiCreateService(data))
      .then(data => updateServiceState(data))
      .then(() => toast.info('Serviço cadastrado com sucesso'))
      .finally(() => setLoading(false))
  }

  function updateService(data: Partial<Service>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getServiceState())
      .then(order => apiUpdateService(order.id, data))
      .then(data => updateServiceState(data))
      .then(() => toast.info('O serviço foi alterado'))
      .finally(() => setLoading(false))
  }

  return {
    service,
    loading,
    createService,
    updateService
  }
}
