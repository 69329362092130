import Joi from 'joi'

import {
  validateCnpj,
  validateCpf,
  validatePhone
} from '../../../utils/validation'

const messages = {
  permissions: {
    required: 'O campo de permissões é obrigatório',
    array: 'O formato das permissões está incorreto'
  },
  nickname: {
    required: 'Nome é obrigatório',
    max: 'Permitido no máximo 100 caracteres'
  },
  email: {
    required: 'E-mail é obrigatório',
    email: 'Deve ser um formato de e-mail válido'
  },
  phoneFormatted: {
    required: 'Celular é obrigatório',
    invalid: 'Não é um número válido'
  },
  role: {
    required: 'Role é obrigatório',
    invalid: 'Escolha entre: "ADMIN", "EMPLOYEE" ou "PARTNER"'
  },
  password: {
    required: 'Senha é obrigatória',
    strong:
      'A senha precisa ter caracteres minúsculos, maiúsculos, numéricos e especiais'
  },
  confirmPassword: {
    match: 'As senhas não correspondem'
  },
  pixKey: {
    required: 'A chave Pix é obrigatória',
    email: 'A chave precisa ser um endereço de e-mail válido',
    phone: 'A chave precisa ser um número de celular válido',
    cpf: 'A chave precisa ser um número de CPF válido',
    cnpj: 'A chave precisa ser um número de CNPJ válido',
    random: 'A chave aleatória não é válida'
  }
}

const REGEX_PASSWORD_COMPLEXITY =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,25}$/

export const userPixSchema = Joi.object({
  type: Joi.string().valid('EMAIL', 'PHONE', 'CPF', 'CNPJ', 'RANDOM').allow(''),
  keyFormatted: Joi.string()
    .allow('')
    .when('type', {
      is: 'EMAIL',
      then: Joi.string().email({ tlds: false }).trim().required().messages({
        'any.required': messages.pixKey.required,
        'string.email': messages.pixKey.email
      })
    })
    .when('type', {
      is: 'PHONE',
      then: Joi.string().custom(validatePhone).required().messages({
        'any.required': messages.pixKey.required,
        'string.phone': messages.pixKey.phone
      })
    })
    .when('type', {
      is: 'CPF',
      then: Joi.string().custom(validateCpf).required().messages({
        'any.required': messages.pixKey.required,
        'string.cpf': messages.pixKey.cpf
      })
    })
    .when('type', {
      is: 'CNPJ',
      then: Joi.string().custom(validateCnpj).required().messages({
        'any.required': messages.pixKey.required,
        'string.cnpj': messages.pixKey.cnpj
      })
    })
    .when('type', {
      is: 'RANDOM',
      then: Joi.string().uuid().required().messages({
        'any.required': messages.pixKey.required,
        'string.guid': messages.pixKey.random
      })
    })
    .trim()
}).optional()

export const userSchema = Joi.object({
  id: Joi.string().uuid({ version: ['uuidv4'] }),
  permissions: Joi.required().messages({
    'any.required': messages.permissions.required
  }),
  nickname: Joi.string().max(100).trim().required().messages({
    'string.max': messages.nickname.max,
    'any.required': messages.nickname.required,
    'string.empty': messages.nickname.required
  }),
  email: Joi.string().email({ tlds: false }).trim().required().messages({
    'string.email': messages.email.email,
    'any.required': messages.email.required,
    'string.empty': messages.nickname.required
  }),
  phoneFormatted: Joi.string().custom(validatePhone).required().messages({
    'string.phone': messages.phoneFormatted.invalid,
    'any.required': messages.phoneFormatted.required
  }),
  role: Joi.string().valid('ADMIN', 'EMPLOYEE', 'PARTNER').required().messages({
    'any.required': messages.role.required
  }),
  password: Joi.string().pattern(REGEX_PASSWORD_COMPLEXITY).messages({
    'string.pattern.base': messages.password.strong,
    'any.required': messages.password.required
  }),
  pix: userPixSchema
})

export const userAccountSchema = Joi.object({
  nickname: Joi.string().max(100).trim().required().messages({
    'string.max': messages.nickname.max,
    'any.required': messages.nickname.required
  }),
  email: Joi.string().email({ tlds: false }).trim().required().messages({
    'string.email': messages.email.email,
    'any.required': messages.email.required
  }),
  phoneFormatted: Joi.string().custom(validatePhone).required().messages({
    'string.phone': messages.phoneFormatted.invalid,
    'any.required': messages.phoneFormatted.required
  }),
  oldPassword: Joi.string(),
  newPassword: Joi.string().pattern(REGEX_PASSWORD_COMPLEXITY).messages({
    'string.pattern.base': messages.password.strong,
    'any.required': messages.password.required
  }),
  confirmPassword: Joi.any().valid(Joi.ref('newPassword')).messages({
    'any.only': messages.confirmPassword.match
  })
})
