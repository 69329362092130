import React, { useState, useContext } from 'react'
import { Dropdown } from 'semantic-ui-react'

import { AuthContext } from '../../../../contexts/AuthContext'

import { EditAccountModal } from './EditAccountModal'
import { OpenSessionsModal } from './OpenSessionsModal'

interface ProfileMenuProps {
  onSandwichClick?: () => void
}

export const ProfileMenu: React.FC<ProfileMenuProps> = () => {
  const { signOut } = useContext(AuthContext)
  const [editAccountModal, setEditAccountModal] = useState(false)
  const [openSessionsModal, setOpenSessionsModal] = useState(false)

  return (
    <>
      <Dropdown item icon="user">
        <Dropdown.Menu>
          <Dropdown.Item
            content="Editar conta"
            icon="edit"
            onClick={() => setEditAccountModal(true)}
          />
          <Dropdown.Item
            content="Sessões"
            icon="key"
            onClick={() => setOpenSessionsModal(true)}
          />
          <Dropdown.Divider />
          <Dropdown.Item content="Sair" icon="sign-out" onClick={signOut} />
        </Dropdown.Menu>
      </Dropdown>
      <EditAccountModal
        open={editAccountModal}
        handleModal={setEditAccountModal}
      />
      <OpenSessionsModal
        open={openSessionsModal}
        handleModal={setOpenSessionsModal}
      />
    </>
  )
}
