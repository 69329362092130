import React, { useEffect, useContext, useState } from 'react'
import { DropdownProps } from 'semantic-ui-react'

import {
  PaginationContext,
  OrderDirection
} from '../../../../../contexts/PaginationContext'
import { DefaultLoader } from '../../../../../components/DefaultLoader'
import { AccountStatus, AccountType } from '../../../../../services/account'

import { AccountFormatted, formatAccount } from '../../../utils/formatAccount'
import { useFetchAccounts } from './hooks'

interface AccountsTableContextContent {
  accounts: AccountFormatted[]
  totalItems: number
  loading: boolean
  reloadAccounts: () => void
  replaceAccount: (data: AccountFormatted) => void
  changeType: (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => void
  changeStatus: (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => void
}

export const AccountsTableContext =
  React.createContext<AccountsTableContextContent>(
    {} as AccountsTableContextContent
  )

interface AccountsTableProviderProps {
  children: React.ReactNode
}

export const AccountsTableProvider: React.FC<AccountsTableProviderProps> = ({
  children
}) => {
  const { pagination, changeQuery } = useContext(PaginationContext)
  const _fetchAccounts = useFetchAccounts()
  const [accounts, setAccounts] = useState<AccountFormatted[]>([])
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(false)

  async function fetchAccounts() {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => _fetchAccounts(pagination))
      .then(({ accounts, totalItems }) => {
        setAccounts(accounts)
        setTotalItems(totalItems)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchAccounts()
  }, [pagination])

  function replaceAccount(data: AccountFormatted) {
    const newList = accounts.map(account => {
      return account.id === data.id ? formatAccount(data) : account
    })

    setAccounts(newList)
  }

  function reloadAccounts() {
    fetchAccounts()
  }

  function changeType(
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) {
    const type = data.value as AccountType
    const orderingByType = {
      RECEIVABLE: { orderBy: 'dueDate', order: 'ASC' as OrderDirection },
      PAYABLE: { orderBy: 'paymentDate', order: 'DESC' as OrderDirection }
    }
    const ordering = orderingByType[type]
    const { order, orderBy } = ordering
    changeQuery({
      ...pagination.query,
      type: data.value as AccountType,
      order,
      orderBy
    })
  }

  function changeStatus(
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) {
    const status = data.value as AccountStatus
    const orderingByStatus = {
      OPEN: { orderBy: 'dueDate', order: 'ASC' as OrderDirection },
      PAID: { orderBy: 'paymentDate', order: 'DESC' as OrderDirection },
      OVERDUE: { orderBy: 'dueDate', order: 'ASC' as OrderDirection }
    }
    const ordering = orderingByStatus[status]
    const { order, orderBy } = ordering
    changeQuery({
      ...pagination.query,
      status,
      order,
      orderBy
    })
  }

  return (
    <AccountsTableContext.Provider
      value={{
        accounts,
        totalItems,
        loading,
        reloadAccounts,
        replaceAccount,
        changeType,
        changeStatus
      }}
    >
      <DefaultLoader active={loading} />
      {children}
    </AccountsTableContext.Provider>
  )
}
