import React, { ReactElement } from 'react'

interface ShowProps {
  condition: boolean | (() => boolean)
  children?: ReactElement | ReactElement[]
  component?: ReactElement | ReactElement[]
}

export const Show = ({
  condition,
  children,
  component
}: ShowProps): ReactElement | null => {
  const conditionResult =
    typeof condition === 'boolean' ? condition : condition()

  const content = children || component

  if (!content) {
    throw new Error('Componente não informado')
  }

  const element = Array.isArray(content) ? <>{content}</> : content

  return conditionResult ? element : null
}
