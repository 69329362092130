import React from 'react'
import { DropdownOnSearchChangeData, DropdownProps } from 'semantic-ui-react'

import {
  Form,
  Currency,
  Select
} from '../../../../../../../../../components/Form'
import { SelectOption } from '../../../../../../../../../components/Form/Select'

interface EditableEmployeeFieldsProps {
  employeeOptions: SelectOption[]
  searchEmployee: (data: DropdownOnSearchChangeData) => void
  handleEmployeeChange: (data: DropdownProps) => void
  changeEmployeeOnBlur: (data: DropdownProps) => void
}

export const EditableEmployeeFields: React.FC<EditableEmployeeFieldsProps> = ({
  employeeOptions,
  searchEmployee,
  handleEmployeeChange,
  changeEmployeeOnBlur
}) => {
  return (
    <Form.Group>
      <Select
        name="employeeId"
        label="Colaborador"
        options={employeeOptions}
        onSearchChange={(_, data) => searchEmployee(data)}
        onChange={(_, data) => handleEmployeeChange(data)}
        onBlur={(_, data) => changeEmployeeOnBlur(data)}
        width={13}
        search
        uppercase
      />
      <Currency name="employeeCommission" label="Comissão" width={3} readOnly />
    </Form.Group>
  )
}
