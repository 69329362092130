import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'

import { pages } from '../config/pages'
import { Template } from '../components/Template'
import { AuthContext } from '../contexts/AuthContext'
import { useCan } from '../hooks/useCan'

import { AuthLoading } from './AuthLoading'

interface PrivateProps {
  component: React.FC
  permissions?: string[]
}

export function Private({
  component: Component,
  permissions = []
}: PrivateProps): React.ReactElement {
  const { isAuth } = useContext(AuthContext)
  const canViewPage = useCan(permissions)

  if (!isAuth) return <Navigate to={pages.login.path} />

  return (
    <Template>{canViewPage ? <Component /> : <AuthLoading active />}</Template>
  )
}
