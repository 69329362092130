import React, { useEffect, useState } from 'react'
import { useField, useForm } from 'react-final-form'
import { SemanticWIDTHS } from 'semantic-ui-react'

import {
  Form,
  Select,
  Input,
  Masked
} from '../../../../../../../../../components/Form'
import { Show } from '../../../../../../../../../components/Show'

const pixKeyTypeOptions = [
  { key: 'NONE', value: '', text: 'Sem chave Pix' },
  { key: 'EMAIL', value: 'EMAIL', text: 'E-mail' },
  { key: 'PHONE', value: 'PHONE', text: 'Celular' },
  { key: 'CPF', value: 'CPF', text: 'CPF' },
  { key: 'CNPJ', value: 'CNPJ', text: 'CNPJ' },
  { key: 'RANDOM', value: 'RANDOM', text: 'Aleatória' }
]

export const PixGroup: React.FC = () => {
  const form = useForm()
  const keyInputName = 'pix.keyFormatted'
  const pixTypeField = useField('pix.type')
  const [pixType, setPixType] = useState()

  useEffect(() => {
    const { value } = pixTypeField.input
    setPixType(value)

    if (value === '') {
      form.change(keyInputName, undefined)
    }
  }, [pixTypeField])

  const pixKeyProps = {
    name: keyInputName,
    label: 'Chave Pix',
    width: 12 as SemanticWIDTHS,
    onBlur: () => form.blur(keyInputName)
  }

  const isEmpty = pixType === 'NONE' || pixType === ''
  const isEmailType = pixType === 'EMAIL'
  const isPhoneType = pixType === 'PHONE'
  const isCpfType = pixType === 'CPF'
  const isCnpjType = pixType === 'CNPJ'
  const isRandomType = pixType === 'RANDOM'

  const phoneMask = '(##) # ####-####'
  const cpfMask = '###.###.###-##'
  const cnpjMask = '##.###.###/####-##'

  return (
    <Form.Group>
      <Select
        name="pix.type"
        label="Tipo de chave"
        options={pixKeyTypeOptions}
        width={4}
      />
      <Show
        condition={isEmpty}
        component={
          <Input
            {...pixKeyProps}
            placeholder="Selecione o tipo da chave pix"
            disabled
          />
        }
      />
      <Show
        condition={isEmailType}
        component={<Input {...pixKeyProps} type="email" />}
      />
      <Show
        condition={isPhoneType}
        component={<Masked {...pixKeyProps} mask={phoneMask} />}
      />
      <Show
        condition={isCpfType}
        component={<Masked {...pixKeyProps} mask={cpfMask} />}
      />
      <Show
        condition={isCnpjType}
        component={<Masked {...pixKeyProps} mask={cnpjMask} />}
      />
      <Show
        condition={isRandomType}
        component={<Input {...pixKeyProps} maxLength={36} />}
      />
    </Form.Group>
  )
}
