import { useContext } from 'react'

import { OrderFormContext } from '../context'

export function useOrderPartner() {
  const { partner } = useContext(OrderFormContext)

  return {
    partner
  }
}
