import { useState } from 'react'
import { toast } from 'react-toastify'

import { deleteUser as apiDeleteUser } from '../../../../../services/users'

export function useUser() {
  const [loading, setLoading] = useState(false)

  function deleteUser(id: string, onSuccess?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiDeleteUser(id))
      .then(() => toast.info('O usuário foi excluído'))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  return {
    loading,
    deleteUser
  }
}
