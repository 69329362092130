import { useState, useCallback } from 'react'

import { UserListResult, listUsers } from '../../../../../services/users'
import { Pagination } from '../../../../../contexts/PaginationContext'

import { formatUser, UserFormatted } from '../../../utils/formatUser'

export interface QueryParameters {
  search?: string
}

export function useUsersList() {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<UserFormatted[]>([])
  const [totalItems, setTotalItems] = useState(0)

  const fetchUsers = useCallback((pagination: Pagination<QueryParameters>) => {
    const { page, limit, orderBy, order, query } = pagination
    const { search } = query || {}

    const onSuccess = (result: UserListResult) => {
      setUsers(result.users.map(formatUser))
      setTotalItems(result.total)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => listUsers({ page, limit, orderBy, order, search }))
      .then(result => onSuccess(result))
      .finally(() => setLoading(false))
  }, [])

  return {
    loading,
    users,
    totalItems,
    fetchUsers
  }
}
