import React, { useEffect, useRef, useState } from 'react'
import { Table, Button, InputOnChangeData, Message } from 'semantic-ui-react'

import { PriceListItem } from '../../../../../../../services/priceListItem'
import { Input } from '../../../../../../../components/Form/pure'

import { useOrderPartner, useOrderItem, usePriceListItem } from '../../../hooks'

export const FindProductOrService: React.FC = () => {
  const { partner } = useOrderPartner()
  const { addOrderItem } = useOrderItem()
  const { items, loading, listPriceListItems } = usePriceListItem()
  const [search, setSearch] = useState('')
  const [addedItemIds, setAddedItemIds] = useState<string[]>([])
  const descriptionInputRef = useRef(null)

  useEffect(() => {
    const input = descriptionInputRef.current as any
    input?.focus()
  }, [])

  function handleSearchInputChange(_: any, data: InputOnChangeData) {
    setSearch(data.value)
  }

  function handleSearchButtonClick() {
    if (!partner?.priceListId) return

    const paginate = { search, limit: 10 }

    listPriceListItems(partner.priceListId, paginate)
  }

  function handleSearchInputKeyPress(evt: React.KeyboardEvent) {
    if (evt.key === 'Enter') {
      handleSearchButtonClick()
    }
  }

  function handleSelectItemButtonClick(priceListItem: PriceListItem) {
    const data = {
      priceListId: priceListItem.priceListId,
      priceListItemId: priceListItem.id,
      quantity: 1,
      discount: 0,
      addition: 0
    }

    addOrderItem(data, () => {
      setAddedItemIds([...addedItemIds, priceListItem.id])
    })
  }

  if (!!partner && !partner.priceListId) {
    return (
      <Message
        warning
        header="Ação necessária"
        content="O parceiro deste pedido não possui uma tabela de preços vinculada a ele. Contate o administrador."
      />
    )
  }

  return (
    <>
      <Input
        name="description"
        placeholder="Descrição"
        onChange={handleSearchInputChange}
        onKeyPress={handleSearchInputKeyPress}
        disabled={loading}
        action={
          <Button
            icon="search"
            loading={loading}
            onClick={handleSearchButtonClick}
          />
        }
        reference={descriptionInputRef}
        uppercase
        fluid
      />
      <Table selectable striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="Descrição" colSpan={2} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map(item => (
            <Table.Row key={item.id}>
              <Table.Cell
                content={item.service?.description || item.product?.description}
              />
              <Table.Cell textAlign="right">
                <Button
                  basic
                  compact
                  circular
                  floated="right"
                  icon="add"
                  onClick={() => handleSelectItemButtonClick(item)}
                  disabled={addedItemIds.includes(item.id)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}
