import { useContext } from 'react'
import { toast } from 'react-toastify'

import {
  createSignature as apiCreateSignature,
  updateSignature as apiUpdateSignature
} from '../../../../../services/signature'

import { SignatureFormatted } from '../../../utils/formatSignature'
import { SignatureFormContext } from '../context'

export function useSignature() {
  const {
    signature,
    loading,
    setLoading,
    getSignatureState,
    updateSignatureState,
    localChangeRecurrence
  } = useContext(SignatureFormContext)

  function createSignature(data: Partial<SignatureFormatted>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiCreateSignature(data))
      .then(data => updateSignatureState(data))
      .then(() => toast.info('Assinatura cadastrada com sucesso'))
      .finally(() => setLoading(false))
  }

  function updateSignature(
    data: Partial<SignatureFormatted>,
    onSuccess?: (data: SignatureFormatted) => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getSignatureState())
      .then(signature => apiUpdateSignature(signature.id, data))
      .then(data => updateSignatureState(data))
      .then(() => getSignatureState())
      .then(data => onSuccess?.(data))
      .then(() => toast.info('A assinatura foi alterada'))
      .finally(() => setLoading(false))
  }

  return {
    signature,
    loading,
    createSignature,
    updateSignature,
    localChangeRecurrence
  }
}
