import { useLocation } from 'react-router-dom'

export const useBreadcrumb = (map: Record<string, string>) => {
  const location = useLocation()
  const list = location.pathname.split('/').filter(path => !!path)
  const lastIndex = list.length - 1
  const breadcrumb = list.map((path, index) => {
    const name = map[path] || ''
    const pathIndex = list.findIndex(value => value === path)
    const backPath = list.slice(0, pathIndex - 1).join('/')
    const to = lastIndex === index ? undefined : '/' + backPath
    return { name, to }
  })

  return breadcrumb
}
