import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Product } from '../../../../../services/product'
import { DefaultLoader } from '../../../../../components/DefaultLoader'
import { pages } from '../../../../../config/pages'

import { useFetchProduct } from './hooks'

interface ProductFormContextContent {
  product?: Product
  loading: boolean
  setLoading: (value: boolean) => void
  getProductState: () => Product
  updateProductState: (product: Product) => Product
}

export const ProductFormContext =
  React.createContext<ProductFormContextContent>(
    {} as ProductFormContextContent
  )

interface ProductFormProviderProps {
  children: React.ReactNode
}

export const ProductFormProvider: React.FC<ProductFormProviderProps> = ({
  children
}) => {
  const [product, setProduct] = useState<Product>()
  const [loading, setLoading] = useState(false)
  const [query] = useSearchParams()
  const fetchProduct = useFetchProduct()
  const navigate = useNavigate()

  function getProductState() {
    if (!product) throw new Error('Nenhum produto foi selecionado')

    return product
  }

  function updateProductState(product: Product) {
    setProduct(product)

    return product
  }

  useEffect(() => {
    const productId = query.get('id')

    if (!productId) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => fetchProduct(productId))
      .then(data => {
        if (data) return updateProductState(data)

        navigate(`${pages.products.path}/inclusao`)
        toast.warn('O produto não foi encontrado, mas você pode criar um novo.')
      })
      .finally(() => setLoading(false))
  }, [])

  return (
    <ProductFormContext.Provider
      value={{
        product,
        loading,
        setLoading,
        getProductState,
        updateProductState
      }}
    >
      <DefaultLoader active={loading} />
      {children}
    </ProductFormContext.Provider>
  )
}
