import { ServerErrorResponse, ValidationError } from '../services/api'

export function formatErrorDetails(body: ServerErrorResponse): string[] {
  const errors = body.details?.validation || []

  if (!errors) return []

  function returnErrorMessages(error: ValidationError): string[] {
    const { errors, children } = error

    if (errors) return Object.values(errors)

    if (children) return children.map(returnErrorMessages).flat()

    return []
  }

  return errors.map(returnErrorMessages).flat()
}
