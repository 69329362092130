import styled from 'styled-components'

export const Container = styled.main`
  height: 100vh;
  display: flex;
  padding: 10%;
  justify-content: center;
  align-items: center;
  background-color: #fdfdfd;
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
  color: #303030;
  cursor: default;

  .ops {
    font-size: 3em;
    font-weight: 700;
    color: #a8a8a8;
  }
`
