import React, { useContext, useState } from 'react'
import { Button, Input } from 'semantic-ui-react'

import { ActionsContainer } from '../../../../../components/RegistrationContainer/styles'
import { useCan } from '../../../../../hooks/useCan'
import { can } from '../../../../../config/permissions'
import {
  Pagination,
  PaginationContext
} from '../../../../../contexts/PaginationContext'

interface ProductsTableActionsProps {
  pagination: Pagination
  onCreateButtonClick: () => void
}

export const ProductsTableActions: React.FC<ProductsTableActionsProps> = ({
  onCreateButtonClick
}) => {
  const [search, setSearch] = useState<string>()
  const { changeQuery } = useContext(PaginationContext)
  const canCreate = useCan(can.order.create)

  function searchProduct() {
    changeQuery({ search })
  }

  function handleSearchKeyPress(evt: React.KeyboardEvent) {
    if (evt.key === 'Enter') {
      searchProduct()
    }
  }

  return (
    <ActionsContainer>
      <Input
        placeholder="Pesquisar"
        value={search}
        onChange={(_, { value }) => setSearch(value.toUpperCase())}
        onKeyPress={handleSearchKeyPress}
        action={<Button icon="search" onClick={searchProduct} />}
      />
      {canCreate ? (
        <Button
          content="Incluir"
          onClick={onCreateButtonClick}
          floated="right"
          primary
        />
      ) : null}
    </ActionsContainer>
  )
}
