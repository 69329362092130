import React, { useState, useEffect } from 'react'
import { InputOnChangeData, Modal, Table } from 'semantic-ui-react'

import { User } from '../../../../../../../services/users'
import { Input } from '../../../../../../../components/Form/pure'
import { Button } from '../../../../../../../components/Button'

import { usePartners, useUser } from '../../../hooks'

interface AddPartnerModalProps {
  open: boolean
  handleModal: (open: boolean) => void
}

export const AddPartnerModal: React.FC<AddPartnerModalProps> = ({
  open,
  handleModal
}) => {
  const { linkAPartner } = useUser()
  const { partners, loading, listPartners } = usePartners()
  const [search, setSearch] = useState('')
  const [addedPartners, setAddedPartners] = useState<string[]>([])

  useEffect(() => {
    setSearch('')
    setAddedPartners([])
  }, [open])

  function handleSearchChange(
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    setSearch(data.value)
  }

  function searchPartner() {
    listPartners(search)
  }

  function handleSearchKeyPress(evt: React.KeyboardEvent) {
    if (evt.key === 'Enter') {
      searchPartner()
    }
  }

  function handleSelectItemButtonClick(partner: User) {
    linkAPartner(partner, () => {
      setAddedPartners([...addedPartners, partner.id])
    })
  }

  return (
    <Modal
      open={open}
      onOpen={() => handleModal(true)}
      onClose={() => handleModal(false)}
      size="tiny"
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header content="Vincular parceiro" />
      <Modal.Content>
        <Input
          placeholder="Nome"
          onChange={handleSearchChange}
          onKeyPress={handleSearchKeyPress}
          disabled={loading}
          action={
            <Button icon="search" loading={loading} onClick={searchPartner} />
          }
          uppercase
          fluid
        />
        <Table selectable striped compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content="Nome" colSpan={2} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {partners.map(partner => (
              <Table.Row key={partner.id}>
                <Table.Cell content={partner.nickname} />
                <Table.Cell textAlign="right">
                  <Button
                    basic
                    compact
                    circular
                    floated="right"
                    icon="add"
                    onClick={() => handleSelectItemButtonClick(partner)}
                    disabled={addedPartners.includes(partner.id)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  )
}
