import { PaymentType } from '../../../services/account'
import { Charge } from '../../../services/charge'
import { dateToLocaleDate } from '../../../utils/date'
import { numberToCurrency } from '../../../utils/number'

export interface ChargeFormatted extends Charge {
  paid: boolean
  paymentTypeFormatted: string
  dueDateFormatted: string
  amountFormatted: string
  amountPaidFormatted: string
}

export function mapPaymentType(type: PaymentType): string {
  const typeMapped = {
    BOLETO: 'Boleto',
    PIX: 'Pix manual / Depósito',
    COB_PIX: 'Pix',
    BOLEPIX: 'Bolepix'
  }

  return typeMapped[type]
}

export function formatCharge(charge: Charge): ChargeFormatted {
  return {
    ...charge,
    paid: !!charge.paymentDate,
    paymentTypeFormatted: mapPaymentType(charge.paymentType),
    dueDateFormatted: dateToLocaleDate(charge.dueDate),
    amountFormatted: numberToCurrency(charge.amount),
    amountPaidFormatted: numberToCurrency(charge.amountPaid)
  }
}
