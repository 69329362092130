import React, { useState, useContext, useEffect } from 'react'
import { Form as FinalForm } from 'react-final-form'
import {
  Button,
  DropdownOnSearchChangeData,
  Form,
  Header
} from 'semantic-ui-react'
import { debounce } from 'debounce'

import { useCan } from '../../hooks/useCan'
import { can } from '../../config/permissions'
import { AuthContext } from '../../contexts/AuthContext'
import { DateInput, Select } from '../../components/Form'
import { SelectOption } from '../../components/Form/Select'
import { PDFViewerModal } from '../../components/PDFViewerModal'
import {
  getToday,
  isSameDate,
  localeDateToDate,
  setCurrentMinute,
  setMaxHourAndMinute,
  removeTimezone
} from '../../utils/date'

import { Wrapper } from './styles'
import {
  categoryToOption,
  groupToOption,
  DEBOUNCE_TIME,
  initialValues,
  statusOptions
} from './utils'
import { useCostReport, useCategories, useGroups } from './hooks'
import { Show } from '../../components/Show'

export const Cost: React.FC = () => {
  const { report, loading: loadingReport, getCostReport } = useCostReport()
  const {
    categories,
    loading: loadingCategories,
    listCategories
  } = useCategories()
  const { groups, loading: loadingGroups, listGroups } = useGroups()
  const { user } = useContext(AuthContext)
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([])
  const [groupOptions, setGroupOptions] = useState<SelectOption[]>([])
  const [pdfViewerModal, setPdfViewerModal] = useState(false)
  const canGetCostReport = useCan(can.report.cost)

  useEffect(() => {
    listCategories()
  }, [])

  useEffect(() => {
    if (categories.length === 0) return

    setCategoryOptions(categories.map(categoryToOption))
  }, [categories])

  useEffect(() => {
    if (groups.length === 0) return

    setGroupOptions(groups.map(groupToOption))
  }, [groups])

  const searchGroup = (data: DropdownOnSearchChangeData) => {
    const { searchQuery } = data

    return debounce(() => listGroups(searchQuery), DEBOUNCE_TIME)()
  }

  function handleSubmit(values: any) {
    const start = removeTimezone(localeDateToDate(values.start))
    const endDate = localeDateToDate(values.end)
    const endIsToday = isSameDate(endDate, new Date())
    const endLastMinute = endIsToday
      ? setCurrentMinute(endDate)
      : setMaxHourAndMinute(endDate)
    const end = removeTimezone(endLastMinute)
    const status = values.status
    const categories = values.categories?.join(',')
    const { groupId } = values
    const query = {
      start,
      end,
      status,
      categories,
      groupId
    }

    getCostReport(query, () => {
      setPdfViewerModal(true)
    })
  }

  const userIsAdmin = user?.role === 'ADMIN'
  const userIsPartnerOfResale =
    user?.role === 'PARTNER' && user?.commissionType === 'RESALE'
  const isAuthorizedUser =
    userIsAdmin || (userIsPartnerOfResale && canGetCostReport)

  if (!isAuthorizedUser) {
    return (
      <Header content="Você não está autorizado a visualizar o relatório de custo" />
    )
  }

  return (
    <>
      <FinalForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validateOnBlur
      >
        {({ handleSubmit }) => (
          <Wrapper>
            <Form onSubmit={handleSubmit} loading={loadingReport}>
              <Form.Group widths={2}>
                <DateInput
                  name="start"
                  label="Início"
                  minDate={new Date(2022, 0, 1)}
                  width={8}
                />
                <DateInput
                  name="end"
                  label="Fim"
                  maxDate={getToday()}
                  width={8}
                />
              </Form.Group>
              <Form.Group>
                <Select
                  name="categories"
                  label="Categorias"
                  options={categoryOptions}
                  loading={loadingCategories}
                  width={16}
                  multiple
                />
              </Form.Group>
              <Form.Group>
                <Select
                  name="status"
                  label="Status do pedido"
                  options={statusOptions}
                  width={16}
                  multiple
                />
              </Form.Group>
              <Show condition={userIsAdmin}>
                <Form.Group>
                  <Select
                    name="groupId"
                    label="Grupo"
                    options={groupOptions}
                    loading={loadingGroups}
                    width={16}
                    onSearchChange={(_, data) => searchGroup(data)}
                    search
                  />
                </Form.Group>
              </Show>
              <Button content="Buscar" primary />
            </Form>
          </Wrapper>
        )}
      </FinalForm>
      <PDFViewerModal
        open={pdfViewerModal}
        handleModal={setPdfViewerModal}
        title="Relatório de comissão"
        pdf={report}
        emptyMessage="Não foram encontrados dados correspondentes ao filtro escolhido"
      />
    </>
  )
}
