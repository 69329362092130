import React, { useContext } from 'react'
import { Divider } from 'semantic-ui-react'

import { Form, Currency } from '../../../../../../../components/Form'
import { AuthContext } from '../../../../../../../contexts/AuthContext'

import { useOrder } from '../../../hooks'

export const TotalsGroup: React.FC = () => {
  const { order } = useOrder()
  const { user } = useContext(AuthContext)

  const userIsAdmin = user?.role === 'ADMIN'

  return (
    <>
      <Divider content="Totais" horizontal />

      <Form.Group widths={4}>
        <Currency name="totalItems" label="Itens" readOnly />
        <Currency name="totalDiscount" label="Desconto" readOnly />
        <Currency name="totalAddition" label="Acréscimo" readOnly />
        <Currency name="totalOrder" label="Pedido" readOnly />
      </Form.Group>

      {userIsAdmin && order?.status !== 'OPEN' ? (
        <Form.Group widths={5}>
          <Currency name="totalCost" label="Custo" readOnly />
          <Currency name="resaleCost" label="Custo Revenda" readOnly />
          <Currency name="totalCommission" label="Comissão" readOnly />
          <Currency name="tax" label="Imposto" readOnly />
          <Currency name="profit" label="Lucro" readOnly />
        </Form.Group>
      ) : null}
    </>
  )
}
