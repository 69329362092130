import { useContext } from 'react'
import { toast } from 'react-toastify'

import {
  createPerson as apiCreatePerson,
  updatePerson as apiUpdatePerson
} from '../../../../../services/person'

import { PersonFormatted } from '../../../utils/formatPerson'
import { PersonFormContext } from '../context'

export function usePerson() {
  const {
    person,
    responsible,
    partner,
    loading,
    setLoading,
    getPersonState,
    updatePersonState
  } = useContext(PersonFormContext)

  function createPerson(data: Partial<PersonFormatted>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiCreatePerson(data))
      .then(data => updatePersonState(data))
      .then(() => toast.info('Pessoa cadastrada com sucesso'))
      .finally(() => setLoading(false))
  }

  function updatePerson(data: Partial<PersonFormatted>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getPersonState())
      .then(Person => apiUpdatePerson(Person.id, data))
      .then(data => updatePersonState(data))
      .then(() => toast.info('O cadastro foi alterado'))
      .finally(() => setLoading(false))
  }

  return {
    person,
    responsible,
    partner,
    loading,
    setLoading,
    createPerson,
    updatePerson
  }
}
