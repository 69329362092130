import { useState } from 'react'
import { toast } from 'react-toastify'

import {
  Account,
  WriteOffData,
  deleteAccount as apiDeleteAccount,
  writeOffAccount as apiWriteOffAccount
} from '../../../../../services/account'

export function useAccount() {
  const [loading, setLoading] = useState(false)

  function deleteAccount(id: string, onSuccess?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiDeleteAccount(id))
      .then(() => toast.info('A conta foi excluída'))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  function writeOffAccount(
    id: string,
    data: WriteOffData,
    onSuccess?: (account: Account) => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiWriteOffAccount(id, data))
      .then(data => onSuccess?.(data))
      .finally(() => setLoading(false))
  }

  return {
    loading,
    deleteAccount,
    writeOffAccount
  }
}
