import React from 'react'
import { toast } from 'react-toastify'

import { ConfirmationModal } from '../../../../../components/ConfirmationModal'

import { UserFormatted } from '../../../utils/formatUser'

interface ConfimDeleteModalProps {
  open: boolean
  user?: UserFormatted
  loading?: boolean
  handleModal: (open: boolean) => void
  onConfirm: (user: UserFormatted) => void
  onDecline: () => void
}

export const ConfirmDeleteModal: React.FC<ConfimDeleteModalProps> = ({
  open,
  user,
  loading,
  handleModal,
  onConfirm,
  onDecline
}) => {
  function handleConfirmDelete() {
    if (!user) {
      toast.error('Nenhum usuário selecionado')

      return
    }

    onConfirm(user)
  }

  return (
    <ConfirmationModal
      open={open}
      loading={loading}
      handleModal={handleModal}
      onConfirm={handleConfirmDelete}
      onDecline={onDecline}
    >
      <span>Deseja excluir o usuário?</span>
      <br />
      <strong>{user?.nickname}</strong>
    </ConfirmationModal>
  )
}
