import api from './api'

export interface System {
  id: string
  name: string
  slug: string
  token: string
  version: string
  createdAt: Date
  updatedAt: Date
}

export interface SystemCreate {
  name: string
}

export interface SystemUpdate {
  name: string
}

export interface SystemPaginate {
  page?: number
  limit?: number
  orderBy?: string
  order?: 'ASC' | 'DESC'
  search?: string
}

export interface SystemListResult {
  systems: System[]
  total: number
}

export interface Root {
  id: string
  systemId: string
}

export async function getSystem(id: string): Promise<System | undefined> {
  const response = await api.get(`/systems/${id}`)
  const system = response.status === 200 ? response.data : undefined

  return system
}

export async function listSystems(
  paginate?: SystemPaginate
): Promise<SystemListResult> {
  const { page, limit, orderBy, order, search } = paginate || {}
  const response = await api.get(`/systems`, {
    params: { page, limit, orderBy, order, search }
  })
  const { items: systems, total } = response.data

  return {
    systems,
    total
  }
}

export async function createSystem(data: SystemCreate): Promise<System> {
  const response = await api.post('/systems', data)
  const system = response.data

  return system
}

export async function updateSystem(
  id: string,
  data: SystemUpdate
): Promise<System> {
  const response = await api.put(`/systems/${id}`, data)
  const system = response.data

  return system
}

export async function updateSystemToken(id: string): Promise<System> {
  const response = await api.patch(`/systems/${id}/token`)
  const system = response.data

  return system
}

export async function deleteSystem(id: string): Promise<boolean> {
  const response = await api.delete(`/systems/${id}`)
  const result = response.data

  return result.status
}

export async function getSystemRoot(slug: string): Promise<Root> {
  const response = await api.get(`/systems/${slug}/root`)
  const root = response.data

  return root
}
