import { useState } from 'react'

import {
  listPriceListItems as apiListPriceListItems,
  PriceListItem,
  ListPriceListItemOptions
} from '../../../../../services/priceListItem'

export function usePriceListItem() {
  const [items, setItems] = useState<PriceListItem[]>([])
  const [loading, setLoading] = useState(false)

  function listPriceListItems(
    priceListId: string,
    options: ListPriceListItemOptions
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiListPriceListItems(priceListId, options))
      .then(data => setItems(data.priceListItems))
      .finally(() => setLoading(false))
  }

  return {
    items,
    loading,
    listPriceListItems
  }
}
