import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Buffer } from 'buffer'

import { getBolepixPdf } from '../../services/public'

export function useBolepixPdf() {
  const [query] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [bolepixPdf, setBolepixPdf] = useState<Buffer>()

  useEffect(() => {
    const orderId = query.get('orderId')
    const chargeId = query.get('chargeId')

    if (!orderId || !chargeId) {
      setError('Link inválido. Entre em contato para mais informações.')
      return
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getBolepixPdf(orderId, chargeId))
      .then(output => setBolepixPdf(output.content))
      .catch(err => setError(err.message))
      .finally(() => setLoading(false))
  }, [])

  return {
    bolepixPdf,
    loading,
    error
  }
}
