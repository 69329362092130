import { localeDateTimeToDate } from '../utils/date'

import api from './api'

export interface APIKey {
  id: string
  description?: string
  api: 'UPDATER' | 'LICENSING'
  key: string
  expiresIn?: string
  ips?: string
  personId: string
  createdAt: string
}

export interface ExternalAPIKey {
  id: string
  description?: string
  api: 'UPDATER' | 'LICENSING'
  key: string
  expiresIn?: string
  ips?: string[]
  personId: string
  createdAt: string
}

function joinIps(ips: string[]): string {
  return ips.join(', ')
}

function splitIps(ips: string): string[] {
  return ips.replaceAll(' ', '').split(/[,| ,]/)
}

function toInternalFormat(data: ExternalAPIKey): APIKey {
  return {
    ...data,
    ips: data.ips && joinIps(data.ips)
  }
}

function toExternalFormat(data: Partial<APIKey>): Partial<ExternalAPIKey> {
  return {
    ...data,
    expiresIn:
      data.expiresIn && localeDateTimeToDate(data.expiresIn).toISOString(),
    ips: data.ips ? splitIps(data.ips) : undefined,
    personId: undefined
  }
}

export async function listApiKeys(personId: string): Promise<APIKey[]> {
  const response = await api.get(`/people/${personId}/api-keys`)
  const apiKeys = response.data.map(toInternalFormat)

  return apiKeys
}

export async function createApiKey(
  personId: string,
  data: Partial<APIKey>
): Promise<APIKey> {
  const body = toExternalFormat(data)
  const response = await api.post(`/people/${personId}/api-keys`, body)
  const apiKey = toInternalFormat(response.data)

  return apiKey
}

export async function updateApiKeyIps(
  id: string,
  personId: string,
  ips: string
): Promise<APIKey> {
  const body = { ips: splitIps(ips) }
  const response = await api.patch(
    `/people/${personId}/api-keys/${id}/ips`,
    body
  )
  const apiKey = toInternalFormat(response.data)

  return apiKey
}

export async function deleteApiKey(
  id: string,
  personId: string
): Promise<boolean> {
  const response = await api.delete(`/people/${personId}/api-keys/${id}`)
  const status = response.data.status

  return status
}
