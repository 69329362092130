import React from 'react'
import { Segment, Divider, Table } from 'semantic-ui-react'

import { Show } from '../../../../../../components/Show'
import { useCan } from '../../../../../../hooks/useCan'
import { can } from '../../../../../../config/permissions'

import { useApiKeys } from '../../hooks/useApiKey'
import {
  ApiKeysTableActions,
  ApiKeysTableHeader,
  ApiKeysTableRow
} from './components'

export const ApiKeysTable: React.FC = () => {
  const { person, apiKeys } = useApiKeys()
  const canCreate = useCan(can.apiKey.create)

  return (
    <Show condition={!!person}>
      <Segment>
        <Divider content="Chaves de acesso" horizontal />
        <Show condition={canCreate} component={<ApiKeysTableActions />} />

        <Table selectable compact>
          <ApiKeysTableHeader />
          <Table.Body>
            {apiKeys.map(apiKey => (
              <ApiKeysTableRow key={apiKey.id} apiKey={apiKey} />
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </Show>
  )
}
