import Joi from 'joi'

const apiKeyMessages = {
  description: {
    max: 'Permitido no máximo 30 caracteres'
  },
  api: {
    required: 'API é obrigatório'
  }
}

export const apiKeyCreateSchema = Joi.object({
  personId: Joi.string().uuid(),
  description: Joi.string().max(30).allow('').messages({
    'string.max': apiKeyMessages.description.max
  }),
  api: Joi.string().valid('UPDATER', 'LICENSING').required().messages({
    'any.required': apiKeyMessages.api.required
  }),
  expiresIn: Joi.string(),
  ips: Joi.string().allow('')
})

export const apiKeyUpdateSchema = Joi.object({
  id: Joi.string().uuid(),
  ips: Joi.string().allow('')
})
