import React from 'react'
import { InputOnChangeData, Table } from 'semantic-ui-react'

import { can } from '../../../config/permissions'
import { Currency } from '../../../components/Form/pure'
import { ItemControllers } from '../../../components/Table'
import { CompactInput } from '../../../styles/CompactInput'
import { replaceInvalidByZero, stringToNumber } from '../../../utils/number'
import { calculateMarkup, calculatePrice } from '../../../utils/markup'

import { PriceListItemFormatted } from './utils'

interface PriceListItemRowProps {
  item: PriceListItemFormatted
  onChangeItem: (item: PriceListItemFormatted) => void
  onBlurItem: (item: PriceListItemFormatted) => void
  onDeleteClick?: (item: PriceListItemFormatted) => void
}

export const PriceListItemRow: React.FC<PriceListItemRowProps> = ({
  item,
  onChangeItem,
  onBlurItem,
  onDeleteClick
}) => {
  function onChangeItemProperty(data: InputOnChangeData) {
    const { name } = data
    const value = stringToNumber(data.value)

    if (name === 'cost') {
      const markup = replaceInvalidByZero(item.markup)
      const price = calculatePrice(value, markup)

      onChangeItem({
        ...item,
        cost: value,
        price,
        changed: true
      })
    }

    if (name === 'markup') {
      const price = calculatePrice(item.cost, value)

      onChangeItem({
        ...item,
        markup: value,
        price,
        changed: true
      })
    }

    if (name === 'price') {
      const markup = calculateMarkup(item.cost, value)

      onChangeItem({
        ...item,
        markup,
        price: value,
        changed: true
      })
    }
  }

  return (
    <Table.Row>
      <Table.Cell
        content={item.product?.description || item.service?.description}
      />
      <Table.Cell textAlign="center">
        <CompactInput w="120px" h="30px" ta="center">
          <Currency
            name="cost"
            value={item.cost}
            onChange={(_, data) => onChangeItemProperty(data)}
            onBlur={() => onBlurItem(item)}
          />
        </CompactInput>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <CompactInput w="120px" h="30px" ta="center">
          <Currency
            name="markup"
            value={item.markup}
            onChange={(_, data) => onChangeItemProperty(data)}
            onBlur={() => onBlurItem(item)}
          />
          {' %'}
        </CompactInput>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <CompactInput w="120px" h="30px" ta="center">
          <Currency
            name="price"
            value={item.price}
            onChange={(_, data) => onChangeItemProperty(data)}
            onBlur={() => onBlurItem(item)}
          />
        </CompactInput>
      </Table.Cell>
      <Table.Cell textAlign="right">
        {onDeleteClick && (
          <ItemControllers
            onDeleteClick={() => onDeleteClick(item)}
            permissionsToDelete={can.priceListItem.delete}
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}
