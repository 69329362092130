import React, { useState } from 'react'
import { Header, Button } from 'semantic-ui-react'

import { FormActionsContainer } from '../../../../../../../styles/FormActionsContainer'
import { Show } from '../../../../../../../components/Show'

import { useOrder } from '../../../hooks'
import { AddItemModal } from './AddItemModal'

export const OrderItemsHeader: React.FC = () => {
  const { order } = useOrder()
  const [showAddItemModal, setShowSetAddItemModal] = useState(false)

  function handleOpenAddItemModalClick() {
    setShowSetAddItemModal(true)
  }

  const orderIsOpen = order?.status === 'OPEN'

  return (
    <FormActionsContainer>
      <Header as="h3" content="Items" floated="left" />
      <Show condition={orderIsOpen}>
        <Button
          content="Adicionar"
          floated="right"
          onClick={handleOpenAddItemModalClick}
          primary
        />
      </Show>
      <AddItemModal
        open={showAddItemModal}
        handleModal={setShowSetAddItemModal}
      />
    </FormActionsContainer>
  )
}
