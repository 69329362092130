import React from 'react'
import { Button } from 'semantic-ui-react'

import { Show } from '../../../../../../../../components/Show'

import { useOrder } from '../../../../hooks'

export const SaveAction: React.FC = () => {
  const { order } = useOrder()

  const orderExist = !!order

  return (
    <Show
      condition={!orderExist}
      component={
        <Button type="submit" content="Salvar" floated="right" primary />
      }
    />
  )
}
