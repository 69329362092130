import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

import { pages } from '../../../../../../../../config/pages'

export const GoToBackAction: React.FC = () => {
  return (
    <Button type="button" content="Voltar" as={Link} to={pages.orders.path} />
  )
}
