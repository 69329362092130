import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table } from 'semantic-ui-react'

import { PaginationContext } from '../../../../contexts/PaginationContext'
import { DefaultLoader } from '../../../../components/DefaultLoader'
import { pages } from '../../../../config/pages'

import { OrderFormatted } from '../../utils/formatOrder'
import { useOrdersList, useOrder } from './hooks'
import {
  OrdersTableActions,
  OrdersTableHeader,
  OrdersTableFooter,
  OrdersTableRow,
  ConfirmDeleteModal
} from './components'

export const OrdersTable: React.FC = () => {
  const {
    pagination,
    goToFirstPage,
    goToPreviousPage,
    goToNextPage,
    goToLastPage,
    changeOrderBy,
    changeLimit
  } = useContext(PaginationContext)
  const { orders, totalItems, loading, fetchOrders } = useOrdersList()
  const { loading: deleteLoading, deleteOrder } = useOrder()
  const navigate = useNavigate()
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState<OrderFormatted>()

  useEffect(() => {
    fetchOrders(pagination)
  }, [pagination])

  function redirectToCreateForm() {
    navigate(`${pages.orders.path}/inclusao`)
  }

  function redirectToUpdateForm(order: OrderFormatted) {
    navigate(`${pages.orders.path}/edicao?id=${order.id}`)
  }

  function openConfirmDeleteModal(order: OrderFormatted) {
    setSelectedOrder(order)
    setShowConfirmDeleteModal(true)
  }

  function handleConfirmDelete(order: OrderFormatted) {
    deleteOrder(order.id, () => {
      setShowConfirmDeleteModal(false)
      fetchOrders(pagination)
    })
  }

  return (
    <>
      <DefaultLoader active={loading} />

      <OrdersTableActions
        pagination={pagination}
        onCreateButtonClick={redirectToCreateForm}
      />

      <Table selectable striped compact sortable>
        <OrdersTableHeader
          pagination={pagination}
          changeOrderBy={changeOrderBy}
        />
        <Table.Body>
          {orders.map(order => (
            <OrdersTableRow
              key={order.id}
              order={order}
              onUpdateButtonClick={redirectToUpdateForm}
              onDeleteButtonClick={openConfirmDeleteModal}
            />
          ))}
        </Table.Body>
        <OrdersTableFooter
          colSpan={10}
          count={orders.length}
          pagination={pagination}
          totalItems={totalItems}
          loading={loading}
          goToFirstPage={goToFirstPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
          changeLimit={changeLimit}
        />
      </Table>

      <ConfirmDeleteModal
        open={showConfirmDeleteModal}
        order={selectedOrder}
        loading={deleteLoading}
        handleModal={setShowConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
        onDecline={() => setShowConfirmDeleteModal(false)}
      />
    </>
  )
}
