import React from 'react'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'

import { PermissionGroup } from './PermissionGroup'
import { OnCheck } from './AllPermissions'

interface GroupPermissionsProps {
  onChange: (data: OnCheck) => void
  isChecked: (resource: string, actions: string[]) => boolean
}

export const GroupPermissions: React.FC<GroupPermissionsProps> = ({
  onChange,
  isChecked
}) => {
  function handleCheck(
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) {
    const value = data.value as string
    const checked = data.checked as boolean
    onChange({ value, checked })
  }

  return (
    <PermissionGroup label="Grupo:">
      <Checkbox
        onChange={handleCheck}
        checked={isChecked('group', ['Get', 'List'])}
        value="group:Get,List"
        label="Ler"
      />
      <Checkbox
        onChange={handleCheck}
        checked={isChecked('group', ['Create'])}
        value="group:Create"
        label="Cadastrar"
      />
      <Checkbox
        onChange={handleCheck}
        checked={isChecked('group', ['Update'])}
        value="group:Update"
        label="Atualizar"
      />
      <Checkbox
        onChange={handleCheck}
        checked={isChecked('group', ['Delete'])}
        value="group:Delete"
        label="Excluir"
      />
    </PermissionGroup>
  )
}
