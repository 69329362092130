import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { Segment } from 'semantic-ui-react'

import { Form } from '../../../../../../components/Form'
import { getDiff, getFormValidate } from '../../../../../../utils/form'

import {
  PersonFormatted,
  personToExternal
} from '../../../../utils/formatPerson'
import { usePerson } from '../../hooks'
import { personSchema } from './schemas'
import { FormActions, AddressFields, PersonFields } from './components'

const initialValues = {
  type: 'COMPANY',
  active: true
}

const validateForm = getFormValidate(personSchema)

export const PersonMain: React.FC = () => {
  const { person, createPerson, updatePerson } = usePerson()

  function handleSubmit(values: PersonFormatted) {
    const data = personToExternal(values)

    if (!person) createPerson(data)

    if (person) {
      const diff = getDiff(person, values)

      if (!diff) return

      const diffData = personToExternal(diff)

      updatePerson(diffData)
    }
  }

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={{
        ...initialValues,
        ...person
      }}
      validate={validateForm}
      validateOnBlur
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormActions />
          <Segment>
            <PersonFields />
            <AddressFields />
          </Segment>
        </Form>
      )}
    </FinalForm>
  )
}
