import { useState, useEffect } from 'react'

import { listSessions, deleteSession, Session } from '../services/session'

export const useSession = () => {
  const [sessions, setSessions] = useState<Session[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => listSessions())
      .then(data => setSessions(data))
      .finally(() => setLoading(false))
  }, [])

  function removeSession(id: string) {
    const onDelete = () => {
      const newList = sessions.filter(session => session.id !== id)
      setSessions(newList)
    }

    return Promise.resolve()
      .then(() => setLoading(true))
      .then(() => deleteSession(id))
      .then(() => onDelete())
      .finally(() => setLoading(false))
  }

  return {
    sessions,
    loading,
    deleteSession: removeSession
  }
}
