import styled from 'styled-components'

export const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: #4183c4;
  display: inline;
  margin: 0;
  padding: 0;

  :disabled {
    cursor: default;
    color: rgba(0, 0, 0, 0.6);
  }
`
