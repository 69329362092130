import React, { useState } from 'react'
import { Button, Header, Segment, Table } from 'semantic-ui-react'

import {
  createPriceListItem,
  updatePriceListItem,
  deletePriceListItem,
  PriceListItem
} from '../../../services/priceListItem'
import { Service } from '../../../services/service'
import { Product } from '../../../services/product'
import { useCan } from '../../../hooks/useCan'
import { can } from '../../../config/permissions'
import { ConfirmationModal } from '../../../components/ConfirmationModal'

import { Wrapper } from './styles'
import { AddItemModal } from './AddItemModal'
import { PriceListItemRow } from './PriceListItemRow'
import { PriceListFormatted, PriceListItemFormatted } from './utils'

interface PriceListItemsTableProps {
  priceList: PriceListFormatted
  onCreateItem?: (item: PriceListItemFormatted) => void
  onUpdateItem?: (item: PriceListItemFormatted) => void
  onDeleteItem?: (item: PriceListItemFormatted) => void
}

interface HandleCreateItemOptions {
  service?: Service
  product?: Product
}

export const PriceListItemsTable: React.FC<PriceListItemsTableProps> = ({
  priceList,
  onCreateItem,
  onUpdateItem,
  onDeleteItem
}) => {
  const [loading, setLoading] = useState(false)
  const [addItemModal, setAddItemModal] = useState(false)
  const [deleteItemModal, setDeleteItemModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<PriceListItemFormatted>()
  const canCreatePriceListItem = useCan(can.priceListItem.modify)

  async function savePriceListItem(
    item: Partial<PriceListItem>
  ): Promise<PriceListItem> {
    if (!priceList) {
      throw new Error('Nenhuma tabela de preços para salvar os itens')
    }

    return Promise.resolve()
      .then(() => setLoading(true))
      .then(() => createPriceListItem(priceList.id, item))
      .finally(() => setLoading(false))
  }

  async function removePriceListItem(item: PriceListItem) {
    if (!priceList) return

    return Promise.resolve()
      .then(() => setLoading(true))
      .then(() => deletePriceListItem(item.id, priceList.id))
      .then(() => setDeleteItemModal(false))
      .finally(() => setLoading(false))
  }

  function handleCreateItem(toAdd: HandleCreateItemOptions) {
    if (!priceList) return

    const product = toAdd.product && { ...toAdd.product, changed: false }
    const service = toAdd.service && { ...toAdd.service, changed: false }

    const data = {
      serviceId: service?.id,
      productId: product?.id,
      cost: 0,
      price: 0
    }

    Promise.resolve()
      .then(() => savePriceListItem(data))
      .then(item =>
        onCreateItem?.({
          ...item,
          markup: 0,
          changed: false,
          product,
          service
        })
      )
  }

  function openDeleteItemModal(item: PriceListItemFormatted) {
    setSelectedItem(item)
    setDeleteItemModal(true)
  }

  function handleDeleteItem() {
    if (!selectedItem) return

    Promise.resolve()
      .then(() => removePriceListItem(selectedItem))
      .then(() => onDeleteItem?.(selectedItem))
  }

  function handleValueChange(item: PriceListItemFormatted) {
    onUpdateItem?.(item)
  }

  function handleValueBlur(item: PriceListItemFormatted) {
    if (!priceList) return

    if (!item.changed) return

    const { price, cost } = item

    Promise.resolve()
      .then(() => updatePriceListItem(item.id, priceList.id, { price, cost }))
      .then(() => onUpdateItem?.({ ...item, changed: false }))
  }

  return (
    <Segment>
      <Wrapper>
        <Header as="h3" content="Produtos / Serviços" floated="left" />
        {canCreatePriceListItem && (
          <Button
            content="Adicionar"
            floated="right"
            onClick={() => setAddItemModal(true)}
            primary
          />
        )}
      </Wrapper>
      <Table selectable striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="Descrição" />
            <Table.HeaderCell content="Custo" />
            <Table.HeaderCell content="Markup" />
            <Table.HeaderCell content="Venda" />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {priceList?.items.map(item => (
            <PriceListItemRow
              key={item.id}
              item={item}
              onChangeItem={handleValueChange}
              onBlurItem={handleValueBlur}
              onDeleteClick={openDeleteItemModal}
            />
          ))}
        </Table.Body>
      </Table>
      <AddItemModal
        open={addItemModal}
        priceListId={priceList.id}
        handleModal={setAddItemModal}
        onSelect={handleCreateItem}
      />
      <ConfirmationModal
        open={deleteItemModal}
        handleModal={setDeleteItemModal}
        onConfirm={handleDeleteItem}
        onDecline={() => setDeleteItemModal(false)}
        loading={loading}
      >
        <span>Deseja excluir este item?</span>
        <br />
        <strong>
          {selectedItem?.service?.description ||
            selectedItem?.product?.description}
        </strong>
      </ConfirmationModal>
    </Segment>
  )
}
