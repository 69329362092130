import React from 'react'
import { Button } from 'semantic-ui-react'

import { useCan } from '../../../hooks/useCan'

interface ItemControllersProps {
  onUpdateClick?: () => void
  onDeleteClick?: () => void
  permissionsToUpdate?: string[]
  permissionsToDelete?: string[]
}

export const ItemControllers: React.FC<ItemControllersProps> = ({
  onUpdateClick,
  onDeleteClick,
  permissionsToUpdate = [],
  permissionsToDelete = []
}) => {
  const canUpdate = useCan(permissionsToUpdate)
  const canDelete = useCan(permissionsToDelete)

  return (
    <>
      {onUpdateClick && canUpdate && (
        <Button
          icon="edit"
          onClick={onUpdateClick}
          disabled={!onUpdateClick}
          size="mini"
          circular
        />
      )}
      {onDeleteClick && canDelete && (
        <Button
          icon="trash"
          onClick={onDeleteClick}
          disabled={!onDeleteClick}
          size="mini"
          circular
        />
      )}
    </>
  )
}
