import { useState } from 'react'
import { toast } from 'react-toastify'

import { deleteService as apiDeleteService } from '../../../../../services/service'

export function useService() {
  const [loading, setLoading] = useState(false)

  function deleteService(id: string, onSuccess?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiDeleteService(id))
      .then(() => toast.info('O serviço foi excluído'))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  return {
    loading,
    deleteService
  }
}
