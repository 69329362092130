import { useState } from 'react'

import {
  GetProofOutput,
  getProof as apiGetProof
} from '../../../../../services/pix'

export function usePix() {
  const [loading, setLoading] = useState(false)
  const [proofFile, setProofFile] = useState<GetProofOutput>()

  function getProof(
    orderId: string,
    accountId: string,
    onSuccess?: (output: GetProofOutput) => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiGetProof(orderId, accountId))
      .then(data => {
        setProofFile(data)
        return data
      })
      .then(output => onSuccess?.(output))
      .finally(() => setLoading(false))
  }

  return {
    loading,
    proofFile,
    getProof
  }
}
