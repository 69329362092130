import api from './api'
import { Group } from './group'
import { Person } from './person'
import { User } from './users'

export type AccountType = 'PAYABLE' | 'RECEIVABLE'

export type AccountCostType = 'FIXED' | 'VARIABLE' | 'NONE'

export type AccountStatus = 'OPEN' | 'PAID'

export type AccountOrigin = 'MANUAL' | 'ORDER'

export type PaymentType = 'BOLETO' | 'PIX' | 'COB_PIX' | 'BOLEPIX'

export interface Account {
  id: string
  categoryId: string
  orderId?: string
  personId: string
  type: AccountType
  costType: AccountCostType
  paymentType: PaymentType
  description: string
  dueDate: string
  amount: number
  paymentDate?: string
  amountPaid: number
  origin: AccountOrigin
  groupId?: string
  createdAt: string
  updatedAt: string
  person: Person
  partner?: User
  group?: Group
}

export interface AccountPaginate {
  search?: string
  page?: number
  limit?: number
  orderBy?: string
  order?: 'ASC' | 'DESC'
  type: AccountType
  status: AccountStatus
}

export interface AccountListResult {
  accounts: Account[]
  total: number
}

export interface WriteOffData {
  paymentDate: string
  amountPaid: number
}

export async function getAccount(id: string): Promise<Account | undefined> {
  const response = await api.get(`/accounts/${id}`)
  const account = response.status === 200 ? response.data : undefined

  return account
}

export async function listAccounts(
  paginate?: AccountPaginate
): Promise<AccountListResult> {
  const { search, page, limit, orderBy, order, type, status } = paginate || {}
  const response = await api.get(`/accounts`, {
    params: { search, page, limit, orderBy, order, type, status }
  })
  const { items, total } = response.data
  const accounts = items

  return {
    accounts,
    total
  }
}

export async function createAccount(data: Partial<Account>): Promise<Account> {
  const response = await api.post('/accounts', data)
  const account = response.data

  return account
}

export async function updateAccount(
  id: string,
  data: Partial<Account>
): Promise<Account> {
  const response = await api.put(`/accounts/${id}`, data)
  const account = response.data

  return account
}

export async function deleteAccount(id: string): Promise<boolean> {
  const response = await api.delete(`/accounts/${id}`)
  const result = response.data

  return result.status
}

export async function writeOffAccount(
  id: string,
  data: WriteOffData
): Promise<Account> {
  const { paymentDate, amountPaid } = data
  const response = await api.put(`/accounts/${id}/write-off`, {
    paymentDate,
    amountPaid
  })
  const result = response.data

  return result
}
