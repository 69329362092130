import React from 'react'

import { GroupFormProvider } from './context'
import { GroupMain } from './components'

export const GroupForm: React.FC = () => {
  return (
    <GroupFormProvider>
      <GroupMain />
    </GroupFormProvider>
  )
}
