import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import {
  requestCertificate as apiRequestCertificate,
  renewCertificate as apiRenewCertificate,
  deleteRequest as apiDeleteRequest,
  checkVideoconferencingAvailability as apiCheckVideoconferencingAvailability
} from '../../../../../services/ac'

import { errorHandler } from '../../../utils/errorHandler'
import { OrderFormContext } from '../context'

export function useOrderAc() {
  const { setLoading, getOrderState, updateOrderState } =
    useContext(OrderFormContext)
  const [errors, setErrors] = useState<string[]>([])

  function requestCertificate(onFinally?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiRequestCertificate(order.id))
      .then(data => updateOrderState(data))
      .then(() => toast.info('Solicitação feita'))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function renewCertificate(onFinally?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiRenewCertificate(order.id))
      .then(data => updateOrderState(data))
      .then(() => toast.info('Solicitação feita'))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function deleteRequest(onFinally?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiDeleteRequest(order.id))
      .then(data => updateOrderState(data))
      .then(() => toast.info('Solicitação cancelara'))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function checkVideoconferencingAvailability(
    onSuccess: (availability: boolean) => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiCheckVideoconferencingAvailability(order.id))
      .then(availability => onSuccess(availability))
      .finally(() => setLoading(false))
  }

  return {
    errors,
    requestCertificate,
    renewCertificate,
    deleteRequest,
    checkVideoconferencingAvailability
  }
}
