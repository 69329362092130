import React from 'react'
import { toast } from 'react-toastify'

import { ConfirmationModal } from '../../../../../../../../../components/ConfirmationModal'
import { Person } from '../../../../../../../../../services/person'

import { OrderFormatted } from '../../../../../../../utils/formatOrder'

interface ConfirmationCheckingModalProps {
  open: boolean
  order?: OrderFormatted
  customer?: Person
  responsible?: Person
  loading?: boolean
  title?: string
  question?: string
  handleModal: (open: boolean) => void
  onConfirm: (order: OrderFormatted) => void
  onDecline: () => void
}

export const ConfirmationCheckingModal: React.FC<
  ConfirmationCheckingModalProps
> = ({
  open,
  order,
  customer,
  responsible,
  loading,
  title,
  question,
  handleModal,
  onConfirm,
  onDecline
}) => {
  function handleConfirmDelete() {
    if (!order) {
      toast.error('Nenhum pedido selecionado')

      return
    }

    onConfirm(order)
  }

  return (
    <ConfirmationModal
      open={open}
      loading={loading}
      title={title}
      handleModal={handleModal}
      onConfirm={handleConfirmDelete}
      onDecline={onDecline}
    >
      <span>{question}</span>
      <br />
      {order ? (
        <>
          <strong>{order.issueDateFormatted}</strong>
          <br />
          <strong>{responsible?.name || customer?.name}</strong>
        </>
      ) : null}
    </ConfirmationModal>
  )
}
