import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { Form } from '../../../../../../components/Form'
import { getDiff, getFormValidate } from '../../../../../../utils/form'
import { stringToNumber } from '../../../../../../utils/number'
import { sanitizePhone } from '../../../../../../utils/sanitize'
import { UserPix } from '../../../../../../services/users'

import { permissionsByRole } from '../../../../utils/permissions'
import { userSchema } from '../../../../utils/schemas'
import { UserFormatted } from '../../../../utils/formatUser'
import { sanitizeUserPix } from '../../../../utils/pixUtil'
import { useUser } from '../../hooks'
import { FormActions, FormFields } from './components'

const initialValues = {
  permissions: [],
  role: 'EMPLOYEE',
  commissionType: 'INDICATION'
}

const validateForm = getFormValidate(userSchema)

export const UserMain: React.FC = () => {
  const { user, createUser, updateUser } = useUser()

  function handlePixUpdate(
    data?: UserPix,
    diff?: Partial<UserPix>
  ): UserPix | undefined | null {
    if (!data) return undefined

    if (!diff) return undefined

    if ((data.type as string) === '') return null

    return {
      type: data.type,
      key: data.key
    }
  }

  function handleSubmit(values: UserFormatted) {
    const data = {
      ...values,
      phone: sanitizePhone(`+55${values.phoneFormatted}`),
      phoneFormatted: undefined,
      commission: stringToNumber(values.commissionFormatted),
      commissionFormatted: undefined,
      pix: sanitizeUserPix(values.pix)
    }

    if (!user) {
      const permissions = permissionsByRole[values.role]
      createUser({ ...data, permissions })
    }

    if (user) {
      const diff = getDiff<any>(user, data)
      const pixDiff = getDiff<UserPix>(user.pix || {}, data.pix || {})
      const pix = handlePixUpdate(data.pix, pixDiff)
      delete diff?.pix

      if (!diff && !pixDiff) return

      const update = { ...diff }

      if (pix) Object.assign(update, { pix })

      if (Object.keys(update).length === 0) return

      updateUser(update)
    }
  }

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={{ ...initialValues, ...user }}
      validate={validateForm}
      validateOnBlur
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormActions />
          <FormFields />
        </Form>
      )}
    </FinalForm>
  )
}
