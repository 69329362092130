import React from 'react'

import { ConfirmationModal } from '../../../../../../../../../components/ConfirmationModal'

import { SignatureItemFormatted } from '../../../../../../../utils/formatSignatureItem'

interface SignatureItemsTableRowRemoveModalProps {
  open: boolean
  item: SignatureItemFormatted
  loading: boolean
  handleModal: (open: boolean) => void
  onConfirm: () => void
}

export const SignatureItemsTableRowRemoveModal: React.FC<
  SignatureItemsTableRowRemoveModalProps
> = ({ open, item, loading, handleModal, onConfirm }) => {
  return (
    <ConfirmationModal
      open={open}
      title="Remover item"
      loading={loading}
      handleModal={handleModal}
      onConfirm={onConfirm}
      onDecline={() => handleModal(false)}
    >
      <span>Deseja remover este item da assinatura?</span>
      <br />
      <strong>{item.priceListItem.service?.description}</strong>
    </ConfirmationModal>
  )
}
