import React from 'react'
import { Table } from 'semantic-ui-react'

import { Pagination } from '../../../../../contexts/PaginationContext'
import { PaginationControllers } from '../../../../../components/PaginationControllers'

interface SignaturesTableFooterProps {
  colSpan: number
  count: number
  pagination: Pagination
  totalItems: number
  loading?: boolean
  goToFirstPage: () => void
  goToPreviousPage: () => void
  goToNextPage: () => void
  goToLastPage: (totalItems?: number) => void
  changeLimit: (value: number) => void
}

export const SignaturesTableFooter: React.FC<SignaturesTableFooterProps> = ({
  colSpan,
  count,
  pagination,
  totalItems,
  loading,
  goToFirstPage,
  goToPreviousPage,
  goToNextPage,
  goToLastPage,
  changeLimit
}) => {
  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell textAlign="right" colSpan={colSpan}>
          <PaginationControllers
            count={count}
            page={pagination.page}
            limit={pagination.limit}
            total={totalItems}
            loading={loading}
            goToFirstPage={goToFirstPage}
            goToPreviousPage={goToPreviousPage}
            goToNextPage={goToNextPage}
            goToLastPage={goToLastPage}
            onChangeLimit={changeLimit}
          />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  )
}
