import Joi from 'joi'

const commissionReportMessages = {
  categories: {
    required: 'Deve haver pelo menos uma categoria'
  }
}

export const commissionReportSchema = Joi.object({
  start: Joi.allow(),
  end: Joi.allow(),
  categories: Joi.required().messages({
    'any.required': commissionReportMessages.categories.required
  }),
  partnerId: Joi.allow(),
  employeeId: Joi.allow()
})
