import React, { useContext, useState } from 'react'
import { Button, Input } from 'semantic-ui-react'

import { ActionsContainer } from '../../../../../components/RegistrationContainer/styles'
import { useCan } from '../../../../../hooks/useCan'
import { can } from '../../../../../config/permissions'
import {
  Pagination,
  PaginationContext
} from '../../../../../contexts/PaginationContext'

import { sanitizeSearch } from '../../../utils/search'

interface PeopleTableActionsProps {
  pagination: Pagination
  onCreateButtonClick: () => void
}

export const PeopleTableActions: React.FC<PeopleTableActionsProps> = ({
  onCreateButtonClick
}) => {
  const [search, setSearch] = useState<string>('')
  const { changeQuery } = useContext(PaginationContext)
  const canCreate = useCan(can.order.create)

  function searchPerson() {
    const value = sanitizeSearch(search.trim())

    changeQuery({
      search: value,
      searchBy: ['id', 'name', 'document', 'emails', 'phone']
    })
  }

  function handleSearchKeyPress(evt: React.KeyboardEvent) {
    if (evt.key === 'Enter') {
      searchPerson()
    }
  }

  return (
    <ActionsContainer>
      <Input
        placeholder="Pesquisar"
        value={search}
        onChange={(_, { value }) => setSearch(value.toUpperCase())}
        onKeyPress={handleSearchKeyPress}
        action={<Button icon="search" onClick={searchPerson} />}
      />
      {canCreate ? (
        <Button
          content="Incluir"
          onClick={onCreateButtonClick}
          floated="right"
          primary
        />
      ) : null}
    </ActionsContainer>
  )
}
