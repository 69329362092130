import { useContext } from 'react'

import {
  addSignatureItem as apiAddSignatureItem,
  changeSignatureItem as apiChangeSignatureItem,
  removeSignatureItem as apiRemoveSignatureItem,
  SignatureItemCreate,
  SignatureItemUpdate
} from '../../../../../services/signatureItem'

import {
  addItemInSignature,
  replaceItemInSignature,
  removeItemFromSignature
} from '../../../utils/signatureCalculate'
import { formatSignatureItem } from '../../../utils/formatSignatureItem'
import { SignatureFormContext } from '../context'

export function useSignatureItem() {
  const { loading, getSignatureState, updateSignatureState, setLoading } =
    useContext(SignatureFormContext)

  function addSignatureItem(data: SignatureItemCreate, onSuccess?: () => void) {
    const signature = getSignatureState()

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiAddSignatureItem(signature.id, data))
      .then(data => addItemInSignature(signature, formatSignatureItem(data)))
      .then(data => updateSignatureState(data))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  function changeSignatureItem(itemId: string, data: SignatureItemUpdate) {
    const signature = getSignatureState()

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiChangeSignatureItem(signature.id, itemId, data))
      .then(data =>
        replaceItemInSignature(signature, formatSignatureItem(data))
      )
      .then(data => updateSignatureState(data))
      .finally(() => setLoading(false))
  }

  function removeSignatureItem(itemId: string, onSuccess?: () => void) {
    const signature = getSignatureState()

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiRemoveSignatureItem(signature.id, itemId))
      .then(() => removeItemFromSignature(signature, itemId))
      .then(data => updateSignatureState(data))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  return {
    loading,
    addSignatureItem,
    changeSignatureItem,
    removeSignatureItem
  }
}
