import React from 'react'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'

import { PermissionGroup } from './PermissionGroup'
import { OnCheck } from './AllPermissions'
import { BlankOption } from './styles'

interface OrderPermissionsProps {
  onChange: (data: OnCheck) => void
  isChecked: (resource: string, actions: string[]) => boolean
}

export const OrderPermissions: React.FC<OrderPermissionsProps> = ({
  onChange,
  isChecked
}) => {
  function handleCheck(
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) {
    const value = data.value as string
    const checked = data.checked as boolean
    onChange({ value, checked })
  }

  return (
    <>
      <PermissionGroup label="Pedido:">
        <Checkbox
          onChange={handleCheck}
          checked={
            isChecked('order', ['Get', 'List']) &&
            isChecked('category', ['Get', 'List'])
          }
          value="order:Get,List;category:Get,List"
          label="Ler"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('order', ['Create'])}
          value="order:Create"
          label="Cadastrar"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('order', ['Update'])}
          value="order:Update"
          label="Atualizar"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('order', ['Delete'])}
          value="order:Delete"
          label="Excluir"
        />
      </PermissionGroup>
      <PermissionGroup>
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('order', ['Close'])}
          value="order:Close"
          label="Fechar"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('order', ['Conclude'])}
          value="order:Conclude"
          label="Concluir"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('order', ['Reopen'])}
          value="order:Reopen"
          label="Reabrir"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('order', ['SendDocuments'])}
          value="order:SendDocuments"
          label="Enviar NFS-e"
        />
      </PermissionGroup>
      <PermissionGroup>
        <BlankOption />
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('orderItem', ['Create'])}
          value="orderItem:Create"
          label="Adicionar item"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('orderItem', ['Update'])}
          value="orderItem:Update"
          label="Atualizar item"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('orderItem', ['Delete'])}
          value="orderItem:Delete"
          label="Remover item"
        />
      </PermissionGroup>
      <PermissionGroup>
        <BlankOption />
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('charge', ['Create'])}
          value="charge:Create"
          label="Adicionar cobrança"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('charge', ['Update'])}
          value="charge:Update"
          label="Atualizar cobrança"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('charge', ['Delete'])}
          value="charge:Delete"
          label="Remover cobrança"
        />
      </PermissionGroup>
      <PermissionGroup>
        <BlankOption />
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('cobPix', ['Issue'])}
          value="cobPix:Issue"
          label="Emitir Pix"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('cobPix', ['Cancel'])}
          value="cobPix:Cancel"
          label="Cancelar Pix"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('cobPix', ['Refresh'])}
          value="cobPix:Refresh"
          label="Atualizar Pix"
        />
      </PermissionGroup>
      <PermissionGroup>
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('pix', ['GetProof'])}
          value="pix:GetProof"
          label="Ver comprovante"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('pix', ['Issue'])}
          value="pix:Issue"
          label="Emitir cobrança Pix / Depósito"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('pix', ['Cancel'])}
          value="pix:Cancel"
          label="Cancelar cobrança Pix / Depósito"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('pix', ['AddProof'])}
          value="pix:AddProof"
          label="Adicionar comprovante"
        />
      </PermissionGroup>
      <PermissionGroup>
        <BlankOption />
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('bolepix', ['Issue'])}
          value="bolepix:Issue"
          label="Emitir Bolepix"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('bolepix', ['Cancel'])}
          value="bolepix:Cancel"
          label="Cancelar Bolepix"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('bolepix', ['Refresh'])}
          value="bolepix:Refresh"
          label="Atualizar Bolepix"
        />
      </PermissionGroup>
      <PermissionGroup>
        <BlankOption />
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('nfse', ['Issue'])}
          value="nfse:Issue"
          label="Emitir NFS-e"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('nfse', ['Cancel'])}
          value="nfse:Cancel"
          label="Cancelar NFS-e"
        />
      </PermissionGroup>
    </>
  )
}
