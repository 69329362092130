import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form as FinalForm } from 'react-final-form'
import {
  DropdownOnSearchChangeData,
  Button,
  Segment,
  FormGroup,
  CheckboxProps
} from 'semantic-ui-react'
import { debounce } from 'debounce'

import { getDiff, getFormValidate } from '../../../../../../utils/form'
import {
  Form,
  Select,
  DateInput,
  Currency,
  Masked,
  Input,
  Radio
} from '../../../../../../components/Form'
import { FormActionsContainer } from '../../../../../../styles/FormActionsContainer'
import { SelectOption } from '../../../../../../components/Form/Select'
import { pages } from '../../../../../../config/pages'
import { localeDateToDate } from '../../../../../../utils/date'
import { SignatureRecurrence } from '../../../../../../services/signature'

import {
  categoryToSelectOption,
  personToSelectOption
} from '../../../../utils/selectOptions'
import { SignatureFormatted } from '../../../../utils/formatSignature'
import {
  useSignature,
  useCategory,
  useCustomer,
  useSignatureCustomer
} from '../../hooks'
import { schema } from './schema'

const validateForm = getFormValidate(schema)

const initialValues = {
  recurrence: 'MONTHLY',
  discountPercent: 0
}

const DEBOUNCE_TIME = 500

export const SignatureFields: React.FC = () => {
  const {
    loading,
    signature,
    createSignature,
    updateSignature,
    localChangeRecurrence
  } = useSignature()
  const {
    loading: loadingCategories,
    categories,
    listCategories
  } = useCategory()
  const { loading: loadingCustomers, customers, listCustomers } = useCustomer()
  const { loading: loadingCustomer, customer } = useSignatureCustomer()
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([])
  const [customerOptions, setCustomerOptions] = useState<SelectOption[]>([])
  const editing = !!signature
  const [original, setOriginal] = useState<any>()

  useEffect(() => {
    if (original) return
    if (!signature) return
    setOriginal(signature)
  }, [signature])

  useEffect(() => {
    listCategories()
  }, [])

  useEffect(() => {
    if (!customer) return
    setCustomerOptions([personToSelectOption(customer)])
  }, [customer])

  useEffect(() => {
    setCategoryOptions(categories.map(categoryToSelectOption))
  }, [categories])

  useEffect(() => {
    setCustomerOptions(customers.map(personToSelectOption))
  }, [customers])

  const searchCustomer = (data: DropdownOnSearchChangeData) => {
    const { searchQuery } = data

    return debounce(() => listCustomers(searchQuery), DEBOUNCE_TIME)()
  }

  function handleSubmit(values: SignatureFormatted) {
    const {
      categoryId,
      customerId,
      description,
      recurrence,
      period,
      dueDateFormatted
    } = values
    const dueDate = localeDateToDate(dueDateFormatted).toISOString()

    if (!signature) {
      createSignature({
        categoryId,
        customerId,
        description,
        recurrence,
        period,
        dueDate
      })
    }

    if (signature) {
      const diff = getDiff(original, {
        description,
        recurrence,
        period,
        dueDate
      })
      if (!diff) return
      updateSignature(diff, setOriginal)
    }
  }

  function handleRecurrenceChanging(_: any, data: CheckboxProps) {
    const newRecurrence = data.value as SignatureRecurrence
    localChangeRecurrence(newRecurrence)
  }

  return (
    <FinalForm
      onSubmit={handleSubmit}
      validate={validateForm}
      initialValues={{ ...initialValues, ...signature }}
      validateOnBlur
    >
      {({ handleSubmit, values }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <FormActionsContainer>
            <Button
              type="button"
              content="Voltar"
              as={Link}
              to={pages.signatures.path}
            />
            <Button type="submit" content="Salvar" floated="right" primary />
          </FormActionsContainer>

          <Segment>
            <Select
              name="categoryId"
              label="Categoria"
              options={categoryOptions}
              loading={loadingCategories}
              readOnly={editing}
              search
              required
              uppercase
            />
            <Select
              name="customerId"
              label="Cliente"
              options={customerOptions}
              loading={loadingCustomers || loadingCustomer}
              onSearchChange={(_, data) => searchCustomer(data)}
              readOnly={editing}
              search
              required
              uppercase
            />
            <Input
              name="description"
              label="Descrição"
              maxLength={128}
              required
              uppercase
            />
            <FormGroup inline>
              <label>Recorrência</label>
              <Radio
                name="recurrence"
                label="Mensal"
                value="MONTHLY"
                checked={values.recurrence === 'MONTHLY'}
                onChange={handleRecurrenceChanging}
              />
              <Radio
                name="recurrence"
                label="Trimestral"
                value="QUARTERLY"
                checked={values.recurrence === 'QUARTERLY'}
                onChange={handleRecurrenceChanging}
              />
              <Radio
                name="recurrence"
                label="Semestral"
                value="SEMI_ANNUAL"
                checked={values.recurrence === 'SEMI_ANNUAL'}
                onChange={handleRecurrenceChanging}
              />
              <Radio
                name="recurrence"
                label="Anual"
                value="ANNUAL"
                checked={values.recurrence === 'ANNUAL'}
                onChange={handleRecurrenceChanging}
              />
            </FormGroup>
            <FormGroup>
              <Masked
                name="period"
                label="Período"
                mask="##/####"
                width={4}
                autoComplete="off"
                required
              />
              <DateInput
                name="dueDateFormatted"
                label="Vencimento"
                minDate={new Date()}
                width={12}
                required
              />
            </FormGroup>
            <FormGroup widths={3}>
              <Currency
                name="totalOfItemsFormatted"
                label="Valor total"
                readOnly
              />
              <Currency
                name="totalOfDiscountsFormatted"
                label="Valor dos descontos"
                readOnly
              />
              <Currency name="value" label="Valor da assinatura" readOnly />
            </FormGroup>
          </Segment>
        </Form>
      )}
    </FinalForm>
  )
}
