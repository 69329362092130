import {
  getPreviousMonth,
  getMinDateOfMonth,
  getMaxDateOfMonth,
  dateToLocaleDate,
  getToday
} from '../../utils/date'
import { SelectOption } from '../../components/Form/Select'
import { Category } from '../../services/category'
import { User } from '../../services/users'
import { getFormValidate } from '../../utils/form'

import { commissionReportSchema } from './schema'

export function userToOption(user: User): SelectOption {
  return {
    key: user.id,
    value: user.id,
    text: user.nickname
  }
}

export function categoryToOption(category: Category): SelectOption {
  return {
    key: category.id,
    value: category.id,
    text: category.name
  }
}

export const initialValues = {
  start: dateToLocaleDate(getMinDateOfMonth(getPreviousMonth(getToday()))),
  end: dateToLocaleDate(getMaxDateOfMonth(getPreviousMonth(getToday()))),
  status: ['CONCLUDED']
}

export const validateForm = getFormValidate(commissionReportSchema)

export const DEBOUNCE_TIME = 500

export function removeEmptyStrings(object: any) {
  const cleaned: Record<string, any> = {}

  Object.keys(object).forEach(key => {
    if (object[key] === '') return

    cleaned[key] = object[key]
  })

  return cleaned
}

export const statusOptions = [
  { key: 'CONCLUDED', value: 'CONCLUDED', text: 'Concluído' },
  { key: 'APPROVED', value: 'APPROVED', text: 'Aprovado' },
  { key: 'ISSUED', value: 'ISSUED', text: 'Emitido' },
  { key: 'CLOSED', value: 'CLOSED', text: 'Fechado' },
  { key: 'OPEN', value: 'OPEN', text: 'Aberto' }
]
