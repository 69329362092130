import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Account,
  getAccount as apiGetAccount
} from '../../../../../../services/account'
import { pages } from '../../../../../../config/pages'

import {
  AccountFormatted,
  formatAccount
} from '../../../../utils/formatAccount'

export function useAccount() {
  const [account, setAccount] = useState<AccountFormatted>()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  function updateAccountState(data: Account) {
    const account = formatAccount(data)
    setAccount(account)

    return account
  }

  function getAccount(id: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiGetAccount(id))
      .then(data => {
        if (data) return updateAccountState(data)

        navigate(`${pages.accounts.path}/inclusao`)
        toast.warn('A conta não foi encontrada')
      })
      .finally(() => setLoading(false))
  }

  return {
    account,
    loading,
    updateAccountState,
    setLoading,
    getAccount
  }
}
