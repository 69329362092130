import React, { useContext, useState } from 'react'

import { Show } from '../../../../../../../../../components/Show'
import { Button } from '../../../../../../../../../components/Button'
import { can } from '../../../../../../../../../config/permissions'
import { AddPixProofModal } from '../../../../../../../../../components/PixProof'
import { AccountFormatted } from '../../../../../../../utils/formatAccount'
import { PaginationContext } from '../../../../../../../../../contexts/PaginationContext'
import { dateToLocaleDate } from '../../../../../../../../../utils/date'
import { numberToCurrency } from '../../../../../../../../../utils/number'

interface AddPixProofActionProps {
  account: AccountFormatted
  onAddProof: (account: AccountFormatted) => void
}

export const AddPixProofAction: React.FC<AddPixProofActionProps> = ({
  account,
  onAddProof
}) => {
  const { pagination } = useContext(PaginationContext)
  const [showAddProofModal, setShowAddProofModal] = useState(false)

  function handleButtonClick() {
    setShowAddProofModal(true)
  }

  function handleAddProof() {
    const paymentDate = new Date()
    const update = {
      ...account,
      paymentDate: paymentDate.toISOString(),
      paymentDateFormatted: dateToLocaleDate(paymentDate),
      amountPaid: account.amount,
      amountPaidFormatted: numberToCurrency(account.amount)
    }
    onAddProof(update)
  }

  const isPixReceivable =
    account.paymentType === 'PIX' && pagination.query.type === 'RECEIVABLE'
  const accountIsPaid = account.isPaid

  return (
    <Show condition={isPixReceivable && !accountIsPaid}>
      <Button
        icon="upload"
        size="mini"
        onClick={handleButtonClick}
        can={can.pix.addProof}
        popup="Enviar comprovante"
        invertedPopup
        circular
      />
      <AddPixProofModal
        open={showAddProofModal}
        charge={account}
        handleModal={setShowAddProofModal}
        onAdd={handleAddProof}
      />
    </Show>
  )
}
