import React, { useContext } from 'react'
import { Table } from 'semantic-ui-react'

import { PaginationContext } from '../../../../../../contexts/PaginationContext'

import { AccountsTableContext } from '../../context'
import {
  ReceivablesTableHeader,
  ReceivablesTableRow,
  ReceivablesTableFooter
} from './components'

export const ReceivablesTable: React.FC = () => {
  const { accounts, totalItems, loading, replaceAccount } =
    useContext(AccountsTableContext)
  const { pagination } = useContext(PaginationContext)

  return (
    <Table selectable striped compact sortable>
      <ReceivablesTableHeader />
      <Table.Body>
        {accounts.map(account => (
          <ReceivablesTableRow
            key={account.id}
            account={account}
            onRefresh={replaceAccount}
          />
        ))}
      </Table.Body>
      <ReceivablesTableFooter
        colSpan={pagination.query.status === 'PAID' ? 10 : 8}
        count={accounts.length}
        loading={loading}
        totalItems={totalItems}
      />
    </Table>
  )
}
