import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table } from 'semantic-ui-react'

import { PaginationContext } from '../../../../contexts/PaginationContext'
import { DefaultLoader } from '../../../../components/DefaultLoader'
import { pages } from '../../../../config/pages'
import { Product } from '../../../../services/product'

import { useProductsList, useProduct } from './hooks'
import {
  ProductsTableActions,
  ProductsTableHeader,
  ProductsTableFooter,
  ProductsTableRow,
  ConfirmDeleteModal
} from './components'

export const ProductsTable: React.FC = () => {
  const {
    pagination,
    goToFirstPage,
    goToPreviousPage,
    goToNextPage,
    goToLastPage,
    changeOrderBy,
    changeLimit
  } = useContext(PaginationContext)
  const { products, totalItems, loading, fetchProducts } = useProductsList()
  const { loading: deleteLoading, deleteProduct } = useProduct()
  const navigate = useNavigate()
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<Product>()

  useEffect(() => {
    fetchProducts(pagination)
  }, [pagination])

  function redirectToCreateForm() {
    navigate(`${pages.products.path}/inclusao`)
  }

  function redirectToUpdateForm(product: Product) {
    navigate(`${pages.products.path}/edicao?id=${product.id}`)
  }

  function openConfirmDeleteModal(product: Product) {
    setSelectedProduct(product)
    setShowConfirmDeleteModal(true)
  }

  function handleConfirmDelete(product: Product) {
    deleteProduct(product.id, () => {
      setShowConfirmDeleteModal(false)
      fetchProducts(pagination)
    })
  }

  return (
    <>
      <DefaultLoader active={loading} />

      <ProductsTableActions
        pagination={pagination}
        onCreateButtonClick={redirectToCreateForm}
      />

      <Table selectable striped compact sortable>
        <ProductsTableHeader
          pagination={pagination}
          changeOrderBy={changeOrderBy}
        />
        <Table.Body>
          {products.map(product => (
            <ProductsTableRow
              key={product.id}
              product={product}
              onUpdateButtonClick={redirectToUpdateForm}
              onDeleteButtonClick={openConfirmDeleteModal}
            />
          ))}
        </Table.Body>
        <ProductsTableFooter
          colSpan={2}
          count={products.length}
          pagination={pagination}
          totalItems={totalItems}
          loading={loading}
          goToFirstPage={goToFirstPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
          changeLimit={changeLimit}
        />
      </Table>

      <ConfirmDeleteModal
        open={showConfirmDeleteModal}
        product={selectedProduct}
        loading={deleteLoading}
        handleModal={setShowConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
        onDecline={() => setShowConfirmDeleteModal(false)}
      />
    </>
  )
}
