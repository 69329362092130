import { useContext } from 'react'

import {
  createOrderItem,
  updateOrderItem,
  deleteOrderItem,
  OrderItem,
  OrderItemCreate
} from '../../../../../services/orderItem'

import {
  addItemInOrder,
  removeItemFromOrder
} from '../../../utils/orderItemCalculate'
import { formatOrderItem } from '../../../utils/formatOrderItem'
import { OrderFormContext } from '../context'

export function useOrderItem() {
  const { setLoading, getOrderState, updateOrderState } =
    useContext(OrderFormContext)

  function addOrderItem(data: OrderItemCreate, onSuccess?: () => void) {
    const order = getOrderState()

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => createOrderItem(order.id, data))
      .then(data => addItemInOrder(order, formatOrderItem(data)))
      .then(data => updateOrderState(data))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  function modifyOrderItem(itemId: string, data: Partial<OrderItem>) {
    const order = getOrderState()
    const oldItem = order.items.find(item => item.id === itemId)

    if (!oldItem) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => updateOrderItem(itemId, order.id, data))
      .then(data => {
        const newOrder = removeItemFromOrder(order, itemId)
        return { order: newOrder, item: formatOrderItem(data) }
      })
      .then(data => addItemInOrder(data.order, data.item))
      .then(data => updateOrderState(data))
      .finally(() => setLoading(false))
  }

  function removeOrderItem(itemId: string, onSuccess?: () => void) {
    const order = getOrderState()

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => deleteOrderItem(itemId, order.id))
      .then(() => removeItemFromOrder(order, itemId))
      .then(data => updateOrderState(data))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  return {
    addOrderItem,
    modifyOrderItem,
    removeOrderItem
  }
}
