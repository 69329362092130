import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { RegistrationContainer } from '../../components/RegistrationContainer'
import { PaginationProvider } from '../../contexts/PaginationContext'
import { useBreadcrumb } from '../../hooks/useBreadcrumb'

import { ProductsTable, ProductForm } from './components'

export const Products: React.FC = () => {
  const breadcrumb = useBreadcrumb({
    produtos: 'Produtos',
    inclusao: 'Inclusão',
    edicao: 'Edição'
  })

  return (
    <RegistrationContainer breadcrumbItems={breadcrumb}>
      <PaginationProvider>
        <Routes>
          <Route path="/" element={<ProductsTable />} />
          <Route path="/inclusao" element={<ProductForm />} />
          <Route path="/edicao" element={<ProductForm />} />
        </Routes>
      </PaginationProvider>
    </RegistrationContainer>
  )
}
