import { AxiosProgressEvent } from 'axios'

import api from './api'

export type FileType = 'T' | 'S' | 'C'

export interface File {
  id: string
  name: string
  download: string
  key: string
  modificationDate: Date
  type: FileType
  directoryId: string
  systemId: string
}

export type OnUploadProgress = (progressEvent: AxiosProgressEvent) => void

export async function uploadFile(
  systemId: string,
  directoryId: string,
  data: FormData,
  onUploadProgress?: OnUploadProgress
): Promise<File> {
  const response = await api.post(
    `/systems/${systemId}/directories/${directoryId}/files`,
    data,
    { onUploadProgress }
  )
  const file = response.data

  return file
}

export async function changeFileType(
  id: string,
  systemId: string,
  directoryId: string,
  type: FileType
): Promise<File> {
  const response = await api.patch(
    `/systems/${systemId}/directories/${directoryId}/files/${id}/type`,
    { type }
  )
  const file = response.data

  return file
}

export async function deleteFiles(
  id: string | string[],
  systemId: string,
  directoryId: string
): Promise<boolean> {
  const response = await api.delete(
    `/systems/${systemId}/directories/${directoryId}/files`,
    { params: { id } }
  )
  const result = response.data

  return result.status
}
