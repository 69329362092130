import React from 'react'

import { ServiceFormProvider } from './context'
import { ServiceMain } from './components'

export const ServiceForm: React.FC = () => {
  return (
    <ServiceFormProvider>
      <ServiceMain />
    </ServiceFormProvider>
  )
}
