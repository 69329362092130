import React from 'react'
import { Message, Button } from 'semantic-ui-react'

import { Wrapper } from './styles'
import { content } from './content'

interface CookieBannerProps {
  active: boolean
  onConfirm?: () => void
}

export const CookieBanner: React.FC<CookieBannerProps> = ({
  active,
  onConfirm
}) => {
  if (!active) return null

  return (
    <Wrapper>
      <Message>
        <Message.Header content="Aviso" />
        <Message.Content>{content.bannerText}</Message.Content>
        {onConfirm && (
          <Message.Content>
            <Button
              content={content.buttonText}
              floated="right"
              onClick={onConfirm}
              basic
            />
          </Message.Content>
        )}
      </Message>
    </Wrapper>
  )
}
