import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Group } from '../../../../../services/group'
import { DefaultLoader } from '../../../../../components/DefaultLoader'
import { pages } from '../../../../../config/pages'

import { useFetchGroup } from './hooks'

interface GroupFormContextContent {
  group?: Group
  loading: boolean
  setLoading: (value: boolean) => void
  getGroupState: () => Group
  updateGroupState: (group: Group) => Group
}

export const GroupFormContext = React.createContext<GroupFormContextContent>(
  {} as GroupFormContextContent
)

interface GroupFormProviderProps {
  children: React.ReactNode
}

export const GroupFormProvider: React.FC<GroupFormProviderProps> = ({
  children
}) => {
  const [group, setGroup] = useState<Group>()
  const [loading, setLoading] = useState(false)
  const [query] = useSearchParams()
  const fetchGroup = useFetchGroup()
  const navigate = useNavigate()

  function getGroupState(): Group {
    if (!group) throw new Error('Nenhum grupo foi selecionado')

    return group
  }

  function updateGroupState(data: Group): Group {
    setGroup(data)

    return data
  }

  function _fetchGroup(groupId: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => fetchGroup(groupId))
      .then(data => {
        if (data) return updateGroupState(data)

        navigate(`${pages.groups.path}/inclusao`)
        toast.warn('O grupo não foi encontrado, mas você pode criar um novo.')
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const groupId = query.get('id')

    if (!groupId) return

    _fetchGroup(groupId)
  }, [])

  return (
    <GroupFormContext.Provider
      value={{
        group,
        loading,
        setLoading,
        getGroupState,
        updateGroupState
      }}
    >
      <DefaultLoader active={loading} />
      {children}
    </GroupFormContext.Provider>
  )
}
