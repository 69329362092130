import React, { useContext, useState } from 'react'
import { Table } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

import { ItemControllers } from '../../../../../../../../components/Table'
import { can } from '../../../../../../../../config/permissions'
import { pages } from '../../../../../../../../config/pages'
import { Show } from '../../../../../../../../components/Show'
import { PaginationContext } from '../../../../../../../../contexts/PaginationContext'

import { AccountFormatted } from '../../../../../../utils/formatAccount'
import { AmountCell } from '../../../../styles'
import { ConfirmDeleteModal, WriteOffAction } from './components'

interface PayablesTableRowProps {
  account: AccountFormatted
  onRefresh: (account: AccountFormatted) => void
  onDelete: () => void
}

export const PayablesTableRow: React.FC<PayablesTableRowProps> = ({
  account,
  onRefresh,
  onDelete
}) => {
  const navigate = useNavigate()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { pagination } = useContext(PaginationContext)

  function handleUpdateClick() {
    navigate(`${pages.accounts.path}/edicao?id=${account.id}`)
  }

  function handleDeleteClick() {
    setShowDeleteModal(true)
  }

  return (
    <Table.Row negative={account.isOverdue} positive={account.isPaid}>
      <Table.Cell content={account.person.name} />
      <Table.Cell content={account.group?.name} />
      <Table.Cell content={account.description} />
      <Table.Cell content={account.paymentTypeFormatted} />
      <Table.Cell content={account.dueDateFormatted} />
      <AmountCell content={account.amountFormatted} />
      <Show condition={pagination.query.status === 'PAID'}>
        <Table.Cell content={account.amountPaidFormatted} />
        <Table.Cell content={account.paymentDateFormatted} />
      </Show>
      <Table.Cell textAlign="right">
        <WriteOffAction account={account} onWriteOff={onRefresh} />
        <ItemControllers
          onUpdateClick={handleUpdateClick}
          onDeleteClick={handleDeleteClick}
          permissionsToUpdate={can.account.update}
          permissionsToDelete={can.account.delete}
        />
        <ConfirmDeleteModal
          open={showDeleteModal}
          account={account}
          handleModal={setShowDeleteModal}
          onDelete={onDelete}
        />
      </Table.Cell>
    </Table.Row>
  )
}
