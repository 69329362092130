import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { RegistrationContainer } from '../../components/RegistrationContainer'
import { PaginationProvider } from '../../contexts/PaginationContext'
import { useBreadcrumb } from '../../hooks/useBreadcrumb'

import { UsersTable, UserForm, AccountForm } from './components'

export const Users: React.FC = () => {
  const breadcrumb = useBreadcrumb({
    usuarios: 'Usuários',
    inclusao: 'Inclusão',
    edicao: 'Edição',
    conta: 'Conta'
  })

  return (
    <RegistrationContainer breadcrumbItems={breadcrumb}>
      <PaginationProvider>
        <Routes>
          <Route path="/" element={<UsersTable />} />
          <Route path="/inclusao" element={<UserForm />} />
          <Route path="/edicao" element={<UserForm />} />
          <Route path="/conta" element={<AccountForm />} />
        </Routes>
      </PaginationProvider>
    </RegistrationContainer>
  )
}
