import api from './api'
import { Person } from './person'
import { SignatureItem } from './signatureItem'

export type SignatureRecurrence =
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'SEMI_ANNUAL'
  | 'ANNUAL'

export interface Signature {
  id: string
  categoryId: string
  customerId: string
  description: string
  recurrence: SignatureRecurrence
  period: string
  dueDate: string
  items: SignatureItem[]
  value: number
  lastInvoice: string
  createdAt: string
  updatedAt: string
  customer: Person
}

export interface SignatureCreate extends Signature {
  categoryId: string
  customerId: string
  description: string
  recurrence: SignatureRecurrence
  period: string
  dueDate: string
}

export interface SignaturePaginate {
  page?: number
  limit?: number
}

export interface SignatureListResult {
  signatures: Signature[]
  total: number
}

export async function getSignature(id: string): Promise<Signature | undefined> {
  const response = await api.get(`/signatures/${id}`)
  const signature = response.status === 200 ? response.data : undefined

  return signature
}

export async function listSignatures(
  paginate: SignaturePaginate
): Promise<SignatureListResult> {
  const { page, limit } = paginate
  const response = await api.get(`/signatures`, { params: { page, limit } })
  const { items: signatures, total } = response.data

  return {
    signatures,
    total
  }
}

export async function createSignature(
  data: Partial<SignatureCreate>
): Promise<Signature> {
  const response = await api.post(`/signatures`, data)
  const signature = response.data

  return signature
}

export async function updateSignature(
  id: string,
  data: Partial<Signature>
): Promise<Signature> {
  const response = await api.put(`/signatures/${id}`, data)
  const signature = response.data

  return signature
}

export async function deleteSignature(id: string): Promise<boolean> {
  const response = await api.delete(`/signatures/${id}`)
  const result = response.data

  return result.status
}

export async function invoiceSignature(id: string): Promise<void> {
  await api.post(`/signatures/${id}/invoice`)
}
