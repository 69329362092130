import { UserAccount } from '../../../../../services/userAccount'

export interface UserAccountFormatted extends UserAccount {
  phoneFormatted: string
}

export function formatUserAccount(account: UserAccount): UserAccountFormatted {
  return {
    ...account,
    phoneFormatted: account.phone.replace('+55', '')
  }
}
