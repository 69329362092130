import { useState } from 'react'
import { toast } from 'react-toastify'

import { deletePerson as apiDeletePerson } from '../../../../../services/person'

export function usePerson() {
  const [loading, setLoading] = useState(false)

  function deletePerson(id: string, onSuccess?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiDeletePerson(id))
      .then(() => toast.info('O cadastro foi excluído'))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  return {
    loading,
    deletePerson
  }
}
