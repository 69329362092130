import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table } from 'semantic-ui-react'

import { PaginationContext } from '../../../../contexts/PaginationContext'
import { DefaultLoader } from '../../../../components/DefaultLoader'
import { pages } from '../../../../config/pages'

import { PersonFormatted } from '../../utils/formatPerson'
import { usePeopleList, usePerson } from './hooks'
import {
  PeopleTableActions,
  PeopleTableHeader,
  PeopleTableFooter,
  PeopleTableRow,
  ConfirmDeleteModal
} from './components'

export const PeopleTable: React.FC = () => {
  const {
    pagination,
    goToFirstPage,
    goToPreviousPage,
    goToNextPage,
    goToLastPage,
    changeOrderBy,
    changeLimit
  } = useContext(PaginationContext)
  const { people, totalItems, loading, fetchPeople } = usePeopleList()
  const { loading: deleteLoading, deletePerson } = usePerson()
  const navigate = useNavigate()
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [selectedPerson, setSelectedOrder] = useState<PersonFormatted>()

  useEffect(() => {
    fetchPeople(pagination)
  }, [pagination])

  function redirectToCreateForm() {
    navigate(`${pages.people.path}/inclusao`)
  }

  function redirectToUpdateForm(order: PersonFormatted) {
    navigate(`${pages.people.path}/edicao?id=${order.id}`)
  }

  function openConfirmDeleteModal(order: PersonFormatted) {
    setSelectedOrder(order)
    setShowConfirmDeleteModal(true)
  }

  function handleConfirmDelete(order: PersonFormatted) {
    deletePerson(order.id, () => {
      setShowConfirmDeleteModal(false)
      fetchPeople(pagination)
    })
  }

  return (
    <>
      <DefaultLoader active={loading} />

      <PeopleTableActions
        pagination={pagination}
        onCreateButtonClick={redirectToCreateForm}
      />

      <Table selectable striped compact sortable>
        <PeopleTableHeader
          pagination={pagination}
          changeOrderBy={changeOrderBy}
        />
        <Table.Body>
          {people.map(person => (
            <PeopleTableRow
              key={person.id}
              person={person}
              onUpdateButtonClick={redirectToUpdateForm}
              onDeleteButtonClick={openConfirmDeleteModal}
            />
          ))}
        </Table.Body>
        <PeopleTableFooter
          colSpan={10}
          count={people.length}
          pagination={pagination}
          totalItems={totalItems}
          loading={loading}
          goToFirstPage={goToFirstPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
          changeLimit={changeLimit}
        />
      </Table>

      <ConfirmDeleteModal
        open={showConfirmDeleteModal}
        person={selectedPerson}
        loading={deleteLoading}
        handleModal={setShowConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
        onDecline={() => setShowConfirmDeleteModal(false)}
      />
    </>
  )
}
