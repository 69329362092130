import React from 'react'
import { Table } from 'semantic-ui-react'

import { OrderItemFormatted } from '../../../../../../utils/formatOrderItem'

interface NotEditableRowProps {
  item: OrderItemFormatted
}

export const NotEditableRow: React.FC<NotEditableRowProps> = ({ item }) => {
  return (
    <Table.Row>
      <Table.Cell width={8} content={item.description} />
      <Table.Cell content={item.quantity} />
      <Table.Cell content={item.priceFormatted} />
      <Table.Cell content={item.discountFormatted} />
      <Table.Cell content={item.additionFormatted} />
      <Table.Cell content={item.totalItemFormatted} />
      <Table.Cell />
    </Table.Row>
  )
}
