import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Account } from '../../../../../services/account'
import { Person } from '../../../../../services/person'
import { Group } from '../../../../../services/group'
import { AccountFormatted } from '../../../utils/formatAccount'
import { DefaultLoader } from '../../../../../components/DefaultLoader'

import { useAccount, usePerson, useGroup } from './hooks'

interface AccountFormContextContent {
  account?: AccountFormatted
  person?: Person
  group?: Group
  loading: boolean
  getAccountState: () => AccountFormatted
  updateAccountState: (account: Account) => AccountFormatted
  setLoading: (value: boolean) => void
}

export const AccountFormContext =
  React.createContext<AccountFormContextContent>(
    {} as AccountFormContextContent
  )

interface AccountFormProviderProps {
  children: React.ReactNode
}

export const AccountFormProvider: React.FC<AccountFormProviderProps> = ({
  children
}) => {
  const [query] = useSearchParams()
  const {
    account,
    loading: loadingAccount,
    updateAccountState,
    setLoading,
    getAccount
  } = useAccount()
  const { person, loading: loadingPerson, getPerson } = usePerson()
  const { group, loading: loadingGroup, getGroup } = useGroup()

  useEffect(() => {
    const accountId = query.get('id')

    if (!accountId) return

    getAccount(accountId)
  }, [])

  useEffect(() => {
    if (!account) return

    getPerson(account.personId)
  }, [account])

  useEffect(() => {
    if (!account?.groupId) return

    getGroup(account.groupId)
  }, [account])

  function getAccountState() {
    if (!account) throw new Error('Nenhuma conta foi selecionada')

    return account
  }

  const loading = loadingAccount || loadingPerson || loadingGroup

  return (
    <AccountFormContext.Provider
      value={{
        account,
        person,
        group,
        loading,
        getAccountState,
        updateAccountState,
        setLoading
      }}
    >
      <DefaultLoader active={loading} />
      {children}
    </AccountFormContext.Provider>
  )
}
