import Joi from 'joi'

const messages = {
  name: {
    max: 'Permitido no máximo 100 caracteres',
    required: 'Nome é obrigatório'
  }
}

export const groupSchema = Joi.object({
  id: Joi.string().uuid({ version: ['uuidv4'] }),
  name: Joi.string().max(100).required().messages({
    'string.max': messages.name.max,
    'any.required': messages.name.required
  })
})
