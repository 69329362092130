import api from './api'

export interface Category {
  id: string
  name: string
  description: string
}

export interface CategoryPaginate {
  page?: number
  limit?: number
  search?: string
}

export interface CategoryListResult {
  categories: Category[]
  total: number
}

export async function getCategory(id: string): Promise<Category> {
  const response = await api.get(`/categories/${id}`)
  const category = response.status === 200 ? response.data : undefined

  return category
}

export async function listCategories(
  paginate?: CategoryPaginate
): Promise<CategoryListResult> {
  const { page, limit, search } = paginate || {}
  const response = await api.get(`/categories`, {
    params: { page, limit, search }
  })
  const { items: categories, total } = response.data

  return {
    categories,
    total
  }
}

export async function createCategory(
  data: Partial<Category>
): Promise<Category> {
  const response = await api.post(`/categories`, data)
  const category = response.data

  return category
}

export async function updateCategory(
  id: string,
  data: Partial<Category>
): Promise<Category> {
  const response = await api.put(`/categories/${id}`, data)
  const category = response.data

  return category
}

export async function deleteCategory(id: string): Promise<boolean> {
  const response = await api.delete(`/categories/${id}`)
  const result = response.data

  return result.status
}
