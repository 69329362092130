import React, { useContext } from 'react'

import { CobPix } from '../../../../../../../../../services/cobPix'
import { Show } from '../../../../../../../../../components/Show'
import { Button } from '../../../../../../../../../components/Button'
import { can } from '../../../../../../../../../config/permissions'
import { AccountFormatted } from '../../../../../../../utils/formatAccount'
import { PaginationContext } from '../../../../../../../../../contexts/PaginationContext'
import { dateToLocaleDate } from '../../../../../../../../../utils/date'
import { numberToCurrency } from '../../../../../../../../../utils/number'

import { useCobPix } from '../../../../../hooks'

interface CobPixRefreshActionProps {
  account: AccountFormatted
  onRefresh: (account: AccountFormatted) => void
}

export const CobPixRefreshAction: React.FC<CobPixRefreshActionProps> = ({
  account,
  onRefresh
}) => {
  const { pagination } = useContext(PaginationContext)
  const { loading, refreshCobPix } = useCobPix()

  function updateAccountList(cobPix: CobPix) {
    const pix = cobPix.pix[cobPix.pix.length - 1]

    if (!pix) throw new Error('Pix não foi pago')

    const { paymentDate, paidValue } = pix
    const update = {
      ...account,
      paymentDate,
      amountPaid: paidValue,
      paymentDateFormatted: dateToLocaleDate(paymentDate),
      amountPaidFormatted: numberToCurrency(paidValue),
      cobPix
    }
    onRefresh(update)
  }

  function handleButtonClick() {
    const { orderId } = account

    if (!orderId) return

    refreshCobPix(orderId, account.id, cobPix => {
      updateAccountList(cobPix)
    })
  }

  const isCobPixReceivable =
    account.paymentType === 'COB_PIX' && pagination.query.type === 'RECEIVABLE'
  const accountIsPaid = account.isPaid

  return (
    <Show condition={isCobPixReceivable && !accountIsPaid}>
      <Button
        icon="refresh"
        size="mini"
        onClick={handleButtonClick}
        loading={loading}
        can={can.cobPix.refresh}
        popup="Atualizar cobrança Pix"
        invertedPopup
        circular
      />
    </Show>
  )
}
