import React, { useContext, useEffect, useState } from 'react'
import { Header, Segment, Button } from 'semantic-ui-react'

import { FormActionsContainer } from '../../../../../../styles/FormActionsContainer'
import { Show } from '../../../../../../components/Show'
import { AuthContext } from '../../../../../../contexts/AuthContext'

import { useUser, usePermissions } from '../../hooks'
import { AllPermissions } from './AllPermissions'

export const Permissions: React.FC = () => {
  const { user, updateUser } = useUser()
  const { user: systemUser } = useContext(AuthContext)
  const { scheme, getPermissionsScheme } = usePermissions()
  const [permissions, setPermissions] = useState<string[]>([])

  useEffect(() => {
    getPermissionsScheme()
  }, [])

  useEffect(() => {
    if (!user) return

    setPermissions(user.permissions)
  }, [user])

  function handleCheckAll() {
    setPermissions(['*:*'])
  }

  function handleUncheckAll() {
    setPermissions([])
  }

  function handleSubmit() {
    const notHasChange =
      JSON.stringify(user?.permissions) === JSON.stringify(permissions)

    if (notHasChange) return

    updateUser({ permissions })
  }

  const userExist = !!user
  const systemUserIsAdmin = !!userExist && systemUser?.role === 'ADMIN'

  return (
    <Show condition={systemUserIsAdmin}>
      <Segment>
        <FormActionsContainer>
          <Header as="h3" content="Permissões" floated="left" />
          <Button
            type="button"
            onClick={handleSubmit}
            content="Salvar"
            floated="right"
            primary
          />
          <Button
            type="button"
            onClick={handleUncheckAll}
            content="Desmarcar todos"
            floated="right"
          />
          <Button
            type="button"
            onClick={handleCheckAll}
            content="Marcar todos"
            floated="right"
          />
        </FormActionsContainer>
        <AllPermissions
          permissions={permissions}
          scheme={scheme}
          setPermissions={setPermissions}
        />
      </Segment>
    </Show>
  )
}
