import { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from '../contexts/AuthContext'
import { pages } from '../config/pages'

interface Credentials {
  email: string
  password: string
}

export function useLogin() {
  const { isAuth, signIn } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuth) return

    navigate(pages.home.path)
  }, [])

  async function handleSubmit(values: Credentials) {
    const { email, password } = values

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => signIn({ email, password }))
      .finally(() => setLoading(false))
  }

  return {
    loading,
    handleSubmit
  }
}
