import { useState } from 'react'

import {
  getGroup as apiGetGroup,
  Group
} from '../../../../../../services/group'

export function useGroup() {
  const [group, setGroup] = useState<Group>()
  const [loading, setLoading] = useState(false)

  function getGroup(id: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiGetGroup(id))
      .then(data => setGroup(data))
      .finally(() => setLoading(false))
  }

  return {
    group,
    loading,
    getGroup
  }
}
