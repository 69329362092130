import React from 'react'

import { SignatureFormProvider } from './context'
import { SignatureFields, SignatureItems } from './components'

export const SignatureForm: React.FC = () => {
  return (
    <SignatureFormProvider>
      <SignatureFields />
      <SignatureItems />
    </SignatureFormProvider>
  )
}
