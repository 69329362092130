import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb as SemanticBreadcrumb, Icon } from 'semantic-ui-react'

import { pages } from '../../config/pages'

export interface BreadcrumbItem {
  name: string
  to?: string
}

interface BreadcrumbProps {
  breadcrumbItems?: BreadcrumbItem[]
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumbItems }) => {
  return (
    <SemanticBreadcrumb size="large">
      <SemanticBreadcrumb.Section as={Link} to={pages.home.path}>
        <Icon name="home" />
        {pages.home.title}
      </SemanticBreadcrumb.Section>
      {breadcrumbItems &&
        breadcrumbItems.map(item => (
          <Fragment key={item.name}>
            <SemanticBreadcrumb.Divider icon="right angle" />
            <SemanticBreadcrumb.Section
              as={item.to ? Link : 'span'}
              to={item.to}
              content={item.name}
            />
          </Fragment>
        ))}
    </SemanticBreadcrumb>
  )
}
