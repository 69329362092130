import { useContext } from 'react'
import { toast } from 'react-toastify'

import {
  UserStatus,
  createUser as apiCreateUser,
  updateUser as apiUpdateUser,
  updateUserStatus as apiUpdateUserStatus,
  resetUserPassword as apiResetUserPassword,
  User
} from '../../../../../services/users'
import {
  linkAPartner as apiLinkAPartner,
  unlinkAPartner as apiUnlinkAPartner
} from '../../../../../services/association'

import { UserFormContext } from '../context'

export function useUser() {
  const {
    user,
    priceList,
    group,
    loading,
    setLoading,
    getUserState,
    updateUserState
  } = useContext(UserFormContext)

  function createUser(data: Partial<User>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiCreateUser(data))
      .then(data => updateUserState(data))
      .then(() => toast.info('Usuário cadastrado com sucesso'))
      .finally(() => setLoading(false))
  }

  function updateUser(data: Partial<User>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getUserState())
      .then(user => apiUpdateUser(user.id, data))
      .then(data => updateUserState(data))
      .then(() => toast.info('O usuário foi alterado'))
      .finally(() => setLoading(false))
  }

  function updateUserStatus(status: UserStatus, onFinally?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getUserState())
      .then(user => apiUpdateUserStatus(user.id, status))
      .then(data => updateUserState(data))
      .then(() => toast.info('O usuário foi alterado'))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function resetUserPassword(onFinally?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getUserState())
      .then(user => apiResetUserPassword(user.id))
      .then(() =>
        toast.info('Um e-mail com instruções foi enviado para o usuário')
      )
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function linkAPartner(partner: User, onFinally?: () => void) {
    if (user?.role !== 'EMPLOYEE') return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiLinkAPartner(user.id, partner.id))
      .then(() =>
        updateUserState({ ...user, partners: [...user.partners, partner] })
      )
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function unlinkAPartner(partnerId: string, onFinally?: () => void) {
    if (user?.role !== 'EMPLOYEE') return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiUnlinkAPartner(user.id, partnerId))
      .then(() =>
        updateUserState({
          ...user,
          partners: user.partners.filter(partner => partner.id !== partnerId)
        })
      )
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  return {
    user,
    priceList,
    group,
    loading,
    createUser,
    updateUser,
    updateUserStatus,
    resetUserPassword,
    linkAPartner,
    unlinkAPartner
  }
}
