import React, { useState, useEffect } from 'react'
import { FormField, SemanticWIDTHS } from 'semantic-ui-react'
import { Field, useForm, useField } from 'react-final-form'
import { PatternFormat } from 'react-number-format'

import { useFieldError } from '../../../hooks/useFieldError'
import { getMaskByLength, MOBILE_MASK } from '../../../utils/phone'

interface PhoneProps {
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
  width?: SemanticWIDTHS
  fluid?: boolean
  readOnly?: boolean
}

export const Phone: React.FC<PhoneProps> = ({ name, ...rest }) => {
  const form = useForm()
  const field = useField(name)
  const error = useFieldError(field)
  const [mask, setMask] = useState(MOBILE_MASK)

  useEffect(() => {
    const { value } = field.input

    if (!value) return

    const currentMask = getMaskByLength(value)
    setMask(currentMask)
  }, [])

  function handleBlur() {
    const { value } = field.input
    const currentMask = getMaskByLength(value)
    setMask(currentMask)
    form.blur(name)
  }

  function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
    setMask(MOBILE_MASK)
    const TEN_MILLISECONDS = 10
    setTimeout(() => event.target.select(), TEN_MILLISECONDS)
  }

  return (
    <Field name={name}>
      {({ input }) => (
        <FormField
          {...rest}
          {...input}
          control={PatternFormat}
          format={mask}
          onBlur={handleBlur}
          onFocus={handleFocus}
          error={error}
        />
      )}
    </Field>
  )
}
