import React, { useState, useEffect, useRef } from 'react'
import {
  Modal,
  Table,
  Button,
  InputOnChangeData,
  Message
} from 'semantic-ui-react'

import { Input } from '../../../../../../../../../components/Form/pure'
import { PriceListItem } from '../../../../../../../../../services/priceListItem'

import {
  useSignatureCustomer,
  usePriceListItem,
  useSignatureItem
} from '../../../../../hooks'

export const AddItemModalContent: React.FC = () => {
  const { customer } = useSignatureCustomer()
  const { items, loading, listPriceListItems } = usePriceListItem()
  const { addSignatureItem } = useSignatureItem()
  const [search, setSearch] = useState('')
  const [addedItemIds, setAddedItemIds] = useState<string[]>([])
  const descriptionInputRef = useRef(null)

  useEffect(() => {
    const input = descriptionInputRef.current as any
    input?.focus()
  }, [])

  function handleSearchInputChange(_: any, data: InputOnChangeData) {
    setSearch(data.value)
  }

  function handleSearchButtonClick() {
    if (!customer?.partner?.priceListId) return
    listPriceListItems(customer.partner.priceListId, {
      search,
      limit: 10,
      type: 'service'
    })
  }

  function handleSearchInputKeyPress(evt: React.KeyboardEvent) {
    if (evt.key === 'Enter') {
      handleSearchButtonClick()
    }
  }

  function handleSelectItemButtonClick(priceListItem: PriceListItem) {
    const data = {
      priceListItemId: priceListItem.id,
      quantity: 1
    }
    addSignatureItem(data, () => {
      setAddedItemIds([...addedItemIds, priceListItem.id])
    })
  }

  if (!customer?.partner) {
    return (
      <Message
        error
        header="Houve um erro"
        content="Não foi possível obter o parceiro do cliente"
      />
    )
  }

  if (!customer.partner.priceListId) {
    return (
      <Message
        warning
        header="Ação necessária"
        content="O parceiro do cliente não possui uma tabela de preços vinculada a ele. Contate o administrador."
      />
    )
  }

  return (
    <Modal.Content>
      <Input
        name="description"
        placeholder="Descrição"
        onChange={handleSearchInputChange}
        onKeyPress={handleSearchInputKeyPress}
        disabled={loading}
        action={
          <Button
            icon="search"
            loading={loading}
            onClick={handleSearchButtonClick}
          />
        }
        reference={descriptionInputRef}
        uppercase
        fluid
      />
      <Table selectable striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="Descrição" colSpan={2} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map(item => (
            <Table.Row key={item.id}>
              <Table.Cell
                content={item.service?.description || item.product?.description}
              />
              <Table.Cell textAlign="right">
                <Button
                  basic
                  compact
                  circular
                  floated="right"
                  icon="add"
                  onClick={() => handleSelectItemButtonClick(item)}
                  disabled={addedItemIds.includes(item.id)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Modal.Content>
  )
}
