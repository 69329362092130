import React, { useMemo } from 'react'
import { Message, Segment } from 'semantic-ui-react'

import { Show } from '../../../../../../components/Show'

import {
  useOrder,
  useOrderChargeBoleto,
  useOrderChargeCobPix,
  useOrderChargePix
} from '../../hooks'
import { OrderChargesHeader, OrderChargesTable } from './components'

export const OrderCharges: React.FC = () => {
  const { order } = useOrder()
  const { errors: boletoErrors } = useOrderChargeBoleto()
  const { errors: pixErrors } = useOrderChargePix()
  const { errors: cobPixErrors } = useOrderChargeCobPix()

  const errors = useMemo(() => {
    return [...boletoErrors, ...pixErrors, ...cobPixErrors]
  }, [boletoErrors, pixErrors, cobPixErrors])

  const orderExist = !!order
  const orderTotalIsZero = order?.totalOrder === 0
  const hasErrors = errors.length > 0

  return (
    <Show condition={orderExist && !orderTotalIsZero}>
      <Segment>
        <OrderChargesHeader />
        <Show condition={hasErrors}>
          <Message header="Ocorreu algo inesperado" list={errors} error />
        </Show>
        <OrderChargesTable />
      </Segment>
    </Show>
  )
}
