import { User, getUser } from '../../../../../../services/users'

export function useFetchPartner() {
  return async function fetchPartner(id: string): Promise<User | undefined> {
    try {
      const partner = await getUser(id)
      return partner
    } catch (err: any) {
      const status = err.response?.status

      if (status === 404) return

      throw err
    }
  }
}
