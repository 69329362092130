export function destructurePermission(permission: string): [string, string] {
  const [resource, action] = permission.split(':')

  return [resource, action]
}

export function existsPermission(list: string[], permission: string): boolean {
  const exists = list.includes(permission)

  return exists
}

export function addPermissions(
  list: string[],
  permissions: string[]
): string[] {
  const toAdd: string[] = []

  if (list.includes('*:*')) return list

  permissions.forEach(permission => {
    const [resource] = destructurePermission(permission)
    const wildcard = `${resource}:*`
    const hasWildcard = existsPermission(list, wildcard)

    if (!hasWildcard) toAdd.push(permission)
  })

  return list.concat(toAdd)
}

export function removePermissions(
  list: string[],
  permissions: string[],
  scheme?: Record<string, string[]>
): string[] {
  const toAdd: string[] = []
  const toRemove: string[] = []
  const fullWildcard = '*:*'
  const hasFullWildcard = existsPermission(list, fullWildcard)

  if (hasFullWildcard) {
    const restOfSchemePermissions = Object.keys(scheme || {}).map(resource => {
      return scheme?.[resource].map(action => `${resource}:${action}`) || []
    })
    const restOfPermissions = restOfSchemePermissions
      .flat()
      .filter(permission => !permissions.includes(permission))
      .filter(permission => destructurePermission(permission)[1] !== '*')

    toAdd.push(...restOfPermissions)
    toRemove.push(fullWildcard)
  }

  if (!hasFullWildcard) {
    permissions.forEach(permission => {
      const [resource] = destructurePermission(permission)
      const wildcard = `${resource}:*`
      const hasWildcard = existsPermission(list, wildcard)

      if (hasWildcard) {
        const restOfResourcePermissions =
          scheme?.[resource]
            .map(action => `${resource}:${action}`)
            .filter(current => current !== permission)
            .filter(current => current !== wildcard) || []

        toAdd.push(...restOfResourcePermissions)
        toRemove.push(wildcard)
      }

      if (!hasWildcard) toRemove.push(permission)
    })
  }

  return list.filter(permission => !toRemove.includes(permission)).concat(toAdd)
}
