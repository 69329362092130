import React from 'react'
import { Menu } from 'semantic-ui-react'

import { useCan } from '../../../hooks/useCan'

interface MenuItemProps {
  as?: any
  to?: string
  content?: string
  permission?: string[]
  onClick?: () => void
}

export const MenuItem: React.FC<MenuItemProps> = ({
  as,
  to,
  content,
  permission,
  onClick
}) => {
  const canSee = useCan(permission || [])

  if (!canSee) return null

  return <Menu.Item as={as} to={to} content={content} onClick={onClick} />
}
