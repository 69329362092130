import React from 'react'
import { toast } from 'react-toastify'

import { ConfirmationModal } from '../../../../../components/ConfirmationModal'

import { PersonFormatted, formatDocument } from '../../../utils/formatPerson'

interface ConfimDeleteModalProps {
  open: boolean
  person?: PersonFormatted
  loading?: boolean
  handleModal: (open: boolean) => void
  onConfirm: (person: PersonFormatted) => void
  onDecline: () => void
}

export const ConfirmDeleteModal: React.FC<ConfimDeleteModalProps> = ({
  open,
  person,
  loading,
  handleModal,
  onConfirm,
  onDecline
}) => {
  function handleConfirmDelete() {
    if (!person) {
      toast.error('Nenhum cadastrado selecionado')

      return
    }

    onConfirm(person)
  }

  return (
    <ConfirmationModal
      open={open}
      loading={loading}
      handleModal={handleModal}
      onConfirm={handleConfirmDelete}
      onDecline={onDecline}
    >
      <span>Deseja excluir este cadastro?</span>
      <br />
      {person ? (
        <>
          <strong>{person.name}</strong>
          {person.document ? (
            <>
              <br />
              <strong>{formatDocument(person)}</strong>
            </>
          ) : null}
        </>
      ) : null}
    </ConfirmationModal>
  )
}
