import React from 'react'

import { ConfirmationModal } from '../../../../../components/ConfirmationModal'

import { SignatureFormatted } from '../../../utils/formatSignature'

interface InvoiceSignatureModalProps {
  open: boolean
  loading: boolean
  signature?: SignatureFormatted
  handleModal: (open: boolean) => void
  onConfirm: (signature: SignatureFormatted) => void
}

export const InvoiceSignatureModal: React.FC<InvoiceSignatureModalProps> = ({
  open,
  loading,
  signature,
  handleModal,
  onConfirm
}) => {
  function handleConfirm() {
    if (!signature) return
    onConfirm(signature)
  }

  return (
    <ConfirmationModal
      open={open}
      loading={loading}
      handleModal={handleModal}
      onConfirm={handleConfirm}
      onDecline={() => handleModal(false)}
    >
      <span>Confirmar faturamento da assinatura:</span>
      <br />
      {signature ? (
        <strong>
          {signature.customer.name}
          <br />
          {signature.description}
          <br />
          {signature.valueFormatted}
        </strong>
      ) : null}
    </ConfirmationModal>
  )
}
