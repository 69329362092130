import React from 'react'

import { Button } from './styles'

interface ButtonLinkProps {
  content?: string | number
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children?: React.ReactElement
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  content,
  children,
  ...rest
}) => {
  return <Button {...rest}>{content || children}</Button>
}
