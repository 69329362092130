import React from 'react'
import {
  Form as Semantic,
  SemanticWIDTHS,
  CheckboxProps as SemanticCheckboxProps
} from 'semantic-ui-react'
import { useForm, useField } from 'react-final-form'

import { useFieldError } from '../../../hooks/useFieldError'

import { Wrapper } from './styles'

interface CheckboxProps {
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
  width?: SemanticWIDTHS
  fluid?: boolean
  readOnly?: boolean
  checked?: boolean
  value?: any
  alignField?: boolean
}

export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  alignField,
  ...rest
}) => {
  const form = useForm()
  const field = useField(name)
  const error = useFieldError(field, { pointing: 'left' })

  function handleChange(
    event: React.FormEvent<HTMLInputElement>,
    data: SemanticCheckboxProps
  ) {
    form.change(name, data.checked)
  }

  return (
    <Wrapper alignField={alignField}>
      <Semantic.Checkbox
        {...rest}
        name={name}
        onChange={handleChange}
        error={error}
      />
    </Wrapper>
  )
}
