import React, { useEffect } from 'react'
import { Form, Input, SemanticWIDTHS } from 'semantic-ui-react'
import { Field, useField, useForm } from 'react-final-form'

import { useFieldError } from '../../../hooks/useFieldError'

import { formatValue } from './utils'

interface CurrencyProps {
  name: string
  id?: string | number
  label?: string
  required?: boolean
  disabled?: boolean
  width?: SemanticWIDTHS
  fluid?: boolean
  readOnly?: boolean
  thousandSeparator?: string
  decimalSeparator?: string
  precision?: number
  prefix?: string
}

export const Currency: React.FC<CurrencyProps> = ({
  name,
  thousandSeparator,
  decimalSeparator,
  precision,
  prefix,
  ...rest
}) => {
  const field = useField(name)
  const error = useFieldError(field)
  const form = useForm()

  function toCurrency(value: string) {
    return formatValue(value, {
      thousandSeparator,
      decimalSeparator,
      precision,
      prefix
    })
  }

  useEffect(() => {
    form.change(name, toCurrency(field.input.value))
  }, [field.input.value])

  return (
    <Field name={name}>
      {({ input }) => (
        <Form.Field {...rest} {...input} control={Input} error={error} />
      )}
    </Field>
  )
}
