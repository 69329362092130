import { Signature, getSignature } from '../../../../../../services/signature'

export function useFetchSignature() {
  async function fetchSignature(id: string): Promise<Signature | undefined> {
    const signature = await getSignature(id)
    return signature
  }

  return fetchSignature
}
