import React, { useContext } from 'react'
import { Table } from 'semantic-ui-react'

import { PaginationContext } from '../../../../../contexts/PaginationContext'

import { Text } from './styles'

interface AmountCellProps {
  content: string
}

export const AmountCell: React.FC<AmountCellProps> = ({ content }) => {
  const { pagination } = useContext(PaginationContext)

  return (
    <Table.Cell>
      <Text
        positive={pagination.query.type === 'RECEIVABLE'}
        negative={pagination.query.type === 'PAYABLE'}
      >
        {content}
      </Text>
    </Table.Cell>
  )
}
