import React from 'react'
import { Modal, Image } from 'semantic-ui-react'

interface ImageViewerModalProps {
  open: boolean
  title?: string
  image?: Buffer
  mimetype?: string
  handleModal: (open: boolean) => void
}

export const ImageViewerModal: React.FC<ImageViewerModalProps> = ({
  open,
  title,
  image,
  mimetype,
  handleModal
}) => {
  return (
    <Modal
      open={open}
      onOpen={() => handleModal(true)}
      onClose={() => handleModal(false)}
      size="small"
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header content={title} />
      <Modal.Content>
        {image ? (
          <Image
            alt={title}
            src={`data:${mimetype};base64,${image?.toString('base64')}`}
            fluid
          />
        ) : (
          <span>Não foi possível mostrar o conteúdo</span>
        )}
      </Modal.Content>
    </Modal>
  )
}
