import React from 'react'

import { ProductFormProvider } from './context'
import { ProductMain } from './components'

export const ProductForm: React.FC = () => {
  return (
    <ProductFormProvider>
      <ProductMain />
    </ProductFormProvider>
  )
}
