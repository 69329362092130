import React, { useState } from 'react'
import { Button, Input } from 'semantic-ui-react'

import { ActionsContainer } from './styles'

interface ActionsProps {
  onSearchClick?: (text: string) => void
}

export const Actions: React.FC<ActionsProps> = ({ onSearchClick }) => {
  const [search, setSearch] = useState('')

  function handleSearchKeyPress(evt: React.KeyboardEvent) {
    if (evt.key === 'Enter') {
      onSearchClick?.(search)
    }
  }

  return (
    <ActionsContainer>
      {onSearchClick && (
        <Input
          placeholder="Pesquisar"
          size="mini"
          value={search}
          onChange={(_, { value }) => setSearch(value.toUpperCase())}
          onKeyPress={handleSearchKeyPress}
          action={
            <Button icon="search" onClick={() => onSearchClick(search)} />
          }
        />
      )}
    </ActionsContainer>
  )
}
