import { UserPix } from '../../../services/users'

import { UserPixFormatted } from './formatUser'

export function sanitizeUserPix(pix?: UserPixFormatted): UserPix | undefined {
  if (pix?.type === undefined) return undefined

  const { type } = pix

  const removeMaskFromKey =
    type === 'PHONE' || type === 'CPF' || type === 'CNPJ'
  const keyCleaned = removeMaskFromKey
    ? pix.keyFormatted.replace(/\D/g, '')
    : pix.keyFormatted
  const key = type === 'PHONE' ? `+55${keyCleaned}` : keyCleaned

  return {
    type,
    key
  }
}
