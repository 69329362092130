import React from 'react'
import { Table } from 'semantic-ui-react'

export const SignaturesTableHeader: React.FC = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell content="Cliente" />
        <Table.HeaderCell content="Descrição" />
        <Table.HeaderCell content="Período" />
        <Table.HeaderCell content="Recorrência" />
        <Table.HeaderCell content="Vencimento" />
        <Table.HeaderCell content="Valor" />
        <Table.HeaderCell content="Faturar" />
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
  )
}
