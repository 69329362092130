import React from 'react'
import { Table } from 'semantic-ui-react'

import { ItemControllers } from '../../../../../components/Table'
import { can } from '../../../../../config/permissions'
import { Group } from '../../../../../services/group'

interface GroupsTableRowProps {
  group: Group
  onUpdateButtonClick: (group: Group) => void
  onDeleteButtonClick: (group: Group) => void
}

export const GroupsTableRow: React.FC<GroupsTableRowProps> = ({
  group,
  onUpdateButtonClick,
  onDeleteButtonClick
}) => {
  return (
    <Table.Row>
      <Table.Cell content={group.name} />
      <Table.Cell textAlign="right">
        <ItemControllers
          onUpdateClick={() => onUpdateButtonClick(group)}
          onDeleteClick={() => onDeleteButtonClick(group)}
          permissionsToUpdate={can.group.update}
          permissionsToDelete={can.group.delete}
        />
      </Table.Cell>
    </Table.Row>
  )
}
