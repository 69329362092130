import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

import { FormActionsContainer } from '../../../../../../../../styles/FormActionsContainer'
import { pages } from '../../../../../../../../config/pages'
import { Show } from '../../../../../../../../components/Show'
import { UserStatus } from '../../../../../../../../services/users'

import { useUser } from '../../../../hooks'
import { ConfirmationActionModal } from './ConfirmationActionModal'

export const FormActions: React.FC = () => {
  const { user, loading, updateUserStatus, resetUserPassword } = useUser()
  const [showActiveUser, setShowActiveUser] = useState(false)
  const [showInactiveUser, setShowInactiveUser] = useState(false)
  const [showResetUserPassword, setShowResetUserPassword] = useState(false)

  function handleConfirmChangeStatus(status: UserStatus) {
    updateUserStatus(status, () => {
      setShowActiveUser(false)
      setShowInactiveUser(false)
    })
  }

  function handleConfirmResetPassword() {
    resetUserPassword(() => {
      setShowResetUserPassword(false)
    })
  }

  const userExist = !!user
  const userIsActive = user?.status === 'ACTIVE'

  return (
    <FormActionsContainer>
      <Button type="button" content="Voltar" as={Link} to={pages.users.path} />
      <Button type="submit" content="Salvar" floated="right" primary />
      <Show condition={userIsActive}>
        <Button
          type="button"
          content="Desativar usuário"
          floated="right"
          onClick={() => setShowInactiveUser(true)}
        />
        <ConfirmationActionModal
          open={showInactiveUser}
          title="Alterar status do usuário"
          question="Deseja desativar o usuário?"
          loading={loading}
          handleModal={setShowInactiveUser}
          onConfirm={() => handleConfirmChangeStatus('INACTIVE')}
        />
      </Show>
      <Show condition={userExist && !userIsActive}>
        <Button
          type="button"
          content="Ativar usuário"
          floated="right"
          onClick={() => setShowActiveUser(true)}
        />
        <ConfirmationActionModal
          open={showActiveUser}
          title="Alterar status do usuário"
          question="Deseja ativar o usuário?"
          loading={loading}
          handleModal={setShowActiveUser}
          onConfirm={() => handleConfirmChangeStatus('ACTIVE')}
        />
      </Show>
      <Show condition={userExist}>
        <Button
          type="button"
          content="Resetar senha"
          floated="right"
          onClick={() => setShowResetUserPassword(true)}
        />
        <ConfirmationActionModal
          open={showResetUserPassword}
          title="Alterar status do usuário"
          question="Deseja resetar a senha do usuário?"
          loading={loading}
          handleModal={setShowResetUserPassword}
          onConfirm={() => handleConfirmResetPassword()}
        />
      </Show>
    </FormActionsContainer>
  )
}
