import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 36px;
`

export const AddItemModalWrapper = styled.div`
  .input {
    margin-top: 1em;
  }

  .checkbox {
    margin-right: 1em;
  }
`
