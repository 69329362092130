import React, { useState, useCallback } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

import { pages } from '../../config/pages'
import {
  listCertificate,
  Certificate,
  CertificateListResult
} from '../../services/certificate'
import { RegistrationContainer } from '../../components/RegistrationContainer'
import {
  PaginationProvider,
  Pagination
} from '../../contexts/PaginationContext'
import { useBreadcrumb } from '../../hooks/useBreadcrumb'

import { CertificatesTable } from './CertificatesTable'
import { CertificateForm } from './CertificateForm'
import { formatCertificate, CertificateFormatted } from './utils'

export interface QueryParameters {
  search?: string
}

export const Certificates: React.FC = () => {
  const breadcrumb = useBreadcrumb({
    certificados: 'Certificados',
    inclusao: 'Inclusão',
    edicao: 'Edição'
  })
  const [certificates, setCertificates] = useState<CertificateFormatted[]>([])
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const fetchCertificates = useCallback(
    (pagination: Pagination<QueryParameters>) => {
      const { page, limit, query } = pagination
      const { search } = query || {}

      const onSuccess = (result: CertificateListResult) => {
        setCertificates(result.certificates.map(formatCertificate))
        setTotalItems(result.total)
      }

      Promise.resolve()
        .then(() => setLoading(true))
        .then(() => listCertificate({ page, limit, search }))
        .then(result => onSuccess(result))
        .finally(() => setLoading(false))
    },
    []
  )

  function handleCreateClick() {
    navigate(`${pages.certificates.path}/inclusao`)
  }

  function handleUpdateClick(data: Certificate) {
    navigate(`${pages.certificates.path}/edicao?id=${data.id}`)
  }

  return (
    <RegistrationContainer breadcrumbItems={breadcrumb}>
      <PaginationProvider totalItems={totalItems}>
        <Routes>
          <Route
            path="/"
            element={
              <CertificatesTable
                certificates={certificates}
                totalItems={totalItems}
                loading={loading}
                fetchCertificates={fetchCertificates}
                onCreateClick={handleCreateClick}
                onUpdateClick={handleUpdateClick}
              />
            }
          />
          <Route path="/inclusao" element={<CertificateForm />} />
          <Route path="/edicao" element={<CertificateForm />} />
        </Routes>
      </PaginationProvider>
    </RegistrationContainer>
  )
}
