import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Input, Select } from 'semantic-ui-react'

import { PaginationContext } from '../../../../../contexts/PaginationContext'
import { useCan } from '../../../../../hooks/useCan'
import { can } from '../../../../../config/permissions'
import { Show } from '../../../../../components/Show'
import { pages } from '../../../../../config/pages'

import { AccountsTableContext } from '../context'
import { ActionsContainer } from '../styles'

const typeOptions = [
  { key: 'RECEIVABLE', value: 'RECEIVABLE', text: 'Contas a receber' },
  { key: 'PAYABLE', value: 'PAYABLE', text: 'Contas a pagar' }
]

const statusOptions = [
  { key: 'OPEN', value: 'OPEN', text: 'Não pagas' },
  { key: 'PAID', value: 'PAID', text: 'Pagas' },
  { key: 'OVERDUE', value: 'OVERDUE', text: 'Vencidas' }
]

export const AccountsTableActions: React.FC = () => {
  const { changeType, changeStatus } = useContext(AccountsTableContext)
  const [search, setSearch] = useState<string>()
  const { pagination, changeQuery } = useContext(PaginationContext)
  const navigate = useNavigate()
  const canCreate = useCan(can.account.create)

  function searchAccount() {
    changeQuery({ ...pagination.query, search })
  }

  function handleSearchKeyPress(evt: React.KeyboardEvent) {
    if (evt.key === 'Enter') {
      searchAccount()
    }
  }

  function handleCreateButtonClick() {
    navigate(`${pages.accounts.path}/inclusao`)
  }

  const isPayableList = pagination.query.type === 'PAYABLE'

  return (
    <ActionsContainer>
      <div className="filters">
        <Input
          placeholder="Pesquisar"
          value={search}
          onChange={(_, { value }) => setSearch(value.toUpperCase())}
          onKeyPress={handleSearchKeyPress}
          action={<Button icon="search" onClick={searchAccount} />}
        />
        <Select
          options={typeOptions}
          onChange={changeType}
          value={pagination.query.type}
        />
        <Select
          options={statusOptions}
          onChange={changeStatus}
          value={pagination.query.status}
        />
        <Show condition={canCreate && isPayableList}>
          <Button
            content="Incluir"
            floated="right"
            onClick={handleCreateButtonClick}
            primary
          />
        </Show>
      </div>
    </ActionsContainer>
  )
}
