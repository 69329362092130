import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table } from 'semantic-ui-react'

import { PaginationContext } from '../../../../contexts/PaginationContext'
import { DefaultLoader } from '../../../../components/DefaultLoader'
import { pages } from '../../../../config/pages'
import { Service } from '../../../../services/service'

import { useServicesList, useService } from './hooks'
import {
  ServicesTableActions,
  ServicesTableHeader,
  ServicesTableFooter,
  ServicesTableRow,
  ConfirmDeleteModal
} from './components'

export const ServicesTable: React.FC = () => {
  const {
    pagination,
    goToFirstPage,
    goToPreviousPage,
    goToNextPage,
    goToLastPage,
    changeOrderBy,
    changeLimit
  } = useContext(PaginationContext)
  const { services, totalItems, loading, fetchServices } = useServicesList()
  const { loading: deleteLoading, deleteService } = useService()
  const navigate = useNavigate()
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [selectedService, setSelectedService] = useState<Service>()

  useEffect(() => {
    fetchServices(pagination)
  }, [pagination])

  function redirectToCreateForm() {
    navigate(`${pages.services.path}/inclusao`)
  }

  function redirectToUpdateForm(service: Service) {
    navigate(`${pages.services.path}/edicao?id=${service.id}`)
  }

  function openConfirmDeleteModal(service: Service) {
    setSelectedService(service)
    setShowConfirmDeleteModal(true)
  }

  function handleConfirmDelete(service: Service) {
    deleteService(service.id, () => {
      setShowConfirmDeleteModal(false)
      fetchServices(pagination)
    })
  }

  return (
    <>
      <DefaultLoader active={loading} />

      <ServicesTableActions
        pagination={pagination}
        onCreateButtonClick={redirectToCreateForm}
      />

      <Table selectable striped compact sortable>
        <ServicesTableHeader
          pagination={pagination}
          changeOrderBy={changeOrderBy}
        />
        <Table.Body>
          {services.map(service => (
            <ServicesTableRow
              key={service.id}
              service={service}
              onUpdateButtonClick={redirectToUpdateForm}
              onDeleteButtonClick={openConfirmDeleteModal}
            />
          ))}
        </Table.Body>
        <ServicesTableFooter
          colSpan={3}
          count={services.length}
          pagination={pagination}
          totalItems={totalItems}
          loading={loading}
          goToFirstPage={goToFirstPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
          changeLimit={changeLimit}
        />
      </Table>

      <ConfirmDeleteModal
        open={showConfirmDeleteModal}
        service={selectedService}
        loading={deleteLoading}
        handleModal={setShowConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
        onDecline={() => setShowConfirmDeleteModal(false)}
      />
    </>
  )
}
