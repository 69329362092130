import React, { useState, useEffect, PropsWithChildren } from 'react'
import { AxiosError } from 'axios'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'
import { useNavigate, useLocation } from 'react-router-dom'

import api from '../services/api'
import {
  createSession,
  refreshSession,
  logout,
  Credentials,
  SessionResult,
  UserData
} from '../services/session'
import { getUserAccount } from '../services/userAccount'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { SESSION_KEY_NAME, IS_AUTH_KEY_NAME } from '../utils/constants'
import { pages } from '../config/pages'
import { sessionRefreshTime } from '../config/env'

interface Auth {
  user?: UserData
  permissions: string[]
  isAuth: boolean
  signIn: (credentials: Credentials) => Promise<void>
  signOut: () => void
  updateUserData: (data: UserData) => void
}

interface AccessTokenPayload {
  permissions: string[]
  role: string
}

export const AuthContext = React.createContext<Auth>({} as Auth)

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<UserData>()
  const [permissions, setPermissions] = useState<string[]>([])
  const [isAuth, setIsAuth] = useLocalStorage(IS_AUTH_KEY_NAME)
  const [accessToken, setAccessToken] = useLocalStorage(SESSION_KEY_NAME)
  const navigate = useNavigate()
  const location = useLocation()

  function handleSession(session: SessionResult) {
    const { accessToken, user } = session

    setAccessToken(accessToken)
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    const payload = jwtDecode<AccessTokenPayload>(accessToken)
    setUser(user)
    setPermissions(payload.permissions)
    setIsAuth('true')
  }

  function clearSession() {
    setUser(undefined)
    setIsAuth('false')
    setAccessToken('')
    navigate(pages.login.path)
  }

  function onRefreshFail(err: AxiosError) {
    if (err.response?.status === 401) {
      clearSession()
    }
  }

  function refresh() {
    const isPublic = location.pathname.startsWith('/public')

    if (isPublic) return

    Promise.resolve()
      .then(() => refreshSession())
      .then(session => handleSession(session))
      .catch(err => onRefreshFail(err))
  }

  useEffect(() => {
    if (accessToken) {
      Promise.resolve()
        .then(() => getUserAccount())
        .then(account => setUser(account))
    }

    refresh()

    const refreshIntervalId = setInterval(() => refresh(), sessionRefreshTime)

    return () => clearInterval(refreshIntervalId)
  }, [])

  function onSignInFail(err: AxiosError<any>) {
    if (err.response?.status !== 401) return

    toast.error(err.response.data.message)
  }

  async function signIn(credentials: Credentials) {
    const { email, password } = credentials

    return Promise.resolve()
      .then(() => createSession({ email, password }))
      .then(session => handleSession(session))
      .then(() => navigate(pages.home.path))
      .catch(err => onSignInFail(err))
  }

  function signOut() {
    Promise.resolve()
      .then(() => logout())
      .then(() => clearSession())
  }

  function updateUserData(data: UserData) {
    setUser(data)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        permissions,
        isAuth: isAuth === 'true',
        signIn,
        signOut,
        updateUserData
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
