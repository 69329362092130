import { Person } from '../services/person'
import { masks } from './mask'

export const PHONE_MASK = '(##) ####-####'

export const MOBILE_MASK = '(##) # ####-####'

export function getMaskByLength(value: string): string {
  const numbers = value.replace(/\D/g, '')
  const length = numbers.length

  return length >= 11 ? MOBILE_MASK : PHONE_MASK
}

export function formatDocument(person: Person): string {
  if (!person.document) return ''

  return person.type === 'COMPANY'
    ? masks.cnpj(person.document)
    : masks.cpf(person.document)
}
