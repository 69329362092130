import { dateToLocaleDateTime } from '../utils/date'

import api from './api'
import { UserRole } from './users'

export interface Credentials {
  email: string
  password: string
}

export interface UserData {
  id: string
  role: UserRole
  nickname: string
  email: string
  commissionType: string
}

export interface SessionResult {
  accessToken: string
  user: UserData
}

export interface Session {
  id: string
  description: string
  ip: string
  createdAt: string
}

function toInternalFormat(data: Session): Session {
  return {
    id: data.id,
    description: data.description,
    ip: data.ip,
    createdAt: dateToLocaleDateTime(data.createdAt)
  }
}

export async function listSessions(): Promise<Session[]> {
  const response = await api.get('/sessions')

  const sessions = response.data.items.map(toInternalFormat)

  return sessions
}

export async function createSession(
  credentials: Credentials
): Promise<SessionResult> {
  const { email, password } = credentials
  const response = await api.post('/sessions', { email, password })
  const { accessToken, user } = response.data

  return {
    accessToken,
    user
  }
}

export async function refreshSession(): Promise<SessionResult> {
  const response = await api.put('/sessions')
  const { accessToken, user } = response.data

  return {
    accessToken,
    user
  }
}

export async function logout(): Promise<boolean> {
  const response = await api.delete('/sessions')
  const result = response.data

  return result.status
}

export async function deleteSession(id: string): Promise<boolean> {
  const response = await api.delete(`/sessions/${id}`)
  const result = response.data

  return result.status
}
