import React, { useState, useEffect } from 'react'
import { Table, Accordion, Form, TextArea } from 'semantic-ui-react'

import { listARLogs, ARLog } from '../../services/arLog'
import { dateToLocaleDateTime } from '../../utils/date'
import { DefaultLoader } from '../../components/DefaultLoader'

interface ARLogFormatted extends ARLog {
  createdAtFormatted: string
  itemsFormatted: string
}

export const ARLogs: React.FC = () => {
  const [data, setData] = useState<ARLogFormatted[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState<number>()

  function formatData(arLogs: ARLog[]): ARLogFormatted[] {
    return arLogs.map(arLog => {
      return {
        ...arLog,
        createdAtFormatted: dateToLocaleDateTime(arLog.createdAt),
        itemsFormatted: JSON.stringify(arLog.items, null, 2)
      }
    })
  }

  useEffect(() => {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => listARLogs())
      .then(({ arLogs, total }) => {
        setData(formatData(arLogs))
        setTotal(total)
      })
      .finally(() => setLoading(false))
  }, [])

  function handleClick(index: number) {
    setActive(active === index ? undefined : index)
  }

  return (
    <>
      <DefaultLoader active={loading} />
      <Table selectable striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              content="Mostrando os últimos 50 logs"
              colSpan={2}
            />
            <Table.HeaderCell
              content={`${total} ${total === 1 ? 'item' : 'itens'}`}
              textAlign="right"
            />
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell content="Código" />
            <Table.HeaderCell content="Data" />
            <Table.HeaderCell content="Log" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((arLog, index) => (
            <Table.Row key={arLog.id}>
              <Table.Cell content={arLog.orderCode} width={2} />
              <Table.Cell content={arLog.createdAtFormatted} width={3} />
              <Table.Cell width={11}>
                <Accordion>
                  <Accordion.Title
                    active={active === index}
                    index={index}
                    content="Ver log"
                    icon="dropdown"
                    onClick={() => handleClick(index)}
                  />
                  <Accordion.Content active={active === index}>
                    <Form>
                      <TextArea
                        value={arLog.itemsFormatted}
                        style={{ width: '100%', height: '500px' }}
                        readOnly
                      />
                    </Form>
                  </Accordion.Content>
                </Accordion>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}
