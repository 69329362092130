import { useState, useCallback } from 'react'

import {
  SignatureListResult,
  listSignatures
} from '../../../../../services/signature'
import { Pagination } from '../../../../../contexts/PaginationContext'

import {
  formatSignature,
  SignatureFormatted
} from '../../../utils/formatSignature'

export function useSignatureList() {
  const [loading, setLoading] = useState(false)
  const [signatures, setSignatures] = useState<SignatureFormatted[]>([])
  const [totalItems, setTotalItems] = useState(0)

  const fetchSignatures = useCallback((pagination: Pagination) => {
    const { page, limit } = pagination

    const onSuccess = (result: SignatureListResult) => {
      setSignatures(result.signatures.map(formatSignature))
      setTotalItems(result.total)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => listSignatures({ page, limit }))
      .then(result => onSuccess(result))
      .finally(() => setLoading(false))
  }, [])

  return {
    loading,
    signatures,
    totalItems,
    fetchSignatures
  }
}
