import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { toast } from 'react-toastify'

import { useCan } from '../../../../../../../../hooks/useCan'
import { can } from '../../../../../../../../config/permissions'
import { Show } from '../../../../../../../../components/Show'

import { useOrder, useOrderAc, useOrderCustomer } from '../../../../hooks'
import { HandleOrderConfirmationModal } from '../HandleOrderConfirmationModal'

export const RenewCertificateAction: React.FC = () => {
  const { order, loading, isCertificateOrder } = useOrder()
  const { renewCertificate } = useOrderAc()
  const { customer } = useOrderCustomer()
  const [showRenewCertificateModal, setShowRenewCertificateModal] =
    useState(false)
  const canRenewCertificate = useCan(can.ac.requestCertificate)

  function handleRenewCertificateButtonClick() {
    setShowRenewCertificateModal(true)
  }

  function handleConfirmToRenewCertificate() {
    if (!customer?.emails) {
      setShowRenewCertificateModal(false)
      return toast.error('Cliente não possui endereço de e-mail cadastrado')
    }

    renewCertificate(() => setShowRenewCertificateModal(false))
  }

  const orderExist = !!order
  const orderIsOpen = orderExist && order?.status === 'OPEN'
  const orderHasCertificateProtocol = !!order?.certificateIssueProtocol

  return (
    <Show
      condition={
        isCertificateOrder &&
        !orderIsOpen &&
        !orderHasCertificateProtocol &&
        canRenewCertificate
      }
      component={
        <>
          <Button
            type="button"
            content="Renovar certificado"
            floated="right"
            onClick={handleRenewCertificateButtonClick}
          />
          <HandleOrderConfirmationModal
            open={showRenewCertificateModal}
            order={order}
            loading={loading}
            title="Renovar certificado"
            question="Você deseja fazer uma renovação de certificado digital na AC?"
            handleModal={setShowRenewCertificateModal}
            onConfirm={handleConfirmToRenewCertificate}
            onDecline={() => setShowRenewCertificateModal(false)}
          />
        </>
      }
    />
  )
}
