import React from 'react'
import { Table } from 'semantic-ui-react'

import { ChargeFormatted } from '../../../../../../../../utils/formatCharge'

interface NotEditableCellsProps {
  charge: ChargeFormatted
}

export const NotEditableCells: React.FC<NotEditableCellsProps> = ({
  charge
}) => {
  return (
    <>
      <Table.Cell content={charge.paymentTypeFormatted} />
      <Table.Cell content={charge.dueDateFormatted} />
    </>
  )
}
