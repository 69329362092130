import { useState } from 'react'
import Buffer from 'buffer'

import {
  CostReportOptions,
  getCostReport as apiGetCostReport
} from '../../../services/report'

export function useCostReport() {
  const [report, setReport] = useState<Buffer>()
  const [loading, setLoading] = useState(false)

  function getCostReport(options: CostReportOptions, onSuccess?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiGetCostReport(options))
      .then(pdf => setReport(pdf))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  return {
    report,
    loading,
    getCostReport
  }
}
