import { useState } from 'react'

import {
  CobPix,
  CobPixInfo,
  getCobPix as apiGetCobPix,
  refreshCobPix as apiRefreshCobPix
} from '../../../../../services/cobPix'

export function useCobPix() {
  const [loading, setLoading] = useState(false)
  const [cobPixInfo, setCobPixInfo] = useState<CobPixInfo>()

  function getCobPixInfo(
    orderId: string,
    accountId: string,
    onSuccess?: () => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiGetCobPix(orderId, accountId))
      .then(data => setCobPixInfo(data))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  function refreshCobPix(
    orderId: string,
    accountId: string,
    onSuccess?: (cobPix: CobPix) => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiRefreshCobPix(orderId, accountId))
      .then(data => onSuccess?.(data))
      .finally(() => setLoading(false))
  }

  return {
    cobPixInfo,
    loading,
    getCobPixInfo,
    refreshCobPix
  }
}
