import api from './api'

import { Person } from './person'
import { OrderItem } from './orderItem'
import { Charge } from './charge'
import { NFSe } from './nfse'

export type OrderStatus =
  | 'OPEN'
  | 'CLOSED'
  | 'APPROVED'
  | 'ISSUED'
  | 'CONCLUDED'

export interface Order {
  id: string
  categoryId: string
  code: number
  issueDate: string
  statusDate: string
  status: OrderStatus
  totalOrder: number
  totalDiscount: number
  totalAddition: number
  totalCost: number
  employeeCommission: number
  partnerCommission: number
  tax: number
  resaleCost: number
  customerId: string
  userId: string
  employeeId?: string
  partnerId: string
  certificateIssueProtocol?: string
  hasNfse: boolean
  paid: boolean
  customer: Person
  items: OrderItem[]
  charges: Charge[]
  nfse?: NFSe
}

export interface OrderPaginate {
  page: number
  limit: number
  orderBy?: string
  order?: 'ASC' | 'DESC'
  search?: string
}

export interface OrderListResult {
  orders: Order[]
  total: number
}

export async function getOrder(id: string): Promise<Order | undefined> {
  const response = await api.get(`/orders/${id}`)
  const order = response.status === 200 ? response.data : undefined

  return order
}

export async function listOrders(
  paginate?: OrderPaginate
): Promise<OrderListResult> {
  const { page, limit, orderBy, order, search } = paginate || {}
  const response = await api.get('/orders', {
    params: { page, limit, orderBy, order, search }
  })
  const { items: orders, total } = response.data

  return {
    orders,
    total
  }
}

export async function createOrder(data: Partial<Order>): Promise<Order> {
  const response = await api.post('/orders', data)
  const order = response.data

  return order
}

export async function updateOrder(
  id: string,
  data: Partial<Order>
): Promise<Order> {
  const response = await api.put(`/orders/${id}`, data)
  const order = response.data

  return order
}

export async function deleteOrder(id: string): Promise<boolean> {
  const response = await api.delete(`/orders/${id}`)
  const result = response.data

  return result.status
}

export async function closeOrder(id: string): Promise<Order> {
  const response = await api.patch(`/orders/${id}/close`)
  const order = response.data

  return order
}

export async function concludeOrder(
  id: string,
  statusDate?: Date
): Promise<Order> {
  const response = await api.patch(`/orders/${id}/conclude`, { statusDate })
  const order = response.data

  return order
}

export async function reopenOrder(id: string): Promise<Order> {
  const response = await api.patch(`/orders/${id}/reopen`)
  const order = response.data

  return order
}

export async function sendDocuments(orderId: string): Promise<void> {
  await api.post(`/orders/${orderId}/send-documents`)
}
