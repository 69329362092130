import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Segment } from 'semantic-ui-react'
import { Form as FinalForm } from 'react-final-form'

import { Product } from '../../../../../services/product'
import { getDiff, getFormValidate } from '../../../../../utils/form'
import { Form, Input } from '../../../../../components/Form'
import { FormActionsContainer } from '../../../../../styles/FormActionsContainer'
import { pages } from '../../../../../config/pages'

import { useProduct } from '../hooks/useProduct'
import { productSchema } from './schemas'

const validateForm = getFormValidate(productSchema)

export const ProductMain: React.FC = () => {
  const { product, createProduct, updateProduct } = useProduct()

  function handleSubmit(values: Product) {
    if (!product) createProduct(values)

    if (product) {
      const diff = getDiff(product, values)

      if (!diff) return

      updateProduct(diff)
    }
  }

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={product}
      validate={validateForm}
      validateOnBlur
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormActionsContainer>
            <Button
              type="button"
              content="Voltar"
              as={Link}
              to={pages.products.path}
            />
            <Button type="submit" content="Salvar" floated="right" primary />
          </FormActionsContainer>
          <Segment>
            <input name="id" hidden />
            <Form.Group>
              <Input
                name="description"
                label="Descrição do produto"
                maxLength={60}
                width={16}
                required
                uppercase
              />
            </Form.Group>
          </Segment>
        </Form>
      )}
    </FinalForm>
  )
}
