import styled from 'styled-components'

interface CompactInputProps {
  w?: string
  h?: string
  ta?: 'left' | 'center' | 'right'
}

export const CompactInput = styled.div<CompactInputProps>`
  input {
    ${({ w }) => w && `width: ${w};`}
    ${({ h }) => h && `height: ${h};`}
    ${({ ta }) => ta && `text-align: ${ta} !important;`}
  }
`
