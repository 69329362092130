import { Service, getService } from '../../../../../../services/service'

export function useFetchService() {
  async function fetchService(id: string): Promise<Service | undefined> {
    try {
      const service = await getService(id)
      return service
    } catch (err: any) {
      const status = err.response?.status

      if (status === 404) return

      throw err
    }
  }

  return fetchService
}
