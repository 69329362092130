import React, { useContext } from 'react'

import { PaginationContext } from '../../../../contexts/PaginationContext'
import { Show } from '../../../../components/Show'

import { AccountsTableProvider } from './context'
import {
  AccountsTableActions,
  PayablesTable,
  ReceivablesTable
} from './components'

export const AccountsTable: React.FC = () => {
  const { pagination } = useContext(PaginationContext)

  return (
    <AccountsTableProvider>
      <AccountsTableActions />

      <Show
        condition={pagination.query.type === 'PAYABLE'}
        component={<PayablesTable />}
      />

      <Show
        condition={pagination.query.type === 'RECEIVABLE'}
        component={<ReceivablesTable />}
      />
    </AccountsTableProvider>
  )
}
