import React from 'react'
import { Divider } from 'semantic-ui-react'

import * as permissionsHandler from '../../../../../../../utils/permission'

import { Permissions } from './styles'
import { UserPermissions } from './UserPermissions'
import { PermissionPermissions } from './PermissionPermissions'
import { PersonPermissions } from './PersonPermissions'
import { APIKeyPermissions } from './APIKeyPermissions'
import { ServicePermissions } from './ServicePermissions'
import { ProductPermissions } from './ProductPermissions'
import { PriceListPermissions } from './PriceListPermissions'
import { OrderPermissions } from './OrderPermissions'
import { AcPermissions } from './AcPermissions'
import { CertificatePermissions } from './CertificatePermissions'
import { ARLogsPermissions } from './ARLogsPermissions'
import { AccountPermissions } from './AccountPermissions'
import { ReportPermissions } from './ReportPermissions'
import { SystemPermissions } from './SystemPermissions'
import { CategoryPermissions } from './CategoryPermissions'
import { GroupPermissions } from './GroupPermissions'

interface AllPermissionsProps {
  permissions: string[]
  scheme?: Record<string, string[]>
  setPermissions: (permissions: string[]) => void
}

export interface OnCheck {
  value: string
  checked: boolean
}

export function AllPermissions({
  permissions,
  scheme,
  setPermissions
}: AllPermissionsProps): React.ReactElement {
  function resourceListToArray(list: string): string[] {
    const [resource, actionsList] = list.split(':')
    const actions = actionsList.split(',')
    const permissions = actions.map(action => `${resource}:${action}`)

    return permissions
  }

  function permissionsListToArray(list: string): string[] {
    const listByResource = list.split(';')
    const permissions = listByResource.map(resourceListToArray)

    return permissions.flat()
  }

  function handleChange(data: OnCheck) {
    const value = permissionsListToArray(data.value as string)

    const newPermissionsList = data.checked
      ? permissionsHandler.addPermissions(permissions, value)
      : permissionsHandler.removePermissions(permissions, value, scheme)

    setPermissions(newPermissionsList)
  }

  function isChecked(resource: string, actions: string[]) {
    if (permissions.includes('*:*')) return true

    if (permissions.includes(`${resource}:*`)) return true

    if (actions.every(action => permissions.includes(`*:${action}`)))
      return true

    const permissionsList = actions.map(action => `${resource}:${action}`)
    const checked = permissionsList.every(item => permissions.includes(item))

    return checked
  }

  return (
    <Permissions>
      <UserPermissions onChange={handleChange} isChecked={isChecked} />
      <PermissionPermissions onChange={handleChange} isChecked={isChecked} />
      <CategoryPermissions onChange={handleChange} isChecked={isChecked} />
      <GroupPermissions onChange={handleChange} isChecked={isChecked} />
      <Divider />
      <PersonPermissions onChange={handleChange} isChecked={isChecked} />
      <APIKeyPermissions onChange={handleChange} isChecked={isChecked} />
      <Divider />
      <ServicePermissions onChange={handleChange} isChecked={isChecked} />
      <ProductPermissions onChange={handleChange} isChecked={isChecked} />
      <PriceListPermissions onChange={handleChange} isChecked={isChecked} />
      <OrderPermissions onChange={handleChange} isChecked={isChecked} />
      <AcPermissions onChange={handleChange} isChecked={isChecked} />
      <CertificatePermissions onChange={handleChange} isChecked={isChecked} />
      <Divider />
      <ARLogsPermissions onChange={handleChange} isChecked={isChecked} />
      <AccountPermissions onChange={handleChange} isChecked={isChecked} />
      <Divider />
      <ReportPermissions onChange={handleChange} isChecked={isChecked} />
      <Divider />
      <SystemPermissions onChange={handleChange} isChecked={isChecked} />
    </Permissions>
  )
}
