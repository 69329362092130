import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table } from 'semantic-ui-react'

import { PaginationContext } from '../../../../contexts/PaginationContext'
import { DefaultLoader } from '../../../../components/DefaultLoader'
import { pages } from '../../../../config/pages'
import { Group } from '../../../../services/group'

import { useGroupsList, useGroup } from './hooks'
import {
  GroupsTableActions,
  GroupsTableHeader,
  GroupsTableFooter,
  GroupsTableRow,
  ConfirmDeleteModal
} from './components'

export const GroupsTable: React.FC = () => {
  const {
    pagination,
    goToFirstPage,
    goToPreviousPage,
    goToNextPage,
    goToLastPage,
    changeLimit
  } = useContext(PaginationContext)
  const { groups, totalItems, loading, fetchGroups } = useGroupsList()
  const { loading: deleteLoading, deleteGroup } = useGroup()
  const navigate = useNavigate()
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState<Group>()

  useEffect(() => {
    fetchGroups(pagination)
  }, [pagination])

  function redirectToCreateForm() {
    navigate(`${pages.groups.path}/inclusao`)
  }

  function redirectToUpdateForm(order: Group) {
    navigate(`${pages.groups.path}/edicao?id=${order.id}`)
  }

  function openConfirmDeleteModal(order: Group) {
    setSelectedGroup(order)
    setShowConfirmDeleteModal(true)
  }

  function handleConfirmDelete(order: Group) {
    deleteGroup(order.id, () => {
      setShowConfirmDeleteModal(false)
      fetchGroups(pagination)
    })
  }

  return (
    <>
      <DefaultLoader active={loading} />

      <GroupsTableActions
        pagination={pagination}
        onCreateButtonClick={redirectToCreateForm}
      />

      <Table selectable striped compact sortable>
        <GroupsTableHeader />
        <Table.Body>
          {groups.map(group => (
            <GroupsTableRow
              key={group.id}
              group={group}
              onUpdateButtonClick={redirectToUpdateForm}
              onDeleteButtonClick={openConfirmDeleteModal}
            />
          ))}
        </Table.Body>
        <GroupsTableFooter
          colSpan={10}
          count={groups.length}
          pagination={pagination}
          totalItems={totalItems}
          loading={loading}
          goToFirstPage={goToFirstPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
          changeLimit={changeLimit}
        />
      </Table>

      <ConfirmDeleteModal
        open={showConfirmDeleteModal}
        group={selectedGroup}
        loading={deleteLoading}
        handleModal={setShowConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
        onDecline={() => setShowConfirmDeleteModal(false)}
      />
    </>
  )
}
