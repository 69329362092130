import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'

import { useCan } from '../../../../../../../../hooks/useCan'
import { can } from '../../../../../../../../config/permissions'
import { Show } from '../../../../../../../../components/Show'

import { useOrder } from '../../../../hooks'
import { HandleOrderConfirmationModal } from '../HandleOrderConfirmationModal'

export const SendOrderDocumentsAction: React.FC = () => {
  const { order, loading, sendOrderDocuments } = useOrder()
  const [showSendOrderDocuments, setShowSendOrderDocuments] = useState(false)
  const canSendOrderDocuments = useCan(can.order.sendDocuments)

  function handleSendDocumentsButtonClick() {
    setShowSendOrderDocuments(true)
  }

  function handleConfirmToSendDocuments() {
    sendOrderDocuments(() => setShowSendOrderDocuments(false))
  }

  const orderExist = !!order
  const orderIsOpen = orderExist && order?.status === 'OPEN'

  return (
    <Show
      condition={orderExist && !orderIsOpen && canSendOrderDocuments}
      component={
        <>
          <Button
            type="button"
            content="Enviar por e-mail"
            floated="right"
            onClick={handleSendDocumentsButtonClick}
          />
          <HandleOrderConfirmationModal
            open={showSendOrderDocuments}
            order={order}
            loading={loading}
            title="Enviar e-mail"
            question="Enviar NFS-e e cobranças para o cliente"
            handleModal={setShowSendOrderDocuments}
            onConfirm={handleConfirmToSendDocuments}
            onDecline={() => setShowSendOrderDocuments(false)}
          />
        </>
      }
    />
  )
}
