import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import {
  issueNfse as apiIssueNfse,
  cancelNfse as apiCancelNfse,
  CancelNfseOptions
} from '../../../../../services/nfse'

import { errorHandler } from '../../../utils/errorHandler'
import { OrderFormContext } from '../context'

export function useOrderNfse() {
  const { setLoading, getOrderState, updateOrderState } =
    useContext(OrderFormContext)
  const [errors, setErrors] = useState<string[]>([])

  function issueNfse(onFinally: () => void) {
    const order = getOrderState()

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiIssueNfse(order.id))
      .then(data => updateOrderState({ ...order, nfse: data }))
      .then(() => toast.info('A NFS-e foi emitida'))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function cancelNfse(data: CancelNfseOptions, onFinally: () => void) {
    const order = getOrderState()

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiCancelNfse(order.id, data))
      .then(() => updateOrderState({ ...order, nfse: undefined }))
      .then(() => toast.info('A NFS-e foi cancelada'))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  return {
    errors,
    issueNfse,
    cancelNfse
  }
}
