import React, { useState } from 'react'
import {
  Modal,
  List,
  Progress,
  Message,
  Button,
  SemanticCOLORS
} from 'semantic-ui-react'
import { AxiosProgressEvent } from 'axios'
import Dropzone, { ErrorCode, FileRejection } from 'react-dropzone'
import { filesize } from 'filesize'
import { nanoid } from 'nanoid'
import { toast } from 'react-toastify'

import { Pix, addProof } from '../../../services/pix'
import { Charge } from '../../../services/charge'
import { DropContainer, LocalFile, DroppedFile } from '../../Upload'
import { ButtonLink } from '../../ButtonLink'
import { calculateUpdateProgress } from '../../../utils/upload'

interface AddPixProofModalProps {
  open: boolean
  charge?: Charge
  handleModal: (open: boolean) => void
  onAdd?: (pix: Pix) => void
}

const TEN_MEGABYTES = 10485760

const progressColors: Record<string, SemanticCOLORS> = {
  waiting: 'blue',
  loading: 'blue',
  error: 'red',
  complete: 'green'
}

const acceptedTypes = {
  'image/jpeg': ['.jpeg', '.jpg', '.png', '.gif'],
  'application/pdf': ['.pdf']
}

interface FileError {
  key: string
  message: string
}

export const AddPixProofModal: React.FC<AddPixProofModalProps> = ({
  open,
  charge,
  handleModal,
  onAdd
}) => {
  const [file, setFile] = useState<LocalFile>()
  const [errors, setErrors] = useState<FileError[]>([])

  function handleDropFile(files: DroppedFile[]) {
    const [file] = files

    setFile({
      file,
      name: file.name as string,
      size: filesize(file.size),
      progress: 0,
      status: 'waiting',
      key: nanoid()
    } as LocalFile)
    setErrors([])
  }

  function handleRemoveClick() {
    setFile(undefined)
  }

  function handleReject(rejections: FileRejection[]) {
    const errorMessages: Record<ErrorCode, string> = {
      'file-invalid-type': 'Aceita apenas arquivos .jpg, .png, .gif e .pdf',
      'file-too-large': 'Aceita apenas arquivos menores que 10MB',
      'file-too-small': 'Arquivo muito pequeno',
      'too-many-files': 'Número de arquivos maior que o permitido'
    }

    const errors = rejections
      .map(rejection => {
        return rejection.errors.map(error => {
          const code = error.code as ErrorCode
          return {
            key: nanoid(),
            message: errorMessages[code]
          }
        })
      })
      .flat()

    setErrors(errors)
  }

  function setFileStatus(data: Partial<LocalFile>) {
    const newFileData = {}
    Object.assign(newFileData, file, data)
    setFile(newFileData as LocalFile)
  }

  function addFile(localFile: LocalFile) {
    const data = new FormData()

    data.append('file', localFile.file, localFile.name)

    setFileStatus({ status: 'loading' })

    const onProgress = (event: AxiosProgressEvent) => {
      const progress = calculateUpdateProgress(event)
      setFileStatus({ progress })
    }

    const onSuccess = (pix: Pix) => {
      setFileStatus({ status: 'complete', progress: 100 })
      onAdd?.(pix)
    }

    const onFail = (err: Error) => {
      toast.error(`${localFile.name}: ${err.message}`)
      setFileStatus({ status: 'error' })
    }

    if (!charge?.orderId) return

    addProof(charge.orderId, charge.id, data, onProgress)
      .then(data => onSuccess(data))
      .catch(err => onFail(err))
  }

  function handleSubmit() {
    if (!file) return

    addFile(file)
  }

  function handleClose() {
    setFile(undefined)
    setErrors([])
    handleModal(false)
  }

  return (
    <Modal
      open={open}
      onOpen={() => handleModal(true)}
      onClose={handleClose}
      size="mini"
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header content="Enviar comprovante de pagamento" />
      <Modal.Content>
        {file ? (
          <List>
            <List.Item key={file.key}>
              <List.Content floated="right">
                <ButtonLink
                  content="Remover"
                  disabled={file.status !== 'waiting'}
                  onClick={handleRemoveClick}
                />
              </List.Content>
              <List.Content>
                <List.Header content={file.name} />
                <List.Header content={file.size} />
                <Progress
                  size="tiny"
                  color={progressColors[file.status]}
                  percent={file.progress}
                />
              </List.Content>
            </List.Item>
          </List>
        ) : (
          <>
            <Dropzone
              onDropAccepted={handleDropFile}
              multiple={false}
              maxSize={TEN_MEGABYTES}
              accept={acceptedTypes}
              onDropRejected={handleReject}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <DropContainer {...getRootProps()} active={isDragActive}>
                  <input {...getInputProps()} hidden />
                  <span>Solte o arquivo aqui</span>
                </DropContainer>
              )}
            </Dropzone>
            {errors.map(error => (
              <Message key={error.key} content={error.message} error />
            ))}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Enviar"
          disabled={!file || (file && file.status !== 'waiting')}
          onClick={handleSubmit}
          primary
        />
      </Modal.Actions>
    </Modal>
  )
}
