import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Person } from '../../../../../services/person'
import { User } from '../../../../../services/users'
import { APIKey } from '../../../../../services/apiKey'
import { DefaultLoader } from '../../../../../components/DefaultLoader'
import { pages } from '../../../../../config/pages'

import { PersonFormatted, formatPerson } from '../../../utils/formatPerson'
import { useFetchPerson, useFetchPartner, useFetchApiKeys } from './hooks'
import { useCan } from '../../../../../hooks/useCan'
import { can } from '../../../../../config/permissions'

interface PersonFormContextContent {
  person?: PersonFormatted
  responsible?: Person
  partner?: User
  apiKeys: APIKey[]
  loading: boolean
  setLoading: (value: boolean) => void
  getPersonState: () => PersonFormatted
  updatePersonState: (person: Person) => PersonFormatted
  setApiKeys: (value: APIKey[]) => void
}

export const PersonFormContext = React.createContext<PersonFormContextContent>(
  {} as PersonFormContextContent
)

interface PersonFormProviderProps {
  children: React.ReactNode
}

export const PersonFormProvider: React.FC<PersonFormProviderProps> = ({
  children
}) => {
  const [person, setPerson] = useState<PersonFormatted>()
  const [responsible, setResponsible] = useState<Person>()
  const [partner, setPartner] = useState<User>()
  const [apiKeys, setApiKeys] = useState<APIKey[]>([])
  const [loading, setLoading] = useState(false)
  const [query] = useSearchParams()
  const canReadApiKeys = useCan(can.person.readApiKeys)
  const fetchPerson = useFetchPerson()
  const fetchPartner = useFetchPartner()
  const fetchApiKeys = useFetchApiKeys()
  const navigate = useNavigate()

  function getPersonState(): PersonFormatted {
    if (!person) throw new Error('Nenhum cadastro foi selecionado')

    return person
  }

  function updatePersonState(person: Person): PersonFormatted {
    const update = formatPerson(person)
    setPerson(update)

    return update
  }

  useEffect(() => {
    const personId = query.get('id')

    if (!personId) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => fetchPerson(personId))
      .then(data => {
        if (data) return updatePersonState(data)

        navigate(`${pages.people.path}/inclusao`)
        toast.warn(
          'O cadastro não foi encontrado, mas você pode criar um novo.'
        )
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (!person) return

    const { responsibleId } = person

    if (!responsibleId) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => fetchPerson(responsibleId))
      .then(data => setResponsible(data))
      .finally(() => setLoading(false))
  }, [person])

  useEffect(() => {
    if (!person) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => fetchPartner(person.partnerId))
      .then(data => setPartner(data))
      .finally(() => setLoading(false))
  }, [person])

  useEffect(() => {
    if (!person) return

    if (!canReadApiKeys) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => fetchApiKeys(person.id))
      .then(data => setApiKeys(data))
      .finally(() => setLoading(false))
  }, [person])

  return (
    <PersonFormContext.Provider
      value={{
        person,
        responsible,
        partner,
        apiKeys,
        loading,
        setLoading,
        getPersonState,
        updatePersonState,
        setApiKeys
      }}
    >
      <DefaultLoader active={loading} />
      {children}
    </PersonFormContext.Provider>
  )
}
