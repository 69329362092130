import React from 'react'
import { toast } from 'react-toastify'

import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal'

import { OrderFormatted } from '../../../../../utils/formatOrder'

interface HandleOrderConfirmationModalProps {
  open: boolean
  order?: OrderFormatted
  loading?: boolean
  title?: string
  question?: string
  handleModal: (open: boolean) => void
  onConfirm: (order: OrderFormatted) => void
  onDecline: () => void
}

export const HandleOrderConfirmationModal: React.FC<
  HandleOrderConfirmationModalProps
> = ({
  open,
  order,
  loading,
  title,
  question,
  handleModal,
  onConfirm,
  onDecline
}) => {
  function handleConfirmDelete() {
    if (!order) {
      toast.error('Nenhum pedido selecionado')

      return
    }

    onConfirm(order)
  }

  return (
    <ConfirmationModal
      open={open}
      loading={loading}
      title={title}
      handleModal={handleModal}
      onConfirm={handleConfirmDelete}
      onDecline={onDecline}
    >
      <span>{question}</span>
      <br />
      {order ? (
        <>
          <strong>{order.issueDateFormatted}</strong>
          <br />
          <strong>{order.customer?.name}</strong>
        </>
      ) : null}
    </ConfirmationModal>
  )
}
