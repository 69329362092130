import React, { useContext } from 'react'
import { Table } from 'semantic-ui-react'

import { PaginationContext } from '../../../../../../contexts/PaginationContext'

import { AccountsTableContext } from '../../context'
import {
  PayablesTableHeader,
  PayablesTableRow,
  PayablesTableFooter
} from './components'

export const PayablesTable: React.FC = () => {
  const { accounts, totalItems, loading, reloadAccounts, replaceAccount } =
    useContext(AccountsTableContext)
  const { pagination } = useContext(PaginationContext)

  function handleDeleteAccount() {
    reloadAccounts()
  }

  return (
    <Table selectable striped compact sortable>
      <PayablesTableHeader />
      <Table.Body>
        {accounts.map(account => (
          <PayablesTableRow
            key={account.id}
            account={account}
            onRefresh={replaceAccount}
            onDelete={handleDeleteAccount}
          />
        ))}
      </Table.Body>
      <PayablesTableFooter
        colSpan={pagination.query.status === 'PAID' ? 9 : 7}
        count={accounts.length}
        loading={loading}
        totalItems={totalItems}
      />
    </Table>
  )
}
