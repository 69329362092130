import { useState } from 'react'

import {
  Bolepix,
  refreshBolepix as apiRefreshBolepix,
  getBolepixPdf as apiGetBolepixPdf
} from '../../../../../services/bolepix'

export function useBolepix() {
  const [loading, setLoading] = useState(false)
  const [bolepixPdf, setBolepixPdf] = useState<Buffer>()

  function refreshBolepix(
    orderId: string,
    accountId: string,
    onSuccess?: (bolepix: Bolepix) => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiRefreshBolepix(orderId, accountId))
      .then(data => onSuccess?.(data))
      .finally(() => setLoading(false))
  }

  function getBolepixPdf(
    orderId: string,
    accountId: string,
    onSuccess?: () => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiGetBolepixPdf(orderId, accountId))
      .then(data => setBolepixPdf(data?.content))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  return {
    bolepixPdf,
    loading,
    refreshBolepix,
    getBolepixPdf
  }
}
