import api from './api'

export interface ARLog {
  id: string
  orderCode: string
  items: any[]
  createdAt: string
}

export interface ARLogListResult {
  arLogs: ARLog[]
  total: number
}

export async function listARLogs(): Promise<ARLogListResult> {
  const response = await api.get('/ar-logs')
  const { items: arLogs, total } = response.data

  return {
    arLogs,
    total
  }
}
