import React from 'react'
import { Table } from 'semantic-ui-react'

export const PartnershipsTableHeader: React.FC = () => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell content="Parceiro" />
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
  )
}
