import React, { useContext, useState } from 'react'

import { AccountFormatted } from '../../../../../../../utils/formatAccount'
import { Show } from '../../../../../../../../../components/Show'
import { Button } from '../../../../../../../../../components/Button'
import { PaginationContext } from '../../../../../../../../../contexts/PaginationContext'
import { PDFViewerModal } from '../../../../../../../../../components/PDFViewerModal'

import { useBolepix } from '../../../../../hooks'

interface ViewBolepixPdfActionProps {
  account: AccountFormatted
}

export const ViewBolepixPdfAction: React.FC<ViewBolepixPdfActionProps> = ({
  account
}) => {
  const { pagination } = useContext(PaginationContext)
  const [showPdfModal, setShowPdfModal] = useState(false)
  const { bolepixPdf, loading, getBolepixPdf } = useBolepix()

  function handleButtonClick() {
    const { orderId } = account

    if (!orderId) return

    getBolepixPdf(orderId, account.id, () => {
      setShowPdfModal(true)
    })
  }

  const isBolepixReceivable =
    account.paymentType === 'BOLEPIX' && pagination.query.type === 'RECEIVABLE'
  const accountIsPaid = account.isPaid

  return (
    <Show condition={isBolepixReceivable && !accountIsPaid}>
      <Button
        icon="file alternate"
        size="mini"
        popup="Ver bolepix"
        loading={loading}
        onClick={handleButtonClick}
        invertedPopup
        circular
      />
      <PDFViewerModal
        open={showPdfModal}
        handleModal={setShowPdfModal}
        title="Bolepix"
        pdf={bolepixPdf}
      />
    </Show>
  )
}
