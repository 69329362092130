import React, { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Button, Segment } from 'semantic-ui-react'
import { Form as FinalForm } from 'react-final-form'
import { toast } from 'react-toastify'
import { debounce } from 'debounce'

import {
  getCertificate,
  createCertificate,
  updateCertificate
} from '../../services/certificate'
import {
  listServices,
  getService,
  ServiceListResult
} from '../../services/service'
import { listPeople, getPerson, PersonListResult } from '../../services/person'
import { pages } from '../../config/pages'
import { Form, Select, DateTimeInput } from '../../components/Form'
import { SelectOption } from '../../components/Form/Select'
import { FormActionsContainer } from '../../styles/FormActionsContainer'
import {
  CertificateFormatted,
  DEBOUNCE_TIME,
  formatCertificate,
  initialValues,
  personToOptions,
  serviceToOption,
  validateForm
} from './utils'
import { localeDateTimeToDate } from '../../utils/date'

export const CertificateForm: React.FC = () => {
  const [query, setQuery] = useSearchParams()
  const [certificate, setCertificate] = useState<CertificateFormatted>()
  const [loading, setLoading] = useState(false)
  const [serviceOptions, setServiceOptions] = useState<SelectOption[]>([])
  const [loadingServices, setLoadingServices] = useState(false)
  const [customerOptions, setCustomerOptions] = useState<SelectOption[]>([])
  const [loadingCustomers, setLoadingCustomers] = useState(false)

  useEffect(() => {
    const certificateId = query.get('id')

    if (!certificateId) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getCertificate(certificateId))
      .then(data => setCertificate(formatCertificate(data)))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (!certificate) return

    Promise.resolve()
      .then(() => setLoadingServices(true))
      .then(() => getService(certificate.serviceId))
      .then(data => setServiceOptions([serviceToOption(data)]))
      .finally(() => setLoadingServices(false))
  }, [certificate])

  useEffect(() => {
    if (!certificate) return

    Promise.resolve()
      .then(() => setLoadingCustomers(true))
      .then(() => getPerson(certificate.customerId))
      .then(data => setCustomerOptions([personToOptions(data)]))
      .finally(() => setLoadingCustomers(false))
  }, [certificate])

  useEffect(() => {
    const hasIdInQuery = query.has('id')

    if (!!certificate && !hasIdInQuery) {
      setQuery({ ...query, id: certificate.id })
    }
  }, [certificate])

  function handleSubmit(values: CertificateFormatted) {
    const {
      serviceId,
      customerId,
      issueDateFormatted,
      expirationDateFormatted
    } = values

    const issueDate = localeDateTimeToDate(
      issueDateFormatted.replace(',', '')
    ).toISOString()
    const expirationDate = localeDateTimeToDate(
      expirationDateFormatted.replace(',', '')
    ).toISOString()
    const data = {
      serviceId,
      customerId,
      issueDate,
      expirationDate
    }

    if (!certificate) {
      Promise.resolve()
        .then(() => setLoading(true))
        .then(() => createCertificate(data))
        .then(data => setCertificate(formatCertificate(data)))
        .then(() => toast.info('Dados salvos'))
        .finally(() => setLoading(false))

      return
    }

    const diff = certificate.expirationDateFormatted !== expirationDateFormatted

    if (!diff) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => updateCertificate(certificate.id, { expirationDate }))
      .then(data => setCertificate(formatCertificate(data)))
      .then(() => toast.info('Dados salvos'))
      .finally(() => setLoading(false))
  }

  const searchService = debounce((search: string) => {
    const onSuccess = (result: ServiceListResult) => {
      setServiceOptions(result.services.map(serviceToOption))
    }

    Promise.resolve()
      .then(() => setLoadingServices(true))
      .then(() => listServices({ search }))
      .then(data => onSuccess(data))
      .finally(() => setLoadingServices(false))
  }, DEBOUNCE_TIME)

  const searchCustomer = debounce((search: string) => {
    const onSuccess = (result: PersonListResult) => {
      setCustomerOptions(result.people.map(personToOptions))
    }

    Promise.resolve()
      .then(() => setLoadingCustomers(true))
      .then(() => listPeople({ search }))
      .then(data => onSuccess(data))
      .finally(() => setLoadingCustomers(false))
  }, DEBOUNCE_TIME)

  return (
    <>
      <FinalForm
        onSubmit={handleSubmit}
        initialValues={{
          ...initialValues,
          ...certificate
        }}
        validate={validateForm}
        validateOnBlur
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit} loading={loading}>
            <FormActionsContainer>
              <Button
                type="button"
                content="Voltar"
                as={Link}
                to={pages.certificates.path}
              />
              <Button type="submit" content="Salvar" floated="right" primary />
            </FormActionsContainer>
            <Segment>
              <input name="id" hidden />
              <Select
                name="serviceId"
                label="Certificado"
                options={serviceOptions}
                loading={loadingServices}
                onSearchChange={(_, { searchQuery }) =>
                  searchService(searchQuery)
                }
                readOnly={!!certificate}
                search
                required
                uppercase
              />
              <Select
                name="customerId"
                label="Cliente"
                options={customerOptions}
                loading={loadingCustomers}
                onSearchChange={(_, { searchQuery }) =>
                  searchCustomer(searchQuery)
                }
                readOnly={!!certificate}
                search
                required
                uppercase
              />
              <DateTimeInput
                name="issueDateFormatted"
                label="Data de emissão"
                readOnly={!!certificate}
                required
              />
              <DateTimeInput
                name="expirationDateFormatted"
                label="Data de vencimento"
                minDate={new Date()}
                required
              />
            </Segment>
          </Form>
        )}
      </FinalForm>
    </>
  )
}
