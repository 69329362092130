import { useState, useCallback } from 'react'

import {
  Group,
  GroupListResult,
  listGroups
} from '../../../../../services/group'
import { Pagination } from '../../../../../contexts/PaginationContext'

export interface QueryParameters {
  search?: string
}

export function useGroupsList() {
  const [loading, setLoading] = useState(false)
  const [groups, setGroups] = useState<Group[]>([])
  const [totalItems, setTotalItems] = useState(0)

  const fetchGroups = useCallback((pagination: Pagination<QueryParameters>) => {
    const { page, limit, query } = pagination
    const { search } = query || {}

    const onSuccess = (result: GroupListResult) => {
      setGroups(result.groups)
      setTotalItems(result.total)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => listGroups({ page, limit, search }))
      .then(result => onSuccess(result))
      .finally(() => setLoading(false))
  }, [])

  return {
    loading,
    groups,
    totalItems,
    fetchGroups
  }
}
