import React from 'react'
import { FormField, SemanticWIDTHS } from 'semantic-ui-react'
import { useField } from 'react-final-form'

import { useFieldError } from '../../../hooks/useFieldError'

import { DateInput as PureDateInput } from './DateInput'

interface DateInputProps {
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
  width?: SemanticWIDTHS
  fluid?: boolean
  readOnly?: boolean
  minDate?: string | Date
  maxDate?: string | Date
  onChange?: (event: { value: string }) => void
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

export const DateInput: React.FC<DateInputProps> = props => {
  const field = useField(props.name)
  const error = useFieldError(field)

  return <FormField {...props} control={PureDateInput} error={error} />
}
