import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { toast } from 'react-toastify'

import { Show } from '../../../../../../../../../components/Show'
import { useCan } from '../../../../../../../../../hooks/useCan'
import { can } from '../../../../../../../../../config/permissions'

import { useOrder, useOrderCustomer, useOrderAc } from '../../../../../hooks'
import { ConfirmationCheckingModal } from './ConfirmationCheckingModal'

export const CheckVideoconferencingAvailability: React.FC = () => {
  const { order, loading, isCertificateOrder } = useOrder()
  const { customer, responsible } = useOrderCustomer()
  const { checkVideoconferencingAvailability } = useOrderAc()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const canCheck = useCan(can.ac.checkVideoconferencingAvailability)

  function handleCheckButtonClick() {
    setShowConfirmationModal(true)
  }

  function handleConfirmCheck() {
    checkVideoconferencingAvailability(availability => {
      if (availability) {
        toast.info('O cliente está HABILITADO para videoconferência')
      }

      if (!availability) {
        toast.warn('O cliente NÃO está habilitado para videoconferência')
      }

      setShowConfirmationModal(false)
    })
  }

  const orderExist = !!order
  const orderWasClosed = orderExist && order.status !== 'OPEN'
  const orderHasCertificateProtocol = !!order?.certificateIssueProtocol

  return (
    <Show
      condition={
        canCheck &&
        isCertificateOrder &&
        orderWasClosed &&
        !orderHasCertificateProtocol
      }
      component={
        <>
          <Button
            type="button"
            content="Verificar videoconferência"
            floated="right"
            onClick={handleCheckButtonClick}
          />
          <ConfirmationCheckingModal
            open={showConfirmationModal}
            order={order}
            customer={customer}
            responsible={responsible}
            title="Verificar emissão habilitada"
            question="Verificar se a emissão por videoconferência está habilitada para o cliente:"
            loading={loading}
            handleModal={setShowConfirmationModal}
            onConfirm={handleConfirmCheck}
            onDecline={() => setShowConfirmationModal(false)}
          />
        </>
      }
    />
  )
}
