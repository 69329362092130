import React, { useEffect, useState } from 'react'
import { Divider, DropdownOnSearchChangeData } from 'semantic-ui-react'
import { useField } from 'react-final-form'
import { debounce } from 'debounce'

import {
  Form,
  Radio,
  Input,
  Masked,
  Phone,
  Select,
  Checkbox,
  DateInput
} from '../../../../../../../components/Form'
import { Show } from '../../../../../../../components/Show'
import { SelectOption } from '../../../../../../../components/Form/Select'

import {
  partnerToOption,
  responsibleToOption
} from '../../../../../utils/selectOptions'
import { useResponsible, usePartner, usePerson } from '../../../hooks'
import { CnpjInput } from './CnpjInput'

const DEBOUNCE_TIME = 500

export const PersonFields: React.FC = () => {
  const { responsible, partner, setLoading } = usePerson()
  const { people, loading: loadingResponsibles, listPeople } = useResponsible()
  const { partners, loading: loadingPartners, listPartners } = usePartner()
  const typeField = useField('type')
  const activeField = useField('active')
  const [partnerOptions, setPartnerOptions] = useState<SelectOption[]>([])
  const [responsibleOptions, setResponsibleOptions] = useState<SelectOption[]>(
    []
  )

  useEffect(() => {
    if (people.length === 0) return

    setResponsibleOptions(people.map(responsibleToOption))
  }, [people])

  useEffect(() => {
    if (!responsible) return

    setResponsibleOptions([responsibleToOption(responsible)])
  }, [responsible])

  useEffect(() => {
    if (partners.length === 0) return

    setPartnerOptions(partners.map(partnerToOption))
  }, [partners])

  useEffect(() => {
    if (!partner) return

    setPartnerOptions([partnerToOption(partner)])
  }, [partner])

  const searchResponsible = (data: DropdownOnSearchChangeData) => {
    const { searchQuery } = data

    return debounce(() => listPeople(searchQuery), DEBOUNCE_TIME)()
  }

  const searchPartner = (data: DropdownOnSearchChangeData) => {
    const { searchQuery } = data

    return debounce(() => listPartners(searchQuery), DEBOUNCE_TIME)()
  }

  const isCompany = typeField.input.value === 'COMPANY'
  const isIndividual = typeField.input.value === 'INDIVIDUAL'

  return (
    <>
      <Divider horizontal content="Dados" />
      <input name="id" hidden />
      <Form.Group inline>
        <Radio
          name="type"
          label="Pessoa Jurídica"
          value="COMPANY"
          checked={isCompany}
        />
        <Radio
          name="type"
          label="Pessoa Física"
          value="INDIVIDUAL"
          checked={isIndividual}
        />
      </Form.Group>
      <Form.Group>
        <Show condition={isCompany}>
          <CnpjInput
            name="documentFormatted"
            mask="##.###.###/####-##"
            label="CNPJ"
            width={4}
            onLoading={setLoading}
          />
        </Show>
        <Show condition={isIndividual}>
          <Masked
            name="documentFormatted"
            mask="###.###.###-##"
            label="CPF"
            width={4}
          />
        </Show>
        <Input
          name="name"
          label="Nome"
          width={8}
          maxLength={100}
          required
          uppercase
        />
        <DateInput
          name="birthDateFormatted"
          width={4}
          label={isCompany ? 'Data de fundação' : 'Data de nascimento'}
        />
      </Form.Group>
      <Show condition={isCompany}>
        <Input
          name="tradeName"
          label="Nome Comercial"
          maxLength={100}
          uppercase
        />
        <Select
          name="responsibleId"
          label="Responsável"
          options={responsibleOptions}
          loading={loadingResponsibles}
          onSearchChange={(_, data) => searchResponsible(data)}
          search
          uppercase
        />
      </Show>
      <Form.Group>
        <Input name="emailsFormatted" label="E-mail" width={12} lowercase />
        <Phone name="phoneFormatted" label="Telefone" width={4} />
      </Form.Group>
      <Form.Group>
        <Select
          name="partnerId"
          label="Parceiro"
          options={partnerOptions}
          loading={loadingPartners}
          onSearchChange={(_, data) => searchPartner(data)}
          width={12}
          search
          required
          uppercase
        />
        <Checkbox
          name="active"
          label="Ativo"
          checked={activeField.input.value}
          width={4}
          alignField
        />
      </Form.Group>
    </>
  )
}
