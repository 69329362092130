import React from 'react'
import { Table } from 'semantic-ui-react'

import { TableHeaderCell } from '../../../../../components/Table'
import { Pagination } from '../../../../../contexts/PaginationContext'

interface UsersTableHeaderProps {
  pagination: Pagination
  changeOrderBy: (field: string) => void
}

export const UsersTableHeader: React.FC<UsersTableHeaderProps> = ({
  pagination,
  changeOrderBy
}) => {
  return (
    <Table.Header>
      <Table.Row>
        <TableHeaderCell
          content="Nome"
          name="nickname"
          pagination={pagination}
          onClick={() => changeOrderBy('nickname')}
        />
        <TableHeaderCell
          content="Role"
          name="role"
          pagination={pagination}
          onClick={() => changeOrderBy('role')}
        />
        <TableHeaderCell
          content="E-mail"
          name="email"
          pagination={pagination}
          onClick={() => changeOrderBy('email')}
        />
        <Table.HeaderCell content="Status" />
        <Table.HeaderCell content="Celular" />
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
  )
}
