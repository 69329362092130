import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { User } from '../../../../../services/users'
import { PriceList } from '../../../../../services/priceList'
import { Group } from '../../../../../services/group'
import { DefaultLoader } from '../../../../../components/DefaultLoader'
import { pages } from '../../../../../config/pages'

import { UserFormatted, formatUser } from '../../../utils/formatUser'
import { useFetchUser, useFetchPriceList, useFetchGroup } from './hooks'

interface UserFormContextContent {
  user?: UserFormatted
  priceList?: PriceList
  group?: Group
  loading: boolean
  setLoading: (value: boolean) => void
  getUserState: () => UserFormatted
  updateUserState: (user: User) => UserFormatted
}

export const UserFormContext = React.createContext<UserFormContextContent>(
  {} as UserFormContextContent
)

interface UserFormProviderProps {
  children: React.ReactNode
}

export const UserFormProvider: React.FC<UserFormProviderProps> = ({
  children
}) => {
  const [user, setUser] = useState<UserFormatted>()
  const [priceList, setPriceList] = useState<PriceList>()
  const [group, setGroup] = useState<Group>()
  const [loading, setLoading] = useState(false)
  const [query] = useSearchParams()
  const fetchUser = useFetchUser()
  const fetchPriceList = useFetchPriceList()
  const fetchGroup = useFetchGroup()
  const navigate = useNavigate()

  function getUserState(): UserFormatted {
    if (!user) throw new Error('Nenhum usuário selecionado')

    return user
  }

  function updateUserState(data: User): UserFormatted {
    const update = { ...user, ...formatUser(data) }
    setUser(update)

    return update
  }

  function _fetchUser(userId: string) {
    const onFetchUser = (data?: User) => {
      if (!data) {
        navigate(`${pages.users.path}/inclusao`)
        toast.warn('O usuário não encontrado, mas você pode criar um novo.')
        return
      }

      const user = formatUser(data)
      setUser(user)
      return user
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => fetchUser(userId))
      .then(data => onFetchUser(data))
      .then(async user => {
        if (user?.priceListId) {
          const priceList = await fetchPriceList(user.priceListId)
          setPriceList(priceList)
        }

        if (user?.groupId) {
          const group = await fetchGroup(user.groupId)
          setGroup(group)
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const userId = query.get('id')

    if (userId) {
      _fetchUser(userId)
    }
  }, [])

  return (
    <UserFormContext.Provider
      value={{
        user,
        priceList,
        group,
        loading,
        setLoading,
        getUserState,
        updateUserState
      }}
    >
      <DefaultLoader active={loading} />
      {children}
    </UserFormContext.Provider>
  )
}
