import React from 'react'

import { UserFormProvider } from './context'
import { UserMain, Permissions, Partnership } from './components'

export const UserForm: React.FC = () => {
  return (
    <UserFormProvider>
      <UserMain />
      <Permissions />
      <Partnership />
    </UserFormProvider>
  )
}
