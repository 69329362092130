import React from 'react'
import { Segment } from 'semantic-ui-react'

import { Form, Input, Phone } from '../../../../../components/Form'

export const FormFields: React.FC = () => {
  return (
    <Segment>
      <input name="id" hidden />

      <Form.Group widths={3}>
        <Input name="nickname" label="Nome" maxLength={100} required />
        <Input name="email" label="E-mail" max={50} type="email" required />
        <Phone name="phoneFormatted" label="Telefone" required />
      </Form.Group>

      <Form.Group widths={3}>
        <Input name="oldPassword" label="Senha antiga" type="password" />
        <Input name="newPassword" label="Nova senha" type="password" />
        <Input
          name="confirmPassword"
          label="Confirme a senha"
          type="password"
        />
      </Form.Group>
    </Segment>
  )
}
