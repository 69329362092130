import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  z-index: 999;
  bottom: 30px;
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);

  .message {
    .content:nth-child(3) {
      height: 36px;
    }
  }
`
