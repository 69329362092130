import Joi from 'joi'

const messages = {
  name: {
    required: 'Nome é obrigatório',
    max: 'Permitido no máximo 40 caracteres'
  },
  description: {
    max: 'Permitido no máximo 200 caracteres'
  }
}

export const categorySchema = Joi.object({
  id: Joi.string().uuid({ version: ['uuidv4'] }),
  name: Joi.string().max(40).trim().required().messages({
    'any.required': messages.name.required,
    'string.max': messages.name.max
  }),
  description: Joi.string().max(200).trim().optional().messages({
    'string.max': messages.description.max
  })
})
