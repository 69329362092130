import React from 'react'
import { Form as Semantic, CheckboxProps } from 'semantic-ui-react'
import { useForm, useField } from 'react-final-form'

import { useFieldError } from '../../../hooks/useFieldError'

interface RadioProps {
  name: string
  label?: string
  disabled?: boolean
  fluid?: boolean
  value?: any
  checked?: boolean
  onChange?: (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => void
}

export const Radio: React.FC<RadioProps> = ({ name, onChange, ...rest }) => {
  const form = useForm()
  const field = useField(name)
  const error = useFieldError(field, { pointing: 'left' })

  function handleChange(
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) {
    form.change(name, data.value)
    onChange?.(event, data)
  }

  return (
    <Semantic.Radio
      {...rest}
      name={name}
      onChange={handleChange}
      error={error}
    />
  )
}
