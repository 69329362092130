import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { getPriceList, PriceList } from '../../../services/priceList'

import { PriceListSection } from './PriceListSection'
import { PriceListItemsTable } from './PriceListItemsTable'
import {
  formatPriceList,
  PriceListFormatted,
  PriceListItemFormatted
} from './utils'

export const PriceListForm: React.FC = () => {
  const [query, setQuery] = useSearchParams()
  const [priceList, setPriceList] = useState<PriceListFormatted>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const priceListId = query.get('id')

    if (!priceListId) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getPriceList(priceListId))
      .then(data => data && setPriceList(formatPriceList(data)))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    const hasIdInQuery = query.has('id')

    if (!!priceList && !hasIdInQuery) {
      setQuery({ ...query, id: priceList.id })
    }
  }, [priceList])

  function handleSave(data: PriceList) {
    const formatted = formatPriceList({
      ...data,
      items: priceList?.items || []
    })
    setPriceList(formatted)
  }

  function createItemInThePriceList(toAdd: PriceListItemFormatted) {
    if (!priceList) return

    const items = [...priceList.items, toAdd]

    setPriceList({ ...priceList, items })
  }

  function replaceItemInThePriceList(toReplace: PriceListItemFormatted) {
    if (!priceList) return

    const items = priceList.items.map(item => {
      return item.id === toReplace.id ? toReplace : item
    })

    setPriceList({ ...priceList, items })
  }

  function removeItemFromPriceList(toBeRemoved: PriceListItemFormatted) {
    if (!priceList) return

    const items = priceList.items.filter(item => {
      return item.id !== toBeRemoved.id
    })

    setPriceList({ ...priceList, items })
  }

  return (
    <>
      <PriceListSection
        loading={loading}
        priceList={priceList}
        onSave={handleSave}
      />
      {priceList && (
        <PriceListItemsTable
          priceList={priceList}
          onCreateItem={createItemInThePriceList}
          onUpdateItem={replaceItemInThePriceList}
          onDeleteItem={removeItemFromPriceList}
        />
      )}
    </>
  )
}
