import React, { useState, PropsWithChildren } from 'react'

import { Wrapper, Scrollable, Container } from './styles'
import { Navbar } from './Navbar'
import { Sidebar } from './Sidebar'

export const Template: React.FC<PropsWithChildren> = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false)

  function handleSandwichClick() {
    setShowSidebar(!showSidebar)
  }

  return (
    <Wrapper>
      <Navbar onSandwichClick={handleSandwichClick} />
      <Sidebar show={showSidebar} onHide={() => setShowSidebar(false)}>
        <Scrollable>
          <Container>{children}</Container>
        </Scrollable>
      </Sidebar>
    </Wrapper>
  )
}
