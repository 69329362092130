import React, { useContext } from 'react'
import { Table } from 'semantic-ui-react'

import { TableHeaderCell } from '../../../../../../../components/Table'
import { PaginationContext } from '../../../../../../../contexts/PaginationContext'
import { invertOrder } from '../../../../../../../utils/list'
import { Show } from '../../../../../../../components/Show'

export const PayablesTableHeader: React.FC = () => {
  const { pagination, changeQuery } = useContext(PaginationContext)

  function handleChangeOrderBy(orderBy: string) {
    const order = invertOrder(pagination.query.order)
    changeQuery({ ...pagination.query, orderBy, order })
  }

  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell content="Fornecedor" />
        <Table.HeaderCell content="Grupo" />
        <TableHeaderCell
          content="Descrição"
          name="description"
          pagination={pagination}
          onClick={() => handleChangeOrderBy('description')}
        />
        <Table.HeaderCell content="Pagamento" />
        <TableHeaderCell
          content="Vencimento"
          name="dueDate"
          pagination={pagination}
          onClick={() => handleChangeOrderBy('dueDate')}
        />
        <Table.HeaderCell content="Valor" />
        <Show condition={pagination.query.status === 'PAID'}>
          <Table.HeaderCell content="Pago" />
          <TableHeaderCell
            content="Data do pagamento"
            name="paymentDate"
            pagination={pagination}
            onClick={() => handleChangeOrderBy('paymentDate')}
          />
        </Show>
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
  )
}
