import styled, { css } from 'styled-components'

export const Permissions = styled.div``

const itemCss = css`
  width: 17%;
  display: inline-block;
`

export const PermissionGroupStyle = styled.div`
  margin-top: 8px;

  span {
    ${itemCss}
    font-weight: 700;
  }

  .checkbox {
    width: 17%;
  }
`

export const BlankOption = styled.div`
  ${itemCss}
`
