import React from 'react'
import { Modal, Button } from 'semantic-ui-react'
import { Form as FinalForm } from 'react-final-form'

import { APIKey } from '../../../../../../../services/apiKey'
import { getFormValidate } from '../../../../../../../utils/form'
import { Form, Input } from '../../../../../../../components/Form'

import { useApiKeys } from '../../../hooks/useApiKey'
import { apiKeyUpdateSchema } from './schemas'

interface UpdateApiKeyModalProps {
  open: boolean
  apiKey?: APIKey
  handleModal: (open: boolean) => void
}

const validateForm = getFormValidate(apiKeyUpdateSchema)

export const UpdateApiKeyModal: React.FC<UpdateApiKeyModalProps> = ({
  open,
  apiKey,
  handleModal
}) => {
  const { loading, updateApiKeyIps } = useApiKeys()

  function handleSubmit(values: APIKey) {
    const { ips = '' } = values

    if (!apiKey) return

    updateApiKeyIps(apiKey.id, ips, () => {
      handleModal(false)
    })
  }

  return (
    <Modal
      open={open}
      onOpen={() => handleModal(true)}
      onClose={() => handleModal(false)}
      closeOnDimmerClick={false}
      size="mini"
      closeIcon
    >
      <Modal.Header content="Atualizar chave" />
      <Modal.Content>
        <FinalForm
          onSubmit={handleSubmit}
          initialValues={{ ...apiKey, api: 'LICENSING' }}
          validate={validateForm}
          validateOnBlur
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} loading={loading}>
              <input name="id" hidden />
              <Input name="ips" label="Endereços IP" maxLength={60} />
              <Button content="Salvar" type="submit" fluid primary />
            </Form>
          )}
        </FinalForm>
      </Modal.Content>
    </Modal>
  )
}
