import api from './api'

export interface Service {
  id: string
  code?: string
  description: string
  serviceListCode?: number
}

export interface ServicePaginate {
  page?: number
  limit?: number
  orderBy?: string
  order?: 'ASC' | 'DESC'
  search?: string
}

export interface ServiceListResult {
  services: Service[]
  total: number
}

export async function getService(id: string): Promise<Service> {
  const response = await api.get(`/services/${id}`)
  const service = response.status === 200 ? response.data : undefined

  return service
}

export async function listServices(
  paginate?: ServicePaginate
): Promise<ServiceListResult> {
  const { page, limit, orderBy, order, search } = paginate || {}
  const response = await api.get(`/services`, {
    params: { page, limit, orderBy, order, search }
  })
  const { items: services, total } = response.data

  return {
    services,
    total
  }
}

export async function createService(data: Partial<Service>): Promise<Service> {
  const response = await api.post('/services', data)
  const service = response.data

  return service
}

export async function updateService(
  id: string,
  data: Partial<Service>
): Promise<Service> {
  const response = await api.put(`/services/${id}`, data)
  const service = response.data

  return service
}

export async function deleteService(id: string): Promise<boolean> {
  const response = await api.delete(`/services/${id}`)
  const result = response.data

  return result.status
}
