import { useState } from 'react'
import { toast } from 'react-toastify'

import { deleteProduct as apiDeleteProduct } from '../../../../../services/product'

export function useProduct() {
  const [loading, setLoading] = useState(false)

  function deleteProduct(id: string, onSuccess?: () => void) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiDeleteProduct(id))
      .then(() => toast.info('O produto foi excluído'))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  return {
    loading,
    deleteProduct
  }
}
