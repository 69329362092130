import React from 'react'
import { Modal } from 'semantic-ui-react'

import { FindProductOrService } from './FindProductOrService'

interface AddItemModalProps {
  open: boolean
  handleModal: (open: boolean) => void
}

export const AddItemModal: React.FC<AddItemModalProps> = ({
  open,
  handleModal
}) => {
  return (
    <Modal
      open={open}
      size="tiny"
      closeOnDimmerClick={false}
      onClose={() => handleModal(false)}
      closeIcon
    >
      <Modal.Header content="Adicionar item" />
      <Modal.Content>
        <FindProductOrService />
      </Modal.Content>
    </Modal>
  )
}
