import React from 'react'
import { Dropdown, Table } from 'semantic-ui-react'

import { PaymentType } from '../../../../../../../../../../services/account'
import { DateInput } from '../../../../../../../../../../components/Form/pure'
import { localeDateToDate } from '../../../../../../../../../../utils/date'

import { ChargeFormatted } from '../../../../../../../../utils/formatCharge'
import { useOrderCharge } from '../../../../../../hooks'
import { chargeTypeOptions } from '../../../../utils/charge'

interface EditableCellsProps {
  charge: ChargeFormatted
}

export const EditableCells: React.FC<EditableCellsProps> = ({ charge }) => {
  const { modifyCharge } = useOrderCharge()

  function handleChargeTypeChange(paymentType: PaymentType) {
    modifyCharge(charge.id, { paymentType })
  }

  function handleChargeDueDateBlur(value: string) {
    const dueDate = localeDateToDate(value).toISOString()
    modifyCharge(charge.id, { dueDate })
  }

  return (
    <>
      <Table.Cell>
        <Dropdown
          value={charge.paymentType}
          options={chargeTypeOptions}
          onChange={(_, { value }) =>
            handleChargeTypeChange(value as PaymentType)
          }
        />
      </Table.Cell>
      <Table.Cell>
        <DateInput
          value={charge.dueDateFormatted}
          onBlur={(_, { value }) => handleChargeDueDateBlur(value)}
        />
      </Table.Cell>
    </>
  )
}
