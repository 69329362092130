import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { pages } from '../config/pages'
import { can } from '../config/permissions'

import { AuthProvider } from '../contexts/AuthContext'
import { Test } from '../pages/Test'
import { Login } from '../pages/Login'
import { Home } from '../pages/Home'
import { NotFound } from '../pages/NotFound'
import { Users } from '../pages/Users'
import { People } from '../pages/People'
import { Systems } from '../pages/Systems'
import { Licenses } from '../pages/Licenses'
import { Files } from '../pages/Files'
import { Services } from '../pages/Services'
import { Products } from '../pages/Products'
import { PriceLists } from '../pages/PriceLists'
import { Orders } from '../pages/Orders'
import { Certificates } from '../pages/Certificates'
import { ARLogs } from '../pages/ARLogs'
import { Accounts } from '../pages/Accounts'
import { Commission } from '../pages/Commission'
import { Cost } from '../pages/Cost'
import { Signatures } from '../pages/Signatures'
import { Categories } from '../pages/Categories'
import { CobPixQrCode } from '../pages/CobPixQrCode'
import { Groups } from '../pages/Groups'
import { BolepixPdf } from '../pages/BolepixPdf'

import { Private } from './Private'

function MyRoutes(): React.ReactElement {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/** Publics */}
          <Route path={pages.login.path} element={<Login />} />
          <Route path={pages.cobPixQrCode.path} element={<CobPixQrCode />} />
          <Route path={pages.bolepixPdf.path} element={<BolepixPdf />} />

          {/** Privates */}
          <Route
            path={pages.home.path}
            element={<Private component={Home} />}
          />
          <Route
            path={pages.services.path + '/*'}
            element={
              <Private component={Services} permissions={can.service.read} />
            }
          />
          <Route
            path={pages.products.path + '/*'}
            element={
              <Private component={Products} permissions={can.product.read} />
            }
          />
          <Route
            path={pages.priceLists.path}
            element={
              <Private
                component={PriceLists}
                permissions={can.priceList.read}
              />
            }
          />
          <Route
            path={`${pages.priceLists.path}/:action`}
            element={
              <Private
                component={PriceLists}
                permissions={can.priceList.modify}
              />
            }
          />
          <Route
            path={pages.people.path + '/*'}
            element={
              <Private component={People} permissions={can.person.read} />
            }
          />
          <Route
            path={pages.orders.path + '/*'}
            element={
              <Private component={Orders} permissions={can.order.read} />
            }
          />
          <Route
            path={pages.certificates.path + '/*'}
            element={
              <Private
                component={Certificates}
                permissions={can.certificate.read}
              />
            }
          />
          <Route
            path={pages.accounts.path + '/*'}
            element={
              <Private component={Accounts} permissions={can.account.read} />
            }
          />
          <Route
            path={`${pages.commission.path}`}
            element={<Private component={Commission} />}
          />
          <Route
            path={`${pages.cost.path}`}
            element={<Private component={Cost} />}
          />
          <Route
            path={pages.signatures.path + '/*'}
            element={
              <Private
                component={Signatures}
                permissions={can.signature.read}
              />
            }
          />
          <Route
            path={pages.systems.path}
            element={
              <Private component={Systems} permissions={can.system.read} />
            }
          />
          <Route
            path={`${pages.systems.path}/:action`}
            element={
              <Private component={Systems} permissions={can.system.modify} />
            }
          />
          <Route
            path={pages.files.path}
            element={
              <Private component={Files} permissions={can.directory.read} />
            }
          />
          <Route
            path={`${pages.files.path}/:action`}
            element={
              <Private component={Files} permissions={can.directory.create} />
            }
          />
          <Route
            path={pages.licenses.path}
            element={
              <Private component={Licenses} permissions={can.license.read} />
            }
          />
          <Route
            path={pages.categories.path + '/*'}
            element={
              <Private component={Categories} permissions={can.category.read} />
            }
          />
          <Route
            path={pages.groups.path + '/*'}
            element={
              <Private component={Groups} permissions={can.group.read} />
            }
          />
          <Route
            path={pages.users.path + '/*'}
            element={<Private component={Users} permissions={can.user.read} />}
          />
          <Route
            path="/ar-logs"
            element={
              <Private component={ARLogs} permissions={can.arLog.read} />
            }
          />
          {process.env.NODE_ENV === 'development' && (
            <Route path="/teste" element={<Private component={Test} />} />
          )}
          <Route path="*" element={<Private component={NotFound} />} />
        </Routes>
      </AuthProvider>
    </Router>
  )
}

export { MyRoutes as Routes }
