import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'
import copy from 'copy-to-clipboard'
import { toast } from 'react-toastify'

import { APIKey } from '../../../../../../../services/apiKey'
import { Button } from '../../../../../../../components/Button'
import { ItemControllers } from '../../../../../../../components/Table'
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal'
import { dateToLocaleDateTime } from '../../../../../../../utils/date'
import { handleOptionalCall } from '../../../../../../../utils/function'
import { can } from '../../../../../../../config/permissions'

import { useApiKeys } from '../../../hooks/useApiKey'
import { UpdateApiKeyModal } from './UpdateApiKeyModal'

const api = {
  UPDATER: 'Atualizador',
  LICENSING: 'Licenciamento'
}

interface ApiKeysTableRowProps {
  apiKey: APIKey
}

export const ApiKeysTableRow: React.FC<ApiKeysTableRowProps> = ({ apiKey }) => {
  const { loading, deleteApiKey } = useApiKeys()
  const [updateModal, setUpdateModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedApiKey, setSelectedApiKey] = useState<APIKey>()

  function handleCopyClick(key: string) {
    copy(key)
    toast.info('Chave copiada')
  }

  function handleUpdateClick(apiKey: APIKey) {
    setSelectedApiKey(apiKey)
    setUpdateModal(true)
  }

  function handleDeleteClick(apiKey: APIKey) {
    setSelectedApiKey(apiKey)
    setDeleteModal(true)
  }

  function handleConfirmDelete() {
    if (!selectedApiKey) return

    deleteApiKey(selectedApiKey.id)
  }

  return (
    <>
      <Table.Row key={apiKey.id}>
        <Table.Cell content={apiKey.description} />
        <Table.Cell content={api[apiKey.api]} />
        <Table.Cell>
          <Button
            icon="copy"
            size="tiny"
            onClick={() => handleCopyClick(apiKey.key)}
            circular
          />
        </Table.Cell>
        <Table.Cell content={dateToLocaleDateTime(apiKey.createdAt)} />
        <Table.Cell
          content={handleOptionalCall(dateToLocaleDateTime, apiKey.expiresIn)}
        />
        <Table.Cell content={apiKey.ips} />
        <Table.Cell textAlign="right">
          <ItemControllers
            onUpdateClick={() => handleUpdateClick(apiKey)}
            onDeleteClick={() => handleDeleteClick(apiKey)}
            permissionsToUpdate={can.apiKey.update}
            permissionsToDelete={can.apiKey.delete}
          />
        </Table.Cell>
      </Table.Row>

      <UpdateApiKeyModal
        open={updateModal}
        apiKey={selectedApiKey}
        handleModal={setUpdateModal}
      />

      <ConfirmationModal
        open={deleteModal}
        loading={loading}
        handleModal={setDeleteModal}
        onConfirm={handleConfirmDelete}
        onDecline={() => setDeleteModal(false)}
      >
        <span>
          Ao excluir uma chave você poderá perder o acesso a API. Tem certeza
          que deseja excluir?
        </span>
        <br />
        <strong>{selectedApiKey?.description}</strong>
      </ConfirmationModal>
    </>
  )
}
