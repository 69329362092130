import React, { useState } from 'react'

import { Show } from '../../../../../../../../../components/Show'
import { Button } from '../../../../../../../../../components/Button'
import { can } from '../../../../../../../../../config/permissions'

import {
  AccountFormatted,
  formatAccount
} from '../../../../../../../utils/formatAccount'
import { Account } from '../../../../../../../../../services/account'
import { WriteOffModal } from './WriteOffModal'

interface WriteOffActionProps {
  account: AccountFormatted
  onWriteOff: (account: AccountFormatted) => void
}

export const WriteOffAction: React.FC<WriteOffActionProps> = ({
  account,
  onWriteOff
}) => {
  const [showWriteOffModal, setShowWriteOffModal] = useState(false)

  function handleWriteOffButtonClick() {
    setShowWriteOffModal(true)
  }

  function handleConfirmWriteOff(data: Account) {
    setShowWriteOffModal(false)
    onWriteOff(formatAccount({ ...account, ...data }))
  }

  const isManualAccounts = account.origin === 'MANUAL'
  const accountIsPaid = account.isPaid

  return (
    <Show condition={isManualAccounts && !accountIsPaid}>
      <Button
        icon="arrow down"
        size="mini"
        onClick={handleWriteOffButtonClick}
        can={can.account.writeOff}
        invertedPopup
        circular
      />
      <WriteOffModal
        open={showWriteOffModal}
        account={account}
        handleModal={setShowWriteOffModal}
        onSave={handleConfirmWriteOff}
      />
    </Show>
  )
}
