import React from 'react'

import { ConfirmationModal } from '../../../../../components/ConfirmationModal'

import { SignatureFormatted } from '../../../utils/formatSignature'

interface DeleteSignatureModalProps {
  open: boolean
  signature?: SignatureFormatted
  loading: boolean
  handleModal: (open: boolean) => void
  onConfirm: (signature: SignatureFormatted) => void
}

export const DeleteSignatureModal: React.FC<DeleteSignatureModalProps> = ({
  open,
  signature,
  loading,
  handleModal,
  onConfirm
}) => {
  function handleConfirm() {
    if (!signature) return
    onConfirm(signature)
  }

  return (
    <ConfirmationModal
      open={open}
      loading={loading}
      handleModal={handleModal}
      onConfirm={handleConfirm}
      onDecline={() => handleModal(false)}
      confirmationCode
    >
      <span>Deseja excluir esta assinatura?</span>
      <br />
      {signature ? (
        <strong>
          {signature.customer.name}
          <br />
          {signature.description}
          <br />
          {signature.valueFormatted}
        </strong>
      ) : null}
    </ConfirmationModal>
  )
}
