import React, { useContext } from 'react'
import { Table } from 'semantic-ui-react'

import { PaginationContext } from '../../../../../../../contexts/PaginationContext'
import { PaginationControllers } from '../../../../../../../components/PaginationControllers'

interface PayablesTableFooterProps {
  colSpan: number
  count: number
  totalItems: number
  loading?: boolean
}

export const PayablesTableFooter: React.FC<PayablesTableFooterProps> = ({
  colSpan,
  count,
  totalItems,
  loading
}) => {
  const {
    pagination,
    goToFirstPage,
    goToPreviousPage,
    goToNextPage,
    goToLastPage,
    changeLimit
  } = useContext(PaginationContext)

  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell textAlign="right" colSpan={colSpan}>
          <PaginationControllers
            count={count}
            page={pagination.page}
            limit={pagination.limit}
            total={totalItems}
            loading={loading}
            goToFirstPage={goToFirstPage}
            goToPreviousPage={goToPreviousPage}
            goToNextPage={goToNextPage}
            goToLastPage={() => goToLastPage(totalItems)}
            onChangeLimit={changeLimit}
          />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  )
}
