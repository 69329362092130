import api from './api'
import { Person } from './person'
import { Service } from './service'

export interface Certificate {
  id: string
  serviceId: string
  customerId: string
  issueDate: string
  expirationDate: string
  service: Service
  customer: Person
}

export interface CertificatePaginate {
  page?: number
  limit?: number
  search?: string
}

export interface CertificateListResult {
  certificates: Certificate[]
  total: number
}

export async function getCertificate(id: string): Promise<Certificate> {
  const response = await api.get(`/certificates/${id}`)
  const certificate = response.data

  return certificate
}

export async function listCertificate(
  paginate?: CertificatePaginate
): Promise<CertificateListResult> {
  const { page, limit, search } = paginate || {}
  const response = await api.get(`/certificates`, {
    params: { page, limit, search }
  })
  const { items: certificates, total } = response.data

  return {
    certificates,
    total
  }
}

export async function createCertificate(
  data: Partial<Certificate>
): Promise<Certificate> {
  const response = await api.post(`/certificates`, data)
  const certificate = response.data

  return certificate
}

export async function updateCertificate(
  id: string,
  data: Partial<Certificate>
): Promise<Certificate> {
  const response = await api.put(`/certificates/${id}`, data)
  const certificate = response.data

  return certificate
}

export async function deleteCertificate(id: string): Promise<boolean> {
  const response = await api.delete(`/certificates/${id}`)

  return response.data.status
}
