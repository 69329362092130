import React, { useState, useEffect } from 'react'
import { Modal } from 'semantic-ui-react'

import {
  CobPixInfo,
  CobPixInfoFormatted,
  formatCobPix
} from '../../../../../../../../../../services/cobPix'
import { Button } from '../../../../../../../../../../components/Button'
import { PixQrCodeViewer } from '../../../../../../../../../../components/PixQrCodeViewer'

interface CobPixQrCodeModalProps {
  open: boolean
  cobPix?: CobPixInfo
  handleModal: (open: boolean) => void
}

export const CobPixQrCodeModal: React.FC<CobPixQrCodeModalProps> = ({
  open,
  cobPix,
  handleModal
}) => {
  const [cobPixFormatted, setCobPixFormatted] = useState<CobPixInfoFormatted>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!cobPix) return

    Promise.resolve()
      .then(() => formatCobPix(cobPix))
      .then(data => setCobPixFormatted(data))
      .finally(() => setLoading(false))
  }, [cobPix])

  return (
    <Modal
      open={open}
      onClose={() => handleModal(false)}
      closeOnDimmerClick={false}
      size="small"
      closeIcon
    >
      <Modal.Header content="Cobrança Pix" />
      <Modal.Content>
        <PixQrCodeViewer data={cobPixFormatted} loading={loading} noBorder />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Fechar" onClick={() => handleModal(false)} />
      </Modal.Actions>
    </Modal>
  )
}
