import { Order, OrderStatus } from '../../../services/order'
import { dateToLocaleDateTime } from '../../../utils/date'
import { numberToCurrency } from '../../../utils/number'

import { OrderItemFormatted, formatOrderItem } from './formatOrderItem'
import { ChargeFormatted, formatCharge } from './formatCharge'

export function mapOrderStatus(status: OrderStatus): string {
  const statusMap = {
    OPEN: 'Aberto',
    CLOSED: 'Fechado',
    APPROVED: 'Aprovado',
    ISSUED: 'Emitido',
    CONCLUDED: 'Concluído'
  }

  return status ? statusMap[status] : 'Não definido'
}

function calculateTotalOfCharge(order: Order): number {
  return order.charges
    ?.map(charge => charge.amount)
    .reduce((acc, cur) => acc + cur, 0)
}

export interface OrderFormatted extends Omit<Order, 'items' | 'charges'> {
  statusFormatted: string
  issueDateFormatted: string
  statusDateFormatted: string
  totalOrderFormatted: string
  totalItems?: number
  totalCommission: number
  totalOfCharges: number
  totalOfChargesFormatted: string
  profit: number
  certificateIssueProtocolFormatted?: string
  videoConferenceUrl?: string
  items: OrderItemFormatted[]
  charges: ChargeFormatted[]
}

export function formatOrder(order: Order): OrderFormatted {
  const { status, issueDate, statusDate, certificateIssueProtocol } = order
  const totalItems = order.items
    ?.map(item => item.price * item.quantity)
    .reduce((acc, price) => acc + price, 0)
  const totalCommission = order.employeeCommission + order.partnerCommission
  const totalOfCharges = calculateTotalOfCharge(order)
  const profit =
    order.totalOrder - order.totalCost - totalCommission - order.tax
  const videoConferenceUrl = certificateIssueProtocol
    ? `https://scontti.syngularid.com.br/videoconferencia?protocolo=${certificateIssueProtocol}`
    : undefined

  return {
    ...order,
    statusFormatted: mapOrderStatus(status),
    issueDateFormatted: dateToLocaleDateTime(issueDate),
    statusDateFormatted: dateToLocaleDateTime(statusDate),
    totalOrderFormatted: numberToCurrency(order.totalOrder),
    totalItems,
    totalCommission,
    totalOfCharges,
    totalOfChargesFormatted: numberToCurrency(totalOfCharges),
    profit,
    certificateIssueProtocolFormatted:
      order.certificateIssueProtocol?.toUpperCase(),
    videoConferenceUrl,
    items: order.items?.map(formatOrderItem) || [],
    charges: order.charges?.map(formatCharge) || []
  }
}
