import { AxiosProgressEvent } from 'axios'
import { Buffer } from 'buffer'

import api from './api'

export interface Pix {
  id: string
  payerId: string
  dueDate: string
  value: number
  proof?: string
  createdAt: string
}

export async function issuePix(
  orderId: string,
  chargeId: string,
  data: Partial<Pix>
): Promise<Pix> {
  const response = await api.post(
    `/orders/${orderId}/charges/${chargeId}/pix`,
    data
  )
  const pix = response.data

  return pix
}

export async function cancelPix(
  orderId: string,
  chargeId: string
): Promise<Pix> {
  const response = await api.delete(
    `/orders/${orderId}/charges/${chargeId}/pix`
  )
  const pix = response.data

  return pix
}

export type OnUploadProgress = (progressEvent: AxiosProgressEvent) => void

export async function addProof(
  orderId: string,
  chargeId: string,
  data: FormData,
  onUploadProgress: OnUploadProgress
): Promise<Pix> {
  const response = await api.post(
    `/orders/${orderId}/charges/${chargeId}/pix/proof`,
    data,
    { onUploadProgress }
  )
  const pix = response.data

  return pix
}

export interface GetProofOutput {
  content: Buffer
  mimetype: string
}

export async function getProof(
  orderId: string,
  chargeId: string
): Promise<GetProofOutput> {
  const response = await api.get(
    `/orders/${orderId}/charges/${chargeId}/pix/proof`,
    { responseType: 'arraybuffer', timeout: 5000 }
  )

  return {
    content: Buffer.from(response.data, 'binary'),
    mimetype: response.headers['content-type'] as string
  }
}
