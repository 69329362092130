import React, { useEffect, useState } from 'react'
import { Input, InputOnChangeData, SemanticWIDTHS } from 'semantic-ui-react'

import { formatValue } from './utils'

interface CurrencyProps {
  name?: string
  id?: string | number
  label?: string
  required?: boolean
  disabled?: boolean
  width?: SemanticWIDTHS
  fluid?: boolean
  readOnly?: boolean
  thousandSeparator?: string
  decimalSeparator?: string
  precision?: number
  prefix?: string
  error?: any
  control?: any
  value?: any
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Currency: React.FC<CurrencyProps> = ({
  thousandSeparator,
  decimalSeparator,
  precision,
  prefix,
  value: defaultValue,
  onChange,
  ...rest
}) => {
  const [value, setValue] = useState('')

  function toCurrency(value: string) {
    return formatValue(value, {
      thousandSeparator,
      decimalSeparator,
      precision,
      prefix
    })
  }

  useEffect(() => setValue(toCurrency(defaultValue)), [defaultValue])

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    setValue(toCurrency(data.value))
    onChange?.(
      {
        ...event,
        target: { ...event.target, value: toCurrency(event.target.value) }
      },
      { ...data, value: toCurrency(data.value) }
    )
  }

  return <Input {...rest} value={value} onChange={handleChange} />
}
