import { useState, useCallback } from 'react'

import {
  Product,
  ProductListResult,
  listProducts
} from '../../../../../services/product'
import { Pagination } from '../../../../../contexts/PaginationContext'

export interface QueryParameters {
  search?: string
}

export function useProductsList() {
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<Product[]>([])
  const [totalItems, setTotalItems] = useState(0)

  const fetchProducts = useCallback(
    (pagination: Pagination<QueryParameters>) => {
      const { page, limit, orderBy, order, query } = pagination
      const { search } = query || {}

      const onSuccess = (result: ProductListResult) => {
        setProducts(result.products)
        setTotalItems(result.total)
      }

      Promise.resolve()
        .then(() => setLoading(true))
        .then(() => listProducts({ page, limit, orderBy, order, search }))
        .then(result => onSuccess(result))
        .finally(() => setLoading(false))
    },
    []
  )

  return {
    loading,
    products,
    totalItems,
    fetchProducts
  }
}
