import React, { useContext } from 'react'

import { Bolepix } from '../../../../../../../../../services/bolepix'
import { can } from '../../../../../../../../../config/permissions'
import { PaginationContext } from '../../../../../../../../../contexts/PaginationContext'
import { Button } from '../../../../../../../../../components/Button'
import { Show } from '../../../../../../../../../components/Show'
import { numberToCurrency } from '../../../../../../../../../utils/number'
import { dateToLocaleDate } from '../../../../../../../../../utils/date'

import { AccountFormatted } from '../../../../../../../utils/formatAccount'
import { useBolepix } from '../../../../../hooks'

interface BolepixRefreshActionProps {
  account: AccountFormatted
  onRefresh: (account: AccountFormatted) => void
}

export const BolepixRefreshAction: React.FC<BolepixRefreshActionProps> = ({
  account,
  onRefresh
}) => {
  const { pagination } = useContext(PaginationContext)
  const { loading, refreshBolepix } = useBolepix()

  function updateAccountList(bolepix: Bolepix) {
    const { statusDate: paymentDate, paidValue: amountPaid } = bolepix
    const update = {
      ...account,
      paymentDate,
      amountPaid,
      paymentDateFormatted: dateToLocaleDate(paymentDate),
      amountPaidFormatted: numberToCurrency(amountPaid),
      bolepix
    }
    onRefresh(update)
  }

  function handleButtonClick() {
    const { orderId } = account

    if (!orderId) return

    refreshBolepix(orderId, account.id, bolepix => {
      updateAccountList(bolepix)
    })
  }

  const isBolepixReceivable =
    account.paymentType === 'BOLEPIX' && pagination.query.type === 'RECEIVABLE'
  const accountIsPaid = account.isPaid

  return (
    <Show condition={isBolepixReceivable && !accountIsPaid}>
      <Button
        icon="refresh"
        size="mini"
        disabled={loading}
        onClick={handleButtonClick}
        loading={loading}
        can={can.bolepix.refresh}
        popup="Atualizar Bolepix"
        invertedPopup
        circular
      />
    </Show>
  )
}
