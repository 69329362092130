import { SelectOption } from '../../../components/Form/Select'
import { Person } from '../../../services/person'
import { User } from '../../../services/users'

export function responsibleToOption(responsible: Person): SelectOption {
  return {
    key: responsible.id,
    value: responsible.id,
    text: `${responsible.name} - ${responsible.document || ''}`
  }
}

export function partnerToOption(partner: User): SelectOption {
  return {
    key: partner.id,
    value: partner.id,
    text: `${partner.nickname}`
  }
}
