import React, { useState, useEffect } from 'react'
import {
  Button,
  List,
  Modal,
  Progress,
  SemanticCOLORS
} from 'semantic-ui-react'
import { AxiosProgressEvent } from 'axios'
import Dropzone from 'react-dropzone'
import { filesize } from 'filesize'
import { nanoid } from 'nanoid'
import { toast } from 'react-toastify'

import { ButtonLink } from '../../components/ButtonLink'
import { DropContainer, LocalFile, DroppedFile } from '../../components/Upload'
import { uploadFile } from '../../services/file'
import { calculateUpdateProgress } from '../../utils/upload'

export interface HandleModalInfo {
  complete: boolean
}

interface UploadModalProps {
  open: boolean
  systemId?: string
  directoryId?: string
  handleModal: (value: boolean, info: HandleModalInfo) => void
  onFinish?: () => void
}

const progressColors: Record<string, SemanticCOLORS> = {
  waiting: 'blue',
  loading: 'blue',
  error: 'red',
  complete: 'green'
}

export const UploadModal: React.FC<UploadModalProps> = ({
  open,
  systemId,
  directoryId,
  handleModal,
  onFinish
}: UploadModalProps) => {
  const [files, setFiles] = useState<LocalFile[]>([])
  const [uploading, setUploading] = useState(false)
  // const [directoryId, setDirectoryId] = useState<string>()
  const [complete, setComplete] = useState(false)

  // useEffect(() => setDirectoryId(fileDirectoryId), [fileDirectoryId])

  useEffect(() => {
    setFiles([])
    setComplete(false)
  }, [open])

  useEffect(() => {
    const someInProgress = files.some(file => {
      return file.status === 'loading' || file.status === 'waiting'
    })

    if (!someInProgress && files.length) {
      setUploading(false)
      setComplete(true)
      onFinish?.()
    }
  }, [files])

  function handleDropFile(files: DroppedFile[]) {
    setFiles(
      files.map(file => {
        return {
          file,
          name: file.name as string,
          size: filesize(file.size),
          progress: 0,
          status: 'waiting',
          key: nanoid()
        } as LocalFile
      })
    )
  }

  function handleRemoveClick(localFile: LocalFile) {
    setFiles(files.filter(file => file.key !== localFile.key))
  }

  function setFileStatus(key: string, data: Partial<LocalFile>) {
    setFiles(
      files.map(file => {
        return file.key === key ? Object.assign(file, data) : file
      })
    )
  }

  function sendFile(localFile: LocalFile) {
    if (!directoryId || !systemId) return

    const data = new FormData()
    const modificationDate = new Date(localFile.file.lastModified).toISOString()

    data.append('file', localFile.file, localFile.name)
    data.append('modificationDate', modificationDate)
    data.append('type', 'T')

    setFileStatus(localFile.key, { status: 'loading' })

    const onProgress = (event: AxiosProgressEvent) => {
      const progress = calculateUpdateProgress(event)
      setFileStatus(localFile.key, { progress })
    }

    const onSuccess = () => {
      setFileStatus(localFile.key, { status: 'complete', progress: 100 })
    }

    const onFail = (err: Error) => {
      toast.error(`${localFile.name}: ${err.message}`)
      setFileStatus(localFile.key, { status: 'error' })
    }

    uploadFile(systemId, directoryId, data, onProgress)
      .then(onSuccess)
      .catch(onFail)
  }

  function handleSubmit() {
    setUploading(true)
    files.forEach(sendFile)
  }

  return (
    <Modal
      open={open}
      size="mini"
      closeOnDimmerClick={false}
      onOpen={() => handleModal(true, { complete })}
      onClose={() => handleModal(false, { complete })}
      closeIcon
    >
      <Modal.Header content="Enviar arquivos" />
      <Modal.Content scrolling>
        <Dropzone onDropAccepted={handleDropFile}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <DropContainer {...getRootProps()} active={isDragActive}>
              <input {...getInputProps()} hidden />
              <span>Solte os arquivos aqui</span>
            </DropContainer>
          )}
        </Dropzone>
        <List>
          {files.map(file => (
            <List.Item key={file.key}>
              <List.Content floated="right">
                <ButtonLink
                  content="Remover"
                  disabled={file.status !== 'waiting'}
                  onClick={() => handleRemoveClick(file)}
                />
              </List.Content>
              <List.Content>
                <List.Header content={file.name} />
                <List.Header content={file.size} />
                <Progress
                  size="tiny"
                  color={progressColors[file.status]}
                  percent={file.progress}
                />
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cancelar"
          disabled={uploading}
          onClick={() => handleModal(false, { complete })}
        />
        <Button
          content="Enviar"
          disabled={uploading || complete}
          onClick={handleSubmit}
          primary
        />
      </Modal.Actions>
    </Modal>
  )
}
