import React from 'react'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'

import { PermissionGroup } from './PermissionGroup'
import { OnCheck } from './AllPermissions'
import { BlankOption } from './styles'

interface UserPermissionsProps {
  onChange: (data: OnCheck) => void
  isChecked: (resource: string, actions: string[]) => boolean
}

export const UserPermissions: React.FC<UserPermissionsProps> = ({
  onChange,
  isChecked
}) => {
  function handleCheck(
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) {
    const value = data.value as string
    const checked = data.checked as boolean
    onChange({ value, checked })
  }

  return (
    <>
      <PermissionGroup label="Usuário:">
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('user', ['Get', 'List'])}
          value="user:Get,List"
          label="Ler"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('user', ['Create'])}
          value="user:Create"
          label="Cadastrar"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('user', [
            'Update',
            'UpdateStatus',
            'ResetPassword'
          ])}
          value="user:Update,UpdateStatus,ResetPassword"
          label="Atualizar"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('user', ['Delete'])}
          value="user:Delete"
          label="Excluir"
        />
      </PermissionGroup>
      <PermissionGroup>
        <BlankOption />
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('user', ['LinkAPartner'])}
          value="user:LinkAPartner"
          label="Adicionar parceiro"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('user', ['UnlinkAPartner'])}
          value="user:UnlinkAPartner"
          label="Remover parceiro"
        />
      </PermissionGroup>
    </>
  )
}
