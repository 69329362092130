import { useState } from 'react'

import {
  PriceList,
  listPriceLists as apiListPriceLists
} from '../../../../../services/priceList'

export function usePriceList() {
  const [priceLists, setPriceLists] = useState<PriceList[]>([])
  const [loading, setLoading] = useState(false)

  function listPriceLists(search: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiListPriceLists({ search }))
      .then(data => setPriceLists(data.priceLists))
      .finally(() => setLoading(false))
  }

  return {
    priceLists,
    loading,
    listPriceLists
  }
}
