import React from 'react'

import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal'

interface ConfirmationActionModalProps {
  open: boolean
  title: string
  question: string
  loading: boolean
  handleModal: (open: boolean) => void
  onConfirm: () => void
}

export const ConfirmationActionModal: React.FC<
  ConfirmationActionModalProps
> = ({ open, title, loading, question, handleModal, onConfirm }) => {
  return (
    <ConfirmationModal
      open={open}
      title={title}
      loading={loading}
      handleModal={handleModal}
      onConfirm={onConfirm}
      onDecline={() => handleModal(false)}
    >
      <span>{question}</span>
    </ConfirmationModal>
  )
}
