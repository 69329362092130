import Joi from 'joi'

const messages = {
  categoryId: {
    required: 'Categoria é obrigatória'
  },
  customerId: {
    required: 'Cliente é obrigatório'
  },
  description: {
    required: 'Descrição é obrigatória',
    max: 'Permitido até 128 caracteres'
  },
  recurrence: {
    required: 'Recorrência é obrigatória'
  },
  period: {
    required: 'Período é obrigatório'
  },
  dueDateFormatted: {
    required: 'Data de vencimento é obrigatória'
  }
}

export const schema = Joi.object({
  categoryId: Joi.string().required().messages({
    'any.required': messages.categoryId.required
  }),
  customerId: Joi.string().required().messages({
    'any.required': messages.customerId.required
  }),
  description: Joi.string().max(128).required().messages({
    'any.required': messages.description.required,
    'string.max': messages.description.max
  }),
  recurrence: Joi.string().required().messages({
    'any.required': messages.recurrence.required
  }),
  period: Joi.string().required().messages({
    'any.required': messages.period.required
  }),
  dueDateFormatted: Joi.string().required().messages({
    'any.required': messages.dueDateFormatted.required
  })
})
