import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form as FinalForm } from 'react-final-form'
import { Button, Segment } from 'semantic-ui-react'
import { toast } from 'react-toastify'

import {
  createPriceList,
  updatePriceList,
  PriceList
} from '../../../services/priceList'
import { getFormValidate } from '../../../utils/form'
import { pages } from '../../../config/pages'
import { Form, Input } from '../../../components/Form'
import { FormActionsContainer } from '../../../styles/FormActionsContainer'

import { priceListSchema } from './schemas'
import { PriceListFormatted } from './utils'

interface PriceListSectionProps {
  priceList?: PriceListFormatted
  loading?: boolean
  onSave?: (priceList: PriceList) => void
}

const validateForm = getFormValidate(priceListSchema)

export const PriceListSection: React.FC<PriceListSectionProps> = ({
  priceList,
  loading: parentLoading,
  onSave
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (parentLoading !== undefined) {
      setLoading(parentLoading)
    }
  }, [parentLoading])

  function handleSubmit(values: PriceList) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() =>
        priceList ? updatePriceList(values.id, values) : createPriceList(values)
      )
      .then(priceList => onSave?.({ ...priceList, items: [] }))
      .then(() => toast.info('Dados salvos'))
      .finally(() => setLoading(false))
  }

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={priceList}
      validate={validateForm}
      validateOnBlur
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} loading={loading}>
          <FormActionsContainer>
            <Button
              type="button"
              content="Voltar"
              as={Link}
              to={pages.priceLists.path}
            />
            <Button type="submit" content="Salvar" floated="right" primary />
          </FormActionsContainer>
          <Segment>
            <input name="id" hidden />
            <Input
              name="name"
              label="Nome da tabela"
              maxLength={60}
              required
              uppercase
            />
          </Segment>
        </Form>
      )}
    </FinalForm>
  )
}
