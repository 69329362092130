import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Table, Button, Input } from 'semantic-ui-react'
import copy from 'copy-to-clipboard'
import { toast } from 'react-toastify'

import { deleteSystem, updateSystemToken, System } from '../../services/system'
import { PaginationContext, Pagination } from '../../contexts/PaginationContext'
import { useCan } from '../../hooks/useCan'
import { can } from '../../config/permissions'
import { dateToLocaleDateTime } from '../../utils/date'

import { PaginationControllers } from '../../components/PaginationControllers'
import { ConfirmationModal } from '../../components/ConfirmationModal'
import { TableHeaderCell, ItemControllers } from '../../components/Table'
import { DefaultLoader } from '../../components/DefaultLoader'
import { ActionsContainer } from '../../components/RegistrationContainer/styles'

interface SystemsTableProps {
  systems: System[]
  totalItems: number
  loading?: boolean
  fetchSystems: (pagination: Pagination) => void
  onCreateClick?: () => void
  onUpdateClick?: (system: System) => void
  onUpdateSystem?: (system: System) => void
}
export function SystemsTable({
  systems,
  totalItems,
  loading,
  fetchSystems,
  onCreateClick,
  onUpdateClick
}: SystemsTableProps): React.ReactElement {
  const {
    pagination,
    goToFirstPage,
    goToPreviousPage,
    goToNextPage,
    goToLastPage,
    changeOrderBy,
    changeLimit,
    changeQuery
  } = useContext(PaginationContext)
  const [search, setSearch] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [selectedSystem, setSelectedSystem] = useState<System>()
  const [changeTokenModal, setChangeTokenModal] = useState(false)
  const [changeTokenLoading, setChangeTokenLoading] = useState(false)
  const canCreate = useCan(can.system.create)
  const canUpdateToken = useCan(can.system.updateToken)
  const canGetDirectory = useCan(can.directory.read)

  useEffect(() => {
    fetchSystems(pagination)
  }, [pagination])

  function searchService() {
    changeQuery({ search })
  }

  function handleSearchKeyPress(evt: React.KeyboardEvent) {
    if (evt.key === 'Enter') {
      searchService()
    }
  }

  function handleUpdateClick(system: System) {
    onUpdateClick && onUpdateClick(system)
  }

  function handleDeleteClick(system: System) {
    setDeleteModal(true)
    setSelectedSystem(system)
  }

  function handleDeleteSystem() {
    if (!selectedSystem) return

    Promise.resolve()
      .then(() => setDeleteLoading(true))
      .then(() => deleteSystem(selectedSystem.id))
      .then(() => setDeleteModal(false))
      .then(() => fetchSystems(pagination))
      .finally(() => setDeleteLoading(false))
  }

  function handleTokenCopyClick(token: string) {
    copy(token)
    toast.info('Token copiado')
  }

  function handleChangeTokenClick(system: System) {
    setSelectedSystem(system)
    setChangeTokenModal(true)
  }

  function handleChangeToken() {
    if (!selectedSystem) return

    Promise.resolve()
      .then(() => setChangeTokenLoading(true))
      .then(() => setChangeTokenModal(false))
      .then(() => updateSystemToken(selectedSystem.id))
      .then(system => onUpdateClick?.(system))
      .then(() => toast.info('Novo token gerado'))
      .catch(err => toast.error(err.message))
      .finally(() => setChangeTokenLoading(false))
  }

  return (
    <>
      <DefaultLoader active={loading} />
      <ActionsContainer>
        <Input
          placeholder="Pesquisar"
          value={search}
          onChange={(_, { value }) => setSearch(value.toUpperCase())}
          onKeyPress={handleSearchKeyPress}
          action={<Button icon="search" onClick={searchService} />}
        />
        {onCreateClick && canCreate && (
          <Button
            content="Incluir"
            onClick={onCreateClick}
            floated="right"
            primary
          />
        )}
      </ActionsContainer>
      <Table selectable striped compact sortable>
        <Table.Header>
          <Table.Row>
            <TableHeaderCell
              content="Nome do sistema"
              name="name"
              pagination={pagination}
              onClick={() => changeOrderBy('name')}
            />
            <TableHeaderCell
              content="Versão"
              name="version"
              pagination={pagination}
              onClick={() => changeOrderBy('version')}
            />
            <Table.HeaderCell content="Token" />
            <Table.HeaderCell content="Arquivos" />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {systems.map(system => (
            <Table.Row key={system.id}>
              <Table.Cell content={system.name} />
              <Table.Cell content={dateToLocaleDateTime(system.version)} />
              <Table.Cell>
                <Button
                  size="mini"
                  icon="copy"
                  onClick={() => handleTokenCopyClick(system.token)}
                  circular
                />
                <Button
                  size="mini"
                  icon="sync"
                  onClick={() => handleChangeTokenClick(system)}
                  loading={changeTokenLoading}
                  disabled={!canUpdateToken}
                  circular
                />
              </Table.Cell>
              <Table.Cell>
                <Button
                  size="mini"
                  icon="file alternate"
                  as={Link}
                  to={`/${system.slug}/arquivos`}
                  disabled={!canGetDirectory}
                  circular
                />
              </Table.Cell>
              <Table.Cell textAlign="right">
                <ItemControllers
                  onUpdateClick={() => handleUpdateClick(system)}
                  onDeleteClick={() => handleDeleteClick(system)}
                  permissionsToUpdate={can.system.update}
                  permissionsToDelete={can.system.delete}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={5} textAlign="right">
              <PaginationControllers
                count={systems.length}
                page={pagination.page}
                limit={pagination.limit}
                total={totalItems}
                loading={loading}
                goToFirstPage={goToFirstPage}
                goToPreviousPage={goToPreviousPage}
                goToNextPage={goToNextPage}
                goToLastPage={goToLastPage}
                onChangeLimit={changeLimit}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <ConfirmationModal
        open={deleteModal}
        loading={deleteLoading}
        handleModal={setDeleteModal}
        onConfirm={handleDeleteSystem}
        onDecline={() => setDeleteModal(false)}
        confirmationCode
      >
        <span>Deseja excluir o sistema?</span>
        <br />
        <strong>{selectedSystem?.name}</strong>
      </ConfirmationModal>
      <ConfirmationModal
        open={changeTokenModal}
        handleModal={setChangeTokenModal}
        onConfirm={handleChangeToken}
        onDecline={() => setChangeTokenModal(false)}
        confirmationCode
      >
        <span>Deseja alterar o token do sistema?</span>
      </ConfirmationModal>
    </>
  )
}
