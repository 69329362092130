import React from 'react'
import { FormField, SemanticWIDTHS } from 'semantic-ui-react'
import { useField } from 'react-final-form'

import { useFieldError } from '../../../hooks/useFieldError'

import { DateTimeInput as PureDateTimeInput } from './DateTimeInput'

interface DateTimeInputProps {
  name: string
  label?: string
  required?: boolean
  minDate?: string | Date
  maxDate?: string | Date
  disabled?: boolean
  width?: SemanticWIDTHS
  fluid?: boolean
  readOnly?: boolean
  onChange?: (event: { value: string }) => void
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

export const DateTimeInput: React.FC<DateTimeInputProps> = props => {
  const field = useField(props.name)
  const error = useFieldError(field)

  return <FormField {...props} control={PureDateTimeInput} error={error} />
}
