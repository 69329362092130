import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'
import { toast } from 'react-toastify'

import { useCan } from '../../../../../../../../hooks/useCan'
import { can } from '../../../../../../../../config/permissions'
import { Show } from '../../../../../../../../components/Show'

import { useOrder, useOrderAc, useOrderCustomer } from '../../../../hooks'
import { HandleOrderConfirmationModal } from '../HandleOrderConfirmationModal'

export const RequestCertificateAction: React.FC = () => {
  const { order, loading, isCertificateOrder } = useOrder()
  const { requestCertificate } = useOrderAc()
  const { customer } = useOrderCustomer()
  const [showRequestCertificateModal, setShowRequestCertificateModal] =
    useState(false)
  const canRequestCertificate = useCan(can.ac.requestCertificate)

  function handleRequestCertificateButtonClick() {
    setShowRequestCertificateModal(true)
  }

  function handleConfirmToRequestCertificate() {
    if (!customer?.emails) {
      setShowRequestCertificateModal(false)
      return toast.error('Cliente não possui endereço de e-mail cadastrado')
    }

    requestCertificate(() => setShowRequestCertificateModal(false))
  }

  const orderExist = !!order
  const orderIsOpen = orderExist && order?.status === 'OPEN'
  const orderHasCertificateProtocol = !!order?.certificateIssueProtocol

  return (
    <Show
      condition={
        isCertificateOrder &&
        !orderIsOpen &&
        !orderHasCertificateProtocol &&
        canRequestCertificate
      }
      component={
        <>
          <Button
            type="button"
            content="Solicitar certificado"
            floated="right"
            onClick={handleRequestCertificateButtonClick}
          />
          <HandleOrderConfirmationModal
            open={showRequestCertificateModal}
            order={order}
            loading={loading}
            title="Solicitar certificado"
            question="Você deseja fazer uma solicitação de certificado digital na AC?"
            handleModal={setShowRequestCertificateModal}
            onConfirm={handleConfirmToRequestCertificate}
            onDecline={() => setShowRequestCertificateModal(false)}
          />
        </>
      }
    />
  )
}
