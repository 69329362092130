import { listAccounts } from '../../../../../../services/account'
import { Pagination } from '../../../../../../contexts/PaginationContext'

import { QueryParameters } from '../../../../Accounts'
import { formatAccount } from '../../../../utils/formatAccount'

export function useFetchAccounts() {
  return async function (pagination: Pagination<QueryParameters>) {
    const { limit, page, query } = pagination
    const {
      type = 'RECEIVABLE',
      status = 'OPEN',
      order = 'ASC',
      orderBy = 'dueDate',
      search
    } = query || {}
    const { accounts, total } = await listAccounts({
      limit,
      page,
      order,
      orderBy,
      type,
      status,
      search
    })

    return {
      accounts: accounts.map(formatAccount),
      totalItems: total
    }
  }
}
