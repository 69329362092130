import React, { useState, useCallback } from 'react'
import { Navigate, useParams, useNavigate } from 'react-router-dom'

import { pages } from '../../config/pages'
import { listSystems, System, SystemListResult } from '../../services/system'
import { RegistrationContainer } from '../../components/RegistrationContainer'
import {
  PaginationProvider,
  Pagination
} from '../../contexts/PaginationContext'

import { SystemsTable } from './SystemsTable'
import { SystemForm } from './SystemForm'

const paths = ['inclusao', 'edicao']

export interface QueryParameters {
  search?: string
}

export function Systems(): React.ReactElement {
  const { action } = useParams()
  const [systems, setSystems] = useState<System[]>([])
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const fetchSystems = useCallback(
    (pagination: Pagination<QueryParameters>) => {
      const { page, limit, orderBy, order, query } = pagination
      const { search } = query || {}

      const onSuccess = (result: SystemListResult) => {
        setSystems(result.systems)
        setTotalItems(result.total)
      }

      Promise.resolve()
        .then(() => setLoading(true))
        .then(() => listSystems({ page, limit, orderBy, order, search }))
        .then(result => onSuccess(result))
        .finally(() => setLoading(false))
    },
    []
  )

  function handleCreateClick() {
    navigate(`${pages.systems.path}/inclusao`)
  }

  function handleUpdateClick(data: System) {
    navigate(`${pages.systems.path}/edicao?id=${data.id}`)
  }

  function handleUpdateSystem(data: System) {
    const newList = systems.map(system => {
      return system.id === data.id ? data : system
    })

    setSystems(newList)
  }

  const breadcrumbItems = action
    ? [
        { name: 'Sistemas', to: pages.systems.path },
        { name: action === 'inclusao' ? 'Inclusão' : 'Edição' }
      ]
    : [{ name: 'Sistemas' }]

  const renderTable = !action
  const renderForm = paths.includes(action || '')
  const renderRedirect = action && !paths.includes(action || '')

  return (
    <RegistrationContainer breadcrumbItems={breadcrumbItems}>
      <PaginationProvider totalItems={totalItems}>
        {renderTable && (
          <SystemsTable
            systems={systems}
            totalItems={totalItems}
            loading={loading}
            fetchSystems={fetchSystems}
            onCreateClick={handleCreateClick}
            onUpdateClick={handleUpdateClick}
            onUpdateSystem={handleUpdateSystem}
          />
        )}

        {renderForm && <SystemForm />}

        {renderRedirect && <Navigate to={pages.systems.path} />}
      </PaginationProvider>
    </RegistrationContainer>
  )
}
