import React, { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import {
  Button,
  Checkbox,
  CheckboxProps,
  Dropdown,
  Table
} from 'semantic-ui-react'

import {
  getDirectory,
  DirectoryContent,
  Directory,
  deleteDirectory
} from '../../services/directory'
import {
  changeFileType,
  deleteFiles,
  File,
  FileType
} from '../../services/file'
import { useCan } from '../../hooks/useCan'
import { dateToLocaleDateTime } from '../../utils/date'
import { can } from '../../config/permissions'
import { ButtonLink } from '../../components/ButtonLink'
import { ItemControllers } from '../../components/Table'
import { ConfirmationModal } from '../../components/ConfirmationModal'
import { DefaultLoader } from '../../components/DefaultLoader'

import { UploadModal, HandleModalInfo } from './UploadModal'

interface FilesTableProps {
  rootId?: string
  systemId?: string
  onCreateClick?: () => void
  onChangeDirectory?: (directoryId: string) => void
}

const fileTypeOptions = [
  { key: 'T', value: 'T', text: 'Tudo' },
  { key: 'S', value: 'S', text: 'Servidor' },
  { key: 'C', value: 'C', text: 'Cliente' }
]

const fileTypeNames = {
  T: 'Tudo',
  S: 'Servidor',
  C: 'Cliente'
}

const initialCheck = new Set([])

export const FilesTable: React.FC<FilesTableProps> = ({
  rootId,
  systemId,
  onCreateClick,
  onChangeDirectory
}) => {
  const [directoryId, setDirectoryId] = useState<string>()
  const [directory, setDirectory] = useState<DirectoryContent>()
  const [loading, setLoading] = useState(false)
  const [deleteDirectoryModal, setDeleteDirectoryModal] = useState(false)
  const [deleteDirectoryLoading, setDeleteDirectoryLoading] = useState(false)
  const [selectedDirectory, setSelectedDirectory] = useState<Directory>()
  const [deleteFileModal, setDeleteFileModal] = useState(false)
  const [deleteFileLoading, setDeleteFileLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File>()
  const [uploadModal, setUploadModal] = useState(false)
  const [checkedFiles, setCheckedFiles] = useState<Set<string>>(initialCheck)
  const [deleteMultiFilesModal, setDeleteMultiFilesModal] = useState(false)
  const [deleteMultiFilesLoading, setDeleteMultiFilesLoading] = useState(false)
  const canUploadFile = useCan(can.file.create)
  const canCreateDirectory = useCan(can.directory.create)
  const canUpdateFileType = useCan(can.file.updateType)
  const canDeleteFileAndDirectory = useCan([
    ...can.directory.create,
    ...can.file.delete
  ])

  const fetchData = useCallback(() => {
    if (!directoryId || !systemId) return

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getDirectory(directoryId, systemId))
      .then(directory => setDirectory(directory))
      .finally(() => setLoading(false))
  }, [directoryId])

  useEffect(() => {
    rootId && setDirectoryId(rootId)
  }, [rootId])

  useEffect(() => {
    fetchData()
    directoryId && onChangeDirectory?.(directoryId)
  }, [directoryId])

  function handleDeleteDirectoryClick(directory: Directory) {
    setDeleteDirectoryModal(true)
    setSelectedDirectory(directory)
  }

  function handleDeleteFileClick(file: File) {
    setDeleteFileModal(true)
    setSelectedFile(file)
  }

  function handleDeleteDirectory() {
    if (!selectedDirectory || !systemId) return

    Promise.resolve()
      .then(() => setDeleteDirectoryLoading(true))
      .then(() => deleteDirectory(selectedDirectory.id, systemId))
      .then(() => fetchData())
      .then(() => setDeleteDirectoryModal(false))
      .finally(() => setDeleteDirectoryModal(false))
      .finally(() => setDeleteDirectoryLoading(false))
  }

  function handleDeleteFile() {
    if (!selectedFile || !systemId || !directoryId) return

    Promise.resolve()
      .then(() => setDeleteFileLoading(true))
      .then(() => deleteFiles(selectedFile.id, systemId, directoryId))
      .then(() => fetchData())
      .then(() => setDeleteFileModal(false))
      .finally(() => setDeleteFileModal(false))
      .finally(() => setDeleteFileLoading(false))
  }

  function handleUploadModal(value: boolean, { complete }: HandleModalInfo) {
    setUploadModal(value)
    complete && fetchData()
  }

  function setFileType(id: string, type: FileType) {
    if (!directory) return

    const newDirectoryState = { ...directory }
    const files = newDirectoryState.files.map(file => {
      return file.id === id ? Object.assign(file, { type }) : file
    })
    newDirectoryState.files = files
    setDirectory(newDirectoryState)
  }

  function handleChangeType(id: string, newType: FileType) {
    if (!systemId || !directoryId) return

    const oldType = directory?.files.find(file => file.id === id)?.type

    const onFail = (err: Error) => {
      toast.error(err.message)
      setFileType(id, oldType || 'T')
    }

    Promise.resolve()
      .then(() => setFileType(id, newType))
      .then(() => changeFileType(id, systemId, directoryId, newType))
      .catch(err => onFail(err))
  }

  function handleCheckFile(_: any, data: CheckboxProps) {
    const { checked } = data
    const value = data.value as string
    const list = new Set(checkedFiles.values())

    checked ? list.add(value) : list.delete(value)

    setCheckedFiles(list)
  }

  function handleGeneralCheckClick(_: any, data: CheckboxProps) {
    const { checked } = data
    const list = new Set(checkedFiles.values())

    checked ? directory?.files.forEach(file => list.add(file.id)) : list.clear()

    setCheckedFiles(list)
  }

  function handleDeleteMultiFiles() {
    if (!systemId || !directoryId) return

    const ids = Array.from(checkedFiles)

    Promise.resolve()
      .then(() => setDeleteMultiFilesModal(false))
      .then(() => setDeleteMultiFilesLoading(true))
      .then(() => deleteFiles(ids, systemId, directoryId))
      .then(() => fetchData())
      .then(() => setCheckedFiles(new Set()))
      .catch(err => toast.error(err.message))
      .finally(() => setDeleteMultiFilesLoading(false))
  }

  return (
    <>
      <DefaultLoader active={loading} />
      <Button
        content="Voltar"
        disabled={!directory?.fatherId}
        onClick={() => setDirectoryId(directory?.fatherId)}
      />
      {onCreateClick && canCreateDirectory && (
        <Button
          content="Incluir"
          onClick={onCreateClick}
          floated="right"
          primary
        />
      )}
      <Table selectable striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              content={directory?.name || 'Arquivos'}
              colSpan={2}
            />
            <Table.HeaderCell colSpan={3} textAlign="right">
              {canUploadFile && (
                <Button
                  basic
                  content="Upload"
                  size="small"
                  icon="cloud upload"
                  onClick={() => setUploadModal(true)}
                />
              )}
              {canDeleteFileAndDirectory && (
                <Button
                  basic
                  content="Excluir"
                  size="small"
                  icon="trash"
                  onClick={() => setDeleteMultiFilesModal(true)}
                  disabled={checkedFiles.size === 0}
                  loading={deleteMultiFilesLoading}
                />
              )}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>
              <Checkbox
                onChange={handleGeneralCheckClick}
                checked={
                  checkedFiles.size === directory?.files.length &&
                  checkedFiles.size > 0
                }
              />
            </Table.HeaderCell>
            <Table.HeaderCell content="Nome" />
            <Table.HeaderCell content="Data de modificação" />
            <Table.HeaderCell content="Tipo" />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {directory?.directories.map(directory => (
            <Table.Row key={directory.id}>
              <Table.Cell />
              <Table.Cell>
                <ButtonLink
                  content={directory.name}
                  onClick={() => setDirectoryId(directory.id)}
                />
              </Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell textAlign="right">
                <ItemControllers
                  onDeleteClick={() => handleDeleteDirectoryClick(directory)}
                  permissionsToDelete={can.directory.delete}
                />
              </Table.Cell>
            </Table.Row>
          ))}
          {directory?.files.map(file => (
            <Table.Row key={file.id}>
              <Table.Cell>
                <Checkbox
                  value={file.id}
                  onChange={handleCheckFile}
                  checked={checkedFiles.has(file.id)}
                />
              </Table.Cell>
              <Table.Cell content={file.name} />
              <Table.Cell
                content={dateToLocaleDateTime(file.modificationDate)}
              />
              <Table.Cell>
                <Dropdown
                  text={fileTypeNames[file.type]}
                  value={file.type}
                  options={fileTypeOptions}
                  onChange={(_, { value }) =>
                    handleChangeType(file.id, value as FileType)
                  }
                  disabled={!canUpdateFileType}
                />
              </Table.Cell>
              <Table.Cell textAlign="right">
                <ItemControllers
                  onDeleteClick={() => handleDeleteFileClick(file)}
                  permissionsToDelete={can.file.delete}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell
              textAlign="right"
              content={`${
                (directory?.directories.length || 0) +
                (directory?.files.length || 0)
              } itens(s)`}
              colSpan={5}
            />
          </Table.Row>
        </Table.Footer>
      </Table>
      <ConfirmationModal
        open={deleteDirectoryModal}
        loading={deleteDirectoryLoading}
        handleModal={setDeleteDirectoryModal}
        onConfirm={handleDeleteDirectory}
        onDecline={() => setDeleteDirectoryModal(false)}
      >
        <span>Deseja excluir o diretório?</span>
        <br />
        <strong>{selectedDirectory?.name}</strong>
      </ConfirmationModal>
      <ConfirmationModal
        open={deleteFileModal}
        loading={deleteFileLoading}
        handleModal={setDeleteFileModal}
        onConfirm={handleDeleteFile}
        onDecline={() => setDeleteFileModal(false)}
      >
        <span>Deseja excluir o arquivo?</span>
        <br />
        <strong>{selectedFile?.name}</strong>
      </ConfirmationModal>
      <UploadModal
        open={uploadModal}
        systemId={systemId}
        directoryId={directory?.id}
        handleModal={handleUploadModal}
      />
      <ConfirmationModal
        open={deleteMultiFilesModal}
        loading={deleteFileLoading}
        handleModal={setDeleteMultiFilesModal}
        onConfirm={handleDeleteMultiFiles}
        onDecline={() => setDeleteMultiFilesModal(false)}
      >
        <span>Deseja excluir vários arquivos?</span>
      </ConfirmationModal>
    </>
  )
}
