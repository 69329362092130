import styled from 'styled-components'

interface TextProps {
  positive?: boolean
  negative?: boolean
}

function selectColor({ positive, negative }: TextProps): string {
  if (negative) return 'color: red;'
  if (positive) return 'color: green;'
  return ''
}

export const Text = styled.span<TextProps>`
  ${selectColor}
`

export const ActionsContainer = styled.div`
  .filters > div {
    margin-right: 15px;
  }

  .input {
    width: 40%;
  }
`
