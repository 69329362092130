import { dateToLocaleDate } from '../utils/date'
import { numberToCurrency } from '../utils/number'
import { generateQrCode } from '../utils/qrcode'

import api from './api'

export type CobPixType = 'INTER'

export type CobPixStatus =
  | 'CREATED'
  | 'OPENED'
  | 'PAID'
  | 'CANCELED'
  | 'CANCELED_PSP'

export interface CobPixFine {
  modality: number
  value: number
}

export interface CobPixInterest {
  modality: number
  value: number
}

export interface CobPixAdditionalInfo {
  name: string
  value: string
}

export interface CobPixPayment {
  endToEndId: string
  paidValue: number
  fine: number
  interest: number
  paymentDate: string
}

export interface CobPix {
  id: string
  payerId: string
  type: CobPixType
  txid: string
  key: string
  status: CobPixStatus
  dueDate: string
  expireIn: number
  value: number
  fine?: CobPixFine
  interest?: CobPixInterest
  payerRequest?: string
  additionalInfos?: CobPixAdditionalInfo[]
  pixCopyAndPaste: string
  revision: number
  pix: CobPixPayment[]
  createdAt: string
  updatedAt: string
}

export interface CobPixInfo {
  dueDate: string
  value: number
  pixCopyAndPaste: string
}

export interface CobPixInfoFormatted {
  dueDate: string
  value: string
  pixCopyAndPaste: string
  qrCode: string
}

export async function formatCobPix(
  data: CobPixInfo
): Promise<CobPixInfoFormatted> {
  return {
    dueDate: dateToLocaleDate(data.dueDate),
    value: numberToCurrency(data.value),
    pixCopyAndPaste: data.pixCopyAndPaste,
    qrCode: await generateQrCode(data.pixCopyAndPaste)
  }
}

export async function getCobPix(
  orderId: string,
  chargeId: string
): Promise<CobPixInfo> {
  const response = await api.get(`/public/pix/${orderId}/${chargeId}`)
  const cobPixInfo = response.data

  return cobPixInfo
}

export async function issueCobPix(
  orderId: string,
  chargeId: string,
  data: Partial<CobPix>
): Promise<CobPix> {
  const response = await api.post(
    `/orders/${orderId}/charges/${chargeId}/cob-pix`,
    data
  )
  const cobPix = response.data

  return cobPix
}

export async function refreshCobPix(
  orderId: string,
  chargeId: string
): Promise<CobPix> {
  const response = await api.patch(
    `/orders/${orderId}/charges/${chargeId}/cob-pix/refresh`
  )
  const cobPix = response.data

  return cobPix
}

export async function cancelCobPix(
  orderId: string,
  chargeId: string
): Promise<void> {
  await api.delete(`/orders/${orderId}/charges/${chargeId}/cob-pix`)
}
