import { useContext } from 'react'
import { toast } from 'react-toastify'

import {
  Product,
  createProduct as apiCreateProduct,
  updateProduct as apiUpdateProduct
} from '../../../../../services/product'

import { ProductFormContext } from '../context'

export function useProduct() {
  const { product, loading, setLoading, getProductState, updateProductState } =
    useContext(ProductFormContext)

  function createProduct(data: Partial<Product>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiCreateProduct(data))
      .then(data => updateProductState(data))
      .then(() => toast.info('Produto cadastrado com sucesso'))
      .finally(() => setLoading(false))
  }

  function updateProduct(data: Partial<Product>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getProductState())
      .then(order => apiUpdateProduct(order.id, data))
      .then(data => updateProductState(data))
      .then(() => toast.info('O produto foi alterado'))
      .finally(() => setLoading(false))
  }

  return {
    product,
    loading,
    createProduct,
    updateProduct
  }
}
