import React from 'react'

import { OrderItemFormatted } from '../../../../../../utils/formatOrderItem'

import { EditableRow } from './EditableRow'
import { NotEditableRow } from './NotEditableRow'

interface OrderItemsTableRowProps {
  item: OrderItemFormatted
  readOnly?: boolean
}

export const OrderItemsTableRow: React.FC<OrderItemsTableRowProps> = ({
  item,
  readOnly
}) => {
  return readOnly ? <NotEditableRow item={item} /> : <EditableRow item={item} />
}
