import api from './api'

export interface Group {
  id: string
  name: string
}

export interface GroupPaginate {
  page?: number
  limit?: number
  search?: string
}

export interface GroupListResult {
  groups: Group[]
  total: number
}

export async function getGroup(id: string): Promise<Group> {
  const response = await api.get(`/groups/${id}`)
  const group = response.status === 200 ? response.data : undefined

  return group
}

export async function listGroups(
  paginate?: GroupPaginate
): Promise<GroupListResult> {
  const { page, limit, search } = paginate || {}
  const response = await api.get(`/groups`, {
    params: { page, limit, search }
  })
  const { items: groups, total } = response.data

  return {
    groups,
    total
  }
}

export async function createGroup(data: Partial<Group>): Promise<Group> {
  const response = await api.post(`/groups`, data)
  const group = response.data

  return group
}

export async function updateGroup(
  id: string,
  data: Partial<Group>
): Promise<Group> {
  const response = await api.put(`/groups/${id}`, data)
  const group = response.data

  return group
}

export async function deleteGroup(id: string): Promise<boolean> {
  const response = await api.delete(`/groups/${id}`)
  const result = response.data

  return result.status
}
