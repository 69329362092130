import { useState } from 'react'
import { User, listUsers as apiListUsers } from '../../../../../services/users'

export function usePartners() {
  const [partners, setPartners] = useState<User[]>([])
  const [loading, setLoading] = useState(false)

  function listPartners(search: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiListUsers({ search, role: 'PARTNER', limit: 10 }))
      .then(data => setPartners(data.users))
      .finally(() => setLoading(false))
  }

  return {
    partners,
    loading,
    listPartners
  }
}
