import React from 'react'
import { Table } from 'semantic-ui-react'

import { useSignature } from '../../../../hooks'
import { SignatureItemsTableHeader, SignatureItemsTableRow } from './components'

export const SignatureItemsTable: React.FC = () => {
  const { signature } = useSignature()

  return (
    <Table selectable striped compact>
      <SignatureItemsTableHeader />

      <Table.Body>
        {signature?.items.map(item => (
          <SignatureItemsTableRow key={item.id} item={item} />
        ))}
      </Table.Body>
    </Table>
  )
}
