import React from 'react'

import { OrderFormProvider } from './context'
import { OrderMain, OrderItems, OrderCharges, OrderNfse } from './components'

export const OrderForm: React.FC = () => {
  return (
    <OrderFormProvider>
      <OrderMain />
      <OrderItems />
      <OrderCharges />
      <OrderNfse />
    </OrderFormProvider>
  )
}
