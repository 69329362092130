export function isEmpty(value: any): boolean {
  return value === null || value === undefined
}

export function isObject(value: any): boolean {
  return typeof value === 'object' && !isEmpty(value)
}

export function clearObject(data: Record<string, any>): Record<string, any> {
  const cleaned: Record<string, any> = {}

  Object.keys(data).forEach(key => {
    const value = data[key]

    if (isEmpty(value)) return

    cleaned[key] = isObject(value) ? clearObject(value) : value
  })

  return cleaned
}
