import React, { useState, useEffect } from 'react'
import { Navigate, useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { pages } from '../../config/pages'
import { getSystemRoot, Root } from '../../services/system'
import { RegistrationContainer } from '../../components/RegistrationContainer'

import { FilesTable } from './FilesTable'
import { DirectoryForm } from './DirectoryForm'

const paths = ['inclusao']

export const Files: React.FC = () => {
  const { slug = '', action } = useParams()
  const [root, setRoot] = useState<Root>()
  const [fatherId, setFatherId] = useState<string>()
  const navigate = useNavigate()

  useEffect(() => {
    const onSuccess = (root: Root) => {
      setRoot(root)
      setFatherId(root?.id)
    }

    Promise.resolve()
      .then(() => getSystemRoot(slug))
      .then(onSuccess)
      .catch(err => toast.error(err.message))
  }, [])

  function handleCreateClick() {
    navigate(`${pages.files.path}/inclusao`.replace(':slug', slug))
  }

  const breadcrumbItems = action
    ? [
        { name: 'Sistemas', to: '/sistemas' },
        { name: 'Arquivos', to: `/${slug}/arquivos` },
        { name: 'Inclusão' }
      ]
    : [{ name: 'Sistemas', to: '/sistemas' }, { name: 'Arquivos' }]

  const renderTable = !action
  const renderForm = paths.includes(action || '')
  const renderRedirect = action && !paths.includes(action || '')

  return (
    <RegistrationContainer breadcrumbItems={breadcrumbItems}>
      {renderTable && (
        <FilesTable
          rootId={root?.id}
          systemId={root?.systemId}
          onCreateClick={handleCreateClick}
          onChangeDirectory={setFatherId}
        />
      )}

      {renderForm && (
        <DirectoryForm systemId={root?.systemId} fatherId={fatherId} />
      )}

      {renderRedirect && <Navigate to={pages.files.path} />}
    </RegistrationContainer>
  )
}
