import {
  subMonths,
  startOfMonth,
  endOfMonth,
  addDays,
  endOfDay
} from 'date-fns'
export { differenceInDays } from 'date-fns'

export function prepareDateValue(value: Date | string): Date {
  return typeof value === 'string' ? new Date(value) : value
}

export function dateToLocaleDate(value: Date | string): string {
  const date = prepareDateValue(value)

  return date.toLocaleDateString()
}

export function dateToLocaleDateTime(value: Date | string): string {
  const date = prepareDateValue(value)

  return date.toLocaleString()
}

export function localeDateTimeToDate(value: string): Date {
  const [day, month, year, hour, minute] = value.split(/[/| |:]/)

  return new Date(+year, +month - 1, +day, +hour, +minute)
}

export function localeDateToDate(value: string): Date {
  return new Date(value.split('/').reverse().join('/'))
}

export function getToday(): Date {
  return new Date()
}

export function getTomorrow() {
  return addDays(new Date(), 1)
}

export function getPreviousMonth(date: Date): Date {
  return subMonths(date, 1)
}

export function getMinDateOfMonth(date: Date): Date {
  return startOfMonth(date)
}

export function getMaxDateOfMonth(date: Date): Date {
  return endOfMonth(date)
}

export function getMaxHourOfDay(date: Date): Date {
  return endOfDay(date)
}

export function setCurrentMinute(date: Date): Date {
  const end = new Date(date.toISOString())
  const now = new Date()
  const thisHour = now.getHours()
  const thisMinute = now.getMinutes()
  end.setHours(thisHour, thisMinute, 0)
  return end
}

export function isSameDate(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

export function setMaxHourAndMinute(value: Date): Date {
  const date = new Date(value.toISOString())
  date.setHours(23)
  date.setMinutes(59)
  return date
}

export function removeTimezone(value: Date): Date {
  const timezone = value.getTimezoneOffset()
  const timezoneMs = (timezone / 60) * 3600 * 1000
  return new Date(value.getTime() - timezoneMs)
}
