import { Person, getPerson } from '../../../../../../services/person'

export function useFetchPerson() {
  return async function fetchPerson(id: string): Promise<Person | undefined> {
    try {
      const person = await getPerson(id)
      return person
    } catch (err: any) {
      const status = err.response?.status

      if (status === 404) return

      throw err
    }
  }
}
