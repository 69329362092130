import React from 'react'
import { Link } from 'react-router-dom'
import { Sidebar as UISidebar, Menu } from 'semantic-ui-react'

import { pages } from '../../../config/pages'
import { can } from '../../../config/permissions'

import { MenuItem } from './MenuItem'

interface SidebarProps {
  show?: boolean
  onHide?: () => void
  children?: React.ReactNode
}

export const Sidebar: React.FC<SidebarProps> = ({ show, onHide, children }) => {
  return (
    <UISidebar.Pushable>
      <UISidebar
        as={Menu}
        visible={show}
        onHide={onHide}
        animation="overlay"
        icon="labeled"
        inverted
        vertical
        width="thin"
      >
        <MenuItem content="Menu" />
        <MenuItem
          as={Link}
          to={pages.home.path}
          content={pages.home.title}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.categories.path}
          content={pages.categories.title}
          permission={can.category.all}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.groups.path}
          content={pages.groups.title}
          permission={can.group.all}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.services.path}
          content={pages.services.title}
          permission={can.service.all}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.products.path}
          content={pages.products.title}
          permission={can.product.all}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.priceLists.path}
          content={pages.priceLists.title}
          permission={can.priceList.all}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.people.path}
          content={pages.people.title}
          permission={can.person.read}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.orders.path}
          content={pages.orders.title}
          permission={can.order.read}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.certificates.path}
          content={pages.certificates.title}
          permission={can.certificate.read}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.accounts.path}
          content={pages.accounts.title}
          permission={can.account.read}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.commission.path}
          content={pages.commission.title}
          permission={can.report.commission}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.cost.path}
          content={pages.cost.title}
          permission={can.report.cost}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.signatures.path}
          content={pages.signatures.title}
          permission={can.signature.modify}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.systems.path}
          content={pages.systems.title}
          permission={can.system.all}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.licenses.path}
          content={pages.licenses.title}
          permission={can.license.all}
          onClick={onHide}
        />
        <MenuItem
          as={Link}
          to={pages.users.path}
          content={pages.users.title}
          permission={can.user.all}
          onClick={onHide}
        />
      </UISidebar>
      <UISidebar.Pusher>{children}</UISidebar.Pusher>
    </UISidebar.Pushable>
  )
}
