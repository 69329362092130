import React, { useState, useCallback } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

import { pages } from '../../config/pages'
import {
  listCategories,
  Category,
  CategoryListResult
} from '../../services/category'
import { RegistrationContainer } from '../../components/RegistrationContainer'
import {
  PaginationProvider,
  Pagination
} from '../../contexts/PaginationContext'
import { useBreadcrumb } from '../../hooks/useBreadcrumb'

import { CategoriesTable } from './CategoriesTable'
import { CategoryForm } from './CategoryForm'

export interface QueryParameters {
  search?: string
}

export const Categories: React.FC = () => {
  const breadcrumb = useBreadcrumb({
    categorias: 'Categorias',
    inclusao: 'Inclusão',
    edicao: 'Edição'
  })
  const [categories, setCategories] = useState<Category[]>([])
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const fetchCategories = useCallback(
    (pagination: Pagination<QueryParameters>) => {
      const { page, limit, query } = pagination
      const { search } = query || {}

      const onSuccess = (result: CategoryListResult) => {
        setCategories(result.categories)
        setTotalItems(result.total)
      }

      Promise.resolve()
        .then(() => setLoading(true))
        .then(() => listCategories({ page, limit, search }))
        .then(result => onSuccess(result))
        .finally(() => setLoading(false))
    },
    []
  )

  function handleCreateClick() {
    navigate(`${pages.categories.path}/inclusao`)
  }

  function handleUpdateClick(data: Category) {
    navigate(`${pages.categories.path}/edicao?id=${data.id}`)
  }

  return (
    <RegistrationContainer breadcrumbItems={breadcrumb}>
      <PaginationProvider totalItems={totalItems}>
        <Routes>
          <Route
            path="/"
            element={
              <CategoriesTable
                categories={categories}
                totalItems={totalItems}
                loading={loading}
                fetchCategories={fetchCategories}
                onCreateClick={handleCreateClick}
                onUpdateClick={handleUpdateClick}
              />
            }
          />
          <Route path="/inclusao" element={<CategoryForm />} />
          <Route path="/edicao" element={<CategoryForm />} />
        </Routes>
      </PaginationProvider>
    </RegistrationContainer>
  )
}
