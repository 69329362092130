import Joi from 'joi'

import { currency } from '../../../../../../../../utils/validation'

const messages = {
  name: {
    required: 'Nome da tabela é obrigatório',
    max: 'Permitido no máximo 60 caracteres'
  },
  amount: {
    required: 'Valor é obrigatório',
    positive: 'Valor deve ser positivo'
  }
}

export const chargeSchema = Joi.object({
  orderId: Joi.string().uuid({ version: ['uuidv4'] }),
  amount: Joi.custom(currency().positive()).required().messages({
    'number.currency.positive': messages.amount.positive
  })
})
