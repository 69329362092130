import React, { useState } from 'react'
import { Modal, Table } from 'semantic-ui-react'

import { Session } from '../../../../services/session'
import { useSession } from '../../../../hooks/useSession'
import { DefaultLoader } from '../../../DefaultLoader'
import { ItemControllers } from '../../../Table/ItemControllers'
import { ConfirmationModal } from '../../../ConfirmationModal'

interface OpenSessionsModalProps {
  open: boolean
  handleModal: (open: boolean) => void
}

export const OpenSessionsModal: React.FC<OpenSessionsModalProps> = ({
  open,
  handleModal
}: OpenSessionsModalProps) => {
  const { sessions, loading, deleteSession } = useSession()
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedSession, setSelectedSession] = useState<Session>()

  function handleDeleteClick(session: Session) {
    setDeleteModal(true)
    setSelectedSession(session)
  }

  function handleDeleteSession() {
    if (!selectedSession) return

    Promise.resolve()
      .then(() => deleteSession(selectedSession.id))
      .then(() => setDeleteModal(false))
  }

  return (
    <>
      <Modal
        open={open}
        onOpen={() => handleModal(true)}
        onClose={() => handleModal(false)}
        closeOnDimmerClick={false}
        size="small"
        closeIcon
      >
        <DefaultLoader active={loading} />
        <Modal.Header content="Sessões de usuário" />
        <Modal.Content>
          <Table selectable compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content="Descrição" />
                <Table.HeaderCell content="Data" />
                <Table.HeaderCell content="IP" />
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sessions?.map(session => (
                <Table.Row key={session.id}>
                  <Table.Cell content={session.description} />
                  <Table.Cell content={session.createdAt} />
                  <Table.Cell content={session.ip} />
                  <Table.Cell>
                    <ItemControllers
                      onDeleteClick={() => handleDeleteClick(session)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
      <ConfirmationModal
        open={deleteModal}
        loading={loading}
        handleModal={setDeleteModal}
        onConfirm={handleDeleteSession}
        onDecline={() => setDeleteModal(false)}
      >
        <span>Deseja desconectar essa sessão?</span>
        <br />
        <strong>{selectedSession?.description}</strong>
      </ConfirmationModal>
    </>
  )
}
