import React from 'react'
import { Table } from 'semantic-ui-react'

import { TableHeaderCell } from '../../../../../components/Table'
import { Pagination } from '../../../../../contexts/PaginationContext'

interface PeopleTableHeaderProps {
  pagination: Pagination
  changeOrderBy: (field: string) => void
}

export const PeopleTableHeader: React.FC<PeopleTableHeaderProps> = ({
  pagination,
  changeOrderBy
}) => {
  return (
    <Table.Header>
      <Table.Row>
        <TableHeaderCell
          content="Nome"
          name="name"
          pagination={pagination}
          onClick={() => changeOrderBy('name')}
        />
        <TableHeaderCell
          content="Documento"
          name="document"
          pagination={pagination}
          onClick={() => changeOrderBy('document')}
        />
        <TableHeaderCell
          content="E-mail"
          name="email"
          pagination={pagination}
          onClick={() => changeOrderBy('email')}
        />
        <TableHeaderCell
          content="Telefone"
          name="phone"
          pagination={pagination}
          onClick={() => changeOrderBy('phone')}
        />
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
  )
}
