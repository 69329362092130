import React, { useState } from 'react'
import { Input, Button, Modal } from 'semantic-ui-react'
import { PatternFormat } from 'react-number-format'
import { DateInput as SemanticDateInput } from 'semantic-ui-calendar-react'
import 'moment/locale/pt-br'

import { ModalContentWrapper } from './styles'

interface OnChangeData {
  value: string
}

interface DateInputProps {
  name?: string
  value: string
  readOnly?: boolean
  minDate?: string | Date
  disabled?: boolean
  onChange?: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.SyntheticEvent<HTMLElement, Event>,
    data: OnChangeData
  ) => void
  onBlur?: (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.SyntheticEvent<HTMLElement, Event>,
    data: OnChangeData
  ) => void
}

export const DateInput: React.FC<DateInputProps> = ({
  name,
  value,
  readOnly,
  minDate,
  disabled,
  onChange,
  onBlur
}) => {
  const [modal, setModal] = useState(false)

  function handleModalClose() {
    setModal(false)
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(event, { value: event.target.value })
  }

  function handlePickerChange(
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: any
  ) {
    setModal(false)
    onChange?.(event, { value: data.value })
    onBlur?.(event, { value: data.value })
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    onBlur?.(event, { value: event.target.value })
  }

  return (
    <>
      <Input>
        <PatternFormat
          name={name}
          format="##/##/####"
          value={value}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={disabled}
          onBlur={handleBlur}
        />
        <Button
          icon="calendar"
          type="button"
          onClick={() => setModal(true)}
          disabled={readOnly || disabled}
          basic
        />
      </Input>
      <Modal open={modal} onClose={handleModalClose} size="tiny" basic>
        <Modal.Content>
          <ModalContentWrapper>
            <SemanticDateInput
              value={value}
              onChange={handlePickerChange}
              localization="pt-br"
              dateFormat="DD/MM/YYYY"
              minDate={minDate}
              inline
            />
          </ModalContentWrapper>
        </Modal.Content>
      </Modal>
    </>
  )
}
