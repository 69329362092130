import styled from 'styled-components'

export const Emphasis = styled.strong`
  font-size: 1.3rem;
`

export const ConfirmationContent = styled.div`
  span,
  strong {
    display: block;
  }

  strong {
    font-size: 1.2rem;
    margin-top: 6px;
  }
`
