import React, { useContext, useState } from 'react'
import { Modal, Button, Divider } from 'semantic-ui-react'
import { Form as FinalForm } from 'react-final-form'
import { toast } from 'react-toastify'

import { Form, Input } from '../../../Form'
import { AuthContext } from '../../../../contexts/AuthContext'
import { updateUserAccount } from '../../../../services/userAccount'

interface EditAccountModalProps {
  open: boolean
  handleModal: (open: boolean) => void
}

export const EditAccountModal: React.FC<EditAccountModalProps> = ({
  open,
  handleModal
}) => {
  const { user, updateUserData } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)

  async function handleSubmit(values: any) {
    const { nickname, oldPassword, newPassword } = values

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => updateUserAccount({ nickname, oldPassword, newPassword }))
      .then(account => updateUserData(account))
      .then(() => toast.info('Dados alterados'))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      open={open}
      onOpen={() => handleModal(true)}
      onClose={() => handleModal(false)}
      closeOnDimmerClick={false}
      size="mini"
      closeIcon
    >
      <Modal.Header content="Editar conta" />
      <Modal.Content>
        <FinalForm
          onSubmit={handleSubmit}
          initialValues={{ nickname: user?.nickname }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} loading={loading}>
              <Input name="nickname" label="Nome" />
              <Divider />
              <Input
                name="oldPassword"
                label="Senha atual"
                type="password"
                data-testid="old-password"
                autoComplete="off"
              />
              <Input
                name="newPassword"
                label="Nova senha"
                type="password"
                data-testid="new-password"
                autoComplete="off"
              />
              <Input
                name="confirmPassword"
                label="Confirme a senha"
                type="password"
                data-testid="confirm-password"
                autoComplete="off"
              />
              <Button type="submit" content="Salvar" primary fluid />
            </Form>
          )}
        </FinalForm>
      </Modal.Content>
    </Modal>
  )
}
