import React, { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-perfect-scrollbar/dist/css/styles.css'

import { useLocalStorage } from './hooks/useLocalStorage'
import { useMaintenance } from './hooks/useMaintenance'
import { CookieBanner } from './components/CookieBanner'
import { COOKIES_CONFIRM_KEY_NAME } from './utils/constants'
import { GlobalStyle } from './styles/GlobalStyle'
import { UnderMaintenance } from './pages/UnderMaintenance'

import { Routes } from './Routes'

export const App: React.FC = () => {
  const [cookieBanner, setCookieBanner] = useState(false)
  const [cookiesConfirm, setCookiesConfirm] = useLocalStorage(
    COOKIES_CONFIRM_KEY_NAME
  )
  const { underMaintenance } = useMaintenance()

  if (underMaintenance) {
    return <UnderMaintenance />
  }

  useEffect(() => {
    if (cookiesConfirm !== 'true') {
      const TWO_SECONDS = 1000 * 2
      setTimeout(() => setCookieBanner(true), TWO_SECONDS)
    }
  }, [])

  function handleCookieBanner(active: boolean) {
    setCookieBanner(active)
    setCookiesConfirm(String(!active))
  }

  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <Routes />
      <CookieBanner
        active={cookieBanner}
        onConfirm={() => handleCookieBanner(false)}
      />
    </>
  )
}
