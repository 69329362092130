import axios, { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { Buffer } from 'buffer'

import { SESSION_KEY_NAME } from '../utils/constants'

const baseURL = `${process.env.REACT_APP_BACKEND}/v1`
const accessToken = localStorage.getItem(SESSION_KEY_NAME)

const api = axios.create({
  baseURL,
  withCredentials: true
})

api.defaults.headers.common.Authorization = `Bearer ${accessToken}`

function arrayBufferToJson(buffer: any) {
  return JSON.parse(Buffer.from(buffer, 'utf8').toString())
}

function isArrayBuffer(body: any) {
  return !!body.byteLength
}

function buildErrorMessage(response: AxiosResponse): string | undefined {
  const body = isArrayBuffer(response.data)
    ? arrayBufferToJson(response.data)
    : response.data

  const { message, details } = body

  if (!message) return

  return `${message}${details?.description ? `: ${details.description}` : ''}`
}

api.interceptors.response.use(
  response => response,
  error => {
    const { response } = error

    if (response?.data instanceof Blob) {
      return Promise.reject(error)
    }

    if (response?.status === 400) {
      const message = buildErrorMessage(response)
      toast.error(message || 'Erro na requisição')
    }

    if (response?.status === 401) {
      localStorage.removeItem(SESSION_KEY_NAME)
    }

    if (response?.status === 403) {
      const message = buildErrorMessage(response)
      toast.warn(message)
    }

    if (response?.status === 409) {
      const message = buildErrorMessage(response)
      toast.error(message || 'Houve um erro')
    }

    if (response?.status === 500) {
      const message = buildErrorMessage(response)
      toast.error(message || 'Houve um erro')
    }

    return Promise.reject(error)
  }
)

export default api

export interface ValidationError {
  property: string
  errors?: Record<string, string>
  children?: ValidationError[]
}

export interface ServerErrorResponseDetails {
  validation?: ValidationError[]
}

export interface ServerErrorResponse {
  error?: string
  message?: string
  details?: ServerErrorResponseDetails
}
