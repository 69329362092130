import React from 'react'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'

import { PermissionGroup } from './PermissionGroup'
import { OnCheck } from './AllPermissions'

interface AcPermissionsProps {
  onChange: (data: OnCheck) => void
  isChecked: (resource: string, actions: string[]) => boolean
}

export const AcPermissions: React.FC<AcPermissionsProps> = ({
  onChange,
  isChecked
}) => {
  function handleCheck(
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) {
    const value = data.value as string
    const checked = data.checked as boolean
    onChange({ value, checked })
  }

  return (
    <>
      <PermissionGroup label="Certificadora (AC):">
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('ac', ['CheckVideoconferencingAvailability'])}
          value="ac:CheckVideoconferencingAvailability"
          label="Verificar videoconferência habilitada"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('ac', ['RequestCertificate'])}
          value="ac:RequestCertificate"
          label="Solicitar certificado"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('ac', ['DeleteRequest'])}
          value="ac:DeleteRequest"
          label="Cancelar solicitação"
        />
      </PermissionGroup>
    </>
  )
}
