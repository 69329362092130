import React, { useContext, useEffect, useState } from 'react'
import { SyntheticInputEvent } from 'react-number-format/types/types'
import { InputOnChangeData, Table } from 'semantic-ui-react'

import { Currency, Input } from '../../../../../../../../components/Form/pure'
import { AuthContext } from '../../../../../../../../contexts/AuthContext'
import { OrderItemFormatted } from '../../../../../../utils/formatOrderItem'
import { stringToNumber } from '../../../../../../../../utils/number'
import { ItemControllers } from '../../../../../../../../components/Table'
import { can } from '../../../../../../../../config/permissions'

import { useOrder, useOrderItem } from '../../../../hooks'
import { ConfirmRemoveItemModal } from './ConfirmRemoveItemModal'

interface EditableRowProps {
  item: OrderItemFormatted
}

export const EditableRow: React.FC<EditableRowProps> = ({ item }) => {
  const { loading } = useOrder()
  const { modifyOrderItem, removeOrderItem } = useOrderItem()
  const { user } = useContext(AuthContext)
  const [editableItem, setEditableItem] = useState<OrderItemFormatted>()
  const [showConfirmRemoveItemModal, setShowConfirmRemoveItemModal] =
    useState(false)

  useEffect(() => setEditableItem(item), [item])

  function handleTextInputChange(_: any, data: InputOnChangeData) {
    const { name, value } = data

    setEditableItem({ ...editableItem, [name]: value } as OrderItemFormatted)
  }

  function handleTextInputBlur(event: SyntheticInputEvent) {
    const { name, value } = event.target || {}
    const oldValue = (item as Record<string, any>)[name]

    if (oldValue === value) return

    modifyOrderItem(item.id, { [name]: value })
  }

  function handleNumericInputChange(_: any, data: InputOnChangeData) {
    const { name, value } = data

    setEditableItem({
      ...editableItem,
      [name]: stringToNumber(value)
    } as OrderItemFormatted)
  }

  function handleNumericInputBlur(event: React.ChangeEvent<HTMLInputElement>) {
    const { name } = event.target || {}
    const value = stringToNumber(event.target.value)
    const oldValue = (item as Record<string, any>)[name]

    if (oldValue === value) return

    const { quantity, price, discount, addition } = item

    modifyOrderItem(item.id, {
      quantity,
      price,
      discount,
      addition,
      [name]: value
    })
  }

  function handleDeleteButtonClick() {
    setShowConfirmRemoveItemModal(true)
  }

  function handleConfirmRemoveItem() {
    removeOrderItem(item.id, () => {
      setShowConfirmRemoveItemModal(false)
    })
  }

  const userIsAdmin = user?.role === 'ADMIN'

  return (
    <Table.Row>
      <Table.Cell width={6}>
        <Input
          name="description"
          value={editableItem?.description}
          onChange={handleTextInputChange}
          onBlur={handleTextInputBlur}
          maxLength={120}
          uppercase
          fluid
        />
      </Table.Cell>
      <Table.Cell width={1}>
        <Input
          name="quantity"
          value={editableItem?.quantity}
          onChange={handleNumericInputChange}
          onBlur={handleNumericInputBlur}
          type="number"
          min={1}
          fluid
        />
      </Table.Cell>
      <Table.Cell width={2}>
        <Currency
          name="price"
          value={editableItem?.price || 0}
          onChange={handleNumericInputChange}
          onBlur={handleNumericInputBlur}
          readOnly={!userIsAdmin}
          fluid
        />
      </Table.Cell>
      <Table.Cell width={2}>
        <Currency
          name="discount"
          value={editableItem?.discount || 0}
          onChange={handleNumericInputChange}
          onBlur={handleNumericInputBlur}
          fluid
        />
      </Table.Cell>
      <Table.Cell width={2}>
        <Currency
          name="addition"
          value={editableItem?.addition || 0}
          onChange={handleNumericInputChange}
          onBlur={handleNumericInputBlur}
          fluid
        />
      </Table.Cell>
      <Table.Cell width={2}>
        <Currency value={editableItem?.totalItem || 0} readOnly fluid />
      </Table.Cell>
      <Table.Cell textAlign="right" width={1}>
        <ItemControllers
          onDeleteClick={handleDeleteButtonClick}
          permissionsToDelete={can.orderItem.delete}
        />
        <ConfirmRemoveItemModal
          open={showConfirmRemoveItemModal}
          item={item}
          loading={loading}
          handleModal={setShowConfirmRemoveItemModal}
          onConfirm={handleConfirmRemoveItem}
        />
      </Table.Cell>
    </Table.Row>
  )
}
