import { useContext } from 'react'
import { toast } from 'react-toastify'

import {
  Group,
  createGroup as apiCreateGroup,
  updateGroup as apiUpdateGroup
} from '../../../../../services/group'

import { GroupFormContext } from '../context'

export function useGroup() {
  const { group, loading, setLoading, getGroupState, updateGroupState } =
    useContext(GroupFormContext)

  function createGroup(data: Partial<Group>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiCreateGroup(data))
      .then(data => updateGroupState(data))
      .then(() => toast.info('Grupo cadastrado com sucesso'))
      .finally(() => setLoading(false))
  }

  function updateGroup(data: Partial<Group>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getGroupState())
      .then(group => apiUpdateGroup(group.id, data))
      .then(data => updateGroupState(data))
      .then(() => toast.info('O grupo foi alterado'))
      .finally(() => setLoading(false))
  }

  return {
    group,
    loading,
    createGroup,
    updateGroup
  }
}
