import React, { useState, useEffect } from 'react'
import { FormField, SemanticWIDTHS } from 'semantic-ui-react'
import { PatternFormat } from 'react-number-format'
import { useForm, useField } from 'react-final-form'
import { toast } from 'react-toastify'

import { useFieldError } from '../../../hooks/useFieldError'
import { CepData, getCep } from '../../../services/address'

import { Loader } from './styles'

interface PostalCodeInputProps {
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
  width?: SemanticWIDTHS
  fluid?: boolean
  onSuccess?: (data: CepData) => void
}

const mask = '#####-###'

export const PostalCodeInput: React.FC<PostalCodeInputProps> = ({
  name,
  onSuccess,
  ...rest
}) => {
  const form = useForm()
  const field = useField(name)
  const error = useFieldError(field)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    form.registerField(name, () => undefined, {})
  }, [])

  function searchPostalCode(postalCode: string) {
    const error = 'As informações do CEP digitado não foram encontradas'

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getCep(postalCode))
      .then(data => onSuccess?.(data))
      .catch(() => toast.warn(error))
      .finally(() => setLoading(false))
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value.replace(/\D/g, '')

    form.change(name, value)

    if (value.length < 8) return

    searchPostalCode(value)
  }

  return (
    <>
      <FormField
        {...rest}
        control={PatternFormat}
        format={mask}
        onChange={handleChange}
        value={field.input.value}
        error={error}
      />
      <Loader size="tiny" active={loading} inline />
    </>
  )
}
