import React, { useMemo } from 'react'
import { Table } from 'semantic-ui-react'

interface Pagination {
  orderBy?: string
  order?: string
}

interface TableHeaderCellProps {
  content?: string
  name?: string
  pagination?: Pagination
  onClick?: (name: string) => void
}

export const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
  content,
  name,
  pagination,
  onClick
}) => {
  const sorted = useMemo(() => {
    return pagination?.orderBy === name
      ? pagination?.order === 'ASC'
        ? 'ascending'
        : 'descending'
      : undefined
  }, [pagination])

  return (
    <Table.HeaderCell content={content} sorted={sorted} onClick={onClick} />
  )
}
