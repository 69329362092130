import React, { useState, useEffect, useContext } from 'react'
import { Table, Checkbox, Button, Input } from 'semantic-ui-react'

import {
  setLicenseStatus,
  deleteLicense,
  License
} from '../../services/license'
import { PaginationContext, Pagination } from '../../contexts/PaginationContext'
import { useCan } from '../../hooks/useCan'
import { can } from '../../config/permissions'
import { PaginationControllers } from '../../components/PaginationControllers'
import { DefaultLoader } from '../../components/DefaultLoader'
import { ConfirmationModal } from '../../components/ConfirmationModal'
import { ItemControllers } from '../../components/Table'
import { ActionsContainer } from '../../components/RegistrationContainer/styles'

interface LicensesTableProps {
  licenses: License[]
  totalItems: number
  loading?: boolean
  fetchLicenses: (pagination: Pagination) => void
  onUpdateLicense?: (license: License) => void
}

export const LicensesTable: React.FC<LicensesTableProps> = ({
  licenses,
  totalItems,
  loading,
  fetchLicenses,
  onUpdateLicense
}) => {
  const {
    pagination,
    goToFirstPage,
    goToPreviousPage,
    goToNextPage,
    goToLastPage,
    changeLimit,
    changeQuery
  } = useContext(PaginationContext)
  const [search, setSearch] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [selectedLicense, setSelectedLicense] = useState<License>()
  const [changeLicenseStatusModal, setChangeLicenseStatusModal] =
    useState(false)
  const canUpdateStatus = useCan(can.license.updateStatus)

  useEffect(() => {
    fetchLicenses(pagination)
  }, [pagination])

  function searchLicense() {
    changeQuery({ search })
  }

  function handleSearchKeyPress(evt: React.KeyboardEvent) {
    if (evt.key === 'Enter') {
      searchLicense()
    }
  }

  function handleLicenseStatusCheck(license: License) {
    setChangeLicenseStatusModal(true)
    setSelectedLicense(license)
  }

  function handleChangeLicenseStatus() {
    if (!selectedLicense) return

    const { id, active } = selectedLicense

    Promise.resolve()
      .then(() => setLicenseStatus(id, !active))
      .then(license => onUpdateLicense?.(license))
      .then(() => setChangeLicenseStatusModal(false))
      .finally(() => setSelectedLicense(undefined))
  }

  function handleDeleteClick(license: License) {
    setSelectedLicense(license)
    setDeleteModal(true)
  }

  function handleDeleteLicense() {
    if (!selectedLicense) return

    Promise.resolve()
      .then(() => setDeleteLoading(true))
      .then(() => deleteLicense(selectedLicense.id))
      .then(() => setDeleteModal(false))
      .then(() => fetchLicenses(pagination))
      .finally(() => setDeleteLoading(false))
  }

  return (
    <>
      <DefaultLoader active={loading} />
      <ActionsContainer>
        <Input
          placeholder="Pesquisar"
          value={search}
          onChange={(_, { value }) => setSearch(value.toUpperCase())}
          onKeyPress={handleSearchKeyPress}
          action={<Button icon="search" onClick={searchLicense} />}
        />
      </ActionsContainer>
      <Table selectable striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="Nome de usuário" />
            <Table.HeaderCell content="Nome do computador" />
            <Table.HeaderCell content="Proprietário" />
            <Table.HeaderCell content="Sistema" />
            <Table.HeaderCell content="Ativo" />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {licenses.map(license => (
            <Table.Row key={license.id}>
              <Table.Cell content={license.username} />
              <Table.Cell content={license.computerName} />
              <Table.Cell content={license.person?.name} />
              <Table.Cell content={license.system} />
              <Table.Cell>
                <Checkbox
                  toggle
                  checked={license.active}
                  onChange={() => handleLicenseStatusCheck(license)}
                  disabled={!canUpdateStatus}
                />
              </Table.Cell>
              <Table.Cell textAlign="right">
                <ItemControllers
                  onDeleteClick={() => handleDeleteClick(license)}
                  permissionsToDelete={can.license.delete}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={6} textAlign="right">
              <PaginationControllers
                count={licenses.length}
                page={pagination.page}
                limit={pagination.limit}
                total={totalItems}
                loading={loading}
                goToFirstPage={goToFirstPage}
                goToPreviousPage={goToPreviousPage}
                goToNextPage={goToNextPage}
                goToLastPage={goToLastPage}
                onChangeLimit={changeLimit}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <ConfirmationModal
        open={changeLicenseStatusModal}
        handleModal={setChangeLicenseStatusModal}
        onConfirm={handleChangeLicenseStatus}
        onDecline={() => setChangeLicenseStatusModal(false)}
      >
        <span>Deseja alterar o status desta licença?</span>
        <br />
        <strong>
          {selectedLicense?.username} - {selectedLicense?.person?.name} -{' '}
          {selectedLicense?.system}
        </strong>
      </ConfirmationModal>
      <ConfirmationModal
        open={deleteModal}
        loading={deleteLoading}
        handleModal={setDeleteModal}
        onConfirm={handleDeleteLicense}
        onDecline={() => setDeleteModal(false)}
      >
        <span>Deseja alterar o status desta licença?</span>
        <br />
        <strong>
          {selectedLicense?.username} - {selectedLicense?.person?.name} -{' '}
          {selectedLicense?.system}
        </strong>
      </ConfirmationModal>
    </>
  )
}
