import React from 'react'
import { Segment } from 'semantic-ui-react'

import { Show } from '../../../../../../components/Show'

import { useOrder } from '../../hooks'
import { OrderItemsHeader } from './OrderItemsHeader'
import { OrderItemsTable } from './OrderItemsTable'

export const OrderItems: React.FC = () => {
  const { order } = useOrder()

  const orderExist = !!order

  return (
    <Show condition={orderExist}>
      <Segment>
        <OrderItemsHeader />
        <OrderItemsTable />
      </Segment>
    </Show>
  )
}
