export function mask(value: string, pattern: string): string {
  let i = 0

  return pattern.replace(/#/g, () => value[i++] || '')
}

export function clearMask(value: string): string {
  return value.replace(/\D/g, '')
}

export function cpf(value: string): string {
  const pattern = '###.###.###-##'

  return mask(value, pattern)
}

export function cnpj(value: string): string {
  const pattern = '##.###.###/####-##'

  return mask(value, pattern)
}

export function phone(value: string): string {
  const sanitizedValue = clearMask(value).slice(2)
  const patterns: Record<number, string> = {
    10: '(##) ####-####',
    11: '(##) # ####-####',
    12: '+## (##) ####-####',
    13: '+## (##) # ####-####'
  }
  const pattern = patterns[sanitizedValue.length]

  return pattern ? mask(sanitizedValue, pattern) : sanitizedValue
}

export const masks = {
  cpf,
  cnpj,
  phone
}
