import React from 'react'

import { AccountFormProvider } from './context'
import { AccountMain } from './components'

export const AccountForm: React.FC = () => {
  return (
    <AccountFormProvider>
      <AccountMain />
    </AccountFormProvider>
  )
}
