import React, { useState, useEffect, useContext } from 'react'
import { Table } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

import { PaginationContext } from '../../../../contexts/PaginationContext'
import { DefaultLoader } from '../../../../components/DefaultLoader'
import { pages } from '../../../../config/pages'
import { AuthContext } from '../../../../contexts/AuthContext'

import { UserFormatted } from '../../utils/formatUser'
import { useUsersList } from './hooks/useUsersList'
import { useUser } from './hooks/useUser'
import {
  UsersTableActions,
  UsersTableHeader,
  UsersTableRow,
  UsersTableFooter,
  ConfirmDeleteModal
} from './components'

export const UsersTable: React.FC = () => {
  const {
    pagination,
    goToFirstPage,
    goToPreviousPage,
    goToNextPage,
    goToLastPage,
    changeOrderBy,
    changeLimit
  } = useContext(PaginationContext)
  const { users, totalItems, loading, fetchUsers } = useUsersList()
  const { loading: deleteLoading, deleteUser } = useUser()
  const { user: systemUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState<UserFormatted>()

  useEffect(() => {
    fetchUsers(pagination)
  }, [pagination])

  function redirectToCreateForm() {
    navigate(`${pages.users.path}/inclusao`)
  }

  function redirectToUpdateForm(user: UserFormatted) {
    const isTryingUpdateYourOwnAccount = systemUser?.id === user.id

    if (isTryingUpdateYourOwnAccount) {
      navigate(`${pages.users.path}/conta`)
    }

    if (!isTryingUpdateYourOwnAccount) {
      navigate(`${pages.users.path}/edicao?id=${user.id}`)
    }
  }

  function openConfirmDeleteModal(user: UserFormatted) {
    setSelectedUser(user)
    setShowConfirmDeleteModal(true)
  }

  function handleDeleteUser(user: UserFormatted) {
    deleteUser(user.id, () => {
      setShowConfirmDeleteModal(false)
      fetchUsers(pagination)
    })
  }

  return (
    <>
      <DefaultLoader active={loading} />

      <UsersTableActions
        pagination={pagination}
        onCreateButtonClick={redirectToCreateForm}
      />

      <Table selectable striped compact sortable>
        <UsersTableHeader
          pagination={pagination}
          changeOrderBy={changeOrderBy}
        />
        <Table.Body>
          {users.map(user => (
            <UsersTableRow
              key={user.id}
              user={user}
              onUpdateButtonClick={redirectToUpdateForm}
              onDeleteButtonClick={openConfirmDeleteModal}
            />
          ))}
        </Table.Body>
        <UsersTableFooter
          colSpan={6}
          count={users.length}
          pagination={pagination}
          totalItems={totalItems}
          loading={loading}
          goToFirstPage={goToFirstPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
          changeLimit={changeLimit}
        />
      </Table>

      <ConfirmDeleteModal
        open={showConfirmDeleteModal}
        user={selectedUser}
        loading={deleteLoading}
        handleModal={setShowConfirmDeleteModal}
        onConfirm={handleDeleteUser}
        onDecline={() => setShowConfirmDeleteModal(false)}
      />
    </>
  )
}
