import { SelectOption } from '../../../components/Form/Select'

import { Category } from '../../../services/category'
import { Person } from '../../../services/person'
import { User } from '../../../services/users'

export function categoryToSelectOption(category: Category): SelectOption {
  return {
    key: category.id,
    value: category.id,
    text: category.name
  }
}

export function personToSelectOption(person: Person): SelectOption {
  return {
    key: person.id,
    value: person.id,
    text: `${person.name}${person.document ? ' - ' + person.document : ''}`
  }
}

export function userToSelectOption(user: User): SelectOption {
  return {
    key: user.id,
    value: user.id,
    text: user.nickname
  }
}
