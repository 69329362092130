import React from 'react'

import { User } from '../../../../../../../../../services/users'
import { Form, Currency } from '../../../../../../../../../components/Form'

interface ReadOnlyEmployeeFieldsProps {
  employee?: User
}

export const ReadOnlyEmployeeFields: React.FC<ReadOnlyEmployeeFieldsProps> = ({
  employee
}) => {
  return (
    <Form.Group>
      <Form.Input
        label="Colaborador"
        value={employee?.nickname}
        width={13}
        readOnly
      />
      <Currency name="employeeCommission" label="Comissão" width={3} readOnly />
    </Form.Group>
  )
}
