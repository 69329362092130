import React from 'react'
import { Dropdown } from 'semantic-ui-react'

interface LimitProps {
  limit: number
  onChangeLimit: (value: number) => void
}

export const Limit: React.FC<LimitProps> = ({
  limit,
  onChangeLimit
}: LimitProps) => {
  return (
    <Dropdown
      selection
      compact
      value={limit}
      options={[
        { key: 10, value: 10, text: '10' },
        { key: 25, value: 25, text: '25' },
        { key: 50, value: 50, text: '50' },
        { key: 100, value: 100, text: '100' }
      ]}
      onChange={(_, data) => onChangeLimit(Number(data.value))}
    />
  )
}
