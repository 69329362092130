import { toast } from 'react-toastify'
import { Buffer } from 'buffer'

import api from './api'

type FineType = 'VALUE' | 'PERCENT'

export interface Fine {
  type: FineType
  date: string
  value: number
}

type InterestType = 'VALUE_DAY' | 'PERCENT_MONTHLY'

export interface Interest {
  type: InterestType
  date: string
  value: number
}

type DiscountType =
  | 'VALUE_IN_DATE'
  | 'PERCENT_IN_DATE'
  | 'VALUE_BEFORE_DATE'
  | 'PERCENT_BEFORE_DATE'

export interface Discount {
  type: DiscountType
  date: string
  value: number
}

type BoletoType = 'INTER'

export type BoletoStatus =
  | 'CREATED'
  | 'OPENED'
  | 'PAID'
  | 'OVERDUE'
  | 'CANCELED'
  | 'EXPIRED'

export interface Boleto {
  id: string
  payerId: string
  type: BoletoType
  status: BoletoStatus
  statusDate: string
  dueDate: string
  ourNumber: string
  bankNumber?: string
  value: number
  rebateValue?: number
  paidValue: number
  fine?: Fine
  interest?: Interest
  discounts?: Discount[]
  messages?: string[]
  barCode?: string
  digitableLine?: string
  createdAt?: string
}

export async function issueBoleto(
  orderId: string,
  chargeId: string,
  data: Partial<Boleto>
): Promise<Boleto> {
  const response = await api.post(
    `/orders/${orderId}/charges/${chargeId}/boleto`,
    data
  )
  const boleto = response.data

  return boleto
}

export interface GetBoletoPdf {
  content: Buffer
  mimetype: string
}

export async function getBoletoPdf(
  orderId: string,
  chargeId: string
): Promise<GetBoletoPdf | undefined> {
  try {
    const response = await api.get(
      `/orders/${orderId}/charges/${chargeId}/boleto/pdf`,
      { responseType: 'arraybuffer', timeout: 5000 }
    )

    return {
      content: Buffer.from(response.data, 'binary'),
      mimetype: response.headers['content-type'] as string
    }
  } catch (err: any) {
    if (err.response?.data instanceof Blob) {
      const text = await err.response.data.text()
      const data = JSON.parse(text)
      toast.error(data?.error)

      return
    }

    toast.error('Houve um erro ao tentar baixar o boleto')
  }
}

export async function cancelBoleto(
  orderId: string,
  chargeId: string
): Promise<Boleto> {
  const response = await api.delete(
    `/orders/${orderId}/charges/${chargeId}/boleto`
  )
  const boleto = response.data

  return boleto
}

export async function updateBoletoStatus(
  orderId: string,
  chargeId: string
): Promise<Boleto> {
  const response = await api.patch(
    `/orders/${orderId}/charges/${chargeId}/boleto/status`
  )
  const boleto = response.data

  return boleto
}
