import { useState } from 'react'

import { listPeople, Person } from '../../../../../services/person'

export function useCustomer() {
  const [customers, setCustomers] = useState<Person[]>([])
  const [loading, setLoading] = useState(false)

  function listCustomers(search: string) {
    const searchBy = ['document', 'name']

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => listPeople({ search, searchBy }))
      .then(data => setCustomers(data.people))
      .finally(() => setLoading(false))
  }

  return {
    customers,
    loading,
    listCustomers
  }
}
