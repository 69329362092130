import React from 'react'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'

import { PermissionGroup } from './PermissionGroup'
import { OnCheck } from './AllPermissions'
import { BlankOption } from './styles'

interface SystemPermissionsProps {
  onChange: (data: OnCheck) => void
  isChecked: (resource: string, actions: string[]) => boolean
}

export const SystemPermissions: React.FC<SystemPermissionsProps> = ({
  onChange,
  isChecked
}) => {
  function handleCheck(
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) {
    const value = data.value as string
    const checked = data.checked as boolean
    onChange({ value, checked })
  }

  return (
    <>
      <PermissionGroup label="Sistema:">
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('system', ['Get', 'List', 'GetRootDirectory'])}
          value="system:Get,List,GetRootDirectory"
          label="Ler"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('system', ['Create'])}
          value="system:Create"
          label="Cadastrar"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('system', ['Update', 'UpdateToken'])}
          value="system:Update,UpdateToken"
          label="Atualizar"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('system', ['Delete'])}
          value="system:Delete"
          label="Excluir"
        />
      </PermissionGroup>
      <PermissionGroup>
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('directory', ['Get'])}
          value="directory:Get"
          label="Ler pasta"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('directory', ['Create'])}
          value="directory:Create"
          label="Adicionar pasta"
        />
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('directory', ['Delete'])}
          value="directory:Delete"
          label="Remover pasta"
        />
      </PermissionGroup>
      <PermissionGroup>
        <BlankOption />
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('file', ['Create'])}
          value="file:Create"
          label="Enviar arquivo"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('file', ['UpdateType'])}
          value="file:UpdateType"
          label="Atualizar arquivo"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('file', ['Delete'])}
          value="file:Delete"
          label="Excluir arquivo"
        />
      </PermissionGroup>
      <PermissionGroup>
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('license', ['List'])}
          value="license:List"
          label="Ler licenças"
        />
        <BlankOption />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('license', ['UpdateStatus'])}
          value="license:UpdateStatus"
          label="Atualizar licença"
        />
        <Checkbox
          onChange={handleCheck}
          checked={isChecked('license', ['Delete'])}
          value="license:Delete"
          label="Excluir licença"
        />
      </PermissionGroup>
    </>
  )
}
