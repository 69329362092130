import api from './api'

import { PriceListItem } from './priceListItem'

interface PriceListExternal {
  id: string
  name: string
  items: PriceListItem[]
}

export interface PriceList {
  id: string
  name: string
  items: PriceListItem[]
}

export interface PriceListPaginate {
  page?: number
  limit?: number
  orderBy?: string
  order?: 'ASC' | 'DESC'
  search?: string
}

export interface PriceListListResult {
  priceLists: PriceList[]
  total: number
}

function toInternalFormat(priceList: PriceListExternal): PriceList {
  return {
    id: priceList.id,
    name: priceList.name,
    items: priceList.items
  }
}

function toExternalFormat(
  priceList: Partial<PriceList>
): Partial<PriceListExternal> {
  return {
    name: priceList.name
  }
}

export async function getPriceList(id: string): Promise<PriceList> {
  const response = await api.get(`/price-lists/${id}`)
  const priceList = toInternalFormat(response.data)

  return priceList
}

export async function listPriceLists(
  paginate?: PriceListPaginate
): Promise<PriceListListResult> {
  const { page, limit, orderBy, order, search } = paginate || {}
  const response = await api.get(`/price-lists`, {
    params: { page, limit, orderBy, order, search }
  })
  const { items, total } = response.data
  const priceLists = items.map(toInternalFormat)

  return {
    priceLists,
    total
  }
}

export async function createPriceList(
  data: Partial<PriceList>
): Promise<PriceList> {
  const body = toExternalFormat(data)
  const response = await api.post(`/price-lists`, body)
  const priceList = toInternalFormat(response.data)

  return priceList
}

export async function updatePriceList(
  id: string,
  data: Partial<PriceList>
): Promise<PriceList> {
  const body = toExternalFormat(data)
  const response = await api.put(`/price-lists/${id}`, body)
  const priceList = toInternalFormat(response.data)

  return priceList
}

export async function deletePriceList(id: string): Promise<boolean> {
  const response = await api.delete(`/price-lists/${id}`)
  const result = response.data

  return result.status
}
