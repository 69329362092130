import React from 'react'

import { Limit } from './Limit'
import { Buttons } from './Buttons'
import { Wrapper } from './styles'

interface PaginationControllersProps {
  count?: number
  page?: number
  limit?: number
  total?: number
  loading?: boolean
  goToFirstPage?: () => void
  goToPreviousPage?: () => void
  goToNextPage?: () => void
  goToLastPage?: () => void
  onChangeLimit?: (value: number) => void
}

export const PaginationControllers: React.FC<PaginationControllersProps> = ({
  count = 0,
  page,
  limit = 0,
  total,
  loading,
  goToFirstPage,
  goToPreviousPage,
  goToNextPage,
  goToLastPage,
  onChangeLimit
}) => {
  return (
    <Wrapper>
      {onChangeLimit && (
        <div>
          Resultados por página{' '}
          <Limit limit={limit} onChangeLimit={onChangeLimit} />
        </div>
      )}
      <Buttons
        page={page}
        limit={limit}
        total={total}
        loading={loading}
        goToFirstPage={goToFirstPage}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
        goToLastPage={goToLastPage}
      />
      <span>{count} registros</span>
    </Wrapper>
  )
}
