import React from 'react'
import { Button, Modal } from 'semantic-ui-react'

import { Viewer } from './styles'

interface PDFViewerModalProps {
  open: boolean
  title?: string
  pdf?: Buffer
  emptyMessage?: string
  handleModal: (open: boolean) => void
}

export const PDFViewerModal: React.FC<PDFViewerModalProps> = ({
  open,
  title,
  pdf,
  emptyMessage = '',
  handleModal
}) => {
  return (
    <Modal
      open={open}
      onOpen={() => handleModal(true)}
      onClose={() => handleModal(false)}
      size={pdf ? 'fullscreen' : 'mini'}
      closeOnDimmerClick={false}
      closeIcon
    >
      {pdf ? (
        <Viewer
          title={title}
          src={`data:application/pdf;base64,${pdf?.toString('base64')}`}
        />
      ) : (
        <>
          <Modal.Header content={title} />
          <Modal.Content content={emptyMessage} />
          <Modal.Actions>
            <Button content="OK" onClick={() => handleModal(false)} primary />
          </Modal.Actions>
        </>
      )}
    </Modal>
  )
}
