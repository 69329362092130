import React, { useState, useEffect, useContext } from 'react'
import { Table, Input } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

import { deleteCertificate, Certificate } from '../../services/certificate'
import { PaginationContext, Pagination } from '../../contexts/PaginationContext'
import { useCan } from '../../hooks/useCan'
import { can } from '../../config/permissions'
import { PaginationControllers } from '../../components/PaginationControllers'
import { ConfirmationModal } from '../../components/ConfirmationModal'
import { ItemControllers } from '../../components/Table'
import { DefaultLoader } from '../../components/DefaultLoader'
import { ActionsContainer } from '../../components/RegistrationContainer/styles'
import { Button } from '../../components/Button'

import { CertificateFormatted } from './utils'
import { pages } from '../../config/pages'

interface CertificatesTableProps {
  certificates: CertificateFormatted[]
  totalItems: number
  loading?: boolean
  fetchCertificates: (pagination: Pagination) => void
  onCreateClick?: () => void
  onUpdateClick?: (certificate: Certificate) => void
}

export const CertificatesTable: React.FC<CertificatesTableProps> = ({
  certificates,
  totalItems,
  loading,
  fetchCertificates,
  onCreateClick,
  onUpdateClick
}) => {
  const {
    pagination,
    goToFirstPage,
    goToPreviousPage,
    goToNextPage,
    goToLastPage,
    changeLimit,
    changeQuery
  } = useContext(PaginationContext)
  const [search, setSearch] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [createOrderModal, setCreateOrderModal] = useState(false)
  const [loadingCreateOrder, setLoadingCreateOrder] = useState(false)
  const [selectedCertificate, setSelectedCertificate] = useState<Certificate>()
  const canCreate = useCan(can.certificate.create)
  const navigate = useNavigate()

  useEffect(() => {
    fetchCertificates(pagination)
  }, [pagination])

  function searchCertificate() {
    changeQuery({ search })
  }

  function handleSearchKeyPress(evt: React.KeyboardEvent) {
    if (evt.key === 'Enter') {
      searchCertificate()
    }
  }

  function handleUpdateClick(certificate: Certificate) {
    onUpdateClick?.(certificate)
  }

  function handleDeleteClick(certificate: Certificate) {
    setDeleteModal(true)
    setSelectedCertificate(certificate)
  }

  function handleDeleteCertificate() {
    if (!selectedCertificate) return

    Promise.resolve()
      .then(() => setDeleteLoading(true))
      .then(() => deleteCertificate(selectedCertificate.id))
      .then(() => setDeleteModal(false))
      .then(() => fetchCertificates(pagination))
      .finally(() => setDeleteLoading(false))
  }

  function handleCreateOrderClick(certificate: Certificate) {
    setSelectedCertificate(certificate)
    setCreateOrderModal(true)
  }

  function handleCreateOrder() {
    if (!selectedCertificate) return

    const { id } = selectedCertificate
    const orderFormUrl = `${pages.orders.path}/inclusao?certificateId=${id}`

    Promise.resolve()
      .then(() => setLoadingCreateOrder(true))
      .then(() => navigate(orderFormUrl))
      .catch(() => setLoadingCreateOrder(false))
  }

  return (
    <>
      <DefaultLoader active={loading} />
      <ActionsContainer>
        <Input
          placeholder="Pesquisar"
          value={search}
          onChange={(_, { value }) => setSearch(value.toUpperCase())}
          onKeyPress={handleSearchKeyPress}
          action={<Button icon="search" onClick={searchCertificate} />}
        />
        {onCreateClick && canCreate && (
          <Button
            content="Incluir"
            onClick={onCreateClick}
            floated="right"
            primary
          />
        )}
      </ActionsContainer>
      <Table selectable striped compact sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="Certificado" />
            <Table.HeaderCell content="Vencimento" />
            <Table.HeaderCell content="Cliente" />
            <Table.HeaderCell content="Telefone" />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {certificates.map(certificate => (
            <Table.Row
              key={certificate.id}
              warning={certificate.warning}
              error={certificate.expired}
            >
              <Table.Cell content={certificate.service.description} />
              <Table.Cell content={certificate.expirationDateFormatted} />
              <Table.Cell content={certificate.customer.name} />
              <Table.Cell content={certificate.customerPhoneFormatted} />
              <Table.Cell textAlign="right">
                <>
                  <Button
                    icon="file alternate"
                    size="mini"
                    popup="Gerar pedido"
                    onClick={() => handleCreateOrderClick(certificate)}
                    invertedPopup
                    circular
                  />
                  <ItemControllers
                    onUpdateClick={() => handleUpdateClick(certificate)}
                    onDeleteClick={() => handleDeleteClick(certificate)}
                    permissionsToUpdate={can.certificate.delete}
                    permissionsToDelete={can.certificate.delete}
                  />
                </>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={5} textAlign="right">
              <PaginationControllers
                count={certificates.length}
                page={pagination.page}
                limit={pagination.limit}
                total={totalItems}
                loading={loading}
                goToFirstPage={goToFirstPage}
                goToPreviousPage={goToPreviousPage}
                goToNextPage={goToNextPage}
                goToLastPage={goToLastPage}
                onChangeLimit={changeLimit}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <ConfirmationModal
        open={deleteModal}
        loading={deleteLoading}
        handleModal={setDeleteModal}
        onConfirm={handleDeleteCertificate}
        onDecline={() => setDeleteModal(false)}
      >
        <span>
          Tem certeza que deseja excluir o registro desse certificado?
        </span>
        <br />
        <strong>
          {selectedCertificate?.service.description} para{' '}
          {selectedCertificate?.customer.name}
        </strong>
      </ConfirmationModal>
      <ConfirmationModal
        open={createOrderModal}
        handleModal={setCreateOrderModal}
        onConfirm={handleCreateOrder}
        onDecline={() => setCreateOrderModal(false)}
        loading={loadingCreateOrder}
      >
        <span>
          Deseja criar um pedido de {selectedCertificate?.service.description}{' '}
          para {selectedCertificate?.customer.name}?
        </span>
      </ConfirmationModal>
    </>
  )
}
