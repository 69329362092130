export const pages = {
  // Publics
  login: {
    title: 'Login',
    path: '/'
  },
  cobPixQrCode: {
    title: 'Pagamento Pix',
    path: '/public/pix/qrcode'
  },
  bolepixPdf: {
    title: 'Bolepix',
    path: '/public/bolepix/pdf'
  },

  // Privates
  home: {
    title: 'Home',
    path: '/home'
  },
  users: {
    title: 'Usuários',
    path: '/usuarios'
  },
  people: {
    title: 'Pessoas',
    path: '/pessoas'
  },
  systems: {
    title: 'Sistemas',
    path: '/sistemas'
  },
  files: {
    title: 'Arquivos',
    path: '/:slug/arquivos'
  },
  licenses: {
    title: 'Licenças',
    path: '/licencas'
  },
  services: {
    title: 'Serviços',
    path: '/servicos'
  },
  products: {
    title: 'Produtos',
    path: '/produtos'
  },
  priceLists: {
    title: 'Tabelas de preços',
    path: '/tabelas-de-precos'
  },
  orders: {
    title: 'Pedidos',
    path: '/pedidos'
  },
  accounts: {
    title: 'Financeiro',
    path: '/financeiro'
  },
  commission: {
    title: 'Comissão',
    path: '/comissao'
  },
  cost: {
    title: 'Custo',
    path: '/custo'
  },
  signatures: {
    title: 'Assinaturas',
    path: '/assinaturas'
  },
  categories: {
    title: 'Categorias',
    path: '/categorias'
  },
  groups: {
    title: 'Grupos',
    path: '/grupos'
  },
  certificates: {
    title: 'Certificados',
    path: '/certificados'
  }
}
