import Joi from 'joi'

import { currency } from '../../../../../utils/validation'

const messages = {
  description: {
    required: 'Descrição é obrigatória',
    max: 'Permitido no máximo 60 caracteres'
  },
  dueDateFormatted: {
    required: 'Data de vencimento é obrigatório'
  },
  amountFormatted: {
    required: 'Valor é obrigatório',
    positive: 'Valor deve ser positivo'
  },
  amountPaidFormatted: {
    positive: 'Valor pago deve ser positivo'
  },
  personId: {
    required: 'Cliente / Fornecedor é obrigatório'
  },
  categoryId: {
    required: 'Categoria é obrigatória'
  }
}

export const accountSchema = Joi.object({
  id: Joi.string().uuid({ version: ['uuidv4'] }),
  description: Joi.string().max(120).trim().required().messages({
    'any.required': messages.description.required,
    'string.empty': messages.description.required,
    'string.max': messages.description.max
  }),
  dueDateFormatted: Joi.required().messages({
    'any.required': messages.dueDateFormatted.required
  }),
  amountFormatted: Joi.custom(currency().positive()).required().messages({
    'any.required': messages.amountFormatted.required,
    'number.currency.positive': messages.amountFormatted.positive
  }),
  personId: Joi.string()
    .required()
    .uuid({ version: ['uuidv4'] })
    .messages({
      'any.required': messages.personId.required
    }),
  categoryId: Joi.string()
    .required()
    .uuid({ version: ['uuidv4'] })
    .messages({
      'any.required': messages.categoryId.required
    }),
  groupId: Joi.string()
    .optional()
    .uuid({ version: ['uuidv4'] })
})
