import api from './api'
import { Buffer } from 'buffer'

export interface CommissionReportOptions {
  start: Date
  end: Date
  employeeId?: string
  partnerId?: string
  categories: string
}

export async function getCommissionReport(
  options: CommissionReportOptions
): Promise<Buffer | undefined> {
  const FIFTEEN_SECONDS = 15000
  const response = await api.get('/reports/commission', {
    params: options,
    responseType: 'arraybuffer',
    timeout: FIFTEEN_SECONDS
  })

  if (response.status === 204) return

  return Buffer.from(response.data, 'binary')
}

export interface CostReportOptions {
  start: Date
  end: Date
}

export async function getCostReport(
  options: CostReportOptions
): Promise<Buffer | undefined> {
  const FIFTEEN_SECONDS = 15000
  const response = await api.get('/reports/cost', {
    params: options,
    responseType: 'arraybuffer',
    timeout: FIFTEEN_SECONDS
  })

  if (response.status === 204) return

  return Buffer.from(response.data, 'binary')
}
