import React, { useContext, useMemo } from 'react'
import { Button, Modal, Message } from 'semantic-ui-react'
import { Form as FinalForm } from 'react-final-form'

import { Charge } from '../../../../../../../../services/charge'
import { AuthContext } from '../../../../../../../../contexts/AuthContext'
import {
  Form,
  Select,
  DateInput,
  Currency
} from '../../../../../../../../components/Form'
import { Show } from '../../../../../../../../components/Show'
import {
  dateToLocaleDate,
  getTomorrow,
  getToday,
  localeDateToDate
} from '../../../../../../../../utils/date'
import { getFormValidate } from '../../../../../../../../utils/form'
import { stringToNumber } from '../../../../../../../../utils/number'

import { useOrder, useOrderCharge } from '../../../../hooks'
import {
  chargeTypeOptions,
  filterOptionsByPermissions
} from '../../utils/charge'
import { chargeSchema } from './schema'

const validateForm = getFormValidate(chargeSchema)

interface AddChargeModalProps {
  open: boolean
  handleModal: (open: boolean) => void
}

const initialValues = {
  dueDate: dateToLocaleDate(getTomorrow()),
  amount: 0
}

export const AddChargeModal: React.FC<AddChargeModalProps> = ({
  open,
  handleModal
}) => {
  const { order, loading } = useOrder()
  const { addCharge } = useOrderCharge()
  const { permissions } = useContext(AuthContext)

  const amountDefault = useMemo(() => {
    if (!order) return 0

    return order.totalOrder - order.totalOfCharges
  }, [order])

  const options = useMemo(() => {
    return filterOptionsByPermissions(chargeTypeOptions, permissions)
  }, [])

  function handleSubmit(values: Charge) {
    const data = {
      paymentType: values.paymentType,
      dueDate: values.dueDate && localeDateToDate(values.dueDate).toISOString(),
      amount: stringToNumber(values.amount)
    }
    addCharge(data, () => handleModal(false))
  }

  return (
    <Modal
      open={open}
      onOpen={() => handleModal(true)}
      onClose={() => handleModal(false)}
      size="mini"
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header content="Adicionar cobrança" />
      <Modal.Content>
        <FinalForm
          onSubmit={handleSubmit}
          initialValues={{
            ...initialValues,
            paymentType: options?.[0]?.value,
            amount: amountDefault
          }}
          validate={validateForm}
          validateOnBlur
        >
          {({ handleSubmit }) => (
            <>
              <Show condition={options.length === 0}>
                <Message
                  content="Você não possui permissão para emitir cobranças"
                  warning
                />
              </Show>
              <Form onSubmit={handleSubmit} id="form-charge" loading={loading}>
                <Select name="paymentType" options={options} />
                <DateInput
                  name="dueDate"
                  minDate={dateToLocaleDate(getToday())}
                />
                <Currency name="amount" />
              </Form>
            </>
          )}
        </FinalForm>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Salvar"
          form="form-charge"
          loading={loading}
          disabled={loading || options.length === 0}
          primary
        />
      </Modal.Actions>
    </Modal>
  )
}
