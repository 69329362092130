import React, { useEffect, useState } from 'react'
import { DropdownOnSearchChangeData, Form, Segment } from 'semantic-ui-react'
import { debounce } from 'debounce'

import {
  Currency,
  DateInput,
  Input,
  Select,
  Checkbox
} from '../../../../../../components/Form'
import { SelectOption } from '../../../../../../components/Form/Select'

import { useAccount, useCategory, usePeople, useGroups } from '../../hooks'
import {
  categoryToSelectOption,
  personToSelectOption,
  groupToSelectOptions
} from '../../utils/selectOptions'
import { Show } from '../../../../../../components/Show'

const costTypeOptions = [
  { key: 'FIXED', value: 'FIXED', text: 'Custo fixo' },
  { key: 'VARIABLE', value: 'VARIABLE', text: 'Custo variável' },
  { key: 'NONE', value: 'NONE', text: 'Não aplicável' }
]

const paymentTypeOptions = [
  { key: 'BOLETO', value: 'BOLETO', text: 'Boleto' },
  { key: 'COB_PIX', value: 'COB_PIX', text: 'Pix' }
]

const DEBOUNCE_TIME = 500

export const FormFields: React.FC = () => {
  const { account, person, group } = useAccount()
  const { people, loading: loadingPeople, listPeople } = usePeople()
  const [peopleOptions, setPeopleOptions] = useState<SelectOption[]>([])
  const {
    categories,
    loading: loadingCategories,
    listCategories
  } = useCategory()
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([])
  const { groups, loading: loadingGroups, listGroups } = useGroups()
  const [groupOptions, setGroupOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    if (!account) return

    if (!person) return

    setPeopleOptions([personToSelectOption(person)])
  }, [person])

  useEffect(() => {
    setPeopleOptions(people.map(personToSelectOption))
  }, [people])

  useEffect(() => {
    listCategories()
  }, [])

  useEffect(() => {
    setCategoryOptions(categories.map(categoryToSelectOption))
  }, [categories])

  useEffect(() => {
    setGroupOptions(groups.map(groupToSelectOptions))
  }, [groups])

  useEffect(() => {
    if (!account) return

    if (!group) return

    setGroupOptions([groupToSelectOptions(group)])
  }, [group])

  const searchPerson = (data: DropdownOnSearchChangeData) => {
    const { searchQuery } = data

    return debounce(() => listPeople(searchQuery), DEBOUNCE_TIME)()
  }

  const searchGroup = (data: DropdownOnSearchChangeData) => {
    const { searchQuery } = data

    return debounce(() => listGroups(searchQuery), DEBOUNCE_TIME)()
  }

  const showAutoWriteOffCheck = !account

  return (
    <Segment>
      <input name="id" hidden />
      <Form.Group widths={3}>
        <Select
          name="personId"
          label="Pessoa"
          options={peopleOptions}
          loading={loadingPeople}
          onSearchChange={(_, data) => searchPerson(data)}
          search
          required
          uppercase
        />
        <Select
          name="groupId"
          label="Grupo"
          options={groupOptions}
          loading={loadingGroups}
          onSearchChange={(_, data) => searchGroup(data)}
          search
          uppercase
        />
        <Select
          name="categoryId"
          label="Categoria"
          options={categoryOptions}
          loading={loadingCategories}
          required
          uppercase
        />
      </Form.Group>
      <Form.Group>
        <Input name="typeFormatted" label="Tipo" width={4} readOnly />
        <Select
          name="costType"
          label="Custo"
          width={4}
          options={costTypeOptions}
          required
        />
        <Input
          name="description"
          label="Descrição"
          width={8}
          maxLength={120}
          required
          uppercase
        />
      </Form.Group>
      <Form.Group widths={showAutoWriteOffCheck ? 4 : 3}>
        <DateInput
          name="dueDateFormatted"
          label="Data de vencimento"
          required
        />
        <Select
          name="paymentType"
          label="Forma de pagamento"
          options={paymentTypeOptions}
          required
        />
        <Currency name="amountFormatted" label="Valor" required />
        <Show
          condition={showAutoWriteOffCheck}
          component={
            <Checkbox name="autoWriteOff" label="Baixa automática" alignField />
          }
        />
      </Form.Group>
    </Segment>
  )
}
