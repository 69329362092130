import React from 'react'
import { Button } from 'semantic-ui-react'

interface ButtonsProps {
  page?: number
  limit?: number
  total?: number
  loading?: boolean
  goToFirstPage?: () => void
  goToPreviousPage?: () => void
  goToNextPage?: () => void
  goToLastPage?: () => void
}

export const Buttons: React.FC<ButtonsProps> = ({
  page = 0,
  limit = 0,
  total = 0,
  loading,
  goToFirstPage,
  goToPreviousPage,
  goToNextPage,
  goToLastPage
}) => {
  return (
    <Button.Group>
      {goToFirstPage && (
        <Button
          icon="angle double left"
          onClick={() => goToFirstPage()}
          disabled={page === 1 || loading}
        />
      )}
      {goToPreviousPage && (
        <Button
          icon="angle left"
          onClick={() => goToPreviousPage()}
          disabled={page <= 1 || loading}
        />
      )}
      {page && <Button.Or text={page} />}
      {goToNextPage && (
        <Button
          icon="angle right"
          onClick={() => goToNextPage()}
          disabled={page * limit >= total || loading}
        />
      )}
      {goToLastPage && (
        <Button
          icon="angle double right"
          onClick={() => goToLastPage()}
          disabled={page * limit >= total || loading}
        />
      )}
    </Button.Group>
  )
}
