import { CustomValidator } from 'joi'
import { validateBr } from 'js-brasil'
import moment from 'moment'

import { stringToNumber } from './number'

interface Helper {
  error: (code: string) => any
}

export function validateCpf(value: string, helper: Helper): string {
  const result = validateBr.cpf(value)

  if (!result) return helper.error('string.cpf')

  return value
}

export function validateCnpj(value: string, helper: Helper): string {
  const result = validateBr.cnpj(value)

  if (!result) return helper.error('string.cnpj')

  return value
}

export function validateDocument(value: string, helper: Helper): string {
  const result = validateBr.cpf(value) || validateBr.cnpj(value)

  if (!result) return helper.error('string.document')

  return value
}

export function validatePhone(value: string, helper: Helper): string {
  const result = validateBr.telefone(value.replace('+55', ''))

  if (!result) return helper.error('string.phone')

  return value
}

export function validateDate(value: string, helper: Helper): string {
  const format = 'DD/MM/YYYY'
  const result = moment(value, format, true).isValid()

  if (!result) return helper.error('string.custom.date')

  return value
}

export function validateDateTime(value: string, helper: Helper): string {
  const format = 'DD/MM/YYYY HH:mm'
  const result = moment(value, format, true).isValid()

  if (!result) return helper.error('string.custom.datetime')

  return value
}

export function currency(): Record<string, () => CustomValidator> {
  return {
    positive: () => {
      return function (value: string, helpers: Helper) {
        const numericValue = stringToNumber(value)

        if (numericValue < 0) {
          return helpers.error('number.currency.positive')
        }

        return value
      }
    }
  }
}

function isEmail(value: string): boolean {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
}

export function listOfEmail(value: string, helper: Helper): string | undefined {
  if (!value) return

  const values = value.split(';').map(value => value.trim())

  const isEmailList = values.every(isEmail)

  if (!isEmailList) {
    return helper.error('array.custom.string.email')
  }

  return value
}
