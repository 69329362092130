import { Buffer } from 'buffer'

import api from './api'

export interface GetBolepixPdf {
  content: Buffer
  mimetype: string
}

export async function getBolepixPdf(
  orderId: string,
  chargeId: string
): Promise<GetBolepixPdf> {
  const response = await api.get(`/public/bolepix/${orderId}/${chargeId}/pdf`, {
    responseType: 'arraybuffer',
    timeout: 5000
  })

  return {
    content: Buffer.from(response.data, 'binary'),
    mimetype: response.headers['content-type'] as string
  }
}
