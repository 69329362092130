import { SelectOption } from '../../../components/Form/Select'
import { Group } from '../../../services/group'
import { PriceList } from '../../../services/priceList'

export function priceListToSelectOption(priceList: PriceList): SelectOption {
  return {
    key: priceList.id,
    value: priceList.id,
    text: priceList.name
  }
}

export function groupToSelectOption(group: Group): SelectOption {
  return {
    key: group.id,
    value: group.id,
    text: group.name
  }
}
