import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

interface AuthLoadingProps {
  active?: boolean
}

export const AuthLoading: React.FC<AuthLoadingProps> = ({ active }) => (
  <Dimmer active={active} inverted>
    <Loader active={active} size="large" content="Carregando..." />
  </Dimmer>
)
