import React, { useState } from 'react'
import { Header, Button } from 'semantic-ui-react'

import { FormActionsContainer } from '../../../../../../styles/FormActionsContainer'
import { useCan } from '../../../../../../hooks/useCan'
import { can } from '../../../../../../config/permissions'
import { Show } from '../../../../../../components/Show'
import { ConfirmationModal } from '../../../../../../components/ConfirmationModal'

import { useOrder, useOrderNfse } from '../../hooks'

export const OrderNfseHeader: React.FC = () => {
  const { order, loading } = useOrder()
  const { issueNfse } = useOrderNfse()
  const canIssueNfse = useCan(can.nfse.issue)
  const [showIssueNfseModal, setShowIssueNfseModal] = useState(false)

  function handleOpenIssueNfseModalClick() {
    setShowIssueNfseModal(true)
  }

  function handleConfirmIssue() {
    issueNfse(() => setShowIssueNfseModal(false))
  }

  const nfseExist = !!order?.nfse

  return (
    <FormActionsContainer>
      <Header as="h3" content="NFS-e" floated="left" />
      <Show
        condition={!nfseExist && canIssueNfse}
        component={
          <Button
            content="Gerar NFS-e"
            floated="right"
            onClick={handleOpenIssueNfseModalClick}
            primary
          />
        }
      />
      <ConfirmationModal
        open={showIssueNfseModal}
        handleModal={setShowIssueNfseModal}
        onConfirm={handleConfirmIssue}
        onDecline={() => setShowIssueNfseModal(false)}
        loading={loading}
      >
        Deseja emitir a Nota Fiscal de Serviço para este pedido?
      </ConfirmationModal>
    </FormActionsContainer>
  )
}
