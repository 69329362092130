import { User, UserPix } from '../../../services/users'
import { masks } from '../../../utils/mask'
import { numberToCurrency } from '../../../utils/number'

export interface UserPixFormatted extends UserPix {
  keyFormatted: string
}

export interface UserFormatted extends Omit<User, 'pix'> {
  roleFormatted: string
  phoneFormatted: string
  statusFormatted: string
  commissionFormatted: string
  pix?: UserPixFormatted
}

const rolesMap = {
  ADMIN: 'Administrador',
  EMPLOYEE: 'Colaborador',
  PARTNER: 'Parceiro'
}

const statusMap = {
  ACTIVE: 'Ativo',
  INACTIVE: 'Inativo'
}

export function formatUserPix(pix: UserPix): UserPixFormatted {
  const formatKey: Record<string, (value: string) => string> = {
    EMAIL: (value: string) => value,
    PHONE: (value: string) => masks.phone(value),
    CPF: (value: string) => masks.cpf(value),
    CNPJ: (value: string) => masks.cnpj(value)
  }

  return {
    ...pix,
    keyFormatted: formatKey[pix.type](pix.key)
  }
}

export function formatUser(user: User): UserFormatted {
  return {
    ...user,
    roleFormatted: rolesMap[user.role],
    phoneFormatted: masks.phone(user.phone),
    statusFormatted: statusMap[user.status],
    commissionFormatted: numberToCurrency(user.commission),
    pix: user.pix ? formatUserPix(user.pix) : undefined
  }
}
