interface NumberToCurrencyOptions {
  currencySymbol?: boolean
}

export function numberToCurrency(
  value: number,
  options?: NumberToCurrencyOptions
): string {
  const { currencySymbol = true } = options || {}

  return currencySymbol
    ? new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value)
    : value.toFixed(2).replace('.', ',')
}

export function stringToNumber(value: any): number {
  return typeof value === 'string'
    ? Number(value.replaceAll('.', '').replace(',', '.'))
    : value
}

export function replaceInvalidByZero(number: number): number {
  return number === Infinity || isNaN(number) ? 0 : number
}
