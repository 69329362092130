import styled, { css } from 'styled-components'

interface WrapperProps {
  alignField?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  padding: 0 0.5em;

  ${({ alignField }) =>
    alignField &&
    css`
      margin-top: 32px;
    `}
`
