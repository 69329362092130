import { useState } from 'react'
import { toast } from 'react-toastify'

import {
  getUserAccount as apiGetUserAccount,
  updateUserAccount as apiUpdateUserAccount
} from '../../../../../services/userAccount'

import {
  formatUserAccount,
  UserAccountFormatted
} from '../utils/formatUserAccount'

export function useUserAccount() {
  const [account, setAccount] = useState<UserAccountFormatted>()
  const [loading, setLoading] = useState(false)

  function getUserAccount() {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiGetUserAccount())
      .then(data => setAccount(formatUserAccount(data)))
      .finally(() => setLoading(false))
  }

  function updateUserAccount(data: Partial<UserAccountFormatted>) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiUpdateUserAccount(data))
      .then(data => setAccount(formatUserAccount(data)))
      .then(() => toast.info('Seus dados foram atualizados'))
      .finally(() => setLoading(false))
  }

  return {
    account,
    loading,
    getUserAccount,
    updateUserAccount
  }
}
