export const permissionsByRole = {
  ADMIN: ['*:*'],
  EMPLOYEE: [
    'user:Get',
    'user:List',
    'permission:*',
    'person:Get',
    'person:List',
    'person:Create',
    'person:Update',
    'service:Get',
    'service:List',
    'product:Get',
    'product:List',
    'priceList:Get',
    'priceList:List',
    'priceListItem:List',
    'order:Get',
    'order:List',
    'order:Close',
    'order:Create',
    'order:Update',
    'order:SendDocuments',
    'orderItem:*',
    'payment:*',
    'boleto:*',
    'pix:*',
    'nfse:*',
    'report:CommissionReport',
    'category:Get',
    'category:List',
    'group:Get',
    'group:List'
  ],
  PARTNER: ['report:CommissionReport']
}
