import { useState } from 'react'

import { Group, listGroups as apiListGroups } from '../../../services/group'

export function useGroups() {
  const [groups, setGroups] = useState<Group[]>([])
  const [loading, setLoading] = useState(false)

  function listGroups(search: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiListGroups({ search, limit: 10 }))
      .then(data => setGroups(data.groups))
      .finally(() => setLoading(false))
  }

  return {
    groups,
    loading,
    listGroups
  }
}
