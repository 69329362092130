import { Group, getGroup } from '../../../../../../services/group'

export function useFetchGroup() {
  async function fetchGroup(id: string): Promise<Group | undefined> {
    try {
      const group = await getGroup(id)
      return group
    } catch (err: any) {
      const status = err.response?.status

      if (status === 404) return

      throw err
    }
  }

  return fetchGroup
}
