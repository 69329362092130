import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table } from 'semantic-ui-react'

import { PaginationContext } from '../../../../contexts/PaginationContext'
import { pages } from '../../../../config/pages'
import { DefaultLoader } from '../../../../components/DefaultLoader'

import { SignatureFormatted } from '../../utils/formatSignature'
import { useSignatureList, useSignature } from './hooks'
import {
  SignaturesTableActions,
  SignaturesTableHeader,
  SignaturesTableRow,
  SignaturesTableFooter,
  DeleteSignatureModal,
  InvoiceSignatureModal
} from './components'

export const SignaturesTable: React.FC = () => {
  const {
    pagination,
    goToFirstPage,
    goToPreviousPage,
    goToNextPage,
    goToLastPage,
    changeLimit
  } = useContext(PaginationContext)
  const { signatures, totalItems, loading, fetchSignatures } =
    useSignatureList()
  const { deleteLoading, invoiceLoading, deleteSignature, invoiceSignature } =
    useSignature()
  const [selectedSignature, setSelectedSignature] =
    useState<SignatureFormatted>()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showInvoiceModal, setShowInvoiceModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetchSignatures(pagination)
  }, [pagination])

  function redirectToCreateForm() {
    navigate(`${pages.signatures.path}/inclusao`)
  }

  function redirectToUpdateForm(signature: SignatureFormatted) {
    navigate(`${pages.signatures.path}/edicao?id=${signature.id}`)
  }

  function openConfirmDeleteModal(signature: SignatureFormatted) {
    setShowDeleteModal(true)
    setSelectedSignature(signature)
  }

  function openConfirmInvoiceModal(signature: SignatureFormatted) {
    setSelectedSignature(signature)
    setShowInvoiceModal(true)
  }

  function handleConfirmDelete(signature: SignatureFormatted) {
    deleteSignature(signature.id, () => {
      setShowDeleteModal(false)
      fetchSignatures(pagination)
    })
  }

  function handleConfirmInvoice(signature: SignatureFormatted) {
    invoiceSignature(signature.id, () => {
      setShowInvoiceModal(false)
      fetchSignatures(pagination)
    })
  }

  return (
    <>
      <DefaultLoader active={loading} />

      <SignaturesTableActions onCreateButtonClick={redirectToCreateForm} />

      <Table selectable striped compact sortable>
        <SignaturesTableHeader />

        <Table.Body>
          {signatures.map(signature => (
            <SignaturesTableRow
              key={signature.id}
              signature={signature}
              onUpdateButtonClick={redirectToUpdateForm}
              onDeleteButtonClick={openConfirmDeleteModal}
              onInvoiceButtonClick={openConfirmInvoiceModal}
            />
          ))}
        </Table.Body>

        <SignaturesTableFooter
          colSpan={8}
          count={signatures.length}
          pagination={pagination}
          totalItems={totalItems}
          loading={loading}
          goToFirstPage={goToFirstPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
          changeLimit={changeLimit}
        />
      </Table>

      <DeleteSignatureModal
        open={showDeleteModal}
        signature={selectedSignature}
        loading={deleteLoading}
        handleModal={setShowDeleteModal}
        onConfirm={handleConfirmDelete}
      />

      <InvoiceSignatureModal
        open={showInvoiceModal}
        signature={selectedSignature}
        loading={invoiceLoading}
        handleModal={setShowInvoiceModal}
        onConfirm={handleConfirmInvoice}
      />
    </>
  )
}
