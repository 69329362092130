import React from 'react'

import { Container } from './style'

export const UnderMaintenance: React.FC = () => {
  return (
    <Container>
      <section>
        <span className="ops">Ooops!</span>
        <h1>Sistema em manutenção</h1>
        <span>
          O sistema está em manutenção no momento, por favor tente novamente
          mais tarde.
        </span>
      </section>
    </Container>
  )
}
