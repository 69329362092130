import React, { useState, useEffect, useContext } from 'react'
import { Table, Button, Input } from 'semantic-ui-react'

import { deleteCategory, Category } from '../../services/category'
import { PaginationContext, Pagination } from '../../contexts/PaginationContext'
import { useCan } from '../../hooks/useCan'
import { can } from '../../config/permissions'
import { PaginationControllers } from '../../components/PaginationControllers'
import { ConfirmationModal } from '../../components/ConfirmationModal'
import { ItemControllers } from '../../components/Table'
import { DefaultLoader } from '../../components/DefaultLoader'
import { ActionsContainer } from '../../components/RegistrationContainer/styles'

interface CategoriesTableProps {
  categories: Category[]
  totalItems: number
  loading?: boolean
  fetchCategories: (pagination: Pagination) => void
  onCreateClick?: () => void
  onUpdateClick?: (category: Category) => void
}

export const CategoriesTable: React.FC<CategoriesTableProps> = ({
  categories,
  totalItems,
  loading,
  fetchCategories,
  onCreateClick,
  onUpdateClick
}) => {
  const {
    pagination,
    goToFirstPage,
    goToPreviousPage,
    goToNextPage,
    goToLastPage,
    changeLimit,
    changeQuery
  } = useContext(PaginationContext)
  const [search, setSearch] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<Category>()
  const canCreate = useCan(can.category.create)

  useEffect(() => {
    fetchCategories(pagination)
  }, [pagination])

  function searchCategory() {
    changeQuery({ search })
  }

  function handleSearchKeyPress(evt: React.KeyboardEvent) {
    if (evt.key === 'Enter') {
      searchCategory()
    }
  }

  function handleUpdateClick(category: Category) {
    onUpdateClick?.(category)
  }

  function handleDeleteClick(category: Category) {
    setDeleteModal(true)
    setSelectedCategory(category)
  }

  function handleDeleteCategory() {
    if (!selectedCategory) return

    Promise.resolve()
      .then(() => setDeleteLoading(true))
      .then(() => deleteCategory(selectedCategory.id))
      .then(() => setDeleteModal(false))
      .then(() => fetchCategories(pagination))
      .finally(() => setDeleteLoading(false))
  }

  return (
    <>
      <DefaultLoader active={loading} />
      <ActionsContainer>
        <Input
          placeholder="Pesquisar"
          value={search}
          onChange={(_, { value }) => setSearch(value.toUpperCase())}
          onKeyPress={handleSearchKeyPress}
          action={<Button icon="search" onClick={searchCategory} />}
        />
        {onCreateClick && canCreate && (
          <Button
            content="Incluir"
            onClick={onCreateClick}
            floated="right"
            primary
          />
        )}
      </ActionsContainer>
      <Table selectable striped compact sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="Nome" />
            <Table.HeaderCell content="Descrição" />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {categories.map(category => (
            <Table.Row key={category.id}>
              <Table.Cell content={category.name} />
              <Table.Cell content={category.description} />
              <Table.Cell textAlign="right">
                <ItemControllers
                  onUpdateClick={() => handleUpdateClick(category)}
                  onDeleteClick={() => handleDeleteClick(category)}
                  permissionsToUpdate={can.category.update}
                  permissionsToDelete={can.category.delete}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={3} textAlign="right">
              <PaginationControllers
                count={categories.length}
                page={pagination.page}
                limit={pagination.limit}
                total={totalItems}
                loading={loading}
                goToFirstPage={goToFirstPage}
                goToPreviousPage={goToPreviousPage}
                goToNextPage={goToNextPage}
                goToLastPage={goToLastPage}
                onChangeLimit={changeLimit}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <ConfirmationModal
        open={deleteModal}
        loading={deleteLoading}
        handleModal={setDeleteModal}
        onConfirm={handleDeleteCategory}
        onDecline={() => setDeleteModal(false)}
      >
        <span>
          A categoria só pode ser excluída se não houver contas, pedidos ou
          assinaturas vinculados a ela. Deseja continuar?
        </span>
        <br />
        <span>{selectedCategory?.name}</span>
      </ConfirmationModal>
    </>
  )
}
