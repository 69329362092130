import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import {
  getBolepixPdf as apiGetBolepixPdf,
  issueBolepix as apiIssueBolepix,
  refreshBolepix as apiRefreshBolepix,
  cancelBolepix as apiCancelBolepix,
  Bolepix
} from '../../../../../services/bolepix'
import { Charge } from '../../../../../services/charge'

import { errorHandler } from '../../../utils/errorHandler'
import { OrderFormContext } from '../context'

export function useOrderChargeBolepix() {
  const { setLoading, getOrderState, updateOrderState } =
    useContext(OrderFormContext)
  const [errors, setErrors] = useState<string[]>([])

  function _replaceChargeInOrder(charge: Charge) {
    const order = getOrderState()

    updateOrderState({
      ...order,
      charges: order.charges.map(oldCharge =>
        oldCharge.id === charge.id ? charge : oldCharge
      )
    })
  }

  function _findCharge(chargeId: string) {
    const order = getOrderState()

    return order.charges.find(charge => charge.id === chargeId)
  }

  function getBolepixPdf(
    chargeId: string,
    onSuccess: (content?: Buffer) => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiGetBolepixPdf(order.id, chargeId))
      .then(data => onSuccess(data?.content))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
  }

  function issueBolepix(
    chargeId: string,
    data: Pick<Bolepix, 'type' | 'dueDate'>,
    onFinally?: () => void
  ) {
    const handleSuccess = (bolepix: Bolepix) => {
      const charge = _findCharge(chargeId)

      if (!charge) return

      const newCharge = { ...charge, bolepix }
      _replaceChargeInOrder(newCharge)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiIssueBolepix(order.id, chargeId, data))
      .then(data => handleSuccess(data))
      .then(() => toast.info('A cobrança foi emitida'))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function refreshBolepix(chargeId: string) {
    const handleSuccess = (bolepix: Bolepix) => {
      const charge = _findCharge(chargeId)

      if (!charge) return

      const paid = bolepix.paidValue > charge.amount

      const newCharge = {
        ...charge,
        paymentDate: paid ? bolepix.statusDate : undefined,
        paid,
        bolepix
      }
      _replaceChargeInOrder(newCharge)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiRefreshBolepix(order.id, chargeId))
      .then(data => handleSuccess(data))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
  }

  function cancelBolepix(chargeId: string, onFinally?: () => void) {
    const handleSuccess = () => {
      const charge = _findCharge(chargeId)

      if (!charge) return

      const newCharge = { ...charge, bolepix: undefined }
      _replaceChargeInOrder(newCharge)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiCancelBolepix(order.id, chargeId))
      .then(() => handleSuccess())
      .then(() => toast.info('A cobrança foi cancelada'))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  return {
    errors,
    getBolepixPdf,
    issueBolepix,
    refreshBolepix,
    cancelBolepix
  }
}
