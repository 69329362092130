import React from 'react'
import { Menu, Popup } from 'semantic-ui-react'

import { useAppVersion } from '../../../hooks/useAppVersion'

export const VersionPopup: React.FC = () => {
  const { frontendVersion, backendVersion } = useAppVersion()

  return (
    <Popup
      inverted
      position="bottom right"
      trigger={<Menu.Item icon="help circle" />}
    >
      <span>Web app: {frontendVersion}</span>
      {backendVersion && (
        <>
          <br />
          <span>Gerencial: {backendVersion}</span>
        </>
      )}
    </Popup>
  )
}
