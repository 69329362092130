import React from 'react'
import { Segment } from 'semantic-ui-react'

import { Show } from '../../../../../../components/Show'

import { useSignature } from '../../hooks'
import { SignatureItemsHeader, SignatureItemsTable } from './components'

export const SignatureItems: React.FC = () => {
  const { signature } = useSignature()

  const signatureExist = !!signature

  return (
    <Show condition={signatureExist}>
      <Segment>
        <SignatureItemsHeader />
        <SignatureItemsTable />
      </Segment>
    </Show>
  )
}
