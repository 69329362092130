import { Buffer } from 'buffer'

import api from './api'

export type BolepixType = 'INTER'

export type BolepixStatus =
  | 'CREATED'
  | 'OPENED'
  | 'PAID'
  | 'CANCELED'
  | 'OVERDUE'

export interface BolepixDiscount {
  type: 'PERCENT' | 'VALUE'
  amount: number
  daysBeforeToDueDate?: number
}

export interface BolepixFine {
  type: 'PERCENT' | 'VALUE'
  amount: number
}

export interface BolepixInterest {
  type: 'PERCENT' | 'VALUE'
  amount: number
}

export interface BolepixBoleto {
  barCode: string
  digitableLine: string
}

export interface BolepixPix {
  txid: string
  pixCopyAndPaste: string
}

export interface Bolepix {
  id: string
  payerId: string
  type: BolepixType
  ourNumber: string
  dueDate: string
  status: BolepixStatus
  statusDate: string
  value: number
  paidValue: number
  paymentOrigin?: 'BOLETO' | 'PIX'
  fine?: BolepixFine
  interest?: BolepixInterest
  discounts?: BolepixDiscount[]
  messages?: string[]
  boleto?: BolepixBoleto
  pix?: BolepixPix
  requestCode?: string
  createdAt?: string
  updatedAt?: string
}

export interface GetBolepixPdf {
  content: Buffer
  mimetype: string
}

export async function getBolepixPdf(
  orderId: string,
  chargeId: string
): Promise<GetBolepixPdf> {
  const response = await api.get(
    `/orders/${orderId}/charges/${chargeId}/bolepix/pdf`,
    { responseType: 'arraybuffer', timeout: 5000 }
  )

  return {
    content: Buffer.from(response.data, 'binary'),
    mimetype: response.headers['content-type'] as string
  }
}

export async function issueBolepix(
  orderId: string,
  chargeId: string,
  data: Partial<Bolepix>
): Promise<Bolepix> {
  const response = await api.post(
    `/orders/${orderId}/charges/${chargeId}/bolepix`,
    data
  )
  const bolepix = response.data

  return bolepix
}

export async function refreshBolepix(
  orderId: string,
  chargeId: string
): Promise<Bolepix> {
  const response = await api.patch(
    `/orders/${orderId}/charges/${chargeId}/bolepix/refresh`
  )
  const bolepix = response.data

  return bolepix
}

export async function cancelBolepix(
  orderId: string,
  chargeId: string
): Promise<void> {
  await api.delete(`/orders/${orderId}/charges/${chargeId}/bolepix`)
}
