import React from 'react'

import { PixQrCodeViewer } from '../../components/PixQrCodeViewer'

import { useCobPix } from '../../hooks/useCobPix'

export const CobPixQrCode: React.FC = () => {
  const { loading, error, cobPix } = useCobPix()

  return <PixQrCodeViewer data={cobPix} loading={loading} error={error} />
}
