import React, { useRef } from 'react'
import {
  FormField as SemanticField,
  InputProps as SemanticInputProps,
  Label
} from 'semantic-ui-react'
import { useForm, useField, Field as FinalField } from 'react-final-form'

import { useFieldError } from '../../../hooks/useFieldError'
import { sleep } from '../../../utils/sleep'

import { LoadingIcon } from './styles'

interface InputProps extends Omit<SemanticInputProps, 'onChange'> {
  name: string
  uppercase?: boolean
  lowercase?: boolean
  numeric?: boolean
  loading?: boolean
}

export const Input: React.FC<InputProps> = ({
  name,
  label,
  numeric,
  lowercase,
  uppercase,
  required,
  disabled,
  width,
  inline,
  loading,
  onChange,
  ...rest
}) => {
  const form = useForm()
  const field = useField(name)
  const error = useFieldError(field)
  const ref = useRef<any>()
  const fieldProps = { required, disabled, width, inline, error: !!error }

  function parseValue(value: any) {
    if (typeof value !== 'string') return value

    if (numeric) return value.replace(/[^0-9]+/g, '')

    if (lowercase) return value.toLowerCase()

    if (uppercase) return value.toUpperCase()

    return value
  }

  function setCursorPosition(position: number) {
    ref.current.selectionStart = position
    ref.current.selectionEnd = position
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const cursor = event.target.selectionEnd
    const value = parseValue(event.target.value)
    form.change(name, value)
    onChange?.(event)

    if (!ref.current) return

    Promise.resolve()
      .then(() => sleep(0))
      .then(() => cursor && setCursorPosition(cursor))
  }

  return (
    <FinalField name={name} required={required} disabled={disabled} {...rest}>
      {({ input }) => (
        <SemanticField {...fieldProps}>
          <label htmlFor={name}>{label}</label>
          <input
            id={name}
            type="text"
            ref={ref}
            {...rest}
            {...input}
            onChange={handleChange}
          />
          {loading ? <LoadingIcon size="tiny" active inline /> : null}
          {error ? (
            <Label content={error.content} pointing={error.pointing} prompt />
          ) : null}
        </SemanticField>
      )}
    </FinalField>
  )
}
