import React from 'react'
import { Message, Segment } from 'semantic-ui-react'

import { Show } from '../../../../../../components/Show'

import { useOrder, useOrderNfse } from '../../hooks'
import { OrderNfseHeader } from './OrderNfseHeader'
import { OrderNfseTable } from './OrderNfseTable'

export const OrderNfse: React.FC = () => {
  const { order } = useOrder()
  const { errors } = useOrderNfse()

  const orderWasClosed = !!order && order.status !== 'OPEN'
  const hasErrors = errors.length > 0

  return (
    <Show condition={orderWasClosed}>
      <Segment>
        <OrderNfseHeader />
        <Show condition={hasErrors}>
          <Message header="Ocorreu algo inesperado" list={errors} error />
        </Show>
        <OrderNfseTable />
      </Segment>
    </Show>
  )
}
