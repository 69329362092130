import api from './api'

export type PermissionsScheme = Record<string, string[]>

export async function getPermissionsScheme(): Promise<PermissionsScheme> {
  const response = await api.get('/permissions')
  const scheme = response.data

  return scheme
}
