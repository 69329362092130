import React from 'react'
import { FormField, SemanticWIDTHS } from 'semantic-ui-react'
import { Field, useField } from 'react-final-form'
import { PatternFormat } from 'react-number-format'

import { useFieldError } from '../../../hooks/useFieldError'

interface MaskedProps {
  name: string
  mask: string
  label?: string
  required?: boolean
  disabled?: boolean
  width?: SemanticWIDTHS
  fluid?: boolean
  readOnly?: boolean
  autoComplete?: string
  onBlur?: (event: any) => void
}

export const Masked: React.FC<MaskedProps> = ({
  name,
  mask,
  onBlur,
  ...rest
}) => {
  const field = useField(name)
  const error = useFieldError(field)

  return (
    <Field name={name}>
      {({ input }) => (
        <FormField
          control={PatternFormat}
          {...rest}
          {...input}
          format={mask}
          error={error}
          onBlur={onBlur}
        />
      )}
    </Field>
  )
}
