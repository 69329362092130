import { User, getUser } from '../../../../../../services/users'

export function useFetchUser() {
  async function fetchUser(id: string): Promise<User | undefined> {
    try {
      const user = await getUser(id)
      return user
    } catch (err: any) {
      const status = err.response?.status

      if (status === 404) return

      throw err
    }
  }

  return fetchUser
}
