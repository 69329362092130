import api from './api'

export interface NFSe {
  id: string
  number: number
  series: number
  authenticityCode: string
  status: 'ISSUED'
  link: string
  createdAt: string
  updatedAt: string
}

export async function issueNfse(orderId: string): Promise<NFSe> {
  const response = await api.post(`/orders/${orderId}/nfse`)
  const nfse = response.data

  return nfse
}

export interface CancelNfseOptions {
  observation: string
}

export async function cancelNfse(
  orderId: string,
  options: CancelNfseOptions
): Promise<boolean> {
  const { observation } = options
  const response = await api.put(`/orders/${orderId}/nfse`, { observation })

  return response.data.status
}
