import React from 'react'
import { Table } from 'semantic-ui-react'

import { useOrder } from '../../../../hooks'
import { OrderChargesTableHeader } from './OrderChargesTableHeader'
import { OrderChargesTableRow } from './OrderChargesTableRow'
import { OrderChargesTableFooter } from './OrderChargesTableFooter'

export const OrderChargesTable: React.FC = () => {
  const { order } = useOrder()

  return (
    <Table selectable striped compact>
      <OrderChargesTableHeader />
      <Table.Body>
        {order?.charges.map(charge => (
          <OrderChargesTableRow key={charge.id} charge={charge} />
        ))}
      </Table.Body>
      <OrderChargesTableFooter />
    </Table>
  )
}
