import React, { useContext, useEffect, useState } from 'react'
import { Form as FinalForm } from 'react-final-form'
import { Button, DropdownOnSearchChangeData, Header } from 'semantic-ui-react'
import { debounce } from 'debounce'

import { AuthContext } from '../../contexts/AuthContext'
import { Form, DateInput, Select } from '../../components/Form'
import { Show } from '../../components/Show'
import {
  getToday,
  isSameDate,
  localeDateToDate,
  setCurrentMinute,
  setMaxHourAndMinute,
  removeTimezone
} from '../../utils/date'
import { useCan } from '../../hooks/useCan'
import { can } from '../../config/permissions'
import { SelectOption } from '../../components/Form/Select'
import { CommissionReportOptions } from '../../services/report'
import { PDFViewerModal } from '../../components/PDFViewerModal'

import { Wrapper } from './styles'
import { useCategories, useUsers, useCommissionReport } from './hooks'
import {
  categoryToOption,
  userToOption,
  initialValues,
  validateForm,
  removeEmptyStrings,
  DEBOUNCE_TIME,
  statusOptions
} from './utils'

export const Commission: React.FC = () => {
  const { user } = useContext(AuthContext)
  const canGetReport = useCan(can.report.commission)
  const {
    categories,
    loading: categoriesLoading,
    listCategories
  } = useCategories()
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([])
  const {
    users: partners,
    loading: partnerLoading,
    listUsers: listPartners
  } = useUsers()
  const [partnerOptions, setPartnerOptions] = useState<SelectOption[]>([])
  const {
    users: employees,
    loading: employeeLoading,
    listUsers: listEmployees
  } = useUsers()
  const [employeeOptions, setEmployeeOptions] = useState<SelectOption[]>([])
  const {
    report,
    loading: reportLoading,
    getCommissionReport
  } = useCommissionReport()
  const [showPdfViewerModal, setShowPdfViewerModal] = useState(false)

  useEffect(() => {
    listCategories()
  }, [])

  useEffect(() => {
    setCategoryOptions(categories.map(categoryToOption))
  }, [categories])

  useEffect(() => {
    setPartnerOptions(partners.map(userToOption))
  }, [partners])

  useEffect(() => {
    setEmployeeOptions(employees.map(userToOption))
  }, [employees])

  if (!canGetReport) {
    return (
      <Header content="Entre em contato com o administrado para poder visualizar esta página" />
    )
  }

  function handleSubmit(values: any) {
    const { partnerId, employeeId } = values
    const start = removeTimezone(localeDateToDate(values.start))
    const endDate = localeDateToDate(values.end)
    const endIsToday = isSameDate(endDate, new Date())
    const endLastMinute = endIsToday
      ? setCurrentMinute(endDate)
      : setMaxHourAndMinute(endDate)
    const end = removeTimezone(endLastMinute)
    const status = values.status
    const categories = values.categories?.join(',')
    const query = removeEmptyStrings({
      start,
      end,
      status,
      categories,
      partnerId,
      employeeId
    }) as CommissionReportOptions

    getCommissionReport(query, () => {
      setShowPdfViewerModal(true)
    })
  }

  function searchPartner(data: DropdownOnSearchChangeData) {
    const { searchQuery } = data

    return debounce(() => listPartners(searchQuery, 'PARTNER'), DEBOUNCE_TIME)()
  }

  function searchEmployee(data: DropdownOnSearchChangeData) {
    const { searchQuery } = data

    return debounce(
      () => listEmployees(searchQuery, 'EMPLOYEE'),
      DEBOUNCE_TIME
    )()
  }

  const userIsAdmin = user?.role === 'ADMIN'
  const userIsPartner = user?.role === 'PARTNER'
  const userIsEmployee = user?.role === 'EMPLOYEE'
  const loading =
    categoriesLoading || partnerLoading || employeeLoading || reportLoading
  const minDate = new Date(2022, 0, 1)

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validateForm}
      validateOnBlur
    >
      {({ handleSubmit }) => (
        <Wrapper>
          <Form onSubmit={handleSubmit} loading={loading}>
            <Form.Group>
              <DateInput
                name="start"
                label="Início"
                minDate={minDate}
                width={8}
              />
              <DateInput
                name="end"
                label="Fim"
                maxDate={getToday()}
                width={8}
              />
            </Form.Group>
            <Form.Group>
              <Select
                name="categories"
                label="Categorias"
                options={categoryOptions}
                width={16}
                multiple
              />
            </Form.Group>
            <Form.Group>
              <Select
                name="status"
                label="Status do pedido"
                options={statusOptions}
                width={16}
                multiple
              />
            </Form.Group>
            <Form.Group>
              <Show condition={userIsAdmin}>
                <Select
                  name="partnerId"
                  label="Parceiro"
                  options={partnerOptions}
                  onSearchChange={(_, data) => searchPartner(data)}
                  loading={false}
                  width={8}
                  search
                  clearable
                  uppercase
                />
                <Select
                  name="employeeId"
                  label="Colaborador"
                  options={employeeOptions}
                  onSearchChange={(_, data) => searchEmployee(data)}
                  loading={false}
                  width={8}
                  search
                  clearable
                  uppercase
                />
              </Show>
              <Show condition={userIsPartner}>
                <Select
                  name="employeeId"
                  label="Colaborador"
                  options={employeeOptions}
                  onSearchChange={(_, data) => searchEmployee(data)}
                  loading={false}
                  width={16}
                  search
                  clearable
                  uppercase
                />
              </Show>
              <Show condition={userIsEmployee}>
                <Select
                  name="partnerId"
                  label="Parceiro"
                  options={partnerOptions}
                  onSearchChange={(_, data) => searchPartner(data)}
                  loading={false}
                  width={16}
                  search
                  clearable
                  uppercase
                />
              </Show>
            </Form.Group>
            <Button content="Buscar" primary />
          </Form>
          <PDFViewerModal
            open={showPdfViewerModal}
            handleModal={setShowPdfViewerModal}
            title="Relatório de comissão"
            pdf={report}
            emptyMessage="Não foram encontrados pedidos correspondentes ao filtro escolhido"
          />
        </Wrapper>
      )}
    </FinalForm>
  )
}
