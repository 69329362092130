import React from 'react'
import { Segment, Message } from 'semantic-ui-react'
import copy from 'copy-to-clipboard'
import { toast } from 'react-toastify'

import { CobPixInfoFormatted } from '../../services/cobPix'
import { DefaultLoader } from '../DefaultLoader'
import { Button } from '../Button'

import { Container, Info, QrCode, CopyAndPaste } from './styles'

export interface PixQrCodeViewerProps {
  data?: CobPixInfoFormatted
  loading?: boolean
  error?: string
  noBorder?: boolean
}

export const PixQrCodeViewer: React.FC<PixQrCodeViewerProps> = ({
  data: cobPix,
  loading,
  error,
  noBorder
}) => {
  function handleTextAreaClick(event: any) {
    event.target.select()
  }

  function copyToClipboard() {
    if (!cobPix?.pixCopyAndPaste) return

    copy(cobPix?.pixCopyAndPaste)
    toast.info('Copiado')
  }

  return (
    <Container>
      <Segment basic={noBorder}>
        {loading ? <DefaultLoader active /> : null}

        {error ? <Message negative>{error}</Message> : null}

        {cobPix ? (
          <>
            <Info>
              <span>Vencimento: {cobPix.dueDate}</span>
              <span>Valor: {cobPix.value}</span>
            </Info>
            <QrCode>
              <img src={cobPix.qrCode} alt="QR Code para pagamento" />
            </QrCode>
            <CopyAndPaste>
              <textarea
                rows={5}
                value={cobPix.pixCopyAndPaste}
                onClick={handleTextAreaClick}
                readOnly
              />
              <Button
                content="Copia e cola"
                onClick={copyToClipboard}
                primary
              />
            </CopyAndPaste>
          </>
        ) : null}
      </Segment>
    </Container>
  )
}
