import { Account } from '../../../services/account'
import { dateToLocaleDate } from '../../../utils/date'
import { numberToCurrency } from '../../../utils/number'

export interface AccountFormatted extends Account {
  typeFormatted: string
  costTypeFormatted: string
  paymentTypeFormatted: string
  dueDateFormatted: string
  amountFormatted: string
  paymentDateFormatted?: string
  amountPaidFormatted: string
  isPaid: boolean
  isOverdue: boolean
}

export const typesMapped = {
  PAYABLE: 'Conta a pagar',
  RECEIVABLE: 'Conta a receber'
}

export const costTypeMapped = {
  FIXED: 'Fixo',
  VARIABLE: 'Variável',
  NONE: 'Não aplicável'
}

export const paymentTypesMapped = {
  BOLETO: 'Boleto',
  PIX: 'Pix manual / Depósito',
  COB_PIX: 'Pix',
  BOLEPIX: 'Bolepix'
}

export function formatAccount(account: Account): AccountFormatted {
  const dueDate = new Date(account.dueDate)
  dueDate.setHours(23, 59, 59)

  return {
    ...account,
    typeFormatted: typesMapped[account.type],
    costTypeFormatted: costTypeMapped[account.costType],
    paymentTypeFormatted: paymentTypesMapped[account.paymentType],
    dueDateFormatted: dateToLocaleDate(account.dueDate),
    amountFormatted: numberToCurrency(account.amount),
    paymentDateFormatted:
      account.paymentDate && dateToLocaleDate(account.paymentDate),
    amountPaidFormatted: numberToCurrency(account.amountPaid),
    isPaid: account.amountPaid > 0,
    isOverdue:
      dueDate.getTime() < Date.now() && account.amountPaid < account.amount
  }
}
