import React, { useState } from 'react'
import { Header, Button } from 'semantic-ui-react'

import { FormActionsContainer } from '../../../../../../../../styles/FormActionsContainer'

import { AddItemModal } from './components'

export const SignatureItemsHeader: React.FC = () => {
  const [showAddItemModal, setShowSetAddItemModal] = useState(false)

  function handleOpenAddItemModalClick() {
    setShowSetAddItemModal(true)
  }

  return (
    <FormActionsContainer>
      <Header as="h3" content="Items" floated="left" />
      <Button
        content="Adicionar"
        floated="right"
        onClick={handleOpenAddItemModalClick}
        primary
      />
      <AddItemModal
        open={showAddItemModal}
        handleModal={setShowSetAddItemModal}
      />
    </FormActionsContainer>
  )
}
