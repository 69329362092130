import React from 'react'
import { Menu } from 'semantic-ui-react'

import { Wrapper } from './styles'
import { VersionPopup } from './VersionPopup'
import { ProfileMenu } from './ProfileMenu'

interface NavbarProps {
  onSandwichClick?: () => void
}

export const Navbar: React.FC<NavbarProps> = ({ onSandwichClick }) => {
  return (
    <Wrapper>
      <Menu inverted fixed="top">
        <Menu.Item icon="bars" onClick={onSandwichClick} />
        <Menu.Menu position="right">
          <VersionPopup />
          <ProfileMenu />
        </Menu.Menu>
      </Menu>
    </Wrapper>
  )
}
