import React, { useContext, useState } from 'react'

import { AccountFormatted } from '../../../../../../../utils/formatAccount'
import { Show } from '../../../../../../../../../components/Show'
import { Button } from '../../../../../../../../../components/Button'
import { PaginationContext } from '../../../../../../../../../contexts/PaginationContext'
import { PDFViewerModal } from '../../../../../../../../../components/PDFViewerModal'

import { useBoleto } from '../../../../../hooks'

interface ViewBoletoPdfActionProps {
  account: AccountFormatted
}

export const ViewBoletoPdfAction: React.FC<ViewBoletoPdfActionProps> = ({
  account
}) => {
  const { pagination } = useContext(PaginationContext)
  const [showPdfModal, setShowPdfModal] = useState(false)
  const { boletoPdf, loading, getBoletoPdf } = useBoleto()

  function handleButtonClick() {
    const { orderId } = account

    if (!orderId) return

    getBoletoPdf(orderId, account.id, () => {
      setShowPdfModal(true)
    })
  }

  const isBoletoReceivable =
    account.paymentType === 'BOLETO' && pagination.query.type === 'RECEIVABLE'
  const accountIsPaid = account.isPaid

  return (
    <Show condition={isBoletoReceivable && !accountIsPaid}>
      <Button
        icon="file alternate"
        size="mini"
        popup="Ver boleto"
        loading={loading}
        onClick={handleButtonClick}
        invertedPopup
        circular
      />
      <PDFViewerModal
        open={showPdfModal}
        handleModal={setShowPdfModal}
        title="Boleto"
        pdf={boletoPdf}
      />
    </Show>
  )
}
