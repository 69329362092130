import api from './api'

export interface Product {
  id: string
  description: string
}

export interface ProductPaginate {
  page?: number
  limit?: number
  orderBy?: string
  order?: 'ASC' | 'DESC'
  search?: string
}

export interface ProductListResult {
  products: Product[]
  total: number
}

export async function getProduct(id: string): Promise<Product | undefined> {
  const response = await api.get(`/products/${id}`)
  const product = response.status === 200 ? response.data : undefined

  return product
}

export async function listProducts(
  paginate?: ProductPaginate
): Promise<ProductListResult> {
  const { page, limit, orderBy, order, search } = paginate || {}
  const response = await api.get('/products', {
    params: { page, limit, orderBy, order, search }
  })
  const { items: products, total } = response.data

  return {
    products,
    total
  }
}

export async function createProduct(data: Partial<Product>): Promise<Product> {
  const response = await api.post('/products', data)
  const product = response.data

  return product
}

export async function updateProduct(
  id: string,
  data: Partial<Product>
): Promise<Product> {
  const response = await api.put(`/products/${id}`, data)
  const product = response.data

  return product
}

export async function deleteProduct(id: string): Promise<boolean> {
  const response = await api.delete(`/products/${id}`)
  const result = response.data

  return result.status
}
