export const can = {
  user: {
    all: ['user:*'],
    read: ['user:Get', 'user:List'],
    create: ['user:Create'],
    update: ['user:Update'],
    updateStatus: ['user:UpdateStatus'],
    resetPassword: ['user:ResetPassword'],
    delete: ['user:Delete'],
    modify: [
      'user:Create',
      'user:Update',
      'user:UpdateStatus',
      'user:ResetPassword'
    ],
    linkAPartner: ['user:LinkAPartner'],
    unlinkAPartner: ['user:UnlinkAPartner']
  },
  permission: {
    all: ['permission:*'],
    read: ['permission:Get']
  },
  person: {
    all: ['person:*'],
    read: ['person:Get', 'person:List'],
    readPartners: ['person:Partners'],
    readApiKeys: ['person:ListApiKeys'],
    create: ['person:Create'],
    update: ['person:Update'],
    delete: ['person:Delete'],
    modify: ['person:Create', 'person:Update']
  },
  apiKey: {
    all: ['apiKey:*'],
    create: ['apiKey:Create'],
    update: ['apiKey:UpdateIps'],
    delete: ['apiKey:Delete']
  },
  system: {
    all: ['system:*'],
    read: ['system:Get', 'system:List', 'system:GetRootDirectory'],
    create: ['system:Create'],
    update: ['system:Update'],
    updateToken: ['system:UpdateToken'],
    delete: ['system:Delete'],
    modify: ['system:Create', 'system:Update']
  },
  directory: {
    all: ['directory:*'],
    read: ['directory:Get'],
    create: ['directory:Create'],
    delete: ['directory:Delete']
  },
  file: {
    all: ['file:*'],
    create: ['file:Create'],
    updateType: ['file:UpdateType'],
    delete: ['file:Delete', 'file:DeleteMultiple'],
    modify: ['file:Create', 'file:UpdateType']
  },
  license: {
    all: ['license:*'],
    read: ['license:List'],
    updateStatus: ['license:UpdateStatus'],
    delete: ['license:Delete']
  },
  service: {
    all: ['service:*'],
    read: ['service:Get', 'service:List'],
    create: ['service:Create'],
    update: ['service:Update'],
    delete: ['service:Delete'],
    modify: ['service:Create', 'service:Update']
  },
  product: {
    all: ['product:*'],
    read: ['product:Get', 'product:List'],
    create: ['product:Create'],
    update: ['product:Update'],
    delete: ['product:Delete'],
    modify: ['product:Create', 'product:Update']
  },
  priceList: {
    all: ['priceList:*'],
    read: ['priceList:Get', 'priceList:List'],
    create: ['priceList:Create'],
    update: ['priceList:Update'],
    delete: ['priceList:Delete'],
    modify: ['priceList:Create', 'priceList:Update']
  },
  priceListItem: {
    all: ['priceListItem:*'],
    read: ['priceListItem:List'],
    create: ['priceListItem:Create'],
    update: ['priceListItem:Update'],
    delete: ['priceListItem:Delete'],
    modify: ['priceListItem:Create', 'priceListItem:Update']
  },
  order: {
    all: ['order:*'],
    read: ['order:Get', 'order:List'],
    create: ['order:Create'],
    update: ['order:Update'],
    delete: ['order:Delete'],
    close: ['order:Close'],
    conclude: ['order:Conclude'],
    reopen: ['order:Reopen'],
    sendDocuments: ['order:SendDocuments'],
    modify: ['order:Create', 'order:Update']
  },
  arLog: {
    read: ['arLog:List']
  },
  orderItem: {
    all: ['orderItem:*'],
    create: ['orderItem:Create'],
    update: ['orderItem:Update'],
    delete: ['orderItem:Delete'],
    modify: ['orderItem:Create', 'orderItem:Update']
  },
  charge: {
    all: ['charge:*'],
    create: ['charge:Create'],
    update: ['charge:Update'],
    delete: ['charge:Delete'],
    modify: ['charge:Create', 'charge:Update']
  },

  // Deprecated
  boleto: {
    all: ['boleto:*'],
    issue: ['boleto:Issue'],
    cancel: ['boleto:Cancel'],
    updateStatus: ['boleto:UpdateStatus'],
    modify: ['boleto:Issue', 'boleto:UpdateStatus', 'boleto:Cancel']
  },

  pix: {
    all: ['pix:*'],
    issue: ['pix:Issue'],
    cancel: ['pix:Cancel'],
    addProof: ['pix:AddProof'],
    getProof: ['pix:GetProof'],
    modify: ['pix:Issue', 'pix:Cancel']
  },
  cobPix: {
    all: ['cobPix:*'],
    issue: ['cobPix:Issue'],
    cancel: ['cobPix:Cancel'],
    refresh: ['cobPix:Refresh'],
    modify: ['cobPix:Issue', 'cobPix:Cancel', 'cobPix:Refresh']
  },
  bolepix: {
    all: ['bolepix:*'],
    issue: ['bolepix:Issue'],
    cancel: ['bolepix:Cancel'],
    refresh: ['bolepix:Refresh'],
    modify: ['bolepix:Issue', 'bolepix:Cancel', 'bolepix:Refresh']
  },
  nfse: {
    all: ['nfse:*'],
    issue: ['nfse:Issue'],
    cancel: ['nfse:Cancel']
  },
  account: {
    all: ['account:*'],
    read: ['account:Get', 'account:List'],
    create: ['account:Create'],
    update: ['account:Update'],
    delete: ['account:Delete'],
    modify: ['account:Create', 'account:Update', 'account:WriteOff'],
    writeOff: ['account:WriteOff']
  },
  ac: {
    all: ['ac:*'],
    requestCertificate: ['ac:RequestCertificate'],
    deleteRequest: ['ac:DeleteRequest'],
    checkVideoconferencingAvailability: [
      'ac:CheckVideoconferencingAvailability'
    ],
    modify: ['ac:RequestCertificate', 'ac:DeleteRequest']
  },
  report: {
    all: ['report:*'],
    commission: ['report:CommissionReport'],
    cost: ['report:CostReport']
  },
  signature: {
    all: ['signature:*'],
    read: ['signature:Get', 'signature:List'],
    create: ['signature:Create'],
    update: ['signature:Update'],
    delete: ['signature:Delete'],
    modify: [
      'signature:Create',
      'signature:Update',
      'signature:Invoice',
      'signature:AddItem',
      'signature:ChangeItem',
      'signature:RemoveItem'
    ],
    invoice: ['signature:Invoice']
  },
  category: {
    all: ['category:*'],
    read: ['category:Get', 'category:List'],
    create: ['category:Create'],
    update: ['category:Update'],
    delete: ['category:Delete'],
    modify: ['category:Create', 'category:Update']
  },
  certificate: {
    all: ['certificate:*'],
    read: ['certificate:Get', 'certificate:List'],
    create: ['certificate:Create'],
    update: ['certificate:Update'],
    delete: ['certificate:Delete'],
    modify: ['certificate:Create', 'certificate:Update']
  },
  group: {
    all: ['group:*'],
    read: ['group:Get', 'group:List'],
    create: ['group:Create'],
    update: ['group:Update'],
    delete: ['group:Delete'],
    modify: ['group:Create', 'group:Update']
  }
}
