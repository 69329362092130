import React from 'react'
import { Modal, Button } from 'semantic-ui-react'
import { Form as FinalForm } from 'react-final-form'

import { APIKey } from '../../../../../../../services/apiKey'
import { getFormValidate } from '../../../../../../../utils/form'
import {
  Form,
  Input,
  Select,
  DateTimeInput
} from '../../../../../../../components/Form'

import { useApiKeys } from '../../../hooks/useApiKey'
import { apiKeyCreateSchema } from './schemas'

interface CreateApiKeyModalProps {
  open: boolean
  handleModal: (open: boolean) => void
}

const apiOptions = [
  { key: 'UPDATER', value: 'UPDATER', text: 'ATUALIZADOR' },
  { key: 'LICENSING', value: 'LICENSING', text: 'LICENCIAMENTO' }
]

const validateForm = getFormValidate(apiKeyCreateSchema)

export const CreateApiKeyModal: React.FC<CreateApiKeyModalProps> = ({
  open,
  handleModal
}) => {
  const { loading, createApiKey } = useApiKeys()

  function handleSubmit(values: APIKey) {
    createApiKey(values, () => {
      handleModal(false)
    })
  }

  return (
    <Modal
      open={open}
      onOpen={() => handleModal(true)}
      onClose={() => handleModal(false)}
      closeOnDimmerClick={false}
      size="mini"
      closeIcon
    >
      <Modal.Header content="Criar nova chave" />
      <Modal.Content>
        <FinalForm
          onSubmit={handleSubmit}
          initialValues={{ api: 'LICENSING' }}
          validate={validateForm}
          validateOnBlur
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} loading={loading}>
              <Input
                name="description"
                label="Descrição (opcional)"
                maxLength={30}
                uppercase
              />
              <Select name="api" label="API" options={apiOptions} required />
              <DateTimeInput name="expiresIn" label="Expiração (opcional)" />
              <Input name="ips" label="Endereços IP" maxLength={60} />
              <Button content="Salvar" type="submit" fluid primary />
            </Form>
          )}
        </FinalForm>
      </Modal.Content>
    </Modal>
  )
}
