import type { SelectOption } from '../../../../../../../components/Form/Select'

export interface CustomSelectOption extends SelectOption {
  required: string[]
}

export const chargeTypeOptions: CustomSelectOption[] = [
  {
    key: 'BOLEPIX',
    value: 'BOLEPIX',
    text: 'Bolepix',
    required: ['bolepix:*', 'bolepix:Issue']
  },
  {
    key: 'PIX',
    value: 'PIX',
    text: 'Pix manual / depósito',
    required: ['pix:*', 'pix:Issue']
  }
]

export function filterOptionsByPermissions(
  options: CustomSelectOption[],
  permissions: string[]
): SelectOption[] {
  return options.filter(option => {
    if (permissions.includes('*:*')) return true

    return permissions.some(permission => option.required.includes(permission))
  })
}
