import React from 'react'
import {
  Popup,
  Button as SemanticButton,
  ButtonProps as SemanticButtonProps,
  SemanticShorthandItem,
  StrictPopupHeaderProps
} from 'semantic-ui-react'

import { useCan } from '../../hooks/useCan'

interface ButtonProps extends SemanticButtonProps {
  can?: string[]
  popup?: string
  basicPopup?: boolean
  disabledPopup?: boolean
  flowingPopup?: boolean
  headerPopup?: SemanticShorthandItem<StrictPopupHeaderProps>
  hideOnScrollPopup?: boolean
  hoverablePopup?: boolean
  invertedPopup?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  can,
  popup,
  basicPopup,
  disabledPopup,
  flowingPopup,
  headerPopup,
  hideOnScrollPopup,
  hoverablePopup,
  invertedPopup,
  ...props
}) => {
  const canDoIt = useCan(can || [])

  if (!canDoIt) return null

  const popupProps = {
    content: popup,
    basic: basicPopup,
    disabled: disabledPopup,
    flowing: flowingPopup,
    header: headerPopup,
    hideOnScroll: hideOnScrollPopup,
    hoverable: hoverablePopup,
    inverted: invertedPopup,
    trigger: <SemanticButton {...props} />
  }

  return popup ? <Popup {...popupProps} /> : <SemanticButton {...props} />
}
