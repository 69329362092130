import React from 'react'
import { Table } from 'semantic-ui-react'

import { ItemControllers } from '../../../../../components/Table'
import { can } from '../../../../../config/permissions'
import { Service } from '../../../../../services/service'

interface ServicesTableRowProps {
  service: Service
  onUpdateButtonClick: (service: Service) => void
  onDeleteButtonClick: (service: Service) => void
}

export const ServicesTableRow: React.FC<ServicesTableRowProps> = ({
  service,
  onUpdateButtonClick,
  onDeleteButtonClick
}) => {
  return (
    <Table.Row>
      <Table.Cell content={service.code} />
      <Table.Cell content={service.description} />
      <Table.Cell textAlign="right">
        <ItemControllers
          onUpdateClick={() => onUpdateButtonClick(service)}
          onDeleteClick={() => onDeleteButtonClick(service)}
          permissionsToUpdate={can.service.update}
          permissionsToDelete={can.service.delete}
        />
      </Table.Cell>
    </Table.Row>
  )
}
