import React, { useState, useEffect } from 'react'
import { Modal, Button, Input, InputOnChangeData } from 'semantic-ui-react'

import { randomAlphaNumeric } from '../../utils/random'

import { ConfirmationCodeArea } from './styles'

interface ConfirmationModalProps {
  open: boolean
  title?: string
  loading?: boolean
  confirmationCode?: boolean
  children?: React.ReactNode
  handleModal: (open: boolean) => void
  onConfirm: () => void
  onDecline: () => void
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  title = 'Confirmação',
  loading,
  confirmationCode,
  handleModal,
  onConfirm,
  onDecline,
  children
}) => {
  const [randomText, setRandomText] = useState<string>()
  const [confirmationText, setConfirmationText] = useState('')

  useEffect(() => {
    confirmationCode && setRandomText(randomAlphaNumeric(6))
    confirmationText !== '' && setConfirmationText('')
  }, [open])

  function handleChange(_: any, data: InputOnChangeData) {
    setConfirmationText(data.value)
  }

  return (
    <Modal
      open={open}
      onOpen={() => handleModal(true)}
      onClose={() => handleModal(false)}
      closeOnDimmerClick={false}
      size="mini"
      closeIcon
    >
      <Modal.Header content={title} />
      <Modal.Content>
        {children}
        {confirmationCode && (
          <ConfirmationCodeArea>
            <span>
              Código de confirmação: <strong>{randomText}</strong>
            </span>
            <Input
              fluid
              value={confirmationText}
              onChange={handleChange}
              placeholder="Código de confirmação"
            />
          </ConfirmationCodeArea>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cancelar"
          onClick={onDecline}
          disabled={loading}
          floated="left"
          negative
        />
        <Button
          content="Confirmar"
          onClick={onConfirm}
          loading={loading}
          disabled={confirmationCode && confirmationText !== randomText}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}
