import { OrderItem } from '../../../services/orderItem'
import { numberToCurrency } from '../../../utils/number'

import { calculateTotalItem } from './orderItemCalculate'

export interface OrderItemFormatted extends OrderItem {
  totalItem: number
  priceFormatted: string
  discountFormatted: string
  additionFormatted: string
  totalItemFormatted: string
}

export function formatOrderItem(item: OrderItem): OrderItemFormatted {
  const totalItem = calculateTotalItem(item)

  return {
    ...item,
    totalItem,
    priceFormatted: numberToCurrency(item.price),
    discountFormatted: numberToCurrency(item.discount),
    additionFormatted: numberToCurrency(item.addition),
    totalItemFormatted: numberToCurrency(totalItem)
  }
}
