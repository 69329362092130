import React, { ChangeEvent, useEffect, useState } from 'react'
import { InputOnChangeData, Table } from 'semantic-ui-react'

import { ItemControllers } from '../../../../../../../../../components/Table'
import { can } from '../../../../../../../../../config/permissions'
import {
  Input,
  Currency
} from '../../../../../../../../../components/Form/pure'
import { stringToNumber } from '../../../../../../../../../utils/number'

import {
  SignatureItemFormatted,
  formatSignatureItem
} from '../../../../../../../utils/formatSignatureItem'
import { useSignatureItem } from '../../../../../hooks'
import { SignatureItemsTableRowRemoveModal } from './SignatureItemsTableRowRemoveModal'

interface SignatureItemsTableRowProps {
  item: SignatureItemFormatted
}

export const SignatureItemsTableRow: React.FC<SignatureItemsTableRowProps> = ({
  item
}) => {
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const { loading, changeSignatureItem, removeSignatureItem } =
    useSignatureItem()
  const [editableItem, setEditableItem] = useState<SignatureItemFormatted>()

  useEffect(() => setEditableItem(item), [item])

  function handleRemoveButtonClick() {
    setShowRemoveModal(true)
  }

  function handleConfirmRemoveItem() {
    removeSignatureItem(item.id, () => {
      setShowRemoveModal(false)
    })
  }

  function handleTextChanging(_: any, data: InputOnChangeData) {
    if (!editableItem) return
    const { name, value } = data
    setEditableItem(
      formatSignatureItem({
        ...editableItem,
        [name]: value
      })
    )
  }

  function handleTextBlur(event: ChangeEvent<HTMLInputElement>) {
    const name = event.target.name as keyof SignatureItemFormatted
    const { value } = event.target
    if (editableItem?.[name] === item[name]) return
    changeSignatureItem(item.id, { [name]: value })
  }

  function handleNumericChanging(_: any, data: InputOnChangeData) {
    if (!editableItem) return
    const { name, value } = data
    setEditableItem(
      formatSignatureItem({
        ...editableItem,
        [name]: stringToNumber(value)
      })
    )
  }

  function handleNumericBlur(event: ChangeEvent<HTMLInputElement>) {
    const name = event.target.name as keyof SignatureItemFormatted
    const { value } = event.target
    if (editableItem?.[name] === item[name]) return
    changeSignatureItem(item.id, { [name]: stringToNumber(value) })
  }

  return (
    <Table.Row>
      <Table.Cell>
        <Input
          name="description"
          value={editableItem?.description}
          onChange={handleTextChanging}
          onBlur={handleTextBlur}
          maxLength={128}
          uppercase
          fluid
        />
      </Table.Cell>
      <Table.Cell width={2}>
        <Input
          name="quantity"
          value={editableItem?.quantity}
          onChange={handleNumericChanging}
          onBlur={handleNumericBlur}
          type="number"
          min={1}
          fluid
        />
      </Table.Cell>
      <Table.Cell width={2}>
        <Currency
          name="price"
          value={editableItem?.price ?? 0}
          onChange={handleNumericChanging}
          onBlur={handleNumericBlur}
          fluid
        />
      </Table.Cell>
      <Table.Cell width={2}>
        <Currency
          name="discount"
          value={editableItem?.discount ?? 0}
          onChange={handleNumericChanging}
          onBlur={handleNumericBlur}
          fluid
        />
      </Table.Cell>
      <Table.Cell content={editableItem?.totalPriceFormatted} width={2} />
      <Table.Cell textAlign="right" width={1}>
        <ItemControllers
          onDeleteClick={handleRemoveButtonClick}
          permissionsToDelete={can.signature.modify}
        />
        <SignatureItemsTableRowRemoveModal
          open={showRemoveModal}
          item={item}
          loading={loading}
          handleModal={setShowRemoveModal}
          onConfirm={handleConfirmRemoveItem}
        />
      </Table.Cell>
    </Table.Row>
  )
}
