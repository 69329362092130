import objectDiff from 'object-diff'
import { Schema, ValidationResult } from 'joi'

import { clearObject } from './object'

export function getDiff<Values = any>(
  previous: Partial<Values>,
  current: Partial<Values>
): Partial<Values> | undefined {
  const diff = objectDiff(previous, current)

  Object.keys(diff).forEach(key => {
    if (diff[key] === '') diff[key] = null
  })
  Object.keys(diff).forEach(key => {
    if (diff[key] === undefined) delete diff[key]
  })

  const hasDiffValues = Object.keys(diff).length > 0

  return hasDiffValues ? diff : undefined
}

export function formatErrors(result: ValidationResult): Record<string, string> {
  const errors: Record<string, string> = {}

  result.error?.details.forEach(detail => {
    const { message } = detail
    const { key } = detail.context || {}

    if (key) errors[key] = message
  })

  return errors
}

export function getFormValidate(schema: Schema): any {
  return function validate(
    values: Record<string, any>
  ): Record<string, string> {
    const data = clearObject(values)
    const result = schema.validate(data, {
      abortEarly: false,
      stripUnknown: true
    })
    const errors = formatErrors(result)

    return errors
  }
}

interface FieldError {
  content: string
  pointing: string
}

export function handleFieldErrorMessage(
  name: string,
  errors?: Record<string, string>,
  submitErrors?: Record<string, string>,
  touched?: Record<string, boolean>
): FieldError | undefined {
  const error = errors?.[name]
  const submitError = submitErrors?.[name]
  const isTouched = touched?.[name]
  const content = error || submitError

  return content && isTouched ? { content, pointing: 'above' } : undefined
}

interface GetFieldErrorHandlerOptions {
  errors?: Record<string, string>
  submitErrors?: Record<string, string>
  touched?: Record<string, boolean>
}

export function getFieldErrorHandler(
  options: GetFieldErrorHandlerOptions
): (name: string) => FieldError {
  const { errors, submitErrors, touched } = options

  return function (name: string): any {
    return handleFieldErrorMessage(name, errors, submitErrors, touched)
  }
}
