import React from 'react'

import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal'

import { OrderItemFormatted } from '../../../../../../utils/formatOrderItem'

interface ConfirmRemoveItemModalProps {
  open: boolean
  item: OrderItemFormatted
  loading?: boolean
  handleModal: (open: boolean) => void
  onConfirm: () => void
}

export const ConfirmRemoveItemModal: React.FC<ConfirmRemoveItemModalProps> = ({
  open,
  item,
  loading,
  handleModal,
  onConfirm
}) => {
  return (
    <ConfirmationModal
      open={open}
      title="Remover item do pedido"
      loading={loading}
      handleModal={handleModal}
      onConfirm={onConfirm}
      onDecline={() => handleModal(false)}
    >
      <span>Deseja remover este item do pedido?</span>
      <br />
      <strong>{item.description}</strong>
    </ConfirmationModal>
  )
}
