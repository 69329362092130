import { useContext } from 'react'

import { OrderFormContext } from '../context'

export function useOrderEmployee() {
  const { employee, setEmployee } = useContext(OrderFormContext)

  return {
    employee,
    setEmployee
  }
}
