import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { Form } from '../../../../../components/Form'
import {
  dateToLocaleDate,
  getTomorrow,
  localeDateToDate
} from '../../../../../utils/date'
import { getDiff, getFormValidate } from '../../../../../utils/form'
import { stringToNumber } from '../../../../../utils/number'

import { AccountFormatted, typesMapped } from '../../../utils/formatAccount'
import { accountSchema } from '../utils/schemas'
import { FormActions, FormFields } from './components'
import { useAccount } from '../hooks'

const validateForm = getFormValidate(accountSchema)

const initialValues = {
  type: 'PAYABLE',
  costType: 'NONE',
  typeFormatted: typesMapped.PAYABLE,
  paymentType: 'BOLETO',
  dueDateFormatted: dateToLocaleDate(getTomorrow()),
  amount: 0
}

interface FormValues extends AccountFormatted {
  autoWriteOff: boolean
}

export const AccountMain: React.FC = () => {
  const { account, createAccount, updateAccount, writeOffAccount } =
    useAccount()

  function handleCreate(values: FormValues) {
    const { autoWriteOff } = values
    const data = {
      categoryId: values.categoryId,
      personId: values.personId,
      groupId: values.groupId,
      costType: values.costType,
      paymentType: values.paymentType,
      description: values.description,
      dueDate: localeDateToDate(values.dueDateFormatted).toISOString(),
      amount: stringToNumber(values.amountFormatted)
    }

    createAccount(data, account => {
      if (!autoWriteOff) return

      const writeOffData = {
        paymentDate: account.dueDate,
        amountPaid: data.amount
      }
      writeOffAccount(account.id, writeOffData)
    })
  }

  function handleUpdate(values: FormValues) {
    const diff = getDiff(account || {}, values)

    if (!diff) return

    const data = {
      categoryId: values.categoryId,
      personId: values.personId,
      groupId: values.groupId,
      costType: values.costType,
      paymentType: values.paymentType,
      description: values.description
    }

    const {
      dueDateFormatted,
      amountFormatted,
      paymentDateFormatted,
      amountPaidFormatted
    } = values

    if (dueDateFormatted) {
      Object.assign(data, {
        dueDate: localeDateToDate(dueDateFormatted).toISOString()
      })
    }

    if (amountFormatted) {
      Object.assign(data, { amount: stringToNumber(amountFormatted) })
    }

    if (paymentDateFormatted) {
      Object.assign(data, {
        paymentDate: localeDateToDate(paymentDateFormatted).toISOString()
      })
    }

    if (amountPaidFormatted) {
      Object.assign(data, { amountPaid: stringToNumber(amountPaidFormatted) })
    }

    updateAccount(data)
  }

  function handleSubmit(values: FormValues) {
    if (!account) {
      handleCreate(values)
    }

    if (account) {
      handleUpdate(values)
    }
  }

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={{ ...initialValues, ...account }}
      validate={validateForm}
      validateOnBlur
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <FormActions />
          <FormFields />
        </Form>
      )}
    </FinalForm>
  )
}
