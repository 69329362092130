import React, { useState } from 'react'

import { Show } from '../../../../../../../../../../components/Show'
import { Button } from '../../../../../../../../../../components/Button'
import { can } from '../../../../../../../../../../config/permissions'

import { ChargeFormatted } from '../../../../../../../../utils/formatCharge'
import { useOrder, useOrderChargeCobPix } from '../../../../../../hooks'
import { ChargeConfirmationModal } from '../components/ChargeConfirmationModal'
import { CobPixQrCodeModal } from './CobPixQrCodeModal'

interface CobPixActionsProps {
  charge: ChargeFormatted
}

export const CobPixActions: React.FC<CobPixActionsProps> = ({ charge }) => {
  const { order, loading } = useOrder()
  const { issueCobPix, refreshCobPix, cancelCobPix } = useOrderChargeCobPix()
  const [showIssueModal, setShowIssueModal] = useState(false)
  const [showQrCodeModal, setShowQrCodeModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)

  function handleIssueButtonClick() {
    setShowIssueModal(true)
  }

  function handleRefreshButtonClick() {
    refreshCobPix(charge.id)
  }

  function handleQrCodeButtonClick() {
    setShowQrCodeModal(true)
  }

  function handleCancelButtonClick() {
    setShowCancelModal(true)
  }

  function handleConfirmIssue() {
    const { dueDate } = charge
    issueCobPix(charge.id, { type: 'INTER', dueDate }, () => {
      setShowIssueModal(false)
    })
  }

  function handleConfirmCancel() {
    cancelCobPix(charge.id, () => {
      setShowCancelModal(false)
    })
  }

  const { cobPix } = charge
  const show = order?.status !== 'OPEN' && charge.paymentType === 'COB_PIX'
  const cobPixExist = !!cobPix
  const cobPixIsOpened = cobPix?.status === 'OPENED'

  return (
    <Show condition={show}>
      <Button
        icon="arrow up"
        size="mini"
        disabled={cobPixExist}
        onClick={handleIssueButtonClick}
        can={can.cobPix.issue}
        popup="Emitir Pix"
        invertedPopup
        circular
      />
      <ChargeConfirmationModal
        open={showIssueModal}
        charge={charge}
        title="Emitir cobrança Pix"
        question="Confirmar emissão de cobrança Pix"
        loading={loading}
        handleModal={setShowIssueModal}
        onConfirm={handleConfirmIssue}
      />
      <Button
        icon="refresh"
        size="mini"
        disabled={!cobPixIsOpened}
        onClick={handleRefreshButtonClick}
        can={can.cobPix.refresh}
        popup="Atualizar status"
        invertedPopup
        circular
      />
      <Button
        icon="qrcode"
        size="mini"
        disabled={!cobPixIsOpened}
        onClick={handleQrCodeButtonClick}
        popup="Ver QR Code"
        invertedPopup
        circular
      />
      <CobPixQrCodeModal
        open={showQrCodeModal}
        cobPix={cobPix}
        handleModal={setShowQrCodeModal}
      />
      <Button
        icon="ban"
        size="mini"
        disabled={!cobPixIsOpened}
        onClick={handleCancelButtonClick}
        can={can.cobPix.cancel}
        popup="Cancelar cobrança"
        invertedPopup
        circular
      />
      <ChargeConfirmationModal
        open={showCancelModal}
        charge={charge}
        title="Cancelar cobrança Pix"
        question="Confirmar cancelamento de cobrança Pix"
        loading={loading}
        handleModal={setShowCancelModal}
        onConfirm={handleConfirmCancel}
      />
    </Show>
  )
}
