import styled from 'styled-components'
import { Form } from 'semantic-ui-react'

interface FormFieldProps {
  uppercase?: boolean
  lowercase?: boolean
}

export const FormField = styled(Form.Field)<FormFieldProps>`
  input {
    text-transform: ${({ uppercase, lowercase }) =>
      uppercase ? 'uppercase' : lowercase ? 'lowercase' : 'none'};
  }
`
