import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'

import { useCan } from '../../../../../../../../hooks/useCan'
import { can } from '../../../../../../../../config/permissions'
import { Show } from '../../../../../../../../components/Show'

import { useOrder, useOrderAc } from '../../../../hooks'
import { HandleOrderConfirmationModal } from '../HandleOrderConfirmationModal'

export const DeleteRequestAction: React.FC = () => {
  const { order, loading, isCertificateOrder } = useOrder()
  const { deleteRequest } = useOrderAc()
  const [showDeleteRequestModal, setShowDeleteRequestModal] = useState(false)
  const canDeleteRequest = useCan(can.ac.deleteRequest)

  function handleDeleteRequestButtonClick() {
    setShowDeleteRequestModal(true)
  }

  function handleConfirmToDeleteRequest() {
    deleteRequest(() => setShowDeleteRequestModal(false))
  }

  const orderExist = !!order
  const orderIsOpen = orderExist && order?.status === 'OPEN'
  const orderHasCertificateProtocol = !!order?.certificateIssueProtocol

  return (
    <Show
      condition={
        isCertificateOrder &&
        !orderIsOpen &&
        orderHasCertificateProtocol &&
        canDeleteRequest
      }
      component={
        <>
          <Button
            type="button"
            content="Cancelar solicitação"
            floated="right"
            onClick={handleDeleteRequestButtonClick}
          />
          <HandleOrderConfirmationModal
            open={showDeleteRequestModal}
            order={order}
            loading={loading}
            title="Cancelar solicitação de certificado"
            question="Você deseja cancelar a solicitação de certificado digital na AC?"
            handleModal={setShowDeleteRequestModal}
            onConfirm={handleConfirmToDeleteRequest}
            onDecline={() => setShowDeleteRequestModal(false)}
          />
        </>
      }
    />
  )
}
