import React from 'react'
import {
  Input as SemanticInput,
  InputOnChangeData,
  InputProps as SemanticInputProps
} from 'semantic-ui-react'

interface InputProps extends SemanticInputProps {
  lowercase?: boolean
  uppercase?: boolean
  reference?: any
}

export const Input: React.FC<InputProps> = ({
  lowercase,
  uppercase,
  reference,
  onChange,
  ...rest
}) => {
  function handleChange(
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    if (lowercase) {
      event.target.value = event.target.value.toLowerCase()
      data.value = data.value.toLowerCase()
    }

    if (uppercase) {
      event.target.value = event.target.value.toUpperCase()
      data.value = data.value.toUpperCase()
    }

    onChange?.(event, data)
  }

  return <SemanticInput {...rest} ref={reference} onChange={handleChange} />
}
