export interface CreateOrUpdateResult<T> {
  isNew: boolean
  data: T
}

export interface ItemDefaultProperties {
  id: string
}

export function replaceItemById<Item extends ItemDefaultProperties>(
  list: Item[],
  data: Item
): Item[] {
  return list.map(item => {
    return item.id === data.id ? data : item
  })
}

export function addOrUpdateItem<Item extends ItemDefaultProperties>(
  list: Item[],
  result: CreateOrUpdateResult<Item>
): Item[] {
  const { isNew, data } = result

  return isNew ? [...list, data] : replaceItemById<Item>(list, data)
}

export type OrderDirection = 'ASC' | 'DESC'

export function invertOrder(direction: OrderDirection): OrderDirection {
  return direction === 'ASC' ? 'DESC' : 'ASC'
}
