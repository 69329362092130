import React from 'react'

import {
  Form,
  Input,
  Phone,
  Select
} from '../../../../../../../../../components/Form'

const roleOptions = [
  { key: 'EMPLOYEE', value: 'EMPLOYEE', text: 'Colaborador' },
  { key: 'PARTNER', value: 'PARTNER', text: 'Parceiro' },
  { key: 'ADMIN', value: 'ADMIN', text: 'Administrador' }
]

export const MainGroup: React.FC = () => {
  return (
    <Form.Group widths={4}>
      <Input name="nickname" label="Nome" maxLength={100} required />
      <Input name="email" label="E-mail" max={50} type="email" required />
      <Phone name="phoneFormatted" label="Telefone" required />
      <Select name="role" label="Role" options={roleOptions} required />
    </Form.Group>
  )
}
