import { useState, useCallback } from 'react'

import { OrderListResult, listOrders } from '../../../../../services/order'
import { Pagination } from '../../../../../contexts/PaginationContext'

import { formatOrder, OrderFormatted } from '../../../utils/formatOrder'

export interface QueryParameters {
  search?: string
}

export function useOrdersList() {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState<OrderFormatted[]>([])
  const [totalItems, setTotalItems] = useState(0)

  const fetchOrders = useCallback((pagination: Pagination<QueryParameters>) => {
    const { page, limit, orderBy, order, query } = pagination
    const { search } = query || {}

    const onSuccess = (result: OrderListResult) => {
      setOrders(result.orders.map(formatOrder))
      setTotalItems(result.total)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => listOrders({ page, limit, orderBy, order, search }))
      .then(result => onSuccess(result))
      .finally(() => setLoading(false))
  }, [])

  return {
    loading,
    orders,
    totalItems,
    fetchOrders
  }
}
