import React, { useState, useCallback } from 'react'
import { Navigate, useParams, useNavigate } from 'react-router-dom'

import { pages } from '../../config/pages'
import {
  listPriceLists,
  PriceList,
  PriceListListResult
} from '../../services/priceList'
import { RegistrationContainer } from '../../components/RegistrationContainer'
import {
  PaginationProvider,
  Pagination
} from '../../contexts/PaginationContext'

import { PriceListsTable } from './PriceListsTable'
import { PriceListForm } from './PriceListForm'

const paths = ['inclusao', 'edicao']

export interface QueryParameters {
  search?: string
}

export const PriceLists: React.FC = () => {
  const { action } = useParams()
  const [priceLists, setPriceLists] = useState<PriceList[]>([])
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const fetchPriceLists = useCallback(
    (pagination: Pagination<QueryParameters>) => {
      const { page, limit, orderBy, order, query } = pagination
      const { search } = query || {}

      const onSuccess = (result: PriceListListResult) => {
        setPriceLists(result.priceLists)
        setTotalItems(result.total)
      }

      Promise.resolve()
        .then(() => setLoading(true))
        .then(() => listPriceLists({ page, limit, orderBy, order, search }))
        .then(result => onSuccess(result))
        .finally(() => setLoading(false))
    },
    []
  )

  function handleCreateClick() {
    navigate(`${pages.priceLists.path}/inclusao`)
  }

  function handleUpdateClick(data: PriceList) {
    navigate(`${pages.priceLists.path}/edicao?id=${data.id}`)
  }

  const breadcrumbItems = action
    ? [
        { name: 'Tabelas de preços', to: pages.priceLists.path },
        { name: action === 'inclusao' ? 'Inclusão' : 'Edição' }
      ]
    : [{ name: 'Tabelas de preços' }]

  const renderTable = !action
  const renderForm = paths.includes(action || '')
  const renderRedirect = action && !paths.includes(action || '')

  return (
    <RegistrationContainer breadcrumbItems={breadcrumbItems}>
      <PaginationProvider totalItems={totalItems}>
        {renderTable && (
          <PriceListsTable
            priceLists={priceLists}
            totalItems={totalItems}
            loading={loading}
            fetchPriceLists={fetchPriceLists}
            onCreateClick={handleCreateClick}
            onUpdateClick={handleUpdateClick}
          />
        )}

        {renderForm && <PriceListForm />}

        {renderRedirect && <Navigate to={pages.priceLists.path} />}
      </PaginationProvider>
    </RegistrationContainer>
  )
}
