import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import {
  issueBoleto as apiIssueBoleto,
  getBoletoPdf as apiGetBoletoPdf,
  updateBoletoStatus as apiUpdateBoletoStatus,
  cancelBoleto as apiCancelBoleto,
  Boleto
} from '../../../../../services/boleto'
import { Charge } from '../../../../../services/charge'

import { errorHandler } from '../../../utils/errorHandler'
import { OrderFormContext } from '../context'

export function useOrderChargeBoleto() {
  const { setLoading, getOrderState, updateOrderState } =
    useContext(OrderFormContext)
  const [errors, setErrors] = useState<string[]>([])

  function _replaceChargeInOrder(charge: Charge) {
    const order = getOrderState()

    updateOrderState({
      ...order,
      charges: order.charges.map(oldCharge =>
        oldCharge.id === charge.id ? charge : oldCharge
      )
    })
  }

  function _findCharge(chargeId: string) {
    const order = getOrderState()

    return order.charges.find(charge => charge.id === chargeId)
  }

  function issueBoleto(
    chargeId: string,
    data: Pick<Boleto, 'type' | 'dueDate'>,
    onFinally?: () => void
  ) {
    const handleSuccess = (boleto: Boleto) => {
      const charge = _findCharge(chargeId)

      if (!charge) return

      const newCharge = { ...charge, boleto }
      _replaceChargeInOrder(newCharge)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiIssueBoleto(order.id, chargeId, data))
      .then(data => handleSuccess(data))
      .then(() => toast.info('O boleto foi emitido'))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function getBoletoPdf(
    chargeId: string,
    onSuccess: (content?: Buffer) => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiGetBoletoPdf(order.id, chargeId))
      .then(data => onSuccess(data?.content))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
  }

  function refreshStatusBoleto(chargeId: string) {
    const handleSuccess = (boleto: Boleto) => {
      const charge = _findCharge(chargeId)

      if (!charge) return

      const paid = boleto.paidValue >= charge.amount

      const newCharge = {
        ...charge,
        paymentDate: paid ? boleto.statusDate : undefined,
        paid,
        boleto
      }
      _replaceChargeInOrder(newCharge)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiUpdateBoletoStatus(order.id, chargeId))
      .then(data => handleSuccess(data))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
  }

  function cancelBoleto(chargeId: string, onFinally?: () => void) {
    const handleSuccess = () => {
      const charge = _findCharge(chargeId)

      if (!charge) return

      const newCharge = { ...charge, boleto: undefined }
      _replaceChargeInOrder(newCharge)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiCancelBoleto(order.id, chargeId))
      .then(() => handleSuccess())
      .then(() => toast.info('O boleto foi cancelado'))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  return {
    errors,
    issueBoleto,
    getBoletoPdf,
    refreshStatusBoleto,
    cancelBoleto
  }
}
