import React from 'react'

import { PermissionGroupStyle } from './styles'

interface PermissionGroupProps {
  label?: string
  children?: React.ReactNode
}

export const PermissionGroup: React.FC<PermissionGroupProps> = ({
  label,
  children
}) => {
  return (
    <PermissionGroupStyle>
      {label && <span>{label}</span>}
      {children}
    </PermissionGroupStyle>
  )
}
