import { useMemo } from 'react'
import { FieldRenderProps } from 'react-final-form'

interface UseFieldErrorOptions {
  pointing?: 'above' | 'below' | 'left' | 'right'
}

interface FieldError {
  content: string
  pointing?: 'above' | 'below' | 'left' | 'right'
}

export function useFieldError(
  field: FieldRenderProps<any, HTMLElement>,
  options?: UseFieldErrorOptions
): FieldError | undefined {
  return useMemo(() => {
    const { error, submitError, touched } = field.meta
    const { pointing = 'above' } = options || {}

    if ((error || submitError) && touched) {
      return {
        content: error || submitError,
        pointing
      }
    }
  }, [field])
}
