import Joi from 'joi'

import {
  validateDocument,
  validatePhone,
  validateDate,
  listOfEmail
} from '../../../../../../utils/validation'

const personMessages = {
  name: {
    required: 'Nome é obrigatório',
    max: 'Permitido no máximo 100 caracteres'
  },
  document: {
    document: 'Número do documento é inválido'
  },
  tradeName: {
    max: 'Permitido no máximo 100 caracteres'
  },
  emails: {
    email: 'Precisa ser uma lista de endereços de e-mail válidos'
  },
  phone: {
    invalid: 'Não é um número válido'
  },
  birthDate: {
    invalid: 'Data inválida'
  },
  postalCode: {
    regex: 'CEP inválido',
    length: 'Precisar ter 8 caracteres'
  },
  street: {
    max: 'Permitido no máximo 100 caracteres'
  },
  number: {
    regex: 'Precisa ser um valor numérico',
    max: 'Permitido no máximo 10 caracteres'
  },
  complement: {
    max: 'Permitido no máximo 100 caracteres'
  },
  neighborhood: {
    max: 'Permitido no máximo 100 caracteres'
  },
  city: {
    max: 'Permitido no máximo 100 caracteres'
  },
  state: {
    max: 'Permitido no máximo 20 caracteres'
  }
}

const onlyNumbersRegex = /^\d+$/
const cepRegex = /[0-9]{5}-[0-9]{3}|[0-9]{8}/

export const personSchema = Joi.object({
  id: Joi.string().uuid({ version: ['uuidv4'] }),
  type: Joi.string(),
  name: Joi.string().max(100).trim().required().messages({
    'any.required': personMessages.name.required,
    'string.empty': personMessages.name.required,
    'string.max': personMessages.name.max
  }),
  document: Joi.string().custom(validateDocument).optional().empty().messages({
    'string.document': personMessages.document.document
  }),
  tradeName: Joi.string().max(100).trim().optional().allow('').messages({
    'string.max': personMessages.tradeName.max
  }),
  emailsFormatted: Joi.custom(listOfEmail).optional().messages({
    'array.custom.string.email': personMessages.emails.email
  }),
  phone: Joi.string().custom(validatePhone).optional().allow('').messages({
    'string.phone': personMessages.phone.invalid
  }),
  birthDateFormatted: Joi.string().custom(validateDate).optional().messages({
    'string.custom.date': personMessages.birthDate.invalid
  }),
  active: Joi.boolean().optional(),
  postalCode: Joi.string().regex(cepRegex).optional().messages({
    'string.pattern.base': personMessages.postalCode.regex,
    'string.length': personMessages.postalCode.length
  }),
  street: Joi.string().max(100).trim().optional().allow('').messages({
    'string.max': personMessages.street.max
  }),
  number: Joi.string()
    .max(10)
    .regex(onlyNumbersRegex)
    .optional()
    .allow('')
    .messages({
      'string.pattern.base': personMessages.number.regex,
      'string.max': personMessages.number.max
    }),
  complement: Joi.string().max(100).trim().optional().allow('').messages({
    'string.max': personMessages.state.max
  }),
  neighborhood: Joi.string().max(100).trim().optional().allow('').messages({
    'string.max': personMessages.neighborhood.max
  }),
  city: Joi.string().max(100).trim().optional().allow('').messages({
    'string.max': personMessages.city.max
  }),
  ibgeCity: Joi.string().length(7).optional().allow(''),
  state: Joi.string().max(20).trim().optional().allow('').messages({
    'string.max': personMessages.state.max
  }),
  partnerId: Joi.string()
    .uuid({ version: ['uuidv4'] })
    .required()
    .messages({
      'any.required': 'Selecione um parceiro válido'
    }),
  responsibleId: Joi.string().uuid({ version: ['uuidv4'] })
})

const apiKeyMessages = {
  description: {
    max: 'Permitido no máximo 30 caracteres'
  },
  api: {
    required: 'API é obrigatório'
  }
}

export const apiKeyCreateSchema = Joi.object({
  personId: Joi.string().uuid(),
  description: Joi.string().max(30).allow('').messages({
    'string.max': apiKeyMessages.description.max
  }),
  api: Joi.string().valid('UPDATER', 'LICENSING').required().messages({
    'any.required': apiKeyMessages.api.required
  }),
  expiresIn: Joi.string(),
  ips: Joi.string().allow('')
})
