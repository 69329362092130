import React, { useState } from 'react'
import { Button, Table } from 'semantic-ui-react'

import { User } from '../../../../../../../services/users'
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal'
import { useUser } from '../../../hooks'

interface PartnershipsTableRowProps {
  partner: User
}

export const PartnershipsTableRow: React.FC<PartnershipsTableRowProps> = ({
  partner
}) => {
  const { loading, unlinkAPartner } = useUser()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  function handleDeleteButtonClick() {
    setShowDeleteModal(true)
  }

  function handleConfirmDelete() {
    unlinkAPartner(partner.id, () => {
      setShowDeleteModal(false)
    })
  }

  return (
    <Table.Row>
      <Table.Cell content={partner.nickname} />
      <Table.Cell textAlign="right">
        <Button
          floated="right"
          icon="trash"
          onClick={() => handleDeleteButtonClick()}
          circular
          compact
          basic
        />
        <ConfirmationModal
          open={showDeleteModal}
          handleModal={setShowDeleteModal}
          loading={loading}
          onConfirm={handleConfirmDelete}
          onDecline={() => setShowDeleteModal(false)}
        >
          Tem certeza que deseja desassociar este parceiro?
          <br />
          {partner.nickname}
        </ConfirmationModal>
      </Table.Cell>
    </Table.Row>
  )
}
