import { useState } from 'react'

import {
  getPerson as apiGetPerson,
  Person
} from '../../../../../../services/person'

export function usePerson() {
  const [person, setPerson] = useState<Person>()
  const [loading, setLoading] = useState(false)

  function getPerson(id: string) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiGetPerson(id))
      .then(data => setPerson(data))
      .finally(() => setLoading(false))
  }

  return {
    person,
    loading,
    getPerson
  }
}
