import { OrderItem } from '../../../services/orderItem'
import { OrderFormatted, formatOrder } from './formatOrder'
import { OrderItemFormatted } from './formatOrderItem'

export function calculateTotalItem(item: OrderItem): number {
  const { price, quantity, discount, addition } = item

  return price * quantity - discount + addition
}

export function addItemInOrder(
  order: OrderFormatted,
  item: OrderItemFormatted
): OrderFormatted {
  const { price, quantity, discount, addition } = item
  const updated = formatOrder({
    ...order,
    items: [...order.items, item],
    totalOrder: order.totalOrder + (price * quantity - discount + addition),
    totalDiscount: order.totalDiscount + discount,
    totalAddition: order.totalAddition + addition
  })

  return updated
}

export function removeItemFromOrder(
  order: OrderFormatted,
  itemId: string
): OrderFormatted {
  const item = order.items.find(item => item.id === itemId)

  if (!item) return order

  const { price, quantity, discount, addition } = item
  const updated = formatOrder({
    ...order,
    items: order.items.filter(item => item.id !== itemId),
    totalOrder: order.totalOrder - (price * quantity - discount + addition),
    totalDiscount: order.totalDiscount - discount,
    totalAddition: order.totalAddition - addition
  })

  return updated
}
