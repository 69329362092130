import { PriceList } from '../../../services/priceList'
import { PriceListItem } from '../../../services/priceListItem'
import { Product } from '../../../services/product'
import { Service } from '../../../services/service'
import { calculateMarkup } from '../../../utils/markup'

export interface ProductFormatted extends Product {
  changed: boolean
}

export interface ServiceFormatted extends Service {
  changed: boolean
}

export interface PriceListItemFormatted extends PriceListItem {
  markup: number
  changed: boolean
  product?: ProductFormatted
  service?: ServiceFormatted
}

export interface PriceListFormatted extends Omit<PriceList, 'items'> {
  items: PriceListItemFormatted[]
}

export function formatPriceListItem(
  item: PriceListItem
): PriceListItemFormatted {
  const { price, cost } = item
  const product = item.product && { ...item.product, changed: false }
  const service = item.service && { ...item.service, changed: false }

  return {
    ...item,
    markup: calculateMarkup(cost, price),
    changed: false,
    product,
    service
  }
}

export function formatPriceList(priceList: PriceList): PriceListFormatted {
  return {
    ...priceList,
    items: priceList.items.map(formatPriceListItem)
  }
}
