import React, { useState, useEffect } from 'react'
import { Input, Button, Modal } from 'semantic-ui-react'
import { PatternFormat } from 'react-number-format'
import { DateInput as SemanticDateInput } from 'semantic-ui-calendar-react'
import { useForm, useField } from 'react-final-form'
import 'moment/locale/pt-br'

import { ModalContentWrapper } from '../pure/DateInput/styles'

interface DateInputProps {
  name: string
  readOnly?: boolean
  minDate?: string | Date
  maxDate?: string | Date
  disabled?: boolean
  onChange?: (event: { value: string }) => void
  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

export const DateInput: React.FC<DateInputProps> = ({
  name,
  readOnly,
  minDate,
  maxDate,
  disabled,
  onChange,
  onBlur
}) => {
  const form = useForm()
  const field = useField(name)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    form.registerField(name, () => undefined, {})
  }, [])

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    form.change(name, event.target.value)
  }

  function handleInputBlur(event: React.FocusEvent<HTMLInputElement, Element>) {
    onBlur?.(event)
    form.blur(name)
  }

  function handleModalClose() {
    setModal(false)
  }

  function handlePickerChange(
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: { value: string }
  ) {
    onChange?.(data)
    form.change(name, data.value)
    setModal(false)
  }

  return (
    <>
      <Input>
        <PatternFormat
          format="##/##/####"
          value={field.input.value}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          readOnly={readOnly}
          disabled={disabled}
        />
        <Button
          icon="calendar"
          type="button"
          onClick={() => setModal(true)}
          disabled={readOnly || disabled}
          basic
        />
      </Input>
      <Modal open={modal} onClose={handleModalClose} size="tiny" basic>
        <Modal.Content>
          <ModalContentWrapper>
            <SemanticDateInput
              value={field.input.value}
              onChange={handlePickerChange}
              localization="pt-br"
              dateFormat="DD/MM/YYYY"
              minDate={minDate}
              maxDate={maxDate}
              inline
            />
          </ModalContentWrapper>
        </Modal.Content>
      </Modal>
    </>
  )
}
