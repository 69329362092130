import { useState } from 'react'

import {
  Boleto,
  updateBoletoStatus,
  getBoletoPdf as apiGetBoletoPdf
} from '../../../../../services/boleto'

export function useBoleto() {
  const [loading, setLoading] = useState(false)
  const [boletoPdf, setBoletoPdf] = useState<Buffer>()

  function refreshBoleto(
    orderId: string,
    accountId: string,
    onSuccess?: (boleto: Boleto) => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => updateBoletoStatus(orderId, accountId))
      .then(data => onSuccess?.(data))
      .finally(() => setLoading(false))
  }

  function getBoletoPdf(
    orderId: string,
    accountId: string,
    onSuccess?: () => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => apiGetBoletoPdf(orderId, accountId))
      .then(data => setBoletoPdf(data?.content))
      .then(() => onSuccess?.())
      .finally(() => setLoading(false))
  }

  return {
    boletoPdf,
    loading,
    refreshBoleto,
    getBoletoPdf
  }
}
