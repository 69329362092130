import React from 'react'
import { Divider, Message, Segment } from 'semantic-ui-react'
import { useField } from 'react-final-form'

import { useFieldError } from '../../../../../../../../hooks/useFieldError'
import { Show } from '../../../../../../../../components/Show'

import { MainGroup, CommissionGroup, PixGroup } from './components'

export const FormFields: React.FC = () => {
  const field = useField('keyFormatted')
  const error = useFieldError(field)

  return (
    <Segment>
      <Show condition={!!error}>
        <Message content={error?.content} negative />
      </Show>
      <input name="id" hidden />
      <MainGroup />
      <CommissionGroup />
      <Divider content="Pix" horizontal />
      <PixGroup />
    </Segment>
  )
}
