import React from 'react'

import { User } from '../../../../../../../../../services/users'
import { Form, Currency } from '../../../../../../../../../components/Form'

interface ReadOnlyPartnerFieldsProps {
  partner?: User
}

export const ReadOnlyPartnerFields: React.FC<ReadOnlyPartnerFieldsProps> = ({
  partner
}) => {
  return (
    <Form.Group>
      <Form.Input
        label="Parceiro"
        value={partner?.nickname}
        width={13}
        readOnly
      />
      <Currency name="partnerCommission" label="Comissão" width={3} readOnly />
    </Form.Group>
  )
}
