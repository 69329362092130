import api from './api'
import { PriceListItem } from './priceListItem'

export interface SignatureItem {
  id: string
  signatureId: string
  priceListItemId: string
  description: string
  quantity: number
  discount: number
  price: number
  priceListItem: PriceListItem
}

export interface SignatureItemCreate {
  priceListItemId: string
  quantity: number
  discount?: number
}

export interface SignatureItemUpdate {
  description?: string
  quantity?: number
  discount?: number
}

export async function addSignatureItem(
  signatureId: string,
  data: SignatureItemCreate
): Promise<SignatureItem> {
  const response = await api.post(`/signatures/${signatureId}/items`, data)
  const item = response.data

  return item
}

export async function changeSignatureItem(
  signatureId: string,
  itemId: string,
  data: SignatureItemUpdate
): Promise<SignatureItem> {
  const response = await api.put(
    `/signatures/${signatureId}/items/${itemId}`,
    data
  )
  const item = response.data

  return item
}

export async function removeSignatureItem(
  signatureId: string,
  itemId: string
): Promise<void> {
  await api.delete(`/signatures/${signatureId}/items/${itemId}`)
}
