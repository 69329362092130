import React, { useState } from 'react'

import { GetProofOutput, Pix } from '../../../../../../../../../../services/pix'
import { Show } from '../../../../../../../../../../components/Show'
import { Button } from '../../../../../../../../../../components/Button'
import { AddPixProofModal } from '../../../../../../../../../../components/PixProof'
import { PDFViewerModal } from '../../../../../../../../../../components/PDFViewerModal'
import { ImageViewerModal } from '../../../../../../../../../../components/ImageViewerModal'
import { can } from '../../../../../../../../../../config/permissions'

import {
  ChargeFormatted,
  formatCharge
} from '../../../../../../../../utils/formatCharge'
import { useOrder, useOrderChargePix } from '../../../../../../hooks'
import { ChargeConfirmationModal } from '../components/ChargeConfirmationModal'

interface PixActionsProps {
  charge: ChargeFormatted
}

export const PixActions: React.FC<PixActionsProps> = ({ charge }) => {
  const { order, loading } = useOrder()
  const { issuePix, getProof, cancelPix, replaceChargeInOrder } =
    useOrderChargePix()
  const [showIssueModal, setShowIssueModal] = useState(false)
  const [showAddProofModal, setShowAddProofModal] = useState(false)
  const [showPdfProofModal, setShowPdfProofModal] = useState(false)
  const [showImageProofModal, setShowImageProofModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [proofFile, setProofFile] = useState<GetProofOutput>()

  function handleIssueButtonClick() {
    setShowIssueModal(true)
  }

  function handleAddProofButtonClick() {
    setShowAddProofModal(true)
  }

  function handleVisualizeProofButtonClick() {
    getProof(charge.id, data => {
      setProofFile(data)

      const { mimetype } = data

      if (mimetype === 'application/pdf') {
        setShowPdfProofModal(true)
      }

      if (mimetype !== 'application/pdf') {
        setShowImageProofModal(true)
      }
    })
  }

  function handleCancelButtonClick() {
    setShowCancelModal(true)
  }

  function handleConfirmIssue() {
    const { dueDate } = charge
    issuePix(charge.id, { dueDate }, () => {
      setShowIssueModal(false)
    })
  }

  function handleConfirmCancel() {
    cancelPix(charge.id, () => {
      setShowCancelModal(false)
    })
  }

  function setChargeAsPaid(pix: Pix) {
    const paidCharge = formatCharge({
      ...charge,
      paymentDate: new Date().toISOString(),
      amountPaid: charge.amount,
      pix
    })
    replaceChargeInOrder(paidCharge)
  }

  const { pix } = charge
  const show = order?.status !== 'OPEN' && charge.paymentType === 'PIX'
  const pixExist = !!pix
  const pixHasProf = !!pix?.proof

  return (
    <Show condition={show}>
      <Button
        icon="arrow up"
        size="mini"
        disabled={pixExist}
        onClick={handleIssueButtonClick}
        can={can.pix.issue}
        popup="Emitir cobrança"
        invertedPopup
        circular
      />
      <ChargeConfirmationModal
        open={showIssueModal}
        charge={charge}
        title="Emitir cobrança"
        question="Confirmar emissão da cobrança"
        loading={loading}
        handleModal={setShowIssueModal}
        onConfirm={handleConfirmIssue}
      />
      <Button
        icon="upload"
        size="mini"
        disabled={!pixExist}
        onClick={handleAddProofButtonClick}
        can={can.pix.addProof}
        popup="Enviar comprovante"
        invertedPopup
        circular
      />
      <AddPixProofModal
        open={showAddProofModal}
        charge={charge}
        handleModal={setShowAddProofModal}
        onAdd={setChargeAsPaid}
      />
      <Button
        icon="file alternate"
        size="mini"
        disabled={!pixHasProf}
        onClick={handleVisualizeProofButtonClick}
        can={can.pix.getProof}
        popup="Visualizar comprovante"
        invertedPopup
        circular
      />
      <PDFViewerModal
        open={showPdfProofModal}
        handleModal={setShowPdfProofModal}
        title="Comprovante de pagamento"
        pdf={proofFile?.content}
      />
      <ImageViewerModal
        open={showImageProofModal}
        handleModal={setShowImageProofModal}
        title="Comprovante de pagamento"
        image={proofFile?.content}
        mimetype={proofFile?.mimetype}
      />
      <Button
        icon="ban"
        size="mini"
        disabled={pixHasProf}
        onClick={handleCancelButtonClick}
        can={can.pix.cancel}
        popup="Cancelar cobrança"
        invertedPopup
        circular
      />
      <ChargeConfirmationModal
        open={showCancelModal}
        charge={charge}
        title="Cancelar cobrança"
        question="Confirmar cancelamento da cobrança"
        loading={loading}
        handleModal={setShowCancelModal}
        onConfirm={handleConfirmCancel}
      />
    </Show>
  )
}
