import React, { useState } from 'react'
import { Button } from 'semantic-ui-react'

import { useCan } from '../../../../../../../../hooks/useCan'
import { can } from '../../../../../../../../config/permissions'
import { Show } from '../../../../../../../../components/Show'

import { useOrder } from '../../../../hooks'
import { HandleOrderConfirmationModal } from '../HandleOrderConfirmationModal'

export const CloseOrderAction: React.FC = () => {
  const { order, loading, closeOrder } = useOrder()
  const [showCloseModal, setShowCloseModal] = useState(false)
  const canCloseOrder = useCan(can.order.close)

  const orderExist = !!order
  const orderIsOpen = orderExist && order?.status === 'OPEN'

  function handleCloseButtonClick() {
    setShowCloseModal(true)
  }

  function handleConfirmToClose() {
    closeOrder(() => setShowCloseModal(false))
  }

  return (
    <Show
      condition={orderIsOpen && canCloseOrder}
      component={
        <>
          <Button
            type="button"
            content="Fechar pedido"
            floated="right"
            onClick={handleCloseButtonClick}
          />
          <HandleOrderConfirmationModal
            open={showCloseModal}
            order={order}
            loading={loading}
            title="Fechar pedido"
            question="Você deseja fechar este pedido?"
            handleModal={setShowCloseModal}
            onConfirm={handleConfirmToClose}
            onDecline={() => setShowCloseModal(false)}
          />
        </>
      }
    />
  )
}
