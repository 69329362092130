import React from 'react'
import { Table } from 'semantic-ui-react'

import { TableHeaderCell } from '../../../../../components/Table'
import { Pagination } from '../../../../../contexts/PaginationContext'

interface OrdersTableHeaderProps {
  pagination: Pagination
  changeOrderBy: (field: string) => void
}

export const OrdersTableHeader: React.FC<OrdersTableHeaderProps> = ({
  pagination,
  changeOrderBy
}) => {
  return (
    <Table.Header>
      <Table.Row>
        <TableHeaderCell
          content="Código"
          name="code"
          pagination={pagination}
          onClick={() => changeOrderBy('code')}
        />
        <TableHeaderCell
          content="Cliente"
          name="customer.name"
          pagination={pagination}
          onClick={() => changeOrderBy('customer.name')}
        />
        <TableHeaderCell
          content="Data de emissão"
          name="issueDate"
          pagination={pagination}
          onClick={() => changeOrderBy('issueDate')}
        />
        <Table.HeaderCell content="Total" />
        <Table.HeaderCell content="NFS-e" />
        <Table.HeaderCell content="Pago" />
        <TableHeaderCell
          content="Status"
          name="status"
          pagination={pagination}
          onClick={() => changeOrderBy('status')}
        />
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
  )
}
