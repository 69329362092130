import { Person } from '../../../services/person'
import { dateToLocaleDate, localeDateToDate } from '../../../utils/date'
import { masks } from '../../../utils/mask'
import { onlyNumerics, sanitizePhone } from '../../../utils/sanitize'

export function sanitizeExternalPhone(value: string): string {
  return value
    .split('/')
    .map(v => v.trim())[0]
    .replace(/\D/g, '')
}

export function formatDocument(person: Person): string {
  if (!person.document) return ''

  return person.type === 'COMPANY'
    ? masks.cnpj(person.document)
    : masks.cpf(person.document)
}

export interface PersonFormatted extends Person {
  documentFormatted?: string
  phoneFormatted?: string
  emailsFormatted?: string
  birthDateFormatted?: string
  firstEmail?: string
}

export function formatPerson(person: Person): PersonFormatted {
  return {
    ...person,
    documentFormatted: person.document && formatDocument(person),
    phoneFormatted: person.phone && masks.phone(person.phone),
    emailsFormatted: person.emails && person.emails.join(';'),
    birthDateFormatted: person.birthDate && dateToLocaleDate(person.birthDate),
    firstEmail: person.emails && person.emails[0]
  }
}

export function personToExternal(
  person: Partial<PersonFormatted>
): Partial<Person> {
  return {
    type: person.type,
    document:
      person.documentFormatted && onlyNumerics(person.documentFormatted),
    emails: person.emailsFormatted
      ? person.emailsFormatted.split(';').map(email => email.trim())
      : undefined,
    phone: person.phoneFormatted && sanitizePhone(person.phoneFormatted),
    birthDate: person.birthDateFormatted
      ? localeDateToDate(person.birthDateFormatted).toISOString()
      : undefined,
    name: person.name,
    tradeName: person.tradeName,
    postalCode: person.postalCode && onlyNumerics(person.postalCode),
    street: person.street,
    number: person.number,
    complement: person.complement,
    neighborhood: person.neighborhood,
    city: person.city,
    ibgeCity: person.ibgeCity,
    state: person.state,
    active: person.active,
    partnerId: person.partnerId,
    responsibleId: person.responsibleId
  }
}
