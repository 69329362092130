import React from 'react'
import { Card, Button } from 'semantic-ui-react'
import { Form as FinalForm } from 'react-final-form'

import { Form, Input } from '../../components/Form'
import { useLogin } from '../../hooks/useLogin'
import { Logo } from '../../components/Logo'

import { Wrapper } from './styles'

export const Login: React.FC = () => {
  const { loading, handleSubmit } = useLogin()

  return (
    <Wrapper>
      <Card>
        <Card.Content>
          <Logo />
          <FinalForm onSubmit={handleSubmit}>
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Input
                  type="email"
                  name="email"
                  label="E-mail"
                  disabled={loading}
                  required
                />
                <Input
                  type="password"
                  name="password"
                  label="Senha"
                  disabled={loading}
                  autoComplete="off"
                  required
                />
                <Button
                  type="submit"
                  content="Acessar"
                  loading={loading}
                  primary
                  fluid
                />
              </Form>
            )}
          </FinalForm>
        </Card.Content>
      </Card>
    </Wrapper>
  )
}
