import { SelectOption } from '../../components/Form/Select'
import { Certificate } from '../../services/certificate'
import { Person } from '../../services/person'
import { Service } from '../../services/service'
import { dateToLocaleDateTime } from '../../utils/date'
import { getFormValidate } from '../../utils/form'
import * as mask from '../../utils/mask'

import { certificateSchema } from './schemas'

export interface CertificateFormatted extends Certificate {
  issueDateFormatted: string
  expirationDateFormatted: string
  customerPhoneFormatted?: string
  warning: boolean
  expired: boolean
}

export function formatCertificate(
  certificate: Certificate
): CertificateFormatted {
  const issueDate = new Date(certificate.issueDate)
  const issueDateFormatted = dateToLocaleDateTime(issueDate)
  const expirationDate = new Date(certificate.expirationDate)
  const expirationDateFormatted = dateToLocaleDateTime(expirationDate)
  const customerPhone = certificate.customer?.phone
  const customerPhoneFormatted = customerPhone && mask.phone(customerPhone)
  const THIRTY_DAYS = 1000 * 60 * 60 * 24 * 30
  const now = Date.now()
  const expired = expirationDate.getTime() <= now
  const warning = expirationDate.getTime() <= now + THIRTY_DAYS && !expired

  return {
    ...certificate,
    issueDateFormatted,
    expirationDateFormatted,
    customerPhoneFormatted,
    warning,
    expired
  }
}

export const initialValues = {
  issueDate: dateToLocaleDateTime(new Date()),
  expirationDate: dateToLocaleDateTime(new Date())
}

export const validateForm = getFormValidate(certificateSchema)

export const DEBOUNCE_TIME = 500

export function serviceToOption(service: Service): SelectOption {
  return {
    key: service.id,
    value: service.id,
    text: `${service.description}`
  }
}

export function personToOptions(person: Person): SelectOption {
  return {
    key: person.id,
    value: person.id,
    text: `${person.name}`
  }
}
