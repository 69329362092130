import { Signature } from '../../../services/signature'
import { dateToLocaleDate, differenceInDays } from '../../../utils/date'
import { numberToCurrency } from '../../../utils/number'

import {
  formatSignatureItem,
  SignatureItemFormatted
} from './formatSignatureItem'

export interface SignatureFormatted extends Signature {
  recurrenceFormatted: string
  dueDateFormatted: string
  valueFormatted: string
  totalOfItems: number
  totalOfItemsFormatted: string
  totalOfDiscounts: number
  totalOfDiscountsFormatted: string
  openToInvoice: boolean
  items: SignatureItemFormatted[]
}

const RECURRENCE_LABEL = {
  MONTHLY: 'Mensal',
  QUARTERLY: 'Trimestral',
  SEMI_ANNUAL: 'Semestral',
  ANNUAL: 'Anual'
}

export function formatSignature(signature: Signature): SignatureFormatted {
  const totalOfItems = signature.items
    .map(item => item.price * item.quantity)
    .reduce((acc, current) => acc + current, 0)
  const totalOfItemsFormatted = numberToCurrency(totalOfItems)
  const totalOfDiscounts = signature.items
    .map(item => item.discount)
    .reduce((acc, current) => acc + current, 0)
  const totalOfDiscountsFormatted = numberToCurrency(totalOfDiscounts)
  const value = totalOfItems - totalOfDiscounts
  const openToInvoice =
    differenceInDays(new Date(signature.dueDate), new Date()) <= 30
  const formatted = {
    ...signature,
    recurrenceFormatted: RECURRENCE_LABEL[signature.recurrence],
    dueDateFormatted: dateToLocaleDate(signature.dueDate),
    value,
    valueFormatted: numberToCurrency(value),
    totalOfItems,
    totalOfItemsFormatted,
    totalOfDiscounts,
    totalOfDiscountsFormatted,
    openToInvoice,
    items: signature.items.map(formatSignatureItem)
  }
  return formatted
}
