import { Order, getOrder } from '../../../../../../services/order'

export function useFetchOrder() {
  async function fetchOrder(id: string): Promise<Order | undefined> {
    try {
      const order = await getOrder(id)
      return order
    } catch (err: any) {
      const status = err.response?.status

      if (status === 404) return

      throw err
    }
  }

  return fetchOrder
}
