import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import {
  issuePix as apiIssuePix,
  getProof as apiGetProof,
  cancelPix as apiCancelPix,
  Pix,
  GetProofOutput
} from '../../../../../services/pix'
import { Charge } from '../../../../../services/charge'

import { errorHandler } from '../../../utils/errorHandler'
import { OrderFormContext } from '../context'

export function useOrderChargePix() {
  const { setLoading, getOrderState, updateOrderState } =
    useContext(OrderFormContext)
  const [errors, setErrors] = useState<string[]>([])

  function replaceChargeInOrder(charge: Charge) {
    const order = getOrderState()

    updateOrderState({
      ...order,
      charges: order.charges.map(oldCharge =>
        oldCharge.id === charge.id ? charge : oldCharge
      )
    })
  }

  function _findCharge(chargeId: string) {
    const order = getOrderState()

    return order.charges.find(charge => charge.id === chargeId)
  }

  function issuePix(
    chargeId: string,
    data: Pick<Pix, 'dueDate'>,
    onFinally?: () => void
  ) {
    const handleSuccess = (pix: Pix) => {
      const charge = _findCharge(chargeId)

      if (!charge) return

      const newCharge = { ...charge, pix }
      replaceChargeInOrder(newCharge)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiIssuePix(order.id, chargeId, data))
      .then(data => handleSuccess(data))
      .then(() => toast.info('A cobrança foi emitida'))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  function getProof(
    chargeId: string,
    onSuccess: (data: GetProofOutput) => void
  ) {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiGetProof(order.id, chargeId))
      .then(data => onSuccess(data))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
  }

  function cancelPix(chargeId: string, onFinally?: () => void) {
    const handleSuccess = () => {
      const charge = _findCharge(chargeId)

      if (!charge) return

      const newCharge = { ...charge, pix: undefined }
      replaceChargeInOrder(newCharge)
    }

    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getOrderState())
      .then(order => apiCancelPix(order.id, chargeId))
      .then(() => handleSuccess())
      .then(() => toast.info('A cobrança foi cancelada'))
      .catch(err => setErrors(errorHandler(err)))
      .finally(() => setLoading(false))
      .finally(() => onFinally?.())
  }

  return {
    errors,
    issuePix,
    getProof,
    cancelPix,
    replaceChargeInOrder
  }
}
