import React from 'react'
import { Table } from 'semantic-ui-react'

import { TableHeaderCell } from '../../../../../components/Table'
import { Pagination } from '../../../../../contexts/PaginationContext'

interface ServicesTableHeaderProps {
  pagination: Pagination
  changeOrderBy: (field: string) => void
}

export const ServicesTableHeader: React.FC<ServicesTableHeaderProps> = ({
  pagination,
  changeOrderBy
}) => {
  return (
    <Table.Header>
      <Table.Row>
        <TableHeaderCell
          content="Código"
          name="code"
          pagination={pagination}
          onClick={() => changeOrderBy('code')}
        />
        <TableHeaderCell
          content="Descrição"
          name="description"
          pagination={pagination}
          onClick={() => changeOrderBy('description')}
        />
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
  )
}
