import api from './api'

import { File } from './file'

export interface Directory {
  id: string
  name: string
  path: string
  fatherId: string
  systemId: string
}

export interface DirectoryCreate {
  name: string
  fatherId: string
}

export interface DirectoryContent extends Directory {
  directories: Directory[]
  files: File[]
}

export async function getDirectory(
  id: string,
  systemId: string
): Promise<DirectoryContent> {
  const response = await api.get(`/systems/${systemId}/directories/${id}`)
  const directory = response.data

  return directory
}

export async function createDirectory(
  systemId: string,
  data: DirectoryCreate
): Promise<Directory> {
  const response = await api.post(`/systems/${systemId}/directories`, data)
  const directory = response.data

  return directory
}

export async function deleteDirectory(
  id: string,
  systemId: string
): Promise<boolean> {
  const response = await api.delete(`/systems/${systemId}/directories/${id}`)
  const result = response.data

  return result.status
}
