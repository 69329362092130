import React from 'react'
import { Link } from 'react-router-dom'
import { Form as FinalForm } from 'react-final-form'
import { Button, Segment } from 'semantic-ui-react'

import { Form, Input } from '../../../../../../components/Form'
import { getDiff, getFormValidate } from '../../../../../../utils/form'
import { Group } from '../../../../../../services/group'
import { pages } from '../../../../../../config/pages'

import { groupSchema } from '../../utils/schema'
import { useGroup } from '../../hooks'

const validateForm = getFormValidate(groupSchema)

export const GroupMain: React.FC = () => {
  const { group, createGroup, updateGroup } = useGroup()

  function handleSubmit(values: Group) {
    if (!group) return createGroup(values)

    const diff = getDiff(group, values)

    if (!diff) return

    updateGroup(diff)
  }

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={{ ...group }}
      validate={validateForm}
      validateOnBlur
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Button
            type="button"
            content="Voltar"
            as={Link}
            to={pages.groups.path}
          />
          <Button type="submit" content="Salvar" floated="right" primary />
          <Segment>
            <Input name="name" label="Nome" required uppercase />
          </Segment>
        </Form>
      )}
    </FinalForm>
  )
}
