import React from 'react'
import { Button } from 'semantic-ui-react'

import { useCan } from '../../../../../hooks/useCan'
import { can } from '../../../../../config/permissions'

import { ActionsContainer } from './styles'

interface SignaturesTableActionsProps {
  onCreateButtonClick: () => void
}

export const SignaturesTableActions: React.FC<SignaturesTableActionsProps> = ({
  onCreateButtonClick
}) => {
  const canCreate = useCan(can.signature.create)

  return (
    <ActionsContainer>
      {canCreate ? (
        <Button
          content="Incluir"
          onClick={onCreateButtonClick}
          floated="right"
          primary
        />
      ) : null}
    </ActionsContainer>
  )
}
