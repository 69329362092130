import api from './api'
import { Order } from './order'

export async function requestCertificate(orderId: string): Promise<Order> {
  const response = await api.post(`/orders/${orderId}/ac/request-certificate`)
  const order = response.data

  return order
}

export async function renewCertificate(orderId: string): Promise<Order> {
  const response = await api.post(`/orders/${orderId}/ac/renew-certificate`)
  const order = response.data

  return order
}

export async function deleteRequest(orderId: string): Promise<Order> {
  const response = await api.delete(`/orders/${orderId}/ac/request-certificate`)
  const order = response.data

  return order
}

export async function checkVideoconferencingAvailability(
  orderId: string
): Promise<boolean> {
  const response = await api.get(
    `/orders/${orderId}/ac/videoconferencing-availability`
  )
  const { availability } = response.data

  return availability
}
